import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CareDocType } from "../../types/care_types";

interface InitialStateType {
  care_docs: any;
  schedule_data:any;
  activeCareProfile:CareDocType|null
  care_plans:any[]
}

const initialState: InitialStateType = {
  care_docs: [],
  schedule_data:[],
  activeCareProfile:null,
  care_plans:[]
};

export const CareSlice = createSlice({
  name: "care_data",
  initialState,
  reducers: {
    loadCareDocs: (state, action: PayloadAction<any>) => {
      state.care_docs = action.payload;
    },
    loadSchedule: (state, action: PayloadAction<any>) => {
      state.schedule_data = action.payload;
    },
    selectCareProfile: (state, action: PayloadAction<any>) => {
      state.activeCareProfile = action.payload;
    },
    loadCarePlans: (state, action: PayloadAction<any>) => {
      state.care_plans = action.payload;
    },
  },
});

export const { loadCareDocs,loadSchedule ,selectCareProfile,loadCarePlans} = CareSlice.actions;

export default CareSlice.reducer;
