import React, { useMemo, useState } from "react";
import MiniReport from "./mini_report";
import EmployeesList from "./employees_list";
import { TbFilterSearch } from "react-icons/tb";
import { AnimatePresence, motion } from "framer-motion";
import Filters from "./filters";
import InviteEmployee from "./invite_new";
import withPermission from "../../../components/auth/withPermission";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { RiAddCircleFill } from "react-icons/ri";
import { circleVariants } from "../../../utils/reusable_objects";
import { toPureString } from "../../../utils/reusable_functions";
import {
  ContractsType,
  EmployeeType,
  TrainingDataType,
} from "../../../types/employee";
import { Button } from "@/components/ui/button";

type FiltersType = {
  client_id: string;
  "position applied for": string;
  employee: string;
  status: string;
};

const Employees = withPermission(() => {
  const [filtersData, setFilters] = useState<FiltersType>({
    client_id: "",
    "position applied for": "",
    employee: "",
    status: "",
  });
  const [filters_open, openFilters] = useState<boolean>(false);
  const [formdata, setFormData] = useState<any>(null);
  const [activeFilter, setActiveFilter] = useState<string>("");
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const employees_docs = useSelector(
    (state: RootState) => state.people_data.employees_docs
  );
  const [search, setSearch] = useState<string>("");

  //Filtering Employees
  const filteredUsers = useMemo(() => {
    const localFilterObject: any = {
      id: filtersData?.employee,
      status: filtersData?.status,
      "position applied for": filtersData?.["position applied for"],
    };
    return [...(employees_docs || [])]
      ?.filter(
        (data: EmployeeType) =>
          toPureString(JSON.stringify(data))?.includes(toPureString(search)) &&
          Object?.keys(localFilterObject).every((key: string) =>
            (data?.[key] || "")?.includes(localFilterObject?.[key])
          ) &&
          (!activeFilter
            ? true
            : activeFilter === "out. trainings"
            ? data?.training?.some(
                (trn: TrainingDataType) => trn?.status === "pending"
              )
            : activeFilter === "out. dbs check"
            ? data?.dbs?.status !== "approved"
            : activeFilter === "out. refefrences"
            ? data?.references_data?.some(
                (ref: any) => ref?.status === "pending"
              )
            : activeFilter === "pending applications"
            ? data?.status === "pending"
            : activeFilter === "active contracts"
            ? data?.contracts?.some(
                (con: ContractsType) =>
                  con.status === "active" &&
                  con?.end_date > Date.now() + 5259492000
              )
            : data?.contracts?.some(
                (con: ContractsType) =>
                  con.status === "active" &&
                  con?.end_date < Date.now() + 5259492000
              ))
      )
      ?.sort((a: any, b: any) =>
        a?.["first name"]?.localeCompare(b?.["first name"])
      )
      ?.slice(0, 100);
  }, [search, filtersData, employees_docs, activeFilter]);

  //Component ==============
  return (
    <React.Fragment>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={circleVariants}
        className="h-fit w-full overflow-hidden shrink-0 flex flex-col items-center gap-4"
      >
        {/**Date Picker and Greetings */}
        <div className="w-full h-fit shrink-0 flex items-center justify-between gap-4">
          <Button
            onClick={() => openFilters(true)}
            variant={"outline"}
            className="h-9 w-28 text-lg hover:text-sky-500 text-zinc-600 bg-white shrink-0 gap-2"
          >
            <TbFilterSearch className="shrink-0" />{" "}
            <span className="text-xxs font-bold uppercase">fitlers</span>
          </Button>
          <div className="w-fit h-fit flex items-center gap-2">
            {session_role?.permissions?.["can the user manage employees"] && (
              <Button
                onClick={() => {
                  setFormData({});
                }}
                className="h-9 w-28 text-xl shrink-0 group"
              >
                <RiAddCircleFill className="group-hover:-rotate-90 transition-all duration-300 shrink-0" />
                <span className="uppercase font-bold text-xxs">
                  Add New
                </span>
              </Button>
            )}
          </div>
        </div>

        {/**Summary Report */}
        <MiniReport
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          filteredUsers={filteredUsers}
        />

        {/**Menu Option */}
        <EmployeesList
          search={search}
          setSearch={setSearch}
          filteredUsers={filteredUsers}
        />
      </motion.div>

      {/**Filters */}
      <AnimatePresence>
        {filters_open && (
          <Filters
            filtersData={filtersData}
            setFilters={setFilters}
            openFilters={openFilters}
          />
        )}
      </AnimatePresence>

      {/**New Invite */}
      <AnimatePresence>
        {formdata &&
          session_role?.permissions?.["can the user manage employees"] && (
            <InviteEmployee formdata={formdata} setFormData={setFormData} />
          )}
      </AnimatePresence>
    </React.Fragment>
  );
});

export default React.memo(Employees);
