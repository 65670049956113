import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NoteType } from "../../types/care_types";
import { retrieveLocalData } from "../../utils/reusable_functions";

interface InitialStateType {
  activeNote: NoteType | null;
  newNote: NoteType | null;
}

const initialState: InitialStateType = {
  activeNote: null,
  newNote: retrieveLocalData("new_note") || null,
};

export const NotesSlice = createSlice({
  name: "notes_data",
  initialState,
  reducers: {
    setActiveNote: (state, action: PayloadAction<NoteType | null>) => {
      state.activeNote = action.payload;
    },
    setNewNote: (state, action: PayloadAction<NoteType | null>) => {
      state.newNote = action.payload;
      localStorage.setItem("new_note", JSON.stringify(action.payload));
    },
  },
});

export const { setActiveNote, setNewNote } = NotesSlice.actions;

export default NotesSlice.reducer;
