import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

type Props = {
  closeBanner: React.Dispatch<React.SetStateAction<null>>;
};

const CokkieBanner = ({ closeBanner }: Props) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      exit={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="fixed inset-0 z-[999999999] pointer-events-none hidden md:flex
       items-end justify-start p-6 md:p-3 overflow-hidden overflow-y-auto no-scrollbar"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          closeBanner(null);
          localStorage.setItem("ckTL", JSON.stringify("yes"));
          window.location.reload();
        }}
        className="w-full md:w-[20rem] h-fit rounded-xl md:rounded border border-zinc-200 bg-white p-6 flex
         flex-col justify-center pointer-events-auto shadow-3xl"
      >
        <div className="flex items-center gap-2 w-full overflow-hidden">
          <img
            src="/images/cookie.png"
            alt="cookie"
            className="h-12 w-12 object-cover object-center shrink-0"
          />
          <h1 className="text-2xl font-extrabold text-zinc-800 text-center shrink-0">
            We use cookies
          </h1>
        </div>
        <p className="text-sm font-medium text-zinc-600 mt-4 shrink-0 text-left">
          We care about your privacy and want you to be informed about how we
          collect and use your data. By using our platform, you agree to the
          collection and use of information as described in this notice.{" "}
          <Link
            target="_blank"
            to="https://3angelscomplexcare.com/index.php/privacy-policy/"
            className="underline"
          >
            Terms of use policy
          </Link>
          {/* <br />
          <br /> */}
          {/* Our data collection practices involve the use of Google Analytics,
          which anonymously gathers data on user interactions to enhance the
          platform and personalize user experiences. Additionally, when you
          create an account or utilize specific features, we may collect
          information to analyze trends, improve functionality, and provide
          relevant content. */}
          {/* <br />
          <br />
          Should you have any questions or concerns regarding our data practices
          or this privacy notice, please {"don't"} hesitate to contact us at{" "}
          &nbsp; */}
        </p>
        <button
          type="submit"
          className="mt-6 h-9 w-36 rounded bg-sky-500 hover:opacity-80 transition-all
           text-xs text-white font-semibold"
        >
          Ok, understood
        </button>
      </form>
    </motion.div>
  );
};

export default CokkieBanner;
