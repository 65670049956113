import React from "react";

import {
  Users,
  CalendarFold,
  Clock10,
  HandCoins,
  WalletCards,
} from "lucide-react";
import SubMenuTemplate from "../sub-menus-template/sub-menu-template";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

const PeoplesLayout = () => {
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const options = [
    {
      name: "employees",
      path: "/people",
      description: "Manage your employees",
      icon: <Users className="regular_icon" />,
    },
    {
      name: "leave",
      path: "/people/leave",
      description: "Manage leave requests",
      icon: <CalendarFold className="regular_icon" />,
    },
    {
      name: "timesheet",
      path: "/people/timesheet",
      description: "Manage timesheets",
      icon: <Clock10 className="regular_icon" />,
    },
    {
      name: "payroll",
      path: "/people/payroll",
      description: "Manage payroll",
      icon: <HandCoins className="regular_icon" />,
    },
    {
      name: "expenses",
      path: "/people/expenses",
      description: "Manage expenses",
      icon: <WalletCards className="regular_icon" />,
    },
  ].filter(
    (tab: any) =>
      !(
        !session_role?.permissions?.["can the user view employees"] &&
        tab.name === "employees"
      ) &&
      !(
        !session_role?.permissions?.["can the user view employees leave"] &&
        tab.name === "leave"
      ) &&
      !(
        !session_role?.permissions?.["can the user view timesheet"] &&
        tab.name === "timesheet"
      ) &&
      !(
        !session_role?.permissions?.["can the user view payroll"] &&
        tab.name === "payroll"
      )
  );

  //JSX Code ========================
  return (
    <React.Fragment>
      <SubMenuTemplate options={options} />
    </React.Fragment>
  );
};

export default PeoplesLayout;
