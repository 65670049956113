import { useAuth } from "../../hooks/useAuth";
import UserData from "./userdata";
import ListsData from "./lists_data";
import FormsData from "./forms_data";
import AssessmentsData from "./assessmentsdata";
import CareData from "./care_data";
import InventoryData from "./inventory_data";
import PeopleData from "./people_data";
import LeaveData from "./leaves_data";
import React from "react";
import PerformanceData from "./performance_data";
import RequestsData from "./requests-data";
import ScheduleData from "./schedule-data";
import RoutesData from "./routes-data";

// collection ref
//const usersRef: any = org && collection(db, `companies/${org}/users`);

const Fetcher = () => {
  const { currentUser }: any = useAuth();

  //Component
  return (
    <>
      {currentUser && (
        <>
          <UserData />
          <ListsData />
          <PeopleData />
          <PerformanceData />
          <FormsData />
          <AssessmentsData />
          <CareData />
          <InventoryData />
          <LeaveData />
          <RequestsData />
          <RoutesData />
          <ScheduleData />
        </>
      )}
    </>
  );
};

export default React.memo(Fetcher);
