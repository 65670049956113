import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { Provider } from "react-redux";
import { store } from "./redux/store.ts";
import { Toaster } from "sonner";
import { ModalsProvider } from "@mantine/modals";
import { MantineProvider, createTheme } from "@mantine/core";
import "./index.css";
import '@mantine/tiptap/styles.css';
import "@mantine/core/styles.css";
import { Modal2Provider } from "./lib/modal/modal2provider.jsx";
import { registerSW } from "virtual:pwa-register";
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
};

const updateSW = registerSW({
  onNeedRefresh() {
    // show a prompt to user to refresh for update
    if (confirm("An update is available. Do you want to refresh now?")) {
      updateSW();
    }
  },
  onOfflineReady() {
    // show a notification that the app is ready to work offline
    console.log("App is ready to work offline");
  },
});

const theme = createTheme({
  primaryColor: "sky",
  colors: {
    sky: [
      "#f0f9ff",
      "#e0f2fe",
      "#bae6fd",
      "#7dd3fc",
      "#38bdf8",
      "#0ea5e9",
      "#0284c7",
      "#0369a1",
      "#075985",
      "#0c4a6e",
    ],
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_KEY}
      options={options}
    >
      <Provider store={store}>
        <Toaster richColors closeButton position="top-center" />
        <Modal2Provider>
          <MantineProvider theme={theme}>
            <ModalsProvider>
              <App />
            </ModalsProvider>
          </MantineProvider>
        </Modal2Provider>
      </Provider>
    </PostHogProvider>
  </React.StrictMode>
);
