import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { BellIcon } from "lucide-react";

const Notifications = React.memo(() => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant={"outline"}
          className="h-8 w-8 drop-shadow-sm shrink-0 border-zinc-300 p-0 text-sm text-zinc-400 bg-zinc-50"
        >
          <BellIcon size={18} fill="currentColor" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-2 w-72 h-96"></DropdownMenuContent>
    </DropdownMenu>
  );
});

export default Notifications;
