import { AnimatePresence, motion } from "framer-motion";
import React, { useMemo, useState } from "react";
import { CareDocType, TaskType } from "../../../types/care_types";
import { useNavigate } from "react-router-dom";
import withPermission from "../../../components/auth/withPermission";
import {
  dateTimeFormattedString,
  dateToLondonMilliseconds,
  retrieveLocalData,
} from "../../../utils/reusable_functions";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import useFirestoreCollection from "../../../hooks/useFirestoreCollection";
import { where } from "firebase/firestore";
import DatePicker from "../../../components/date picker/DatePicker";
import Loader from "../../../components/misc/loader";
import { TbChevronLeft } from "react-icons/tb";

const TaskLogs = withPermission(() => {
  const navigate = useNavigate();
  const [date, setDate] = useState<any>(() => {
    // Create a new Date object for the current date
    const now = new Date();
    // Reset hours, minutes, seconds, and milliseconds to get the start of the day
    now.setHours(0, 0, 0, 0);
    // Get the time in milliseconds since the Unix Epoch
    const startOfDayInMilliseconds = now.getTime();
    return (
      retrieveLocalData("main_tasks_date") || {
        start: startOfDayInMilliseconds,
        end: dateToLondonMilliseconds(),
      }
    );
  });
  const search_params = new URLSearchParams(window.location.search);
  const task_id = search_params.get("task");
  const client_id = useMemo(() => retrieveLocalData("client_id"), []);
  const clients_data = useSelector(
    (state: RootState) => state.care_data.care_docs
  );
  const activeClient: CareDocType = useMemo(() => {
    return (clients_data || [])?.find(
      (data: CareDocType) => data?.id === client_id
    );
  }, [clients_data, client_id]);
  const { data: logs, loading }: { data: TaskType[]; loading: boolean } =
    useFirestoreCollection(
      `care_management/${activeClient?.id}/tasks_logs/`,
      10000,
      task_id,
      [
        where("date", ">=", date?.start),
        where("date", "<=", date?.end + 8640000),
        where("task_id", "==", task_id),
      ],
      [date],
      true
    );

  //Component ===================
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      exit={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="fixed top-0 left-0 right-0 bottom-0 bg-cream z-[9999] flex items-center 
      justify-center overflow-hidden overflow-y-auto p-6"
    >
      <div className="w-full max-w-[70rem] h-full flex flex-col overflow-hidden bg-inherit">
        <div className="w-full h-fit shrink-0 flex items-center justify-between gap-4">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="h-10 w-10 rounded bg-white text-zinc-700 text-xl flex items-center
              justify-center border border-zinc-200"
          >
            <TbChevronLeft />
          </button>
          <div className="flex items-center gap-2">
            <DatePicker
              dates={date}
              additionalStyles={`shrink-0 text-xxs uppercase font-semibold text-zinc-600 w-full h-9 bg-white border 
                border-zinc-300 rounded flex relative`}
              changeDate={(e: any) => {
                setDate(e);
                localStorage.setItem("main_tasks_date", JSON.stringify(e));
              }}
              parentWidth="w-44 h-9"
              datePosition="top-[calc(100%+0.5rem)] right-0"
            />
          </div>
        </div>

        {/**Logs */}
        <table className="mt-6 w-full h-fit flex flex-col overflow-hidden">
          <thead className="w-full h-12 shrink-0 flex overflow-hidden bg-zinc-100 border-y border-zinc-300 px-3">
            <tr className="w-full flex items-center justify-between gap-1 overflow-hidden">
              {["user", "date", "start", "skipped to end"].map(
                (head: string) => {
                  return (
                    <th
                      key={head}
                      className="h-full w-1/5 flex items-center overflow-hidden"
                    >
                      <span className="text-xs font-bold uppercase text-sky-700 whitespace-nowrap truncate">
                        {head}
                      </span>
                    </th>
                  );
                }
              )}
            </tr>
          </thead>

          <tbody className="w-full h-full pr-2 overflow-hidden overflow-y-scroll">
            {logs?.map((log: any) => {
              return (
                <tr
                  key={log.id}
                  className="w-full min-h-[3rem] h-fit flex items-center justify-between gap-1 overflow-hidden
                   border-b border-zinc-200 shrink-0 px-3 py-1"
                >
                  <td className="h-full w-1/5 flex flex-col justify-center overflow-hidden">
                    <span className="text-xs font-semibold capitalize text-zinc-700 whitespace-nowrap truncate">
                      {log?.user?.usename || "unknown"}
                    </span>
                  </td>
                  <td className="h-full w-1/5 flex flex-col justify-center overflow-hidden">
                    <span className="text-xs font-semibold capitalize text-zinc-700 whitespace-nowrap truncate">
                      {dateTimeFormattedString(new Date(log?.date), true)}
                    </span>
                  </td>
                  <td className="h-full w-1/5 flex flex-col justify-center overflow-hidden">
                    <span
                      className={`text-xs font-semibold capitalize whitespace-nowrap truncate text-zinc-700`}
                    >
                      {dateTimeFormattedString(new Date(log?.read_data?.start),true)}
                    </span>
                  </td>
                  {/* <td className="h-full w-1/5 flex flex-col justify-center overflow-hidden">
                    <span className="text-xs font-semibold capitalize text-zinc-700 whitespace-nowrap truncate">
                      {
                        new Date(log?.read_data?.end)
                          .toString()
                          ?.split("GMT")?.[0]
                      }
                    </span>
                  </td> */}
                  <td className="h-full w-1/5 flex flex-col justify-center overflow-hidden">
                    <span className="text-xs font-semibold capitalize text-zinc-700 line-clamp-5">
                      {log?.read_data?.skip_to_end ? "Yes" : "No"}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <AnimatePresence>{loading && <Loader />}</AnimatePresence>
    </motion.div>
  );
});

export default React.memo(TaskLogs);
