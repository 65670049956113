import packageJson from "../../../../package.json";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { RiCloseFill } from "react-icons/ri";
import { clearAllData } from "../../../utils/reusable_functions";
import { useMemo, useState } from "react";
import { NavType } from "../app_layout";
import { UserType } from "../../../types/session_type";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase/firebase_config";
import { TbSettings } from "react-icons/tb";
import { Menu } from "lucide-react";

const SmNav = ({ filteredNav }: { filteredNav: NavType[] }) => {
  const user: UserType = useSelector(
    (state: RootState) => state.session_data.user
  );
  const [checked, setChecked] = useState<null | string>(null);
  const [menuOpen, openMenu] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const filteredNestedNavs: any = useMemo(() => {
    const data = checked
      ? [
          ...(filteredNav.find((nav: NavType) => nav.name === checked || "")
            ?.nested_routes || []),
        ]?.filter(
          (tab: any) =>
            !(
              !session_role?.permissions?.["can the user view employees"] &&
              tab.name === "employees"
            ) &&
            !(
              !session_role?.permissions?.[
                "can the user view employees leave"
              ] && tab.name === "leave"
            ) &&
            !(
              !session_role?.permissions?.["can the user view timesheet"] &&
              tab.name === "timesheet"
            ) &&
            !(
              !session_role?.permissions?.["can the user view payroll"] &&
              tab.name === "payroll"
            ) &&
            !(
              !session_role?.permissions?.["can the user access expenses"] &&
              tab.name === "expenses"
            )
        )
      : null;
    return data;
  }, [checked]);

  //HTML Content ===========================
  return (
    <nav
      className={`order-last w-full h-[5.5rem] shrink-0 pb-4 px-7 items-center justify-between 
        lg:hidden bg-white border-t border-zinc-300 rounded-t-xl shadow ${
          location.pathname.includes("/settings") ||
          location.pathname.includes("/people")
            ? "hidden"
            : "flex"
        } z-20`}
    >
      {filteredNav?.slice(0, 3).map((nav: NavType) => {
        return (
          <button
            onClick={() => {
              const path =
                !session_role?.permissions?.["can the user view employees"] &&
                nav.name === "people"
                  ? "/people/expenses"
                  : nav.path;
              navigate(path);
              //(nav.nested_routes?.length || 0) >= 1 && setChecked(nav.name);
            }}
            title={nav.name}
            key={nav.name}
            className={`h-full w-fit px-2 flex flex-col gap-1 items-center justify-center ${
              location.pathname === nav.path
                ? `text-sky-500 border-t-2 border-sky-500`
                : `text-zinc-500`
            }`}
          >
            {nav.icon}
            <span className="text-[0.55rem] font-bold uppercase !leading-none">
              {nav.name}
            </span>
          </button>
        );
      })}

      {/**Menu Button */}
      <button
        onClick={() => {
          openMenu(true);
        }}
        className={`flex flex-col gap-1 items-center justify-center text-zinc-500`}
      >
        <Menu className="menu_icons" />
        <span className="text-[0.55rem] font-bold uppercase !leading-none">
          menu
        </span>
      </button>

      <AnimatePresence>
        {(menuOpen || checked) && (
          <motion.div
            initial={{ bottom: "-100%", top: "100%" }}
            exit={{ bottom: "-100%", top: "100%" }}
            animate={{ top: 0, bottom: 0, opacity: 1 }}
            className="fixed left-0 right-0 !top-auto bottom-0 h-fit bg-white rounded-t-2xl
               shadow-3xl z-50 p-6 pb-8 flex flex-col overflow-hidden"
          >
            <div className="w-full h-fit flex items-center justify-between gap-4">
              <button
                onClick={() => {
                  navigate("/settings");
                }}
                className="w-full h-fit flex items-center justify-between gap-2  transition-all
                   cursor-pointer select-none overflow-hidden"
              >
                <img
                  src={user?.profile || `/images/default_profile.png`}
                  alt="profile"
                  className="h-14 w-14 shrink-0 rounded-full border-2 border-sky-500/40 object-cover object-center"
                />
                <div className="w-full flex flex-col overflow-hidden">
                  <h1
                    className="text-xs font-bold text-zinc-700 capitalize w-full overflow-hidden
                     whitespace-nowrap truncate text-left"
                  >
                    {user?.["first name"]} {user?.["last name"]}
                  </h1>
                  <span className="text-xss lowercase font-medium text-zinc-500 text-left whitespace-normal truncate">
                    {user?.email}
                  </span>
                </div>
              </button>

              <div className="flex items-center gap-1 shrink-0">
                <button
                  onClick={() => {
                    openMenu(false);
                    setChecked(null);
                  }}
                  className="h-11 w-11 rounded-full bg-zinc-200 shrink-0 flex items-center
                    justify-center text-xl text-zinc-800 hover:rotate-180 transition-all duration-300"
                >
                  <RiCloseFill />
                </button>
              </div>
            </div>

            {/**Divider */}
            <hr className="my-4 w-full h-0 border-b border-dashed border-zinc-300" />

            {/**Menu Items */}
            <div className="w-full h-fit flex flex-wrap content-start gap-2 shrink-0">
              {(checked ? filteredNestedNavs : filteredNav).map(
                (nav: NavType) => {
                  return (
                    <Link
                      onClick={() => {
                        openMenu(false);
                        setChecked(null);
                      }}
                      to={nav.path}
                      title={nav.name}
                      key={nav.name}
                      className={`px-3 py-2 h-fit rounded-md overflow-hidden flex items-center gap-1 border ${
                        location.pathname === nav.path
                          ? `text-sky-500 bg-sky-50 border-sky-200`
                          : `text-zinc-700 bg-zinc-50 border-zinc-200`
                      }`}
                    >
                      {nav.icon}
                      <h3 className="text-xs font-bold uppercase !leading-none whitespace-nowrap truncate shrink-0">
                        {nav.name}
                      </h3>
                    </Link>
                  );
                }
              )}

              {/**Settings */}
              <Link
                onClick={() => {
                  openMenu(false);
                  setChecked(null);
                }}
                to={"/settings"}
                className={`px-3 py-2 h-fit rounded-md overflow-hidden flex items-center gap-1 border ${
                  location.pathname.includes("settings")
                    ? `text-sky-500 bg-sky-50 border-sky-200`
                    : `text-zinc-700 bg-zinc-50 border-zinc-200`
                }`}
              >
                <TbSettings className="text-xl md:text-lg shrink-0" />
                <h3 className="text-xs font-bold uppercase !leading-none whitespace-nowrap truncate shrink-0">
                  settings
                </h3>
              </Link>
            </div>

            {/**Signout */}
            <button
              onClick={() => {
                signOut(auth).then(() => {
                  clearAllData();
                  document.title = "Stellify | Login";
                  localStorage.setItem("ckTL", JSON.stringify("yes"));
                  navigate("/login");
                  window.location.reload();
                });
              }}
              className="w-full h-11 rounded-full my-5 shrink-0 bg-red-500 text-white 
                text-xss uppercase font-bold"
            >
              sign-out
            </button>

            {/**Version */}
            <span className="shrink-0 text-xxs uppercase font-bold text-zinc-500 text-center">
              Version: {packageJson.version}
            </span>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default SmNav;
