import React, { useMemo } from "react";
import { ClientType } from "../../types/client_types";
import { Card } from "@/components/ui/card";
import { ChartPie } from "lucide-react";

type Props = {
  clients_data: ClientType[];
  setActiveStatus: React.Dispatch<React.SetStateAction<string>>;
  activeStatus: string;
};

const Stats = ({ clients_data, setActiveStatus, activeStatus }: Props) => {
  const data = useMemo(() => {
    return [
      { name: "total clients", value: clients_data?.length || 0, prev: 34 },
      {
        name: "active clients",
        value:
          clients_data?.filter(
            (client: ClientType) => client.status === "active"
          )?.length || 0,
      },
      {
        name: "onboarding clients",
        value:
          clients_data?.filter(
            (client: ClientType) => client.status === "onboarding"
          )?.length || 0,
      },
      {
        name: "archived clients",
        value:
          clients_data?.filter(
            (client: ClientType) => client.status === "archived"
          )?.length || 0,
      },
    ];
  }, [clients_data]);

  //Component ======================
  return (
    <div
      className="w-full h-28 gap-2 md:gap-4 shrink-0 overflow-hidden flex items-center
       overflow-x-scroll no-scrollbar"
    >
      {data?.map((stat: any, index: number) => {
        return (
          <Card
            onClick={() =>
              setActiveStatus((prev: string) =>
                prev === stat?.name?.split(" ")[0]
                  ? ""
                  : stat?.name?.split(" ")[0]
              )
            }
            key={stat?.name + index}
            className={`w-[200px] md:w-1/4 shrink-0 md:shrink cursor-pointer flex flex-col items-start p-4 
             h-full overflow-hidden !leading-tight   ${
               activeStatus === stat?.name?.split(" ")[0]
                 ? "border-sky-300/70"
                 : "border-zinc-200"
             }`}
          >
            <span className="text-xs text-zinc-800 font-semibold capitalize shrink-0">
              {stat?.name}
            </span>
            <div className="w-full h-full flex items-center gap-2 overflow-hidden">
              <div
                className="h-9 w-9 rounded-full bg-zinc-800 flex items-center
                 justify-center drop-shadow text-sky-500"
              >
                <ChartPie className="h-4 w-4 fill-current" />
              </div>
              <h2 className="text-2xl font-bold text-zinc-800">
                {stat?.value?.toString()?.padStart(2, "0")}
              </h2>
              <span className="text-xxs text-zinc-500 font-bold">Count</span>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default React.memo(Stats);
