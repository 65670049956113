import { orderBy } from "firebase/firestore";
import useFirestoreCollection from "../../../hooks/useFirestoreCollection";
import React from "react";
import { RiUserAddFill } from "react-icons/ri";
import { EmployeeType } from "../../../types/employee";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AnimatePresence, motion } from "framer-motion";
import { useModals } from "../../../lib/modal/modal2provider";
import { toast } from "sonner";
import { deleteInvite } from "../../../firebase/functions";
import { toPureString } from "../../../utils/reusable_functions";

type Invites = {
  createdAt: number;
  doc_id: string;
  email: string;
  id: string;
};

const Invites = ({ search }: { search: string }) => {
  const modals = useModals();
  const employees_docs: EmployeeType[] = useSelector(
    (state: RootState) => state.people_data.employees_docs
  );
  // Get Invites
  const { data: invites_data } = useFirestoreCollection(
    `invites`,
    500,
    "invites",
    [orderBy("createdAt", "desc")],
    [],
    true
  );

  //Filtered Invits
  const filteredInvites = React.useMemo(() => {
    const results = invites_data?.filter(
      (inv: Invites) =>
        !employees_docs?.some(
          (emp: EmployeeType) => emp?.email === inv?.email
        ) && toPureString(inv?.email).includes(toPureString(search))
    );
    return results;
  }, [employees_docs, invites_data,search]);

  const deleteInvites = (id: string) => {
    modals.openConfirmModal({
      title: `Are you sure you want to Delete this invite ?`,
      description: `This invite will be revoked and deemed invalid. A new invites will be required to add this employee`,
      labels: { cancel: "Cancel", confirm: "Confirm" },
      onCancel: () => modals.closeModal(),
      onConfirm: () => {
        toast.promise(deleteInvite(id), {
          loading: "Please wait ...",
          success: () => {
            modals.closeModal();
            return "Invite delete";
          },
          error: (error: any) => {
            return error?.message || "We ran into an error";
          },
        });
      },
      //closeModal: () => modals.closeModal(),
    });
  };

  //JSX Code =============
  return (
    <AnimatePresence>
      {filteredInvites?.length >= 1 ? (
        <motion.ul className="w-full h-full flex flex-col gap-1 overflow-hidden overflow-y-scroll md:pr-2 pb-6">
          {filteredInvites?.map((inv: Invites) => {
            return (
              <motion.li
                key={inv.id}
                layoutId={inv.id}
                className="w-full h-16 rounded bg-white border border-zinc-200 px-3 flex items-center 
                  gap-3 shrink-0 overflow-hidden"
              >
                <div className="w-full flex flex-col gap-1 overflow-hidden">
                  <span className="font-bold text-zinc-800 text-xs">
                    {new Date(inv.createdAt).toDateString()}
                  </span>
                  <span
                    className="text-xss font-medium text-sky-500 whitespace-nowrap truncate lowercase
                     !leading-none shrink-0 py-0.5"
                  >
                    {inv?.email}
                  </span>
                </div>
                <button
                  onClick={() => {
                    deleteInvites(inv.id);
                  }}
                  className="w-16 h-6 md:pt-0.5 text-xxs uppercase bg-red-50 border border-red-200 text-red-500
                     font-semibold !leading-none rounded shrink-0"
                >
                  revoke
                </button>
              </motion.li>
            );
          })}
        </motion.ul>
      ) : (
        <div
          className="w-full h-full bg-gradient-to-b from-white to-transparent rounded-t border
         border-zinc-200 border-b-0 p-6 flex flex-col items-center justify-center"
        >
          <div
            className="flex items-center justify-center w-fit h-fit hover:gap-1
              transition-all cursor-pointer ml-12"
          >
            {Array.from({ length: 3 }).map((_, i) => {
              return (
                <div
                  key={i}
                  className="h-24 even:h-32 w-24 -ml-12 bg-white rounded shadow-sm even:shadow-lg even:shadow-sky-600/30
                   p-3 -rotate-[9deg] even:rotate-0 even:z-10 last:rotate-[9deg]"
                >
                  <RiUserAddFill className="text-xl text-zinc-200 shrink-0" />
                  <div className="mt-1 h-1 w-8 rounded-full bg-zinc-200" />
                  <div className="mt-2 h-1 w-12 rounded-full bg-zinc-200" />
                  {Array.from({ length: 4 }).map((_, i) => (
                    <div
                      key={i}
                      className="mt-1 h-1 w-full last:w-3/5 rounded-full bg-zinc-200"
                    />
                  ))}
                </div>
              );
            })}
          </div>
          <h1 className="mt-7 text-base font-semibold text-zinc-700 text-center">
            Add employees
          </h1>
          <h2 className="mt-2 text-center max-w-[20rem] text-xs font-medium text-zinc-500">
            There are no invites to display, add employees to get started
          </h2>
        </div>
      )}
    </AnimatePresence>
  );
};

export default React.memo(Invites);
