import { orderBy, where } from "firebase/firestore";
import { useMemo } from "react";
import { retrieveLocalData } from "../../../utils/reusable_functions";
import useFirestoreCollection from "../../../hooks/useFirestoreCollection";
import { RiArrowRightUpLine } from "react-icons/ri";
import { TbReport } from "react-icons/tb";
import { useNavigate } from "react-router-dom";


const RecentIncidents = () => {
  const navigate = useNavigate();
  const client_id = useMemo(() => retrieveLocalData("client_id"), []);
  const { data: fetchedIncidentsData } = useFirestoreCollection(
    `incidents`,
    1000,
    client_id,
    [
      where("client_id", "==", client_id),
      where("is_reviewed", "==", false),
      orderBy("date", "desc"),
    ]
  );

  //Component ====================
  return (
    <>
      {fetchedIncidentsData?.length >= 1 && (
        <>
          <div className="mt-6 w-full flex items-center gap-4 justify-between shrink-0">
            <h1 className="text-xs font-bold uppercase text-zinc-800 whitespace-nowrap truncate shrink-0">
              Recent Incidents
            </h1>
            <hr className="w-full border-b border-zinc-300 border-dashed"/>
          </div>

          {/**Incidents */}
          {fetchedIncidentsData?.length <= 0 ? (
            <div
              className="w-full min-h-[20rem] h-full mt-4 rounded bg-gradient-to-b from-white to-transparent flex flex-col
              items-center justify-center"
            >
              <img
                src="/images/incident.png"
                alt="no_data"
                className="h-16 w-auto grayscale opacity-50"
              />
              <p className="mt-2 shrink-0 text-xss uppercase text-zinc-400 font-bold text-center">
                Today you all catched up,
                <br />
                any new incidents will be displayed here
              </p>
            </div>
          ) : (
            <ul
              className="mt-2 w-full max-h-[18rem] min-h-fit h-fit flex flex-col gap-2 overflow-hidden overflow-y-scroll
         no-scrollbar no-scrollbar::-webkit-scrollbar shrink-0"
            >
              {fetchedIncidentsData?.map((incident: any) => {
                return (
                  <li
                    onClick={() => {
                      navigate("/care_profile/incidents");
                    }}
                    key={incident?.id}
                    className="w-full h-16 bg-white border border-zinc-200 shrink-0 px-4 flex items-center
                      justify-between overflow-hidden cursor-pointer select-none gap-3 group rounded"
                  >
                    <div
                      className="h-10 w-10 rounded-full bg-sky-500 text-white text-xl text-center
                    flex items-center justify-center shrink-0"
                    >
                      <TbReport />
                    </div>
                    <div className="w-full flex flex-col overflow-hidden">
                      <h1 className="text-xss font-bold capitalize text-zinc-700 whitespace-nowrape truncate">
                        {incident?.["nature of incidents"] ||
                          incident?.["nature of incident"]}
                      </h1>
                      <h2 className="text-xxs font-semibold uppercase text-zinc-600 whitespace-nowrape truncate">
                        {new Date(incident?.["date of the incident"])
                          ?.toString()
                          ?.split(" ")
                          ?.slice(0, 5)
                          ?.join(" ")}
                      </h2>
                    </div>
                    <div
                      className={`h-fit w-fit px-2 py-1 shrink-0 text-xxs font-bold  uppercase border
                  !leading-none rounded-full ${
                    incident?.is_reviewed
                      ? `border-green-300 bg-green-100 text-green-600`
                      : `border-yellow-300 bg-yellow-100 text-yellow-600`
                  }`}
                    >
                      {incident?.is_reviewed ? "reviewed" : "pending"}
                    </div>
                    <RiArrowRightUpLine className="text-2xl shrink-0 text-zinc-500 group-hover:text-sky-500 transition-all" />
                  </li>
                );
              })}
            </ul>
          )}
        </>
      )}
    </>
  );
};

export default RecentIncidents;
