import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AnimatePresence } from "framer-motion";
import ClockIn from "./clock_in";
import React, { useMemo, useState } from "react";
import { ScheduleEventType } from "../../../types/schedule_types.d";
import DatePicker from "../../../components/date picker/DatePicker";
import { retrieveLocalData } from "../../../utils/reusable_functions";
import Shifts from "./shifts";
import { sum } from "moderndash";
import { HiDocumentText } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { changeDashboardDates } from "../../../redux/slices/schedule_slice";
import { Button } from "@/components/ui/button";

const Ground = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dates = useSelector(
    (state: RootState) => state.schedule_data?.dashboard_dates
  );
  const tabs = ["pending", "clocked_in", "clocked_out", "missed"];
  const [activeTab, setTab] = useState<string>(
    () => retrieveLocalData("shifts_tabs") || "pending"
  );
  const [clockMOdalOpen, setClockModal] = useState<ScheduleEventType | null>(
    null
  );
  const user = useSelector((state: RootState) => state.session_data.user);
  const schedule_data = useSelector(
    (state: RootState) => state.schedule_data.active_user_shifts
  );
  const filteredShifts = useMemo(() => {
    const data = [...(schedule_data || [])];
    const final =
      data?.filter((event: ScheduleEventType) => event?.status === activeTab) ||
      [];
    const sorted = [...final]?.sort(
      (a: ScheduleEventType, b: ScheduleEventType) =>
        a?.start_date - b?.start_date
    );
    localStorage.setItem("licsts", JSON.stringify(sorted));
    return sorted;
  }, [schedule_data, activeTab]);
  const statsData = useMemo(() => {
    const est_salary =
      sum(
        schedule_data
          ?.filter((data: ScheduleEventType) => data?.status === "clocked_out")
          ?.map(
            (data: ScheduleEventType) =>
              (Number(data?.duration) / 3600000) *
                Number(
                  data?.is_night_shift
                    ? Number(data?.assigned_to?.night_shift_rate) || 0
                    : data?.is_sleep_in
                    ? Number(data?.assigned_to?.sleep_in_rate) || 0
                    : Number(data?.assigned_to?.rate) || 0
                ) +
              Number(
                (data?.overtime || 0) *
                  Number(data?.assigned_to?.overtime_rate) || 0
              )
          )
      ) || 0;
    const hours =
      sum(
        schedule_data?.map(
          (data: ScheduleEventType) =>
            Number(data?.duration?.toString()?.replace("-", "")) / 3600000
        )
      ) || 0;
    return [
      {
        name: "estimated salary",
        category: "hr",
        count: hours?.toFixed(2) || 0,
        amount: Number(est_salary)?.toFixed(2),
      },
      {
        name: "night shifts",
        category: "hr",
        count: sum(
          schedule_data?.map((data: ScheduleEventType) =>
            data?.is_night_shift ? data?.duration / 3600000 : 0
          )
        )?.toFixed(0),
        amount: schedule_data?.filter(
          (data: ScheduleEventType) => data?.is_night_shift
        )?.length,
      },
      {
        name: "sleep-in shifts",
        category: "hr",
        count: sum(
          schedule_data?.map((data: ScheduleEventType) =>
            data?.is_sleep_in ? data?.duration / 3600000 : 0
          )
        )?.toFixed(0),
        amount: schedule_data?.filter(
          (data: ScheduleEventType) => data?.is_sleep_in
        )?.length,
      },
      {
        name: "overtime",
        category: "hr",
        count: sum(
          schedule_data?.map((data: ScheduleEventType) => data?.overtime || 0)
        )?.toFixed(0),
        amount: schedule_data?.filter(
          (data: ScheduleEventType) => (data?.overtime || 0) >= 1
        )?.length,
      },
      {
        name: "late comings",
        category: "hr",
        count: sum(
          schedule_data?.map((data: ScheduleEventType) =>
            data?.is_late ? data?.duration / 3600000 : 0
          )
        )?.toFixed(0),
        amount: schedule_data?.filter(
          (data: ScheduleEventType) => data?.is_late
        )?.length,
      },
      {
        name: "missed shifts",
        category: "hr",
        count: sum(
          schedule_data?.map((data: ScheduleEventType) =>
            data?.status === "missed" ? data?.duration / 3600000 : 0
          )
        )?.toFixed(0),
        amount: schedule_data?.filter(
          (data: ScheduleEventType) => data?.status === "missed"
        )?.length,
      },
    ];
  }, [filteredShifts, schedule_data]);

  //Component ========================
  return (
    <div className="w-full h-fit overflow-hidden flex flex-col items-center shrink-0">
      {/**Date Picker and Greetings */}
      <div className="w-full h-fit pb-2 shrink-0 flex items-center justify-between gap-4">
        <DatePicker
          dates={dates}
          additionalStyles={`shrink-0 text-xxs uppercase font-semibold text-zinc-600 w-44 h-10
           bg-white border border-zinc-300 flex relative rounded-md pr-1`}
          changeDate={(e: any) => {
            dispatch(changeDashboardDates(e));
          }}
          parentWidth="w-44 h-10"
          datePosition="top-[calc(100%+0.5rem)] left-0"
        />

        <Button
          onClick={() => {
            navigate(`/people/employee/${user?.["first name"]}?id=${user?.id}`);
          }}
          title="manage profile"
          className="w-40 h-10 uppercase font-bold flex items-center justify-center px-4 gap-1"
        >
          <HiDocumentText className="text-lg" />
          <span className="font-bold"> my work profile</span>
        </Button>
      </div>

      {/**Stats Cards */}
      <div className="mt-2 shrink-0 w-full h-fit grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 overflow-hidden">
        {statsData?.map((data: any) => {
          return (
            <div
              key={data?.name}
              className="col-span-1 h-20 md:h-24 rounded bg-white border border-zinc-300 py-2
               md:py-4 p-4 overflow-hidden flex flex-col justify-between shrink-0 shadow-sm"
            >
              <h2 className="text-xss uppercase font-bold text-zinc-600 whitespace-nowrap truncate">
                {data?.name}
              </h2>
              <h2 className="text-lg xl:text-xl uppercase font-extrabold text-zinc-800 whitespace-nowrap">
                {data.name === "estimated salary" && "£"}{" "}
                {isNaN(data?.amount) ? "00" : data?.amount}
              </h2>
              <h2 className="!leading-none text-xxs md:text-xss uppercase font-bold text-zinc-400 whitespace-nowrap truncate">
                <span className="text-sky-500">
                  {isNaN(data?.count) ? "00" : data?.count}
                </span>{" "}
                {data?.category}
              </h2>
            </div>
          );
        })}
      </div>

      {/**Upcoming Shifts */}
      <div
        className="mt-4 w-full h-fit shrink-0 flex justify-between items-center flex-nowrap
         gap-2 overflow-hidden overflow-x-scroll no-scrollbar"
      >
        <div
          className="w-full h-fit flex items-center gap-1 overflow-hidden pb-2 border-b border-zinc-400 
          border-dashed shrink-0"
        >
          {tabs
            .map((tab: string) => ({
              label:
                tab === "clocked_out" ? "completed" : tab?.replace(/_/gim, " "),
              value: tab,
            }))
            ?.map((tab: any) => {
              return (
                <button
                  onClick={() => {
                    setTab(tab?.value as string);
                    localStorage.setItem(
                      "shifts_tabs",
                      JSON.stringify(tab?.value)
                    );
                  }}
                  className={`h-8 w-1/4 md:w-24 text-[0.55rem] !leading-none uppercase rounded font-bold
                     bg-white border ${
                       activeTab === tab?.value
                         ? "text-sky-500 border-sky-300"
                         : "text-zinc-800 border-zinc-300"
                     }`}
                >
                  {tab.label}
                </button>
              );
            })}
        </div>
      </div>

      {filteredShifts?.length >= 1 ? (
        <Shifts filteredShifts={filteredShifts} setClockModal={setClockModal} />
      ) : (
        <div
          className="mt-2 w-full h-[25rem] flex flex-col items-center justify-center gap-2 overflow-hidden
           bg-gradient-to-b from-white to-transparent rounded border border-zinc-200"
        >
          <>
            <img
              src="/images/clock.gif"
              alt="no shift"
              className="h-16 w-16 grayscale opacity-60"
            />
            <p className="text-center font-semibold text-xs text-zinc-600">
              {"There's is no upcoming shifts at the moment,"}
              <br />
              all new shifts will be displayed here.
            </p>
          </>
        </div>
      )}

      {/**Clock In Modal */}
      <AnimatePresence>
        {clockMOdalOpen && (
          <ClockIn
            clockMOdalOpen={clockMOdalOpen}
            setClockModal={setClockModal}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default React.memo(Ground);
