import { TaksLogsType, TaskType } from "../../../types/care_types";
import useFirestoreCollection from "../../../hooks/useFirestoreCollection";
import {
  dateFromLondonMilliseconds,
  decryptData,
  retrieveLocalData,
} from "../../../utils/reusable_functions";
import React from "react";
import { ClientType } from "../../../types/client_types";
import { where } from "firebase/firestore";
import TasksList from "../tasks/tasks-list";
import { useOutletContext } from "react-router-dom";
import { ShiftType } from "../../../types/schedule_types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { RoleType } from "../../../types/session_type";

const Tasks = () => {
  const session_role: RoleType = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const date = React.useMemo(() => {
    // Create a new Date object for the current date
    const now = new Date();
    // Reset hours, minutes, seconds, and milliseconds to get the start of the day
    now.setHours(0, 0, 0, 0);
    // Get the time in milliseconds since the Unix Epoch
    const startOfDayInMilliseconds = now.getTime();
    return startOfDayInMilliseconds;
  }, []);
  const client: ClientType = React.useMemo(
    () =>
      decryptData(
        retrieveLocalData("active_client_doc"),
        import.meta.env.VITE_APP_CHECK_KEY
      ) as ClientType,
    []
  );

  const [shift]: [ShiftType] = useOutletContext();
  const { data: fetchedTasks } = useFirestoreCollection(
    `care_management/${client?.id || client?.uid}/tasks`,
    1000,
    client,
    [],
    []
  );
  const { data: tasks_logs }: { data: TaksLogsType[] } = useFirestoreCollection(
    `care_management/${client?.id || client?.uid}/tasks_logs`,
    1000,
    date,
    [where("date", ">=", date), where("date", "<=", date + 86400000)],
    [date]
  );
  const tasks_today = React.useMemo(() => {
    const date_milli = dateFromLondonMilliseconds();
    const all_tasks = fetchedTasks || [];
    return (
      all_tasks
        ?.filter(
          (task: TaskType) =>
            task?.frequency === "everyday" ||
            dateFromLondonMilliseconds(task?.due_date)
              .toString()
              .split(" ")
              .slice(0, 4)
              .join(" ") ===
              date_milli.toString().split(" ").slice(0, 4).join(" ") ||
            (task?.frequency === "daily" &&
              (task?.day_of_the_week || [])?.includes(
                date_milli.toString().split(" ")?.[0]?.toString()?.toLowerCase()
              ))
          //&& task?.access?.includes((session_role?.role as string) || "")
        )
        ?.sort((a: TaskType, b: TaskType) =>
          a?.due_time < b?.due_time ? -1 : 1
        ) || []
    );
  }, [fetchedTasks]);
  const restricted_tasks = React.useMemo(() => {
    const hide_on_shift =
      session_role?.permissions?.["limit task to user shift"];
    const start_hour =
      hide_on_shift && shift ? new Date(shift?.start_date||"").getHours() : 0;
    const end_hour =
      hide_on_shift && shift ? new Date(shift?.end_date||"").getHours() : 24;

    const refined = [...(tasks_today || [])]?.filter(
      (task: TaskType) =>
        parseInt(task.due_time.slice(0, 2)) >= start_hour &&
        parseInt(task.due_time.slice(0, 2)) <= end_hour
    );
    return refined;
  }, [tasks_today, shift, date]);

  //JSX Code ===================
  return (
    <>
      <div className="mt-4 w-full flex items-center gap-4 justify-between shrink-0 overflow-hidden">
        <h1 className="text-xs font-bold uppercase text-zinc-800 whitespace-nowrap truncate shrink-0">
          Upcoming Tasks
        </h1>

        <hr className="w-full border-b border-zinc-300 border-dashed" />
      </div>
      {/**Tasks List */}
      <TasksList
        tasks_today={restricted_tasks}
        tasks_logs={tasks_logs}
        setTask={null}
        date={{ start: date, end: date + 86400000 }}
      />
    </>
  );
};

export default Tasks;
