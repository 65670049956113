import AssessmentsSlice from "./slices/assessment_slice";
import SessionSlice from "./slices/session_slice";
import SpotlightSlice from "./slices/spotlight";
import FormsSlice from "./slices/forms_slice";
import ListSlice from "./slices/lists_slice";
import CareSlice from "./slices/care_slice";
import InventorySlice from "./slices/inventory_slice";
import ChartsSlice from "./slices/charts_slice";
import PeopleSlice from "./slices/peoples_slice";
import NotesSlice from "./slices/notes_slice";
import TasksSlice from "./slices/tasks_slice";
import ScheduleSlice from "./slices/schedule_slice";
import LeaveSlice from "./slices/leave_slice";
import RequestsSlice from "./slices/requests-slice";

const rootReducer: any = {
  session_data: SessionSlice,
  spotlight_data: SpotlightSlice,
  assessments_data: AssessmentsSlice,
  lists_data: ListSlice,
  forms_data: FormsSlice,
  care_data: CareSlice,
  inventory_data: InventorySlice,
  charts_data: ChartsSlice,
  people_data: PeopleSlice,
  notes_data: NotesSlice,
  tasks_data: TasksSlice,
  schedule_data: ScheduleSlice,
  leave_data: LeaveSlice,
  requests_data: RequestsSlice,
};

export default rootReducer;
