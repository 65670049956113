
import { onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { assessmentsRef } from "../functions"; 
import { setAssessmentsData } from "../../redux/slices/assessment_slice";

const AssessmentsData = () => {
  const { currentUser }: any = useAuth();
  const dispatch = useDispatch();

  //Fetch Data And Store It Locally
  useEffect((): any => {
    return currentUser?.uid&&onSnapshot(
      assessmentsRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
            setAssessmentsData(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );
  }, [dispatch, currentUser]);

  //Compoent ===========================
  return <></>;
};

export default AssessmentsData;
