import React, { useMemo } from "react";
import { ScheduleEventType } from "../../../types/schedule_types";
import EmptyStateV1 from "../../reports page/incidents/empty_state_v1";
import { getDateRangeArray } from "../../../utils/reusable_functions";
import { AreaChart } from "@tremor/react";

type Props = {
  schedule_data: ScheduleEventType[];
  dates: { start: number; end: number };
};

const AttandanceHeatMap = ({ schedule_data, dates }: Props) => {
  const statsData = useMemo(() => {
    const results = [
      {
        name: "pending",
        category: "scheduled hours",
        amount: schedule_data?.filter(
          (data: ScheduleEventType) => data?.status === "pending"
        )?.length,
      },
      {
        name: "clocked-in",
        category: "scheduled hours",
        amount: schedule_data?.filter(
          (data: ScheduleEventType) => data?.status === "clocked_in"
        )?.length,
      },
      {
        name: "clocked-out",
        category: "scheduled hours",
        amount: schedule_data?.filter(
          (data: ScheduleEventType) => data?.status === "clocked_out"
        )?.length,
      },
      {
        name: "missed shifts",
        category: "scheduled hours",
        amount: schedule_data?.filter(
          (data: ScheduleEventType) => data?.status === "missed"
        )?.length,
      },
      {
        name: "late comings",
        category: "hours",
        amount: schedule_data?.filter(
          (data: ScheduleEventType) => data?.is_late
        )?.length,
      },
    ];
    return results?.sort((a, b) => b.amount - a.amount) || [];
  }, [schedule_data]);

  const trend = useMemo(() => {
    const previoud_14 = getDateRangeArray(dates?.end - 1209600000, dates?.end);
    const results = previoud_14.map((date: number) => {
      return {
        date: new Date(date).toDateString().split(" ").slice(1, 3).join(" "),
        shifts:
          schedule_data?.filter(
            (data: ScheduleEventType) =>
              new Date(data?.start_date).toDateString() ===
              new Date(date).toDateString()
          )?.length || 0,
      };
    });
    return results;
  }, [schedule_data, dates?.end]);

  //JSX Code
  return (
    <div
      className="w-full h-96 shrink-0 overflow-hidden bg-white rounded-md
         border border-zinc-200 p-4 gap-2 flex flex-col relative"
    >
      <div
        className="w-full h-fit shrink-0 flex items-center gap-2 overflow-hidden overflow-x-scroll no-scrollbar
        divide-x divide-zinc-200 border-b border-zinc-200"
      >
        {statsData.map((opt: any) => {
          return (
            <div
              key={opt.name}
              className="w-1/5 min-w-[7rem] h-20 md:h-24 bg-white overflow-hidden p-4 flex flex-col
               items-center justify-center gap-1 shrink-0"
            >
              <h1 className="text-xl md:text-2xl font-bold text-zinc-800 !leading-tight">
                {opt?.amount?.toString()?.padStart(2, "0") || 0}
              </h1>
              <span
                className="text-xxs uppercase whitespace-nowrap truncate text-center font-bold
               text-zinc-600 !leading-tight"
              >
                {opt.name}
              </span>
            </div>
          );
        })}
      </div>

      {/**Charts */}
      {schedule_data?.length >= 1 ? (
        <div
          className="w-full h-full border border-zinc-300 border-dashed bg-zinc-50 rounded-md md:rounded
           overflow-hidden p-1 pt-4"
        >
          <AreaChart
          curveType="monotone"
          showLegend={false}
          showYAxis={false}
          tickGap={innerWidth <= 762 ? 6 : 2}
            showAnimation
            allowDecimals={false}
            className="h-full w-full"
            data={trend}
            index="date"
            categories={["shifts"]}
            colors={["sky"]}
            yAxisWidth={60}
          />
        </div>
      ) : (
        <EmptyStateV1 />
      )}
    </div>
  );
};

export default React.memo(AttandanceHeatMap);
