import React, { useMemo, useState } from "react";
//import PieBreakdown from "./pie_breakdown";
import { formatNumberToInt } from "../../../utils/reusable_functions";
import { sum } from "moderndash";
import {
  ContractsType,
  EmployeeType,
  TrainingDataType,
} from "../../../types/employee";
import { Card } from "@/components/ui/card";

const MiniReport = ({
  activeFilter,
  setActiveFilter,
  filteredUsers,
}: {
  activeFilter: string;
  setActiveFilter: any;
  filteredUsers: EmployeeType[];
}) => {
  const positions = useMemo(() => {
    return [
      ...new Set(
        [...(filteredUsers || [])]?.map(
          (emp: EmployeeType) => emp?.["position applied for"]
        )
      ),
    ];
  }, [filteredUsers]);
  const [activePosition, setActivePosition] = useState<string>("");
  const filtered_employees = useMemo(() => {
    return [...(filteredUsers || [])]?.filter((emp: EmployeeType) =>
      emp?.["position applied for"].includes(activePosition)
    );
  }, [filteredUsers, activePosition]);
  const stats = useMemo(
    () => [
      {
        name: "out. trainings",
        value: [...(filteredUsers || [])]?.filter((emp: EmployeeType) =>
          emp?.training?.some(
            (trn: TrainingDataType) => trn?.status === "pending"
          )
        )?.length,
        unit: "",
        clickable: true,
      },
      {
        name: "out. dbs check",
        value: [...(filteredUsers || [])]?.filter(
          (emp: EmployeeType) => emp?.dbs?.status !== "approved"
        )?.length,
        unit: "",
        clickable: true,
      },
      {
        name: "out. refefrences",
        value:
          [...(filteredUsers || [])]?.filter((emp: EmployeeType) =>
            emp?.references_data?.some((ref: any) => ref?.status === "pending")
          )?.length || 0,
        unit: "",
        clickable: true,
      },
      {
        name: "pending applications",
        value:
          [...(filteredUsers || [])]?.filter(
            (emp: EmployeeType) => emp?.status === "pending"
          )?.length || 0,
        unit: "",
        clickable: true,
      },
      {
        name: "active contracts",
        value:
          filteredUsers?.filter((emp: EmployeeType) =>
            emp?.contracts?.some(
              (con: ContractsType) =>
                con.status === "active" &&
                con?.end_date > Date.now() + 5259492000
            )
          )?.length || 0,
        unit: "",
        clickable: true,
      },
      {
        name: "due for review",
        value:
          filteredUsers?.filter((emp: EmployeeType) =>
            emp?.contracts?.some(
              (con: ContractsType) =>
                con.status === "active" &&
                con?.end_date < Date.now() + 5259492000
            )
          )?.length || 0,
        unit: "",
        clickable: true,
      },
    ],
    [filteredUsers]
  );
  const employees_stats = useMemo(() => {
    return [
      { name: "total", value: filtered_employees?.length || 0, unit: "" },
      {
        name: "permanent",
        value:
          filtered_employees?.filter((emp: EmployeeType) =>
            emp?.contracts?.some(
              (con: ContractsType) =>
                con.status === "active" && con?.type === "permanent"
            )
          )?.length || 0,
        unit: "",
      },
      {
        name: "zero-hour",
        value:
          filtered_employees?.filter((emp: EmployeeType) =>
            emp?.contracts?.some(
              (con: ContractsType) =>
                con.status === "active" && con?.type === "zero-hour"
            )
          )?.length || 0,
        unit: "",
      },
      {
        name: "contractor",
        value:
          filtered_employees?.filter((emp: EmployeeType) =>
            emp?.contracts?.some(
              (con: ContractsType) =>
                con.status === "active" && con?.type === "contractor"
            )
          )?.length || 0,
        unit: "",
      },
    ];
  }, [filtered_employees]);
  const total = useMemo(() => {
    return sum(employees_stats?.map((stat: any) => stat?.value + 1));
  }, []);

  //Component
  return (
    <div
      className="w-full h-fit overflow-hidden flex items-center justify-between gap-4
        overflow-x-scroll no-scrollbar shrink-0"
    >
      {/**Employees Samary */}
      <Card
        className="w-[90%] md:w-[25rem] h-[18rem] bg-white p-6 shrink-0 flex 
        flex-col gap-8 overflow-hidden"
      >
        <div className="w-full h-fit srink-0 flex items-center justify-between">
          <h1 className="text-xs font-bold uppercase text-zinc-400">
            Employees summary
          </h1>
          <select
            onChange={(e) => setActivePosition(e.target.value)}
            name="filter_trend"
            id="filter_trend"
            className="h-7 w-24 rounded border border-zinc-200 bg-zinc-50 select-none font-bold text-xxs
           uppercase !leading-none px-2 shrink-0 cursor-pointer text-zinc-500 focus:border-zinc-200"
          >
            <option value="">all</option>
            {positions?.map((role: string) => {
              return (
                <option key={role} value={role}>
                  {role}
                </option>
              );
            })}
          </select>
        </div>
        <div className="w-full h-full flex justify-between gap-1 overflow-hidden">
          {employees_stats?.map((stat: any) => {
            return (
              <div
                key={stat?.name}
                className="h-full w-20 flex flex-col items-center gap-2 overflow-hidden text-xxs"
              >
                <div
                  style={{ backgroundImage: `url("/images/diagnal.png")` }}
                  className="h-full w-10 rounded bg-zinc-50 border border-zinc-300/60 relative 
                   flex flex-col justify-end items-center  gap-10.5 p-[1px] bg-no-repeat bg-cover bg-center
                    after:absolute after:h-1.5 after:w-[1px] after:bg-zinc-300 after:-bottom-2"
                >
                  <span className="text-xxs uppercase font-bold text-zinc-800 whitespace-nowrap">
                    {isNaN(stat?.value) ? "0.0" : stat?.value}
                  </span>
                  <div
                    style={{
                      height: `${(((stat?.value || 0) / total) * 100)?.toFixed(
                        2
                      )}%`,
                    }}
                    className="bg-sky-500 w-full rounded-sm cursor-pointer hover:bg-sky-500 transition-all"
                  ></div>
                </div>

                <span className="text-[0.5rem] md:text-[0.6rem] uppercase font-bold text-zinc-500 whitespace-nowrap">
                  {stat?.name}
                </span>
              </div>
            );
          })}
        </div>
      </Card>

      {/**Stats */}
      <div className="min-w-[30rem] w-full h-[18rem] grid grid-cols-3 gap-2">
        {stats?.map((stat: any) => {
          return (
            <Card
              key={stat?.name}
              onClick={() =>
                setActiveFilter((prev: string) =>
                  prev?.toLowerCase() === stat?.name?.toLowerCase()
                    ? ""
                    : stat?.name?.toLowerCase()
                )
              }
              className={`h-full col-span-1 shrink-0 p-4 overflow-hidden  flex flex-col items-center
                 justify-center gap-2 hover:bg-sky-50 transition-all cursor-pointer select-none ${
                 activeFilter === stat?.name
                   ? `bg-sky-50 border-sky-500/60`
                   : `border-zinc-200 bg-white`
               }`}
            >
              <div className="flex items-center justify-center gap-1 overflow-hidden w-full">
                <h1 className="text-lg text-zinc-700 uppercase font-extrabold whitespace-nowrap truncate !leading-none">
                  {stat?.unit === "gbp"
                    ? formatNumberToInt(stat?.value)
                    : stat?.value?.toString()?.padStart(2, "0")}
                </h1>
                <span className="text-xxs text-sky-500/70 uppercase font-bold whitespace-nowrap truncate !leading-none">
                  {stat?.unit}
                </span>
              </div>
              <h2 className="text-xxs text-zinc-500 uppercase font-bold whitespace-nowrap truncate !leading-none">
                {stat?.name}
              </h2>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(MiniReport);
