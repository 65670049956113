import React, { useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RiUserAddFill } from "react-icons/ri";
import Stats from "./stats";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ClientType } from "../../types/client_types";
import CrudClient from "./crud_client";
import { circleVariants, new_client_obj } from "../../utils/reusable_objects";
import withPermission from "../../components/auth/withPermission";
import { toPureString } from "../../utils/reusable_functions";
import Client from "./client";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
//import Carrie from "../../components/carrie/carrie";

const Care = withPermission(() => {
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const clients = useSelector((state: RootState) => state.lists_data.clients);
  const [preview, setPreview] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [activeStatus, setActiveStatus] = React.useState<string>("");
  const filtered_clients: ClientType[] = useMemo(() => {
    const results = clients?.filter((ct: ClientType) =>
      toPureString(JSON.stringify(ct))?.includes(toPureString(search))
    );
    return [...(results || [])]
      ?.filter((client: ClientType) => {
        if (activeStatus === "active" || activeStatus === "onboarding") {
          return client?.status === activeStatus;
        } else {
          return true;
        }
      })
      ?.sort((a: ClientType, b: ClientType) =>
        a?.["first name"].localeCompare(b?.["first name"])
      );
  }, [search, clients, activeStatus]);

  // const addNewCarePlans = async () => {
  //   const care_plans = clients_data
  //     ?.map((data: any) =>
  //       data?.care_plans?.length >= 1
  //         ? data?.care_plans?.map((plan: any) => ({
  //             ...plan,
  //             client_id: data?.id,
  //             id: "",
  //           }))
  //         : []
  //     )
  //     ?.flat()
  //     ?.filter(Boolean);

  //   const promise = async () => {
  //     return await Promise.all(
  //       care_plans?.map(async (plan: any) => {
  //         await addCarePlan(plan, user);
  //       })
  //     );
  //   };

  //   toast.promise(promise(), {
  //     loading: "Adding care plans...",
  //     success: "Care plans added successfully",
  //     error: "Failed to add care plans",
  //   });
  // };

  //Component =========================
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={circleVariants}
      className={`h-fit w-full flex flex-col shrink-0 items-center overflow-hidden relative`}
    >
      {/**Stats */}
      <Stats
        clients_data={filtered_clients}
        setActiveStatus={setActiveStatus}
        activeStatus={activeStatus}
      />

      {/**Search */}
      <div className="w-full h-fit shrink-0 flex items-center gap-2 mt-4 mb-2">
        <Input
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search || ""}
          autoFocus={search ? true : false}
          className="h-10 w-full placeholder:text-zinc-400 text-xs font-medium
           focus-visible:border-sky-500/50 focus-visible:ring-0"
          placeholder="Quick search for a client ?"
          type="search"
        />
        {/**New Client */}
        {session_role?.permissions?.["can manage clients"] && (
          <Button
            onClick={() => {
              setPreview(new_client_obj);
            }}
            className="h-10 w-10 md:w-32 rounded font-bold uppercase shrink-0 gap-2 group"
          >
            <RiUserAddFill className="text-lg shrink-0 lg:hidden" />
            <span className="hidden md:flex text-xxs font-bold !leading-none">
              new client
            </span>
          </Button>
        )}
        {/* <Carrie
          data={`Following are all clients profile: ${JSON.stringify(
            filtered_clients
          )}`}
          bubbles={[
            `Summarize this clients data and provide a breakdown`,
            `What are the needs of the clients and how can we meet them`,
            `What are the common issues faced by the clients and how can we solve them`,
          ]}
          button_styles="h-10 w-10"
        /> */}
      </div>

      {/**Clients List */}
      {filtered_clients?.length >= 1 ? (
        <div
          className="mt-2 w-full h-fit grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4
          2xl:grid-cols-5 gap-1.5 overflow-hidden shrink-0 pb-10"
        >
          {filtered_clients?.map((client: ClientType) => {
            return <Client key={client.id} client={client} />;
          })}
        </div>
      ) : (
        <div
          className="w-full h-full bg-gradient-to-b from-white to-transparent rounded-t border
           border-zinc-200 border-b-0 p-6 flex flex-col items-center justify-center"
        >
          <div
            className="flex items-center justify-center w-fit h-fit h-shrink-0 hover:gap-1
           transition-all cursor-pointer ml-12"
          >
            {Array.from({ length: 3 }).map((_, i) => {
              return (
                <div
                  key={i}
                  className="h-24 even:h-32 w-24 -ml-12 bg-white rounded shadow-sm even:shadow-lg even:shadow-sky-600/30
                 p-3 -rotate-[9deg] even:rotate-0 even:z-10 last:rotate-[9deg]"
                >
                  <RiUserAddFill className="text-xl text-zinc-200 shrink-0" />
                  <div className="mt-1 h-1 w-8 rounded-full bg-zinc-200" />
                  <div className="mt-2 h-1 w-12 rounded-full bg-zinc-200" />
                  {Array.from({ length: 4 }).map((_, i) => (
                    <div
                      key={i}
                      className="mt-1 h-1 w-full last:w-3/5 rounded-full bg-zinc-200"
                    />
                  ))}
                </div>
              );
            })}
          </div>
          <h1 className="mt-7 text-base font-semibold text-zinc-700 text-center">
            Add clients
          </h1>
          <h2 className="mt-2 text-center max-w-[20rem] text-xs font-medium text-zinc-500">
            There are no clients to display, add clients to get started
          </h2>
        </div>
      )}

      {/**New Client */}
      <AnimatePresence>
        {preview && (
          <CrudClient
            preview={preview}
            setPreview={setPreview}
            expectedPermission={"can manage clients"}
            expectedValue={true}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
});

export default React.memo(Care);
