import React, { useState } from "react";
import { TbArrowUpRight, TbPhotoEdit, TbTrash } from "react-icons/tb";
import { PiFilePdf } from "react-icons/pi";
import { Link } from "react-router-dom";
import { uploadFile } from "../../../utils/reusable_functions";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { CgAsterisk } from "react-icons/cg";

type Props = {
  i_value?: string;
  formValues: any;
  onChange: any;
  field: any;
  child_index?: number;
  group_name?: string;
};

//Loader component
const Loader = () => (
  <div
    className="absolute top-0 bottom-0 left-0 right-0 bg-white/20 backdrop-blur-sm flex 
     items-center justify-center gap-2 p-3 overflow-hidden"
  >
    <div className="h-6 w-6 rounded-full border-2 border-zinc-300 border-l-sky-500 animate-spin shrink-0"></div>
    <span
      className="whitespace-nowrap truncate text-xxs uppercase text-zinc-100
     group-hover:text-sky-500 transition-all font-semibold"
    >
      uploading ...
    </span>
  </div>
);

const FileInput = ({
  i_value,
  formValues,
  onChange,
  field,
  child_index,
  group_name,
}: Props) => {
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const value =
    i_value ||
    (group_name
      ? formValues?.[group_name]
          ?.find((data: any) => data?.index === child_index)
          ?.values?.find(
            (value: { name: string; value: any }) => value?.name === field?.name
          )?.value || ""
      : formValues?.[field?.name] || "");

  //Component ===================
  return (
    <>
      {!value ? (
        <label
          htmlFor={field?.name + (child_index || "")}
          className="w-full h-fit relative cursor-pointer select-none overflow-hidden flex"
        >
          <input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const target: any = e.target;
              const content: any = target.files[0];
              setFile(content);
            }}
            type="file"
            required={field?.required}
            accept={field?.accept}
            className="absolute scale-0"
            id={field?.name + (child_index || "")}
            name={field?.name + (child_index || "")}
          />
          <div
            className="h-16 w-full bg-inherit shrink-0 p-2 px-3 overflow-hidden flex items-center
           justify-between gap-4 group relative"
          >
            <div className="flex items-center overflow-hidden gap-3">
              <div
                className="h-10 w-10 bg-white rounded flex items-center justify-center text-2xl text-zinc-500
             group-hover:text-sky-500 transition-all shadow shrink-0 border border-dashed border-zinc-200"
              >
                {field?.accept?.includes("pdf") ? (
                  <PiFilePdf />
                ) : (
                  <TbPhotoEdit />
                )}
              </div>
              <div
                className="overflow-hidden text-xxs uppercase text-zinc-500
               group-hover:text-sky-500 transition-all font-bold flex items-center gap-1"
              >
                <span className="whitespace-nowrap truncate overflow-hidden">
                  upload{" "}
                  {file?.name?.replace("_", " ") ||
                    field?.placeholder?.replace("_", " ") ||
                    field?.name?.replace("_", " ")}{" "}
                </span>
                {field?.required && (
                  <span className="text-red-600 text-sm shrink-0">
                    <CgAsterisk />
                  </span>
                )}
              </div>
            </div>

            {/**Upload file */}
            {file && (
              <button
                onClick={() => {
                  if (file) {
                    setLoading(true);
                    uploadFile(file)
                      .then((data) => {
                        onChange(data);
                        setFile(null);
                        setLoading(false);
                      })
                      .catch((error: any) => {
                        console.log(error);
                        setLoading(false);
                      });
                  }
                }}
                type="button"
                className="h-8 w-20 bg-sky-500 text-white font-bold text-xxs flex items-center justify-center
                gap-0.5 hover:opacity-80 transition-all uppercase shrink-0 border border-inherit rounded-full"
              >
                <span className="!leading-none">upload</span>
              </button>
            )}
          </div>
          {/**Loader */}
          {loading && <Loader />}
        </label>
      ) : (
        <div
          className="w-full h-16 bg-inherit p-2 px-3 relative overflow-hidden
          flex items-center justify-between gap-2 shrink-0"
        >
          <div className="flex items-center gap-3 w-full overflow-hidden">
            <img
              src={field?.accept?.includes("pdf") ? "/images/pdf.png" : value}
              alt="file preview"
              className="h-10 w-10 rounded-full shrink-0 border border-zinc-200 bg-white object-cover object-center"
            />
            <span
              className="whitespace-nowrap truncate text-xxs uppercase text-zinc-500
           group-hover:text-sky-500 transition-all font-semibold"
            >
              preview {field?.name}
            </span>
          </div>
          <div className="flex items-center gap-2 shrink-0 w-fit overflow-hidden">
            <Link
              to={value}
              target="_blank"
              className="h-8 w-24 bg-white rounded text-sky-500 text-xxs flex items-center justify-center
                gap-0.5 hover:gap-1 transition-all uppercase shrink-0 border border-sky-500/50"
            >
              <span className="!leading-none font-bold">preview</span>
              <TbArrowUpRight className="text-lg" />
            </Link>

            {/**Delete File ==================== */}
            <button
              onClick={() => {
                setLoading(true);
                const storage = getStorage();
                const fileRef = ref(storage, value);
                deleteObject(fileRef)
                  .then(() => {
                    onChange("");
                    setLoading(false);
                  })
                  .catch((error: any) => {
                    console.log(error);
                    setLoading(false);

                    if (error?.code === "storage/object-not-found")
                      onChange("");
                  });
              }}
              type="button"
              className="h-8 w-8 bg-red-600 text-white text-xxs flex items-center justify-center
                gap-0.5 hover:gap-1 transition-all uppercase shrink-0 rounded"
            >
              <TbTrash className="text-lg" />
            </button>
          </div>
          {/**Loader */}
          {loading && <Loader />}
        </div>
      )}
    </>
  );
};

export default FileInput;
