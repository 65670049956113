import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateType {
  spotlight_open: boolean;
}

const initialState: InitialStateType = {
    spotlight_open:false
  };

export const SpotlightSlice = createSlice({
  name: "spotlight_data",
  initialState,
  reducers: {
    openSpotlight: (state, action: PayloadAction<boolean>) => {
      state.spotlight_open = action.payload;
    },
  },
});

export const { openSpotlight } = SpotlightSlice.actions;

export default SpotlightSlice.reducer;
