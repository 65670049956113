import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScheduleEventType } from "../../types/schedule_types.d";
import {
  getCurrentMonthSETimestamps,
  retrieveLocalData,
} from "../../utils/reusable_functions";
import { RoutesDataType } from "@/types/auto-scheduler";

interface InitialStateType {
  new_event: ScheduleEventType | null;
  active_user_shifts: ScheduleEventType[];
  dashboard_dates: { start: number; end: number };
  routes_data: RoutesDataType[];
}

const initialState: InitialStateType = {
  new_event: null,
  active_user_shifts: [],
  dashboard_dates:
    retrieveLocalData("dashboard_dates") || getCurrentMonthSETimestamps(),
  routes_data: [],
};

export const ScheduleSlice = createSlice({
  name: "schedule_data",
  initialState,
  reducers: {
    setNewEvent: (state, action: PayloadAction<any>) => {
      state.new_event = action.payload;
    },
    loadShifts: (state, action: PayloadAction<ScheduleEventType[]>) => {
      state.active_user_shifts = action.payload;
    },
    changeDashboardDates: (state, action: PayloadAction<any>) => {
      state.dashboard_dates = action.payload;
      localStorage.setItem("dashboard_dates", JSON.stringify(action.payload));
    },
    loadRoutesData: (state, action: PayloadAction<RoutesDataType[]>) => {
      state.routes_data = action.payload;
    },
  },
});

export const { setNewEvent, loadShifts, changeDashboardDates, loadRoutesData } =
  ScheduleSlice.actions;

export default ScheduleSlice.reducer;
