import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CareDocType } from "../../types/care_types";
import { PerformanceMetricsType } from "../../types/employee";

interface InitialStateType {
  employees_docs: any;
  schedule_data: any;
  activeCareProfile: CareDocType | null;
  performance_metrics: PerformanceMetricsType[];
}

const initialState: InitialStateType = {
  employees_docs: [],
  schedule_data: [],
  activeCareProfile: null,
  performance_metrics:[]
};

export const PeopleSlice = createSlice({
  name: "people_data",
  initialState,
  reducers: {
    loadEmployees: (state, action: PayloadAction<any>) => {
      state.employees_docs = action.payload;
    },
    loadSchedule: (state, action: PayloadAction<any>) => {
      state.schedule_data = action.payload;
    },
    selectCareProfile: (state, action: PayloadAction<any>) => {
      state.activeCareProfile = action.payload;
    },
    loadPerformanceMetrics: (state, action: PayloadAction<PerformanceMetricsType[]|[]>) => {
      state.performance_metrics = action.payload;
    },
  },
});

export const { loadEmployees, loadSchedule, selectCareProfile,loadPerformanceMetrics } =
  PeopleSlice.actions;

export default PeopleSlice.reducer;
