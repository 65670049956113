import { Button } from "@/components/ui/button";
import { House } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Smallnav from "./small-nav";
import { ReactNode } from "react";

const Topnav = ({
  options,
}: {
  options: {
    name: string;
    description: string;
    path: string;
    icon: ReactNode;
  }[];
}) => {
  const navigate = useNavigate();

  // JSX Code ========================
  return (
    <nav
      className="h-14 md:h-3 w-full shrink-0 px-5 flex items-center
      justify-between bg-transparent"
    >
      <Smallnav options={options} />
      <Button
        onClick={() => {
          navigate("/");
        }}
        variant="outline"
        className="p-0 h-8 w-20 text-base flex gap-2 md:hidden drop-shadow-sm border-zinc-300 bg-zinc-50"
      >
        <House className="h-4 w-4 fill-zinc-200" />{" "}
        <span className="text-zinc-600 text-xss capitalize font-bold">
          home
        </span>
      </Button>
    </nav>
  );
};

export default Topnav;
