import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { createPortal } from "react-dom";
import React from "react";
import Topnav from "./top-nav";
import Sidenav from "./side-nav";

type Props = {
  options: {
    name: string;
    description: string;
    icon: React.ReactNode;
    path: string;
  }[];
};

const SubMenuTemplate = ({ options }: Props) => {
  // JSX Code ========================
  return (
    <React.Fragment>
      {createPortal(
        <motion.div
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          exit={{ y: 50 }}
          transition={{ duration: 0.3 }}
          className="h-full w-full flex flex-col overflow-hidden fixed inset-0 z-30 bg-white md:bg-black/80"
        >
          <Topnav options={options} />
          <div
            className="flex h-full w-full bg-zinc-100 rounded-t-3xl md:rounded-t-xl overflow-hidden
             drop-shadow border-t border-zinc-200"
          >
            <Sidenav options={options} />
            <div className="w-full h-full overflow-hidden pt-5">
              <div className="w-full h-full overflow-hidden overflow-y-scroll p-5 pt-0 bg-inherit">
                {/* Outlet */}
                <div className="w-full h-fit shrink-0 overflow-hidden">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </motion.div>,
        document.body
      )}
    </React.Fragment>
  );
};

export default SubMenuTemplate;
