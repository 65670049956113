import React, { Suspense, lazy } from "react";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import Loader from "./components/misc/loader";
import Login from "./pages/login page/login";
import PrivateRoutes from "./providers/private_routes";
import Home from "./pages/home page/home";
import Appshell from "./layout/app/app_layout";
import Care from "./pages/care page/care";
import PeopleEmployees from "./pages/people page/employees/employees";
import Summary from "./pages/care profiles/summary/summary";
import NotFound from "./not-found";
import { retrieveLocalData } from "./utils/reusable_functions";
import CokkieBanner from "./components/misc/cookie_banner";
import TaskLogs from "./pages/care profiles/tasks/task-logs";
import AutoScheduler from "./pages/scheduler/auto-scheduler";
import SchedulerLayout from "./layout/scheduler/scheduler-layout";
import PeoplesLayout from "./layout/peoples-layout/peoples-layout";
import ReportsLayout from "./layout/reports layout/reports-layout";
import SettingsLayout from "./layout/settings layout/settings-layout";

//Lazy Loading
const Lists = lazy(() => import("./pages/masterlists page/page"));
const Master = lazy(() => import("./pages/inventory/master/master"));
const Logs = lazy(() => import("./pages/inventory/master/logs"));
const ForgotPassword = lazy(
  () => import("./pages/forgot_password/forgot_password")
);
const ResetPassword = lazy(
  () => import("./pages/reset password/reset_password")
);
const CareLogs = lazy(
  () => import("./pages/care profiles/inventory/care_logs")
);
const Inventory = lazy(() => import("./pages/inventory/inventory"));
const Wiki = lazy(() => import("./pages/wiki/wiki"));
const Notes = lazy(() => import("./pages/care profiles/notes/notes"));
const Tasks = lazy(() => import("./pages/care profiles/tasks/tasks"));
const CarePlan = lazy(
  () => import("./pages/care profiles/care plan/care_plan")
);
const CarePlanCreateV2 = lazy(
  () => import("./pages/care profiles/care plan v2/create_plan_v2")
);
const CarePlanPreviewV2 = lazy(
  () => import("./pages/care profiles/care plan v2/preview_plan_v2")
);
const Schedule = lazy(() => import("./pages/care profiles/schedule/schedule"));
const Medication = lazy(
  () => import("./pages/care profiles/medication/medication")
);
const Charts = lazy(() => import("./pages/care profiles/charts/charts"));
const BodyMaps = lazy(
  () => import("./pages/care profiles/body maps/body_maps")
);
const Complaints = lazy(
  () => import("./pages/care profiles/complaints/complaints")
);
const Clientinventory = lazy(
  () => import("./pages/care profiles/inventory/inventory")
);
const Forms = lazy(() => import("./pages/forms page/forms"));
const CareProfile = lazy(() => import("./pages/care profiles/care_profile"));
//const People = lazy(() => import("./pages/people page/people"));
//const Reports = lazy(() => import("./pages/reports page/reports"));
//const Settings = lazy(() => import("./pages/settings page/settings"));
const RolesPermissions = lazy(
  () => import("./pages/settings page/roles_permissions/roles_permissions")
);
const AccountSettings = lazy(
  () => import("./pages/settings page/account/account")
);
const SecuritySetting = lazy(
  () => import("./pages/settings page/security/security")
);
const UsersSettings = lazy(() => import("./pages/settings page/users/users"));
const MedInventory = lazy(
  () => import("./pages/inventory/medication/med_inventory")
);

//Assessments
const Assessments = lazy(
  () => import("./pages/care profiles/assessments/assessments")
);
const Assessment1 = lazy(
  () => import("./pages/care profiles/assessments/charts/assessment_1")
);
const Assessment2 = lazy(
  () => import("./pages/care profiles/assessments/charts/assessment_2")
);
const InitialAssessment = lazy(
  () =>
    import(
      "./pages/care profiles/assessments/charts/initial assesment/initial_assessment"
    )
);

//Others
const Incidents = lazy(
  () => import("./pages/care profiles/incidents/incidents")
);
const Employee = lazy(
  () => import("./pages/people page/employees/employee/employee")
);
const Overview = lazy(
  () => import("./pages/people page/employees/employee/overview/overview")
);
const Training = lazy(
  () => import("./pages/people page/employees/employee/training/training")
);
const PerfomanceManagement = lazy(
  () =>
    import(
      "./pages/people page/employees/employee/performance management/performance_management"
    )
);
const PerformancePreview = lazy(
  () => import("./pages/people page/performance_preview/performance_review")
);
const Leave = lazy(
  () => import("./pages/people page/employees/employee/leave/leave")
);
const NewEmployeeForm = lazy(
  () => import("./pages/new_employee_form/new_employee_form")
);
const EmployeeDetailsCorrection = lazy(
  () =>
    import("./pages/employee_details_correction/employee_details_correction")
);
const FormSubmissionSuccess = lazy(
  () => import("./pages/form submission success/form_submission_success")
);
const ReferencesForm = lazy(
  () => import("./pages/references_form/references_form")
);
const HealthQForm = lazy(
  () => import("./pages/health questionnaire form/health_q_form")
);
const DisciplinaryDoc = lazy(
  () => import("./pages/disciplinary doc/disciplinary_doc")
);
const ReferencesPreview = lazy(
  () => import("./pages/references_preview/reference_preview")
);
const LeaveManagement = lazy(
  () => import("./pages/people page/leave management/leave_management")
);
const Payroll = lazy(() => import("./pages/people page/payroll/payroll"));
const Timesheet = lazy(
  () => import("./pages/people page/time sheet/timesheet")
);
const Expenses = lazy(() => import("./pages/people page//expenses/expenses"));
const NoAccess = lazy(() => import("./pages/no access/no_access"));

//Charts ===========================
const PegChart = lazy(
  () => import("./pages/care profiles/charts/charts preview/peg chart/peg")
);
const RegularChart = lazy(
  () =>
    import("./pages/care profiles/charts/charts preview/regular chart/regular")
);
const SkinCheckChart = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/skin check chart/skin_check"
    )
);
const OralHygieneChart = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/oral hygiene chart/oral_hygiene"
    )
);
const HygieneChart = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/daily hygiene/hygiene_chart"
    )
);
const PegMaintenance = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/peg maintenance/peg_maintenance"
    )
);
const FridgeRoomTemperature = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/fridge & room temperature/fridge_room_temperature"
    )
);
const WeightMonitoring = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/weight monitoring/weight_monitoring"
    )
);
const BloodSugarMonitoring = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/blood sugar monitoring/blood_sugar_monitoring"
    )
);
const AccuCheckNano = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/accu check nano/accu_check_nano"
    )
);
const NexusControlCheck = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/nexus control check/nexus_control_check"
    )
);
const VentilationShiftCheckList = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/ventilation shift checklist/ventilation_shift_checklist"
    )
);
const WaterTemp = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/water temperature/water_temperature"
    )
);
const ActivityLog = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/activity log/activity_log"
    )
);
const AbbeyPainScale = lazy(
  () =>
    import(
      "./pages/care profiles/charts/charts preview/abbey pain scale/abbey_pain_scale"
    )
);
const AllChartsLogs = lazy(
  () => import("./pages/care profiles/charts/all charts logs/all_charts_logs")
);

//Reports
const TrainingMatrix = lazy(
  () => import("./pages/reports page/training matrix/training_matrix")
);
const IncidentsReport = lazy(
  () => import("./pages/reports page/incidents/incidents")
);
const ComplaintsReport = lazy(
  () => import("./pages/reports page/complaints/complaints_report")
);
const NotesReport = lazy(() => import("./pages/reports page/notes/notes"));
const Performance = lazy(
  () => import("./pages/reports page/performance/performance")
);
const Competencies = lazy(
  () => import("./pages/reports page/competencies/competencies")
);

//Scheduler
const SchedulerRoutes = lazy(() => import("./pages/scheduler/routes/routes"));
const SchedulerSchedule = lazy(
  () => import("./pages/scheduler/schedule/schedule")
);
const Distances = lazy(() => import("./pages/scheduler/distances/distances"));
const SchedulerSettings = lazy(
  () => import("./pages/scheduler/settings/settings")
);
const Docs = lazy(() => import("./pages/scheduler/docs/docs"));
const CareRequest = lazy(() => import("./pages/scheduler/requests/requests"));

function App() {
  const [cookieBanner, closeBanner] = React.useState<any>(
    () => retrieveLocalData("ckTL") || null
  );

  return (
    <ErrorBoundary>
      <div className="overflow-hidden">
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <AnimatePresence>
              <Routes>
                {/**Error Page ===========================*/}
                <Route path="*" element={<NotFound />} />
                <Route path="/logIn" element={<Login />} />
                <Route path="/forgot_password" element={<ForgotPassword />} />
                <Route path="/reset_password" element={<ResetPassword />} />
                <Route element={<PrivateRoutes />}>
                  <Route path="/" element={<Appshell />}>
                    <Route path="/auto" element={<AutoScheduler />} />
                    <Route path="/auto2" element={<Routes />} />
                    <Route path="" element={<Home />} />
                    <Route
                      path="care"
                      element={
                        <AnimatePresence>
                          <Care
                            expectedPermission={"can view care page"}
                            expectedValue={true}
                          />
                        </AnimatePresence>
                      }
                    />
                    <Route
                      path="care_profile/"
                      element={
                        <CareProfile
                          expectedPermission={"can view client's care profile"}
                          expectedValue={true}
                        />
                      }
                    >
                      <Route path="" element={<Summary />} />
                      <Route
                        path="notes"
                        element={
                          <Notes
                            expectedPermission={
                              "can the user view client's notes"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="tasks"
                        element={
                          <Tasks
                            expectedPermission={
                              "can the user view client's tasks"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="tasks/logs"
                        element={
                          <TaskLogs
                            expectedPermission={
                              "can the user view client's tasks"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="care_plan"
                        element={
                          <CarePlan
                            expectedPermission={
                              "can the user view client's care plans"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="care_plan_create"
                        element={
                          <CarePlanCreateV2
                            expectedPermission={
                              "can the user view client's care plans"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="care_plan_v2"
                        element={
                          <CarePlanPreviewV2
                            expectedPermission={
                              "can the user view client's care plans"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="schedule"
                        element={
                          <Schedule
                            expectedPermission={
                              "can the user view client's schedule"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="medication"
                        element={
                          <Medication
                            expectedPermission={
                              "can the user view client's medication"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="daily_charts"
                        element={
                          <Charts
                            expectedPermission={
                              "can the user view client's daily charts"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="body_maps"
                        element={
                          <BodyMaps
                            expectedPermission={"can the user view body maps"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="incidents"
                        element={
                          <Incidents
                            expectedPermission={"can the user view incidetns"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="assessments"
                        element={
                          <Assessments
                            expectedPermission={"can the user view assessments"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="complaints"
                        element={
                          <Complaints
                            expectedPermission={"can the user view complaints"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="inventory"
                        element={
                          <Clientinventory
                            expectedPermission={
                              "can the user manage client inventory"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="inventory_logs/:itemId"
                        element={
                          <CareLogs
                            expectedPermission={
                              "can the user manage client inventory"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                    </Route>
                    <Route path="people" element={<PeoplesLayout />}>
                      <Route
                        path=""
                        element={
                          <PeopleEmployees
                            expectedPermission={"can the user view employees"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="employee/:employeeId"
                        element={
                          <AnimatePresence>
                            <Employee />
                          </AnimatePresence>
                        }
                      >
                        <Route
                          path=""
                          element={
                            <AnimatePresence>
                              <Overview />
                            </AnimatePresence>
                          }
                        />
                        <Route
                          path="training"
                          element={
                            <AnimatePresence>
                              <Training />
                            </AnimatePresence>
                          }
                        />
                        <Route
                          path="performance"
                          element={
                            <AnimatePresence>
                              <PerfomanceManagement />
                            </AnimatePresence>
                          }
                        />
                        <Route
                          path="leave"
                          element={
                            <AnimatePresence>
                              <Leave />
                            </AnimatePresence>
                          }
                        />
                      </Route>
                      <Route
                        path="leave"
                        element={
                          <LeaveManagement
                            expectedPermission={
                              "can the user view employees leave"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="timesheet"
                        element={
                          <Timesheet
                            expectedPermission={"can the user view timesheet"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="payroll"
                        element={
                          <Payroll
                            expectedPermission={"can the user view payroll"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="expenses"
                        element={
                          <Expenses
                            expectedPermission={"can the user access expenses"}
                            expectedValue={true}
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="reports"
                      element={
                        <ReportsLayout
                          expectedPermission={"can the user view reports"}
                          expectedValue={true}
                        />
                      }
                    >
                      <Route
                        path=""
                        element={
                          <IncidentsReport
                            expectedPermission={"can the user view reports"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="complaints"
                        element={
                          <ComplaintsReport
                            expectedPermission={"can the user view reports"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="notes"
                        element={
                          <NotesReport
                            expectedPermission={"can the user view reports"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="training"
                        element={
                          <TrainingMatrix
                            expectedPermission={"can the user view reports"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="performance"
                        element={
                          <Performance
                            expectedPermission={"can the user view reports"}
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="competencies"
                        element={
                          <Competencies
                            expectedPermission={"can the user view reports"}
                            expectedValue={true}
                          />
                        }
                      />
                    </Route>
                    <Route path="settings" element={<SettingsLayout />}>
                      <Route path="" element={<AccountSettings />} />
                      <Route path="security" element={<SecuritySetting />} />
                      <Route
                        path="users"
                        element={
                          <UsersSettings
                            expectedPermission={
                              "can the user view and manage users auth accounts"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="roles_permissions"
                        element={
                          <RolesPermissions
                            expectedPermission={
                              "can the user view and manage roles and permissions"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="forms"
                      element={
                        <Forms
                          expectedPermission={
                            "can the user view and manage forms"
                          }
                          expectedValue={true}
                        />
                      }
                    />
                    <Route
                      path="lists"
                      element={
                        <Lists
                          expectedPermission={
                            "can the user view and manage lists"
                          }
                          expectedValue={true}
                        />
                      }
                    />
                    <Route
                      path="inventory"
                      element={
                        <Inventory
                          expectedPermission={
                            "can the user view and manage inventory list"
                          }
                          expectedValue={true}
                        />
                      }
                    >
                      <Route
                        path=""
                        element={
                          <Master
                            expectedPermission={
                              "can the user view and manage inventory list"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="logs/:itemId"
                        element={
                          <Logs
                            expectedPermission={
                              "can the user view and manage inventory list"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                      <Route
                        path="med"
                        element={
                          <MedInventory
                            expectedPermission={
                              "can the user view and manage inventory list"
                            }
                            expectedValue={true}
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="wiki"
                      element={
                        <Wiki
                          expectedPermission={"can the user view wiki docs"}
                          expectedValue={true}
                        />
                      }
                    />

                    {/**Scheduler */}
                    <Route path="scheduler" element={<SchedulerLayout expectedPermission={
                              "can the user view and manage auto scheduler"
                            }
                            expectedValue={true} />}>
                      <Route path="" element={<SchedulerSchedule />} />
                      <Route path="routes" element={<SchedulerRoutes />} />
                      <Route path="distances" element={<Distances />} />
                      <Route path="docs" element={<Docs />} />
                      <Route path="settings" element={<SchedulerSettings />} />
                      <Route
                      path="requests"
                      element={
                        <CareRequest
                          expectedPermission={
                            "can the user view and manage care requests"
                          }
                          expectedValue={true}
                        />
                      }
                    />
                    </Route>
                  </Route>

                  {/**Charts  */}
                  <Route path="peg" element={<PegChart />} />
                  <Route path="assessment1" element={<Assessment1 />} />
                  <Route path="assessment2" element={<Assessment2 />} />
                  <Route
                    path="initialassessment"
                    element={<InitialAssessment />}
                  />
                  <Route path="regular" element={<RegularChart />} />
                  <Route path="skin-check" element={<SkinCheckChart />} />
                  <Route path="oral_hygiene" element={<OralHygieneChart />} />
                  <Route path="daily_hygiene" element={<HygieneChart />} />
                  <Route path="peg_maintenance" element={<PegMaintenance />} />
                  <Route
                    path="fridge_&_room_temperature"
                    element={<FridgeRoomTemperature />}
                  />
                  <Route
                    path="weight_monitoring"
                    element={<WeightMonitoring />}
                  />
                  <Route
                    path="blood_sugar_monitoring"
                    element={<BloodSugarMonitoring />}
                  />
                  <Route path="accu_check_nano" element={<AccuCheckNano />} />
                  <Route
                    path="nexus_control_check"
                    element={<NexusControlCheck />}
                  />
                  <Route
                    path="ventilation_shift_checklist"
                    element={<VentilationShiftCheckList />}
                  />
                  <Route path="water_temperature" element={<WaterTemp />} />
                  <Route path="activity_log" element={<ActivityLog />} />
                  <Route path="abbey_pain_scale" element={<AbbeyPainScale />} />
                  <Route
                    path="all_charts_logs"
                    element={
                      <AllChartsLogs
                        expectedValue={true}
                        expectedPermission="can the user add new logs on daily charts"
                      />
                    }
                  />
                </Route>
                <Route
                  path="new_employee_form"
                  element={
                    <AnimatePresence>
                      <NewEmployeeForm />
                    </AnimatePresence>
                  }
                />
                <Route
                  path="employee_details_correction"
                  element={
                    <AnimatePresence>
                      <EmployeeDetailsCorrection />
                    </AnimatePresence>
                  }
                />
                <Route
                  path="form_submission_success"
                  element={
                    <AnimatePresence>
                      <FormSubmissionSuccess />
                    </AnimatePresence>
                  }
                />
                <Route
                  path="references_form"
                  element={
                    <AnimatePresence>
                      <ReferencesForm />
                    </AnimatePresence>
                  }
                />
                <Route
                  path="health_q"
                  element={
                    <AnimatePresence>
                      <HealthQForm />
                    </AnimatePresence>
                  }
                />
                <Route
                  path="/performance_preview"
                  element={
                    <AnimatePresence>
                      <PerformancePreview />
                    </AnimatePresence>
                  }
                />
                <Route
                  path="reference_preview"
                  element={
                    <AnimatePresence>
                      <ReferencesPreview />
                    </AnimatePresence>
                  }
                />
                <Route
                  path="disciplinary_doc"
                  element={
                    <AnimatePresence>
                      <DisciplinaryDoc />
                    </AnimatePresence>
                  }
                />
                <Route path="no_acess" element={<NoAccess />} />
              </Routes>

              <AnimatePresence>
                {cookieBanner !== "yes" && (
                  <CokkieBanner closeBanner={closeBanner} />
                )}
              </AnimatePresence>
            </AnimatePresence>
          </Suspense>
        </BrowserRouter>
      </div>
    </ErrorBoundary>
  );
}

export default App;
