import { cardio } from "ldrs";

cardio.register();

const Loader = () => {
  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center
        z-[999999] bg-white"
    >
      <l-cardio size="123" stroke="8" speed="2" color="#0ea5e9"></l-cardio>
    </div>
  );
};

export default Loader;
