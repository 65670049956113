import React from "react";
import Task from "./task";
import { TaksLogsType, TaskType } from "../../../types/care_types";
import CurrentTime from "./current_time";
import { dateFromLondonMilliseconds } from "../../../utils/reusable_functions";

type Props = {
  tasks_today: TaskType[];
  tasks_logs: TaksLogsType[];
  setTask: any;
  date: { start: number; end: number };
};

const TasksList = ({ tasks_today, tasks_logs, setTask, date }: Props) => {
  //JSX Code ===================
  return (
    <div
      className="mt-0.5 w-full h-fit flex flex-col shrink-0 border border-zinc-400/60 bg-diagnal
     rounded-lg md:rounded overflow-hidden"
    >
      {Array(24)
        .fill(0)
        .map((_any, index: number) => {
          const hour = dateFromLondonMilliseconds().getHours();
          return (
            <div
              key={index}
              className={`w-full min-h-[2.5rem] h-fit flex items-stretch border-b border-zinc-300 shrink-0
              overflow-hidden relative`}
            >
              {/**Indicate current time */}
              {index === hour && <CurrentTime />}

              {/**Hours */}
              <div
                className="w-20 shrink-0 bg-white border-r border-zinc-300 flex items-center 
                 justify-center text-xxs uppercase font-bold text-zinc-800"
              >
                <span className="font-mono">
                  {index.toString().padStart(2, "0")}:00 hr
                </span>
              </div>

              {/**Tasks per hour */}
              <div className="w-full h-fit flex flex-wrap items-venter p-1 gap-1 overflow-hidden overflow-x-scroll no-scrollbar">
                {[...(tasks_today || [])]
                  ?.filter(
                    (task: TaskType) =>
                      Number(task.due_time?.split(":")?.[0]) === index
                  )
                  ?.map((task: TaskType) => {
                    return (
                      <Task
                        key={task?.id}
                        task={task}
                        setTask={setTask}
                        date={date}
                        tasks_logs={tasks_logs}
                      />
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default React.memo(TasksList);
