import { Command } from "cmdk";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useClickOutside, useHotkeys, useOs } from "@mantine/hooks";
import { openSpotlight } from "../../redux/slices/spotlight";
import { TbArrowUpRight } from "react-icons/tb";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { orderBy } from "firebase/firestore";
import { Link } from "react-router-dom";
import { IoDocumentText } from "react-icons/io5";

const Spotlight = () => {
  const os = useOs();
  const dispatch = useDispatch();
  const spotlight_open = useSelector(
    (state: RootState) => state.spotlight_data.spotlight_open
  );
  const cmdkRef: any = useClickOutside(() => dispatch(openSpotlight(false)));

  //Fetch Wiki Docs
  const { data: fetched_wiki_docs, loading }: any = useFirestoreCollection(
    `wiki`,
    10000,
    "98",
    [orderBy("createdAt", "desc")],
    [],
    true
  );

  //Open Shorcut
  useHotkeys([
    ["ctrl+K", () => dispatch(openSpotlight(!spotlight_open))],
    ["mod+K", () => dispatch(openSpotlight(!spotlight_open))],
  ]);

  //Component ================
  return (
    <Command.Dialog
      ref={cmdkRef}
      open={spotlight_open}
      onOpenChange={(e) => {
        dispatch(openSpotlight(e));
      }}
      contentClassName="fixed inset-0 p-4 pt-[15vh] overflow-y-auto"
      overlayClassName="fixed inset-0 backdrop-blur-md bg-black/60"
    >
      <div
        className="bg-white border-zinc-300 max-w-2xl mx-auto rounded-lg shadow-2xl relative
         flex flex-col gap-2 p-2"
      >
        <Command.Input
          placeholder="What are you looking for ?"
          className="border-b border-zinc-200 focus:border-sky-300 transition-all h-12
           placeholder:text-zinc-500 text-sm"
        />

        <Command.List className="max-h-72 overflow-y-auto flex flex-col">
          {loading && (
            <Command.Loading className="mt-4 text-zinc-500 h-12 shrink-0 text-center">
              Hang on…
            </Command.Loading>
          )}

          <Command.Empty className="mt-4 text-zinc-500 h-12 shrink-0 text-center">
            No results found.
          </Command.Empty>

          <Command.Group heading="" className="w-full flex flex-col px-2">
            {fetched_wiki_docs?.map((doc: any) => {
              return (
                <Command.Item
                  key={doc?.id}
                  className="h-9 shrink-0 gap-2 text-sm capitalize text-zinc-700"
                >
                  <Link
                    to={doc?.link || ""}
                    target="_blank"
                    className="w-full h-full flex items-center justify-between gap-4 overflow-hidden
                     hover:opacity-80 transition-all"
                  >
                    <div className="w-full flex items-center gap-1">
                      <IoDocumentText className="text-lg text-zinc-400" />
                      <span className="capitalize font-medium">
                        {doc?.name}
                      </span>
                    </div>
                    <TbArrowUpRight className="text-lg text-zinc-500" />
                  </Link>
                </Command.Item>
              );
            })}
          </Command.Group>
        </Command.List>

        {/**Intructions */}
        <div
          className="w-full h-10 shrink-0 flex items-center justify-between border-t
         border-zinc-200 px-2"
        >
          <span className="text-xs font-medium text-zinc-500">
            Spotlight search
          </span>
          <div className="flex items-center gap-1">
            <div
              className="shrink-0 flex items-center bg-white shadow h-6 w-fit px-2 border
             border-zinc-200 rounded text-xxs font-bold text-zinc-700"
            >
              {os === "macos" ? "⌘ + k" : "ctrl + k"}
            </div>
            <button
              onClick={() => dispatch(openSpotlight(false))}
              className="shrink-0 flex items-center bg-white shadow h-6 w-fit px-2 border
             border-zinc-200 rounded text-xxs font-bold text-zinc-700"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Command.Dialog>
  );
};

export default Spotlight;
