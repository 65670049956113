import { RiStethoscopeFill } from "react-icons/ri";
import { IoPeople } from "react-icons/io5";
import { Link } from "react-router-dom";
import { GiTakeMyMoney } from "react-icons/gi";
import { TbTransformFilled } from "react-icons/tb";
import useFirestoreCollection from "../../../hooks/useFirestoreCollection";
import { useState } from "react";
import DatePicker from "../../../components/date picker/DatePicker";
import { orderBy, where } from "firebase/firestore";
import AttandanceHeatMap from "./attandance_heatmap";
import Locations from "./locations/locations";

const Admin = () => {
  const options = [
    {
      name: "manage clients",
      path: "care",
      icon: <RiStethoscopeFill className="text-xl shrink-0 text-sky-500 h-8" />,
    },
    {
      name: "manage employees",
      path: "people",
      icon: <IoPeople className="text-xl shrink-0 text-lime-500 h-8" />,
    },
    {
      name: "run a payroll",
      path: "people/payroll",
      icon: <GiTakeMyMoney className="text-xl shrink-0 text-violet-500" />,
    },
    {
      name: "manage forms",
      path: "forms",
      icon: <TbTransformFilled className="text-xl shrink-0 text-rose-500" />,
    },
  ];
  const [dates, setDates] = useState<any>({
    start: new Date().getTime() - 604800000,
    end: new Date().getTime(),
  });
  const { data: schedule_data } = useFirestoreCollection(
    `schedule`,
    500,
    dates,
    [
      where("start_date", ">=", dates.start),
      where("start_date", "<=", dates.end + 86400000),
      where("published", "==", true),
      orderBy("start_date", "desc"),
    ],
    [dates],
    true
  );

  //Component
  return (
    <div
      className="w-full h-fit shrink-0 overflow-hidden flex flex-col"
    >
      <div className="w-full h-fit shrink-0 hidden mflex items-center justify-between gap-4">
        {/**Quick Actions */}
        <div
          className="w-full h-fit content-start flex itesm-center overflow-hidden overflow-x-scroll
          no-scrollbar gap-1.5"
        >
          {options.map((option) => {
            return (
              <Link
                to={option.path}
                className="h-10 w-fit px-3 bg-white rounded overflow-hidden cursor-pointer border
                border-zinc-300 transitiona-ll flex items-center gap-2 shrink-0"
              >
                {option.icon}
                <h1 className="text-xxs !leading-none uppercase font-bold text-zinc-800 shrink-0">
                  {option.name}
                </h1>
              </Link>
            );
          })}
        </div>

        <DatePicker
          dates={dates}
          additionalStyles={`shrink-0 text-xxs uppercase font-semibold text-zinc-600 w-full h-full bg-white border 
            border-zinc-300 rounded flex relative pl-[0.1rem] pr-2`}
          changeDate={(e: any) => {
            localStorage.setItem("dashboard_dates", JSON.stringify(e));
            setDates({ ...dates, ...e });
          }}
          parentWidth="w-44 h-10"
          datePosition="top-[calc(100%+1rem)] right-0"
        />
      </div>

      <AttandanceHeatMap schedule_data={schedule_data} dates={dates} />
      <Locations schedule_data={schedule_data} />

      {/**Clock In Data and Clients*/}
      {/* <div className="mt-4 w-full h-fit shrink-0 gap-4 flex flex-col lg:flex-row overflow-hidden">
        <ScheduleData chatData={chatData} statsData={statsData} />
        <Stats dates={dates} statsData={statsData} />
      </div> */}

      {/**Logs */}
      {/* <Clocks schedule_data={schedule_data as ScheduleEventType[]} /> */}
    </div>
  );
};

export default Admin;
