import { limit, onSnapshot, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllRoles,
  loadAllUsers,
  loadRole,
  loadSystemSettings,
  updateUserData,
} from "../../redux/slices/session_slice";
import { useAuth } from "../../hooks/useAuth";
import { rolesRef, systemRef, usersRef } from "../functions";
import { RootState } from "../../redux/store";

const UserData = () => {
  const { currentUser }: any = useAuth();
  const user = useSelector((state: RootState) => state.session_data.user);
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const dispatch = useDispatch();

  //Fetch Data And Store It Locally
  useEffect(() => {
    let unsubscribe: () => void;
  
    if (currentUser?.uid) {
      unsubscribe = onSnapshot(
        query(usersRef, where("uid", "==", currentUser?.uid), limit(1)),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            updateUserData(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))[0]
            )
          );
        }
      );
    }
  
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, currentUser]);
  

  //Fetch Data And Store It Locally
  useEffect(() => {
    let unsubscribe: () => void;

    if (
      currentUser?.uid &&
      session_role?.permissions?.[
        "can the user view and manage users auth accounts"
      ]
    ) {
      unsubscribe = onSnapshot(
        usersRef,
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            loadAllUsers(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, currentUser, session_role?.permissions]);

  //Fetch Data And Store It Locally
  useEffect(() => {
    let unsubscribe: () => void;

    if (currentUser?.uid && user?.role_id) {
      unsubscribe = onSnapshot(
        query(rolesRef, where("id", "==", user?.role_id || ""), limit(1)),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            loadRole(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))[0]
            )
          );
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, currentUser, user?.role_id]);

  //Fetch Data And Store It Locally
  useEffect(() => {
    let unsubscribe: () => void;

    if (
      currentUser?.uid &&
      session_role?.permissions?.[
        "can the user view and manage roles and permissions"
      ]
    ) {
      unsubscribe = onSnapshot(
        rolesRef,
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            loadAllRoles(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, currentUser, session_role?.permissions]);

  //System Data It Locally
  useEffect(() => {
    let unsubscribe: () => void;

    if (currentUser?.uid) {
      unsubscribe = onSnapshot(
        systemRef,
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            loadSystemSettings(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, currentUser]);

  //Compoent ===========================
  return <></>;
};

export default UserData;