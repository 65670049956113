import { limit, onSnapshot, query } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { customListsRef, masterListsRef } from "../functions";
import {
  updateLocalMasterLists,
  updateLocalOptionalList,
} from "../../redux/slices/lists_slice";

const ListsData = () => {
  const { currentUser }: any = useAuth();
  const dispatch = useDispatch();

  //Fetch Data And Store It Locally
  useEffect((): any => {
    return (
      onSnapshot(
        query(masterListsRef, limit(300)),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            updateLocalMasterLists(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      )
    );
  }, [dispatch, currentUser]);

  //Fetch Data And Store It Locally
  useEffect((): any => {
    return (
      onSnapshot(
        query(customListsRef, limit(5000)),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          const data = snapshot.docs.map(
            (doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            })
          );
          dispatch(updateLocalOptionalList(data));
        }
      )
    );
  }, [dispatch, currentUser]);

  //Compoent ===========================
  return <></>;
};

export default ListsData;
