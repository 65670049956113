import React, { useMemo } from "react";
import {
  StepFormType,
} from "../../../../types/forms_types";
import useFirestoreCollection from "../../../../hooks/useFirestoreCollection";
import {
  retrieveLocalData,
  toPureString,
} from "../../../../utils/reusable_functions";
import { orderBy, where } from "firebase/firestore";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { RiTempColdLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ComplaintType } from "../../../../types/complaint";
import { PiNoteFill } from "react-icons/pi";
import { RoleType } from "../../../../types/session_type";

const Stats = ({ date }: { date: { start: number; end: number } }) => {
  const navigate = useNavigate();
  const forms = useSelector((state: RootState) => state.forms_data.forms);
  const activeForm = useMemo(() => {
    return [...(forms || [])]?.find(
      (form: StepFormType | any) =>
        form?.type === "charts form" &&
        toPureString(form?.name) === toPureString("client monitoring form")
    );
  }, [forms]);
  const client_id = retrieveLocalData("client_id");
  const { data: fetchedIncidentsData } = useFirestoreCollection(
    `incidents`,
    10,
    client_id,
    [
      where("client_id", "==", client_id || ""),
      where("date", ">=", date.start),
      where("date", "<=", date.end),
      orderBy("date", "desc"),
    ]
  );
  const { data: fetchedComplaintsData }: { data: ComplaintType[] | [] } =
    useFirestoreCollection(
      `complaints`,
      10,
      client_id,
      [
        where("client_id", "==", client_id),
        where("status", "==", "open"),
        orderBy("date", "desc"),
      ],
      [client_id]
    );
  const { data: fetchedMedRecords } = useFirestoreCollection(
    `medication_admin_logs`,
    1000,
    activeForm?.name,
    [
      where("date", ">=", date.start),
      where("date", "<=", date.end),
      where("parent_id", "==", retrieveLocalData("client_id")),
      where("administered", "==", "r"),
      orderBy("date", "desc"),
    ]
  );
  const session_role: RoleType = useSelector(
    (state: RootState) => state.session_data?.session_role
  );

  //Component ======================
  return (
    <div
      className="w-full h-fit grid grid-cols-3 md:grid-cols-5 gap-2 overflow-hidden py-2 shrink-0"
    >
      {/**Check Recejcted medications */}
      <button
        onClick={() => navigate("/care_profile/medication")}
        className="col-span-1 h-[7.5rem] rounded-md bg-red-500 shrink-0 p-4 flex 
        flex-col justify-center gap-1 hover:shadow-md transition-all"
      >
        <h1 className="shrink-0 text-zinc-100 font-bold text-xl">
          {(fetchedMedRecords?.length || 0)?.toString()?.padStart(2, "0")}
        </h1>
        <span className="text-white text-xss font-bold uppercase text-left">
          rejected / refused medication
        </span>
      </button>

      {/**Go to incidents */}
      <button
        onClick={() => navigate("/care_profile/incidents")}
        className="col-span-1 h-[7.5rem] rounded-md bg-red-500 shrink-0 p-4 flex 
        flex-col justify-center gap-1 hover:shadow-md transition-all"
      >
        <h1 className="shrink-0 text-zinc-100 font-bold text-xl">
          {(fetchedIncidentsData?.length || 0)?.toString()?.padStart(2, "0")}
        </h1>
        <span className="text-white text-xss font-bold uppercase text-left">
          reported or recorded incidents
        </span>
      </button>

      {/**Go to incidents */}
      <button
        onClick={() => navigate("/care_profile/complaints")}
        className="col-span-1 h-[7.5rem] rounded-md bg-red-500 shrink-0 p-4 flex 
        flex-col justify-center gap-1 hover:shadow-md transition-all"
      >
        <h1 className="shrink-0 text-zinc-100 font-bold text-xl">
          {(fetchedComplaintsData?.length || 0)?.toString()?.padStart(2, "0")}
        </h1>
        <span className="text-white text-xss font-bold uppercase text-left">
          reported or recorded incidents
        </span>
      </button>

      {/**Notes */}
      {session_role?.permissions?.["can the user view client's notes"] && (
        <button
          onClick={() => navigate("/care_profile/daily_charts")}
          className="col-span-1 h-[7.5rem] rounded-md bg-sky-500 shrink-0 p-4 hidden md:flex 
        flex-col justify-center gap-1 hover:shadow-md transition-all"
        >
          <PiNoteFill className="shrink-0 text-zinc-100 text-3xl" />
          <span className="text-white text-xss font-bold uppercase text-left">
            view recent or add new notes
          </span>
        </button>
      )}

      {/**Update monitoring data */}
      <button
        onClick={() => navigate("/care_profile/daily_charts")}
        className="col-span-1 h-[7.5rem] rounded-md bg-sky-500 shrink-0 p-4 hidden md:flex 
        flex-col justify-center gap-1 hover:shadow-md transition-all"
      >
        <RiTempColdLine className="shrink-0 text-zinc-100 text-3xl" />
        <span className="text-white text-xss font-bold uppercase text-left">
          update monitoring data
        </span>
      </button>
    </div>
  );
};

export default React.memo(Stats);
