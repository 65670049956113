import {
  UploadResult,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import CryptoJS from "crypto-js";
import { getAggregateFromServer, query, sum, where } from "firebase/firestore";
import { scheduleRef } from "../firebase/functions";
import { auth } from "../firebase/firebase_config";
import { signOut } from "firebase/auth";
import { PreferredTimesType } from "@/types/auto-scheduler";
import { ShiftType } from "@/types/schedule_types";

interface DataObject {
  [key: string]: any; // Adjust the type according to your data structure
}

export const toUpper = (str: string) => {
  const firstChar = str?.charAt(0).toUpperCase();
  const otherPart = str?.split("");
  otherPart?.shift();
  return firstChar + otherPart?.join("")?.toLowerCase();
};

//Email Validation function ============
export const isEmail = (email: any) => {
  return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
};

export const saveLocal = (myString: any) => {
  function toBinary(string: any) {
    const codeUnits = Uint16Array.from({ length: string.length }, (_, index) =>
      string.charCodeAt(index)
    );
    const charCodes = new Uint8Array(codeUnits.buffer);

    let result = "";
    charCodes.forEach((char) => {
      result += String.fromCharCode(char);
    });
    return result;
  }

  const converted = toBinary(myString);
  return btoa(converted);
};

export const retriveLocal = (encoded: any) => {
  function fromBinary(binary: any) {
    const bytes = Uint8Array.from({ length: binary.length }, (_, index) =>
      binary.charCodeAt(index)
    );
    const charCodes = new Uint16Array(bytes.buffer);

    let result = "";
    charCodes.forEach((char) => {
      result += String.fromCharCode(char);
    });
    return result;
  }

  const decoded = atob(encoded);
  return fromBinary(decoded);
};

export const formatNumber = (number: number, format?: string) => {
  if (format === "duration") {
    let duration = number;
    const hours = Math.floor(duration / 3600000);
    duration = duration % 3600000;
    const minutes = Math.floor(duration / 60000);

    return `${hours}H ${minutes}M`;
  }

  if (number >= 10 && number < 1000 && format !== "duration")
    return number.toString();
  if (number < 10 && format !== "duration") return "0" + number.toString();

  const units = ["K", "M", "B", "T"];
  let unitIndex = -1;

  while (number >= 1000 && format !== "duration") {
    number /= 1000;
    unitIndex++;
  }

  return `${number.toFixed(1)}${units[unitIndex]}`;
};

export function isPhoneNumber(phoneNumber: any) {
  // Strip out all non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, "");
  // Allow numbers with 10 or more digits up to a maximum of 15 digits
  const isValidLength = digitsOnly.length >= 10 && digitsOnly.length <= 15;
  // Use a regex to check if the string contains only digits
  const containsOnlyDigits = /^\d+$/.test(digitsOnly);
  return isValidLength && containsOnlyDigits;
}

//Number Spacing ====
export const numberWithSpaces = (x: any) => {
  const parts = x?.toString()?.split(".");
  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts?.join(".");
};

//Get An Array Of Miliseconds
export const getDateRangeArray = (
  startDateMs: number,
  endDateMs: number
): number[] => {
  const dates = [];
  let currentDateMs = startDateMs;
  while (currentDateMs <= endDateMs) {
    dates.push(currentDateMs);
    currentDateMs += 86400000; // add one day in milliseconds
  }
  return dates;
};

export function getMonthTimestamps(month: number, year: number): number[] {
  //MOnths are zero indexed
  const startDate = new Date(year, month, 1).getTime();
  const endDate = new Date(year, month + 1, 0).getTime() + 86399999;

  const timestamps: number[] = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    timestamps.push(currentDate);
    currentDate += 86400000; // add one day in milliseconds
  }

  return timestamps;
}

// define function to calculate time difference
export const getTimeDifference = (date: number) => {
  const now = new Date().getTime();
  const diff = now - new Date(date).getTime();
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const seconds = Math.floor((diff / 1000) % 60);
  return `${(hours < 0 ? 0 : hours).toString().padStart(2, "0")}:${(minutes < 0
    ? 0
    : minutes
  )
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

//Get Duration
export function formatMilliseconds(ms: number, subtractMs?: number): string {
  const totalSeconds = Math.floor((subtractMs ? ms - subtractMs : ms) / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formatNumber = (num: number) =>
    (isNaN(num) ? 0 : num)?.toString()?.padStart(2, "0");

  return `${formatNumber(hours)}H :${formatNumber(minutes)}M :${formatNumber(
    seconds
  )}S`;
}

//Average Time In MM:SS Format
export function calculateAverage(milliseconds: number, number: number) {
  // Calculate the average number of milliseconds per "number"
  const averageMillisPerNumber = milliseconds / number;

  // Convert the average milliseconds per "number" to hours, minutes, and seconds
  const hours = Math.floor(averageMillisPerNumber / 3600000);
  const minutes = Math.floor((averageMillisPerNumber % 3600000) / 60000);
  const seconds = Math.floor((averageMillisPerNumber % 60000) / 1000);

  // Format the output as "00H:00M:00S"
  const formattedOutput = `${(isNaN(hours) ? 0 : hours)
    .toString()
    .padStart(2, "0")}:${(isNaN(minutes) ? 0 : minutes)
    .toString()
    .padStart(2, "0")}:${(isNaN(seconds) ? 0 : seconds)
    .toString()
    .padStart(2, "0")}`;

  // Return the formatted output
  return formattedOutput;
}

//Extract Images Only
export const extractImages = (Str: any): string => {
  const images = Str?.match(/<img[^>]*>/gims);
  const text = Str?.replace(/<img[^>]*>/gims, "");
  const newStr = `${text} ${images ? "<br/>" + images?.join(" ") : ""}`;

  return newStr;
};

// Decrypt
export const decrypt = (salt: string, encoded: string): string | null => {
  const textToChars = (text: string): number[] =>
    text
      ?.toString()
      ?.split("")
      ?.map((c) => c?.charCodeAt(0));
  const applySaltToChar = (code: number): number =>
    textToChars(salt)?.reduce((a, b) => a ^ b, code);

  const encodedPairs = encoded.match(/.{1,2}/g);
  if (!encodedPairs) return null;

  return encodedPairs
    .map((hex: string) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode: number) => String.fromCharCode(charCode))
    .join("")
    ?.replace(/[\u0000-\u0019]+/g, "")
    ?.replace(/\n/g, "\\n")
    ?.replace(/\r/g, "\\r")
    ?.replace(/\t/g, "\\t");
};

// Encrypt
export const crypt = (salt: string, text: string): string => {
  const textToChars = (text: string): number[] =>
    text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n: number): string => ("0" + n.toString(16)).substr(-2);
  const applySaltToChar = (code: number[]): number[] =>
    code.map((charCode) => textToChars(salt).reduce((a, b) => a ^ b, charCode));

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .flat()
    .map(byteHex)
    .join("");
};

export const getCurrentDateInput = () => {
  const dateObj = new Date();

  // get the month in this format of 04, the same for months
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();

  const shortDate = `${year}-${month}-${day}`;

  return shortDate;
};

export function formatDuration(milliseconds: number): string {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  const formattedDays = days.toString().padStart(2, "0");
  const formattedHours = remainingHours.toString().padStart(2, "0");
  const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function parseDuration(durationString: string): number {
  const [days, hours, minutes, seconds] = durationString.split(":").map(Number);
  const totalSeconds =
    days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
  const totalMilliseconds = totalSeconds * 1000;
  return totalMilliseconds;
}

export function generateTimeArray() {
  const times = [];

  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 5) {
      const hourString = hours < 10 ? "0" + hours : hours.toString();
      const minuteString = minutes < 10 ? "0" + minutes : minutes.toString();
      const timeString = `${hourString}:${minuteString}`;
      times.push(timeString);
    }
  }

  return times;
}

export function compareValues(value1: any, value2: any, property: any) {
  // If the values are objects, compare based on the specified property
  if (typeof value1 === "object" && typeof value2 === "object") {
    // eslint-disable-next-line no-prototype-builtins
    if (!value1.hasOwnProperty(property) || !value2.hasOwnProperty(property)) {
      // If either object doesn't have the specified property, they can't be compared
      return false;
    }
    return value1[property] === value2[property];
  }

  // If the values are arrays, compare based on the elements at the same index
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) {
      // If the arrays have different lengths, they can't be compared
      return false;
    }
    for (let i = 0; i < value1.length; i++) {
      if (!compareValues(value1[i], value2[i], property)) {
        // If any element is different, the arrays are different
        return false;
      }
    }
    return true;
  }

  // If the values are not objects or arrays, compare them directly
  return value1 === value2;
}

export function compareArraysAndObjects(
  value1: any,
  value2: any,
  property?: any
) {
  // If the values are not both arrays or both objects, they can't be compared
  if (typeof value1 !== typeof value2) {
    return false;
  }

  // If the values are objects, compare based on the specified property
  if (typeof value1 === "object") {
    if (Array.isArray(value1)) {
      return compareValues(value1, value2, property);
    } else {
      if (Object.keys(value1).length !== Object.keys(value2).length) {
        // If the objects have different numbers of properties, they can't be compared
        return false;
      }
      for (const key in value1) {
        if (!compareValues(value1[key], value2[key], property)) {
          // If any property is different, the objects are different
          return false;
        }
      }
      return true;
    }
  }

  // If the values are not objects, compare them directly
  return value1 === value2;
}

export const getPasswordStrength = (password: string, rule: string) => {
  switch (rule) {
    case "Minimum of 8 characters":
      return password.length >= 8;
    case "At least one special character (e.g. @, #, $, %, .)":
      return /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/.test(password);
    case "At least one uppercase/capital character":
      return /[A-Z]+/.test(password);
    case "At least one number":
      return /[0-9]+/.test(password);
    default:
      return false;
  }
};

export function limitInputField(input: string): string {
  // Remove any special characters except for numbers, letters, and spaces
  const sanitizedInput = input.replace(/[^a-zA-Z0-9\s]/g, "");

  // Truncate the input to a maximum of 15 characters
  const limitedInput = sanitizedInput.slice(0, 15);

  return limitedInput;
}

export const flatString = (str: any): string => {
  return str?.toString()?.toLowerCase()?.replace(/\s/gim, "");
};

//Clear Site Data
export function clearAllData() {
  // Clear all caches
  caches.keys().then(function (cacheNames) {
    cacheNames.forEach(function (cacheName) {
      caches.delete(cacheName);
    });
  });

  // Clear local storage
  localStorage.clear();
  // Clear all site data
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    registrations.forEach(function (registration) {
      registration.unregister();
    });
  });
  document.cookie = "";
}

//Extract Ticket ID =======================
export const extractID = (str: string): any => {
  const regex = /#[A-Z0-9-]{15}/;
  const match = str.match(regex);
  const id = match ? match[0] : null;
  return id;
};

//Check Difference Between 2 objects =================
export function objectsMatch(obj1: any, obj2: any, propertiesToMatch: any) {
  for (let i = 0; i < propertiesToMatch.length; i++) {
    const property = propertiesToMatch[i];
    if (obj1[property] !== obj2[property]) {
      return false;
    }
  }
  return true;
}

//Remove URls ====================
export function checkUrls(htmlString: string): string[] {
  // Create a regular expression to match Firebase Storage URLs
  const firebaseStorageRegex =
    /(https?:\/\/(?!firebasestorage)\S+)|(https?:\/\/firebasestorage\.\S+)(?=\b|[\s<>])/gim;

  // Find all Firebase Storage URLs in the HTML string
  const matches = htmlString.match(firebaseStorageRegex);

  // Return an array of URLs if found, or an empty array if not
  return matches ? matches : [];
}

//Generate a random password
export function generatePassword() {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let password = "";
  for (let i = 0; i < 10; i++) {
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return password;
}

//Generate Unique ID
export const uniqueID = (string: string) => {
  const name = string?.replace(/[^a-zA-Z]|\s/gi, "");
  const combined = `#${
    name?.split("")?.slice(0, 4)?.join("")?.toUpperCase() +
    new Date().getFullYear().toString().slice(2, 4) +
    new Date().toISOString().slice(5, 7) +
    new Date().toISOString().slice(8, 10) +
    "-" +
    new Date().getMilliseconds()?.toString()?.charAt(0) +
    new Date().toISOString().slice(11, 13) +
    new Date().toISOString().slice(14, 16) +
    new Date().toISOString().slice(17, 19)
  }`;
  return combined?.replace(/\s/g, "");
};

//const fetch images from markdown
export const fetchImageLink = (dataString: string) => {
  const imageRegex = /!\[([^\]]+)\]\(([^)]+)\)/;
  const match = dataString?.match(imageRegex);
  if (match) {
    const imageUrl = match[2]; // "https://example.com/image.jpg"
    return imageUrl;
  } else {
    return;
  }
};

//Generate receipt for whatsapp
export function generateWhatsAppURL(data: any, company_name: string) {
  const products = data.products;
  const customerDetails = data.customers_details;

  // Create the message body
  let message = `*Order Receipt From ${company_name}*\n`;
  message += `Customer: ${customerDetails.name}\n\n`;

  // Iterate over the products
  products.forEach((product: any, index: number) => {
    const { prod_obj, quantity } = product;
    const { name, buying_price_in_usd } = prod_obj;
    const totalPrice = buying_price_in_usd * quantity;
    const formattedPrice = totalPrice.toFixed(2);

    message += `Product ${index + 1}:\n`;
    message += `Name: *${name}*\n`;
    message += `Price: $${formattedPrice}\n`;
    message += `Quantity: ${quantity}\n\n`;
  });
  //divide
  message += `--------------------------------\n`;

  // Add total price and customer details to the message
  const { total, currency_name } = data;
  const formattedTotal = total.toFixed(2);
  message += `Total Price: $${formattedTotal} ${currency_name
    ?.toString()
    ?.toUpperCase()}\n`;

  //Divide
  message += `---------------------------------\n\n`;
  message += `Customer Details:\n`;
  message += `Name: ${customerDetails.name}\n`;
  message += `Phone: ${customerDetails["primary phone"]}\n`;
  message += `Email: ${customerDetails.email}\n`;
  message += `Address: ${customerDetails.address}\n\n`;

  // Add thank you message
  message += `---------------------------------\n`;
  message += `Thank you for your order! Have an amazing Day`;

  // Construct the WhatsApp URL
  const encodedMessage = encodeURIComponent(message);
  const whatsappURL = `https://wa.me/+${customerDetails["primary phone"]}?text=${encodedMessage}`;
  return whatsappURL;
}

//Upload functions
//uploadFile Func
export const uploadFile = async (file: File, folder?: string) => {
  if (file) {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `${folder ? "/" + folder + "/" : "/uploads/"}${file.name}+${
        Date.now()?.toString() + auth?.currentUser?.uid?.toString()
      }`
    );
    const uploadTask = uploadBytes(storageRef, file);

    return new Promise<string>((resolve, reject) => {
      uploadTask
        .then((snapshot: UploadResult) => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(snapshot.ref).then(
            (downloadURL: string | PromiseLike<string>) => {
              resolve(downloadURL);
            }
          );
        })
        .catch((error) => {
          console.log(error.message);
          reject(error);
        });
    });
  }
};

//Check is form values are complete
export const isResumeComplete = (fieldsArray: any[], formValues: any) => {
  const missingFields = [];

  for (const field of fieldsArray) {
    if (field?.required && !field?.is_linked) {
      const fieldName = field?.name;
      const fieldValue = formValues?.[fieldName];

      if (!fieldValue) {
        missingFields.push(fieldName);
      }
    }
  }

  if (missingFields.length > 0) {
    console.log("Missing required fields:", missingFields);
    return false;
  } else {
    return true;
  }
};
/*
  <input type="text" name="myField" pattern="[A-Za-z0-9 ]{0,15}" maxlength="15">
  */

//Calculate age
export const calculateAgeInYears = (
  dateOfBirth: number,
  currentDate: number
): number => {
  // Calculate the age in milliseconds by subtracting dateOfBirth from currentDate
  const ageInMilliseconds = currentDate - dateOfBirth;

  // Convert milliseconds to years
  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Approximate number of milliseconds in a year
  const ageInYears = ageInMilliseconds / millisecondsInYear;

  return Number(ageInYears?.toFixed(0));
};

// ============== Compare Objects =================
export const objectsAreDifferent = (obj1: any, obj2: any): boolean => {
  // Check if the objects have the same type
  if (typeof obj1 !== typeof obj2) {
    return true;
  }

  // Check if both objects are arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    // Compare the lengths of the arrays
    if (obj1.length !== obj2.length) {
      return true;
    }

    // Recursively compare array elements
    for (let i = 0; i < obj1.length; i++) {
      if (objectsAreDifferent(obj1?.[i], obj2?.[i])) {
        return true;
      }
    }

    return false; // The arrays are the same
  }

  // Check if both objects are objects (non-arrays)
  if (typeof obj1 === "object" && typeof obj2 === "object") {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Compare the number of keys in the objects
    if (keys1.length !== keys2.length) {
      return true;
    }

    // Recursively compare object properties
    for (const key of keys1) {
      if (objectsAreDifferent(obj1[key], obj2[key])) {
        return true;
      }
    }

    return false; // The objects are the same
  }

  // Compare primitive values
  return obj1 !== obj2;
};

//Create an array from string
export const splitString = (input: string): string[] => {
  let result: string[] = [];
  if (input.includes(",")) {
    result = input.split(",").map((s) => s.trim());
  } else {
    result.push(input.trim());
  }
  return result?.filter((str: string) => str.trim() !== "");
};

export function formatTime12Hour(timemilliseconds: number) {
  const now = new Date(timemilliseconds);
  let hours = now.getHours();
  let minutes: number | string = now.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  // Add leading zero to single-digit minutes
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  const formattedTime = `${hours}:${minutes} ${ampm}`;
  return formattedTime;
}

//Retrieve local data
export const retrieveLocalData = (name: string): any => {
  try {
    const data: string | null = localStorage.getItem(name);
    if (data && data !== "undefined") {
      return JSON.parse(data);
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error retrieving local data:", error);
    return null;
  }
};

//Calculate Days
export function daysBetweenDetailed(
  startDateMillis: number,
  endDateMillis: number
): { days_between: number; days: number[] } {
  const oneDayMillis = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

  // Normalize to the start of the day (midnight)
  const start = new Date(startDateMillis);
  start.setHours(0, 0, 0, 0);

  const end = new Date(endDateMillis);
  end.setHours(0, 0, 0, 0);

  const daysDifference =
    Math.round((end.getTime() - start.getTime()) / oneDayMillis) + 1;

  // Generating the array of dates
  const daysArray: number[] = [];
  for (let i = 0; i < daysDifference; i++) {
    daysArray.push(new Date(start.getTime() + i * oneDayMillis).getTime());
  }

  return {
    days_between: daysDifference,
    days: daysArray,
  };
}

export function timeDifferenceFromNow(dateInMilliseconds: number): string {
  const now = new Date().getTime();
  const differenceInSeconds = Math.floor((now - dateInMilliseconds) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;

  if (differenceInSeconds < minute) {
    return `${differenceInSeconds} secs ago`;
  } else if (differenceInSeconds < hour) {
    const minutes = Math.floor(differenceInSeconds / minute);
    return `${minutes} min${minutes > 1 ? "s" : ""} ago`;
  } else if (differenceInSeconds < day) {
    const hours = Math.floor(differenceInSeconds / hour);
    return `${hours} hr${hours > 1 ? "s" : ""} ago`;
  } else if (differenceInSeconds < week * 2) {
    const days = Math.floor(differenceInSeconds / day);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Intl.DateTimeFormat("en-GB", options).format(
      new Date(dateInMilliseconds)
    );
  }
}

//Capitalise
export const capitalize = (string: string): string => {
  return string
    ?.split("")
    ?.map((str: string, index: number) =>
      index === 0 ? str?.toUpperCase() : str
    )
    ?.join("");
};

export function removeDuplicates(arr: any, key: any) {
  const map = new Map();
  return arr.filter((item: { [x: string]: any }) => {
    if (!map.has(item[key])) {
      map.set(item[key], true);
      return true;
    }
    return false;
  });
}

export const toPureString = (str: string | any): string => {
  return str?.toString()?.toLowerCase()?.replace(/\s/gim, "");
};

export function formatNumberToInt(
  num: number,
  decimalPlaces: number = 2,
  locale: string = "en-GB"
): string {
  return num.toLocaleString(locale, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

export const getTimeBasedGreeting = (): string => {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return "Good morning";
  } else if (currentHour < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
};

export const getCurrentDateFormatted = (date: number = Date.now()): string => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const now = new Date(date);
  const dayOfWeek = daysOfWeek[now.getDay()];
  const month = monthsOfYear[now.getMonth()];
  const dayOfMonth = now.getDate();

  return `It's ${dayOfWeek}, ${month} ${dayOfMonth}`;
};

export function toggleItemInArray(items: string[], item: string): string[] {
  const index = items.indexOf(item);

  if (index === -1) {
    // Item not found, add it to the array
    return [...items, item];
  } else {
    // Item found, remove it from the array
    return items.filter((i) => i !== item);
  }
}

// Clear Datd based on linked values
export const clear_data_on_linked = (
  steps: any,
  field: any,
  setValues: (item: any) => void
) => {
  //Fetch All related fields
  const relatedFields = [...steps]
    ?.find((section: any) =>
      section?.fields?.some((fld: any) => fld?.name === field?.name)
    )
    ?.fields?.filter(
      (fld: any) =>
        field?.index < fld?.index && fld?.["clear data based on linked field"]
    );
  //console.log(relatedFields?.length);

  //Loop through every linked value and clear the valuer
  if (relatedFields) {
    for (const item of relatedFields) {
      setValues((prev: any) => ({
        ...prev,
        [item?.name]: item?.type === "group" ? null : "",
      }));
    }
  }
};

export function getDaysArray(
  monthAbbrev: string,
  year = new Date().getFullYear()
) {
  const months: any = {
    jan: 0,
    feb: 1,
    mar: 2,
    apr: 3,
    may: 4,
    jun: 5,
    jul: 6,
    aug: 7,
    sep: 8,
    oct: 9,
    nov: 10,
    dec: 11,
  };
  const monthNumber = months[monthAbbrev.toLowerCase()];

  if (monthNumber === undefined) {
    throw new Error("Invalid month abbreviation");
  }

  const daysInMonth = new Date(year, monthNumber + 1, 0).getDate();
  const daysArray = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(year, monthNumber, day);
    const dayString = `${day} ${date.toLocaleString("en-GB", {
      weekday: "short",
    })}`;
    const dayMill = date.getTime();

    daysArray.push({ day_string: dayString, day: day, day_mill: dayMill });
  }

  return daysArray;
}

export interface DayObject {
  day_number: number;
  day_millisecond: number;
  day_of_the_week: number;
}

export const getDaysBetweenDates = (
  startDateMillis: number,
  endDateMillis: number
): DayObject[] => {
  const oneDayMillis = 1000 * 60 * 60 * 24; // Milliseconds in a day
  const days: DayObject[] = [];

  for (
    let millis = startDateMillis;
    millis <= endDateMillis;
    millis += oneDayMillis
  ) {
    const currentDate = new Date(millis);
    const dayObject: DayObject = {
      day_number: currentDate.getDate(),
      day_millisecond: millis,
      day_of_the_week: currentDate.getDay(),
    };
    days.push(dayObject);
  }

  return days;
};

export function filterDuplicates<T>(array: T[], props: (keyof T)[]): T[] {
  const unique = array.reduce((acc: T[], current: T) => {
    const duplicate = acc.some((item) =>
      props.every((prop) => item[prop] === current[prop])
    );
    if (!duplicate) {
      acc.push(current);
    }
    return acc;
  }, []);
  return unique;
}

export function convertMillisToDateTimeLocal(millis: any) {
  const date: any = new Date(millis);
  const offset = date.getTimezoneOffset() * 60000; // offset in milliseconds
  const localISOTime = new Date(date - offset).toISOString().slice(0, 16);
  return localISOTime;
}

export function addTimeToDate(
  dateInMilliseconds: number,
  time: string
): number {
  // Create a Date object for London from the milliseconds
  const londonDate = new Date(
    dateInMilliseconds ||
      new Date().toLocaleString("en-GB", { timeZone: "Europe/London" })
  );

  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(":").map(Number);

  // Set the time on the Date object (already in London timezone)
  londonDate.setHours(hours, minutes);

  // Return the updated Date object as milliseconds
  return londonDate.getTime();
}

export function getWeekDaysInMilliseconds(
  dateInMilliseconds: number
): number[] {
  const dayInMs = 24 * 60 * 60 * 1000; // milliseconds in a day
  const hourInMs = 60 * 60 * 1000; // milliseconds in an hour

  // Convert the given milliseconds to a Date object
  const date = new Date(dateInMilliseconds);

  // Find the previous Monday
  const dayOfWeek = date.getDay();
  const difference = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Adjust for Sunday being 0
  const monday = new Date(dateInMilliseconds - difference * dayInMs);

  // Set the time to 1 AM
  monday.setHours(1, 0, 0, 0);

  // Generate the week array
  return Array.from({ length: 7 }, (_, index) =>
    new Date(monday.getTime() + index * dayInMs + hourInMs).getTime()
  );
}

export const replaceUndefinedWithEmptyString = (obj: any): any => {
  // Helper function to check if a value is an object
  const isObject = (val: any) =>
    val && typeof val === "object" && !Array.isArray(val);

  // Function to recursively process the object
  const processObject = (currentObj: any): any => {
    Object.keys(currentObj).forEach((key) => {
      if (currentObj[key] === undefined) {
        currentObj[key] = ""; // Replace undefined with empty string
      } else if (isObject(currentObj[key])) {
        processObject(currentObj[key]); // Recurse into nested objects
      }
    });
  };

  // Clone the object to avoid mutating the original one
  const clonedObj = JSON.parse(JSON.stringify(obj));
  processObject(clonedObj);

  return clonedObj;
};

export const areDatesSame = (
  date1Millis: number,
  date2Millis: number
): boolean => {
  // Convert milliseconds to Date objects
  const date1 = new Date(date1Millis);
  const date2 = new Date(date2Millis);

  // Compare the day, month, and year components
  const sameDay = date1.getDate() === date2.getDate();
  const sameMonth = date1.getMonth() === date2.getMonth();
  const sameYear = date1.getFullYear() === date2.getFullYear();

  // Dates are the same if all components match
  return sameDay && sameMonth && sameYear;
};

//Xsls Downloader
export const downloadXLSX = (
  passed_data: DataObject[],
  fileName: string,
  columnOrder?: string[]
) => {
  const data = [{}, ...passed_data];
  if (data.length === 0) {
    return; // Return early if data is empty
  }

  // Determine column order
  if (!columnOrder) {
    columnOrder = Object.keys(data[0]);
  }

  // Create a new workbook and worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet([]);

  // Add data to worksheet
  const formattedData = data.map((row) => {
    const formattedRow: any = {};
    columnOrder!.forEach((column) => {
      formattedRow[column] =
        row[column] === undefined || row[column] === null ? " " : row[column];
    });
    return formattedRow;
  });

  // Add formatted data to worksheet
  XLSX.utils.sheet_add_json(worksheet, formattedData, {
    skipHeader: true,
    origin: -1,
  });

  // Format headers and transform to uppercase
  const formattedHeaders = columnOrder.map((header) =>
    header.replace(/_/g, " ").toUpperCase()
  );

  // Add formatted headers to worksheet
  XLSX.utils.sheet_add_aoa(worksheet, [formattedHeaders], { origin: 0 });

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write the workbook to a blob
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  // Convert the string to a buffer
  const buffer = new ArrayBuffer(wbout.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < wbout.length; i++) {
    view[i] = wbout.charCodeAt(i) & 0xff;
  }

  // Use FileSaver to save the file
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(blob, `${fileName}.xlsx`);
};

//Get Start And Emd Time of a month
interface MonthTimestamps {
  start: number;
  end: number;
}

//Get Start And End Time of a month
export function getCurrentMonthSETimestamps(): MonthTimestamps {
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  return {
    start: startDate.getTime(),
    end: endDate.getTime(),
  };
}

//Format Audio Duration
export const formatAudioDuration = (passedValue: number) => {
  const time = !isFinite(passedValue) ? 0 : passedValue;
  const hours = Math.floor(time / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor(time % 60)
    .toString()
    .padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

// Function to convert date to milliseconds in Europe/London timezone
export const dateToLondonMilliseconds = (date: Date = new Date()): number => {
  return moment(date).tz("Europe/London").valueOf();
};

// Function to retrieve stored value (milliseconds) and display date and time in Europe/London timezone
export const displayLondonTime = (milliseconds: number): string => {
  const londonTime = moment.tz(milliseconds, "Europe/London");
  return londonTime.format("YYYY-MM-DD HH:mm:ss");
};

// Function to convert milliseconds to a Date object in Europe/London timezone
export const dateFromLondonMilliseconds = (
  milliseconds: number = Date.now()
): Date => {
  return moment.tz(milliseconds, "Europe/London").toDate();
};

//Get File Type
export function getTypeAndExtensionFromBlob(blob: Blob): {
  type: string;
  extension: string;
} {
  const blobType = blob.type;
  const mimeTypeParts = blobType.split("/");
  const fileExtension = mimeTypeParts[1];

  return {
    type: blobType?.split(";")?.[0],
    extension: fileExtension?.split(";")?.[0],
  };
}

//Form Filter Functions

interface ArrType {
  [key: string]: number | string;
}

export const updateObjectAtIndex = (
  passed_array: ArrType[],
  field_name: string,
  old_index: number,
  new_index: number
): ArrType[] => {
  const arr = passed_array.map((obj) => ({ ...obj }));

  // Find the object with the matching field_name and old_index
  const indexToUpdate = arr.findIndex(
    (obj) => Number(obj[field_name]) === old_index
  );

  // If no object with matching field_name and old_index is found, return the original array
  if (indexToUpdate === -1) {
    return arr;
  }

  // Update the object with the new_index
  arr[indexToUpdate] = { ...arr[indexToUpdate], [field_name]: new_index };

  // Recursive function to adjust conflicting objects' values
  function adjustConflicts(startIndex: number, endIndex: number): void {
    if (startIndex >= endIndex) {
      return;
    }

    const objToUpdate = arr.find(
      (obj) => Number(obj[field_name]) === startIndex
    );
    if (objToUpdate) {
      const val = startIndex - 1;
      const newIndex = val < 0 ? arr.length : val;
      arr[arr.indexOf(objToUpdate)] = {
        ...objToUpdate,
        [field_name]: newIndex,
      };
    }

    adjustConflicts(startIndex - 1, endIndex);
  }

  // Start adjusting conflicts from new_index towards the beginning of the array
  adjustConflicts(new_index - 1, old_index);

  // Sort the array based on the field_name
  arr
    .sort((a: any, b: any) => a[field_name] - b[field_name])
    .map((obj: any, index: number) => ({ ...obj, index }));

  return arr;
};

export const moveItem = (
  array: ArrType[],
  field_to_match: string,
  currentPosition: number,
  newPosition: number
): ArrType[] => {
  // Check if currentPosition and newPosition are within array bounds
  if (
    currentPosition < 0 ||
    currentPosition >= array.length ||
    newPosition < 0 ||
    newPosition >= array.length
  ) {
    return array;
  }

  // Remove the item from the current position
  const item = array.splice(currentPosition, 1)[0];

  // Insert the item at the new position
  array.splice(newPosition, 0, item);
  const data = array.map((obj: any, index: number) => ({
    ...obj,
    [field_to_match]: index,
  }));
  return data;
};

interface YearDateRange {
  start: number;
  end: number;
}

export function getYearDateRange(year: number): YearDateRange {
  // Create a new Date object for the start of the year
  const startDate = new Date(year, 0, 1);

  // Create a new Date object for the end of the year
  const endDate = new Date(year, 11, 31, 23, 59, 59, 999);

  // Return an object containing start and end dates in milliseconds
  return {
    start: startDate.getTime(),
    end: endDate.getTime(),
  };
}

export const toHHMM = (milliseconds: number): string => {
  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  return hours + minutes > 0
    ? `${hours}:${String(minutes).padStart(2, "0")}`
    : "00:00";
};

export interface UserAgentDetails {
  browser: string;
  browserVersion: string;
  os: string;
  osVersion: string;
}

export const parseUserAgent = (userAgentString: string): UserAgentDetails => {
  const userAgent = userAgentString.toLowerCase();
  let browser = "";
  let browserVersion = "";
  let os = "";
  let osVersion = "";

  // Parse browser
  if (userAgent.includes("firefox")) {
    browser = "Firefox";
    browserVersion = userAgent?.split("firefox/")[1].split(" ")[0];
  } else if (userAgent.includes("chrome")) {
    browser = "Chrome";
    browserVersion = userAgent?.split("chrome/")[1].split(" ")[0];
  } else if (userAgent.includes("safari")) {
    browser = "Safari";
    browserVersion = userAgent?.split("version/")[1].split(" ")[0];
  } else if (userAgent.includes("opera")) {
    browser = "Opera";
    browserVersion = userAgent?.split("opr/")[1].split(" ")[0];
  } else if (userAgent.includes("edge")) {
    browser = "Edge";
    browserVersion = userAgent?.split("edge/")[1].split(" ")[0];
  } else if (userAgent.includes("msie")) {
    browser = "Internet Explorer";
    browserVersion = userAgent?.split("msie ")?.[1].split(";")?.[0];
  } else {
    browser = "Unknown";
  }

  // Parse operating system
  if (userAgent.includes("windows")) {
    os = "Windows";
    osVersion = userAgent?.split("windows nt ")?.[1].split(";")?.[0];
  } else if (userAgent.includes("mac os")) {
    os = "Mac OS";
    osVersion = userAgent
      ?.split("mac os x ")?.[1]
      ?.split(")")?.[0]
      ?.replace(/_/g, ".");
  } else if (userAgent.includes("linux")) {
    os = "Linux";
  } else if (userAgent.includes("android")) {
    os = "Android";
    osVersion = userAgent?.split("android ")?.[1].split(";")?.[0];
  } else if (userAgent.includes("iphone")) {
    os = "iPhone";
    osVersion = userAgent
      ?.split("iphone os ")?.[1]
      ?.split(" ")?.[0]
      ?.replace(/_/g, ".");
  } else if (userAgent.includes("ipad")) {
    os = "iPad";
    osVersion = userAgent
      ?.split("cpu os ")?.[1]
      ?.split(" ")?.[0]
      ?.replace(/_/g, ".");
  } else {
    os = "Unknown";
  }

  return { browser, browserVersion, os, osVersion };
};

export const showRequiredFieds = (fieldsArray: any[], formValues: any) => {
  const missingFields = [];

  for (const field of fieldsArray) {
    if (
      field?.required &&
      !field?.is_linked &&
      !field?.["hide if data source is empty"]
    ) {
      const fieldName = field?.name;
      const fieldValue = formValues?.[fieldName];

      if (!fieldValue) {
        missingFields.push(fieldName);
      }
    }
  }

  return missingFields || [];
};

export function encryptData<T>(
  data: T,
  secretKey: string = import.meta.env.VITE_API_KEY
): string {
  if (!secretKey) {
    throw new Error("Encryption key not found.");
  }
  const dataString = JSON.stringify(data); // Stringify before encrypting
  const ciphertext = CryptoJS.AES.encrypt(dataString, secretKey);
  return ciphertext.toString();
}

export function decryptData<T>(
  encryptedData: string,
  secretKey: string = import.meta.env.VITE_API_KEY
): T | null {
  if (!secretKey) {
    throw new Error("Encryption key not found.");
  }
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString); // Parse after decrypting
  } catch (error) {
    console.error("Decryption failed:", error);
    return null; // Or handle the error differently
  }
}

export const getMillisecondsWorked = async (
  employee_id: string,
  start: number,
  end: number
) => {
  const q = query(
    scheduleRef,
    where("assigned_to.uid", "==", employee_id),
    where("start_date", ">=", start),
    where("start_date", "<=", end)
  );
  const snapshot = await getAggregateFromServer(q, {
    totalHours: sum("duration"),
  });

  return snapshot.data().totalHours || 0;
};

export const dateTimeFormattedString = (
  date: Date,
  includeTime: boolean = false
): string => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const pad = (num: number) => (num < 10 ? "0" : "") + num;

  let formattedDate = `${pad(day)} ${month} ${year}`;

  if (includeTime) {
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    formattedDate += ` - ${hours}:${minutes}:${seconds}`;
  }

  return formattedDate;
};

export const signout_and_clear_data = async (no_reload?: boolean) => {
  await signOut(auth).then(async () => {
    await clearAllData();
    await clearAllData();
    document.title = "Stellfy";
    localStorage.setItem("ckTL", JSON.stringify("yes"));
    !no_reload && window.location.reload();
    return;
  });
};

//Xsls Downloader
export const downloadV2XLSX = (
  passed_data: DataObject[],
  fileName: string,
  columnOrder?: string[]
) => {
  //const data = [{}, ...passed_data];
  const data = [...passed_data];
  if (data.length === 0) {
    return; // Return early if data is empty
  }

  // Determine column order
  if (!columnOrder) {
    columnOrder = Object.keys(data[0]);
  }

  // Create a new workbook and worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet([]);

  // Add data to worksheet
  const formattedData = data.map((row) => {
    const formattedRow: any = {};
    columnOrder!.forEach((column) => {
      formattedRow[column] =
        row[column] === undefined || row[column] === null ? " " : row[column];
    });
    return formattedRow;
  });

  // Add formatted data to worksheet
  XLSX.utils.sheet_add_json(worksheet, formattedData, {
    skipHeader: true,
    origin: -1,
  });

  // Format headers and transform to uppercase
  const formattedHeaders = columnOrder.map((header) =>
    header.replace(/_/g, " ").toUpperCase()
  );

  // Add formatted headers to worksheet
  XLSX.utils.sheet_add_aoa(worksheet, [formattedHeaders], { origin: 0 });

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write the workbook to a blob
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  // Convert the string to a buffer
  const buffer = new ArrayBuffer(wbout.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < wbout.length; i++) {
    view[i] = wbout.charCodeAt(i) & 0xff;
  }

  // Use FileSaver to save the file
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(blob, `${fileName}.xlsx`);
};

export function addTimes(time: string, duration: number): string {
  const [hours, minutes] = time.split(":").map(Number);

  const durationHours = Math.floor(duration);
  const durationMinutes = Math.round((duration - durationHours) * 100); // Handles the decimal as minutes

  let newHours = hours + durationHours;
  let newMinutes = minutes + durationMinutes;

  // Handle minute overflow
  if (newMinutes >= 60) {
    newHours += Math.floor(newMinutes / 60);
    newMinutes = newMinutes % 60;
  }

  // Handle hour overflow
  if (newHours >= 24) {
    newHours = newHours % 24;
  }

  // Ensure two digits for hours and minutes
  const formattedHours = String(newHours).padStart(2, "0");
  const formattedMinutes = String(newMinutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

export const createAutoSchedulerShifts = (
  prefs: PreferredTimesType
): ShiftType[] => {
  const auto_scheduler_shifts = ["morning", "lunch", "tea", "bed"].map(
    (shift: string) => {
      const shift_data = {
        title: shift,
        start_time: prefs[shift as "morning"]?.from,
        end_time: addTimes(
          prefs[shift as "morning"]?.from,
          prefs[shift as "morning"]?.hours_to_spend
        ),
        duration: prefs[shift as "morning"]?.hours_to_spend * 3600000,
        required_staff: prefs[shift as "morning"]?.staff_required || 1,
        id: Date.now().toString() + shift,
      };

      return shift_data as ShiftType;
    }
  );

  const other = {
    title: "other",
    start_time: "00:00",
    end_time: "23:59",
    duration: 24 * 3600000,
    required_staff: 1,
    id: Date.now().toString() + "other",
  } as ShiftType;

  return [...auto_scheduler_shifts, other];
};
