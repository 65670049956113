import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChartFiltersType } from "../../types/charts_type";
import { StepFormType } from "../../types/forms_types";

interface InitialStateType {
  charts_filters: ChartFiltersType;
  chart_logs:any
}

const initialState: InitialStateType = {
  charts_filters: {
    chart_name: "",
    start: new Date().getTime() - 86400000,
    end: new Date().getTime(),
    activeGlobalForm:{} as StepFormType
  },
  chart_logs:[]
};

export const ChartsSlice = createSlice({
  name: "charts_data",
  initialState,
  reducers: {
    updateChartFilters: (state, action: PayloadAction<any>) => {
      state.charts_filters = action.payload;
    },
    loadChartLogs: (state, action: PayloadAction<any>) => {
      state.chart_logs = action.payload;
    },
  },
});

export const { updateChartFilters } = ChartsSlice.actions;

export default ChartsSlice.reducer;
