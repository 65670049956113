;
import { limit, onSnapshot, query} from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { formsRef} from "../functions";
import { updateLocalFormsData } from "../../redux/slices/forms_slice";

const FormsData = () => {
  const { currentUser }: any = useAuth();
  const dispatch = useDispatch();

  //Fetch Data And Store It Locally
  useEffect((): any => {
    return (
      currentUser?.uid &&
      onSnapshot(
        query(formsRef, limit(50)),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            updateLocalFormsData(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))?.sort((a:any,b:any)=>a?.name?.localeCompare(b?.name))
            )
          );
        }
      )
    );
  }, [dispatch, currentUser]);

  //Compoent ===========================
  return <></>;
};

export default FormsData;
