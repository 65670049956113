import { motion } from "framer-motion";
import FileInput from "../../components/forms/stepper/files_input";
import { ClientType } from "../../types/client_types";
import { Select, MultiSelect } from "@mantine/core";
import React, { useMemo, useState } from "react";
import ProcessLoading from "../../components/misc/processing_loader";
import { updateClient } from "../../firebase/functions";
import { v4 as uuidv4 } from "uuid";
import { toast } from "sonner";
import SearchAutocomplete from "../../components/forms/stepper/search_address";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  MasterListDataFieldType,
  MasterListType,
} from "../../types/master_list_type";
import { useNavigate } from "react-router-dom";
import withPermission from "../../components/auth/withPermission";
import { UserType } from "../../types/session_type";
import { useModals } from "../../lib/modal/modal2provider";
import { encryptData } from "../../utils/reusable_functions";
import { StepFormType } from "../../types/forms_types";

export const customOrder = [
  "profile",
  "title",
  "first name",
  "last name",
  "preferred name",
  "date of birth",
  "ethnicity",
  "religion",
  "gender",
  "about",
  "email",
  "full_address",
  "state",
  "status",
  "weekly allocated hours",
  "competencies",
  "NHS number",
  "GP details",
  "primary contact",
  "emergency contact",
  "key contact name",
  "key contact relationship",
  "key contact phone number",
  "assigned nurse",
  "preferred visitors gender",
  "email for alerts",
  "daily charts",
  "peg feeding instructions",
];

type Props = {
  preview: any;
  setPreview: any;
};

const CrudClient = withPermission(({ preview, setPreview }: Props) => {
  const modals = useModals();
  const navigate = useNavigate();
  const users: UserType[] = useSelector(
    (state: RootState) => state?.session_data?.users
  );
  const [loading, setLoading] = useState<boolean>(false);
  const masterlists = useSelector(
    (state: RootState) => state.lists_data.master_lists_data
  );
  const compatencies: any = useMemo(() => {
    const data = [
      ...(masterlists?.find(
        (data: MasterListType) => data?.name === "competencies"
      )?.data || []),
    ];
    return data?.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
  }, [masterlists]);
  const religions: any = useMemo(() => {
    const data = [
      ...(masterlists
        ?.find((data: MasterListType) => data?.name === "religions")
        ?.data?.map((val: MasterListDataFieldType) => val.value) || []),
    ];
    return data?.sort((a: string, b: string) => (a > b ? 1 : -1));
  }, [masterlists]);
  const ethnicities: string[] = useMemo(() => {
    const data = [
      ...(masterlists
        ?.find((data: MasterListType) => data?.name === "ethnicities")
        ?.data?.map((val: MasterListDataFieldType) => val.value) || []),
    ];
    return data?.sort((a: string, b: string) => (a > b ? 1 : -1));
  }, [masterlists]);
  const relationships: string[] = useMemo(() => {
    const data = [
      ...(masterlists
        ?.find((data: MasterListType) => data?.name === "relationships")
        ?.data?.map((val: MasterListDataFieldType) => val.value) || []),
    ];
    return data?.sort((a: string, b: string) => (a > b ? 1 : -1));
  }, [masterlists]);
  const forms: StepFormType[] = useSelector(
    (state: RootState) => state.forms_data.forms
  );

  //Combine data sources
  const data_source: any = {
    ethnicity: ethnicities?.sort((a: string, b: string) => (b > a ? -1 : 1)),
    religion: religions?.sort((a: string, b: string) => (b > a ? -1 : 1)),
    gender: ["male", "female", "other"],
    status: ["active", "inactive", "onboarding"],
    "key contact relationship": relationships?.sort((a: string, b: string) =>
      b > a ? -1 : 1
    ),
    competencies: compatencies,
    "assigned nurse": users?.map((user: any) => ({
      label: `${user?.["first name"]} ${user?.["last name"]}`,
      value: user?.id || "",
    })),
    "preferred visitors gender": ["females", "males", "any"],
    "daily charts":
      [...(forms || [])]
        ?.map((form: StepFormType) =>
          form?.type === "charts form"
            ? { label: form?.name, value: form?.id }
            : false
        )
        ?.filter(Boolean) || [],
  };

  //Handle Submit
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const promise = async () => {
      setLoading(true);
      if (preview?.id || preview?.uid) {
        return await updateClient(preview);
      } else {
        const combined_data = {
          data: preview,
          password: uuidv4(),
          email: preview?.email,
        };

        return await fetch(import.meta.env.VITE_CREATECLIENT, {
          mode: "cors",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(combined_data),
        });
      }
    };

    toast.promise(promise, {
      loading: "Please wait ...",
      success: (data: any) => {
        localStorage.setItem(
          "active_client_doc",
          JSON.stringify(
            encryptData(data?.client, import.meta.env.VITE_APP_CHECK_KEY)
          )
        );
        setLoading(false);
        setPreview(null);
        return "Client added successfully";
      },
      error: (error: any) => {
        setLoading(false);
        return error?.message;
      },
    });
  };

  const deleteCLient = () => {
    modals.openConfirmModal({
      title: `Are you sure you want to delete this Client`,
      description: `This action is destructive and can not be reversed. Are you sure you want to proceed ?`,
      labels: { cancel: "Cancel", confirm: "Confirm" },
      onCancel: () => modals.closeModal(),
      onConfirm: () => {
        const promise = async () => {
          return await fetch(import.meta.env.VITE_DELETECLIENT, {
            mode: "cors",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ uid: preview?.id || preview?.uid }),
          });
        };

        toast.promise(promise, {
          loading: "Please wait ...",
          success: () => {
            setLoading(false);
            setPreview(null);
            navigate("/care");
            modals.closeModal();
            return "Client deleted successfully";
          },
          error: (error: any) => {
            modals.closeModal();
            setLoading(false);
            return error?.message;
          },
        });
      },
      //closeModal: () => modals.closeModal(),
    });
  };

  // console.log(!(true &&
  //   preview?.["daily charts"]?.includes("vPy0SBO2MHaHU1e63V4c")))

  //Component ======================
  return (
    <motion.div
      initial={{ right: "-100%", left: "100%" }}
      exit={{ right: "-100%", left: "100%" }}
      animate={{ right: 0, left: 0 }}
      className="fixed top-0 left-0 right-0 bottom-0 bg-black/60 backdrop-blur-md z-[999999] flex
       items-center justify-end overflow-hidden"
    >
      <form
        onSubmit={handleSubmit}
        className="w-full md:w-[37rem] h-full shrink-0 bg-cream p-4 pb-10 md:pb-2 flex flex-col overflow-hidden"
      >
        <div className="w-full h-fit flex items-center justify-between gap-4 overflow-hidden shrink-0">
          <h1
            className="whitespace-nowrap truncate text-lg uppercase font-extrabold
          text-zinc-700 shrink-0 mb-1"
          >
            {preview?.id || preview?.uid ? "update client" : "create client"}
          </h1>
        </div>
        <p className="mt-4 line-clamp-3 text-xs font-medium text-zinc-600 shrink-0 mb-3">
          Client's profiles are a reusable object accross multiple forms helping
          you avoid reputative information capturing while improving your
          efficiency
        </p>
        <div className="w-full h-full overflow-hidden overflow-y-auto gap-4 flex flex-col px-1 pr-2 pb-8">
          <div className="bg-white w-full h-fit">
            <FileInput
              formValues={preview}
              onChange={(e: string) => {
                setPreview((prev: any) => ({
                  ...prev,
                  profile: e,
                }));
              }}
              field={{
                index: 211,
                name: "profile",
                description: "User profile",
                placeholder: "User profile",
                required: false,
                type: "file",
                accept: ".jpg, .jpeg, .png, .gif, .webp",
                is_linked: false,
                linked_field: "",
                linked_value: "",
              }}
            />
          </div>

          {Object.keys(preview || {})
            .filter((field: string) => {
              const excludedFields = [
                "createdAt",
                "last_updated",
                "id",
                "profile",
                "role",
                "uid",
                "latitude",
                "longitude",
              ];

              const isExcludedField = excludedFields.includes(field);
              const isPegFeedingInstruction =
                field === "peg feeding instructions";
              const isDailyChartsMissingId = !preview?.[
                "daily charts"
              ]?.includes("vPy0SBO2MHaHU1e63V4c");

              return (
                !isExcludedField &&
                !(isPegFeedingInstruction && isDailyChartsMissingId)
              );
            })
            ?.sort(
              (a: string, b: string) =>
                customOrder?.indexOf(a) - customOrder?.indexOf(b)
            )
            ?.map((field: string, index: number) => {
              return (
                <label
                  key={field + index}
                  htmlFor={field}
                  className="flex flex-col gap-1 w-full h-fit shrink-0"
                >
                  <span className="text-xss uppercase font-bold text-zinc-600 whitespace-nowrap truncate">
                    {field?.replace(/_/gim, " ")}
                  </span>
                  <div
                    id={field === "full_address" ? "address_box" : ""}
                    className="w-full h-fit min-h-[3.5rem] bg-white border border-zinc-200 focus-within:border-sky-500/70
                    focus-within:ring ring-sky-500/30 transition-all flex items-center relative rounded"
                  >
                    {field === "about" || field === "GP details" ? (
                      <textarea
                        onChange={(e) =>
                          setPreview((prev: ClientType | any) => ({
                            ...prev,
                            [field]: e.target.value,
                          }))
                        }
                        value={preview?.[field]}
                        placeholder={`${field?.replace(/_/gim, " ")} ...`}
                        className="w-full h-32 min-h-full bg-inherit rounded border-none outline-none resize-none text-xs
                       text-zinc-700 placeholder:text-zinc-400 placeholder:capitalize font-medium px-3 pt-3"
                      ></textarea>
                    ) : data_source?.[field] &&
                      ![
                        "competencies",
                        "daily charts",
                        "assigned nurse",
                      ].includes(field) ? (
                      <Select
                        onChange={(e) => {
                          setPreview((prev: any) => ({
                            ...prev,
                            [field]: e,
                          }));
                        }}
                        value={preview?.[field] || []}
                        classNames={{
                          input:
                            "!border-none bg-inherit text-xs capitalize !h-12 !pt-1",
                          root: "!capitalize w-full !h-fit !min-h-full !border-none !capitalize",
                          wrapper:
                            "!capitalize w-full !h-fit !min-h-full !border-none",
                          options: " !capitalize",
                        }}
                        className="w-full h-fit min-h-full bg-inherit rounded border-none outline-none text-xs
                       text-zinc-700 placeholder:text-zinc-400 placeholder:capitalize font-medium !capitalize"
                        required
                        id={field}
                        name={field}
                        placeholder={`${field?.replace(/_/gim, " ")} ...`}
                        searchable
                        clearable
                        data={data_source?.[field] || []}
                      />
                    ) : field === "full_address" ? (
                      <SearchAutocomplete
                        onChange={(e: {
                          latitude: string;
                          longitude: string;
                          address: string;
                          state: string;
                        }) =>
                          setPreview((prev: ClientType | any) => ({
                            ...prev,
                            [field]: e?.address,
                            latitude: e?.latitude,
                            longitude: e?.longitude,
                          }))
                        }
                        value={preview?.[field]}
                        className="w-full h-fit min-h-full bg-inherit border-none outline-none text-sm
                          text-zinc-700 placeholder:text-zinc-400 placeholder:capitalize font-medium px-3
                          absolute top-0 left-0 right-0 bottom-0"
                      />
                    ) : [
                        "competencies",
                        "daily charts",
                        "assigned nurse",
                      ].includes(field) ? (
                      <MultiSelect
                        onChange={(e) => {
                          setPreview((prev: any) => ({
                            ...prev,
                            [field]: e,
                          }));
                        }}
                        value={preview?.[field] || []}
                        classNames={{
                          input:
                            "!border-none bg-inherit text-xs capitalize !h-fit !min-h-[3rem] !pt-3",
                          root: "!capitalize w-full !h-fit !min-h-[3rem] !border-none !capitalize text-xxs",
                          wrapper:
                            "!capitalize w-full !h-fit !min-h-full !border-none pb-1",
                          options: "!capitalize",
                          pill: `!rounded !capitalize pt-0.5 pb-1 shrink-0 bg-zinc-50 border border-zinc-200 shadow`,
                        }}
                        className="w-full h-fit min-h-full bg-inherit rounded border-none outline-none text-xs
                       text-zinc-700 placeholder:text-zinc-400 placeholder:capitalize font-medium !capitalize"
                        id={field}
                        name={field}
                        hidePickedOptions
                        placeholder={`${field?.replace(/_/gim, " ")} ...`}
                        searchable
                        clearable
                        data={data_source?.[field] || []}
                      />
                    ) : (
                      <input
                        onChange={(e) =>
                          setPreview((prev: ClientType | any) => ({
                            ...prev,
                            [field]: e.target.value,
                          }))
                        }
                        value={preview?.[field]}
                        type={
                          field.includes("email")
                            ? "email"
                            : field === "date of birth"
                            ? "date"
                            : "text"
                        }
                        required
                        id={field}
                        name={field}
                        placeholder={`${field?.replace(/_/gim, " ")} ...`}
                        className="w-full h-fit min-h-full rounded bg-inherit border-none outline-none text-xs
                       text-zinc-700 placeholder:text-zinc-400 placeholder:capitalize font-medium px-3"
                      />
                    )}
                  </div>
                </label>
              );
            })}
        </div>

        {/** =============== Bottom Nav =================== */}
        <div className="w-full h-16 shrink-0 flex items-center justify-between gap-2 overflow-hidden">
          <button
            onClick={() => {
              setPreview(null);
              localStorage.removeItem("preview");
            }}
            type="button"
            className="h-10 w-1/3 bg-white border border-sky-500/40 text-xxs text-sky-500  
            font-bold uppercase hover:opacity-80 transition-all rounded"
          >
            cancel
          </button>
          {(preview?.id || preview?.uid) && (
            <button
              onClick={deleteCLient}
              type="button"
              className="h-10 w-1/3 bg-red-500 text-xxs text-white font-bold uppercase
             hover:opacity-80 transition-all rounded"
            >
              delete
            </button>
          )}
          <button
            type="submit"
            className="h-10 w-1/3 bg-sky-500 text-xxs text-white font-bold uppercase
             hover:opacity-80 transition-all rounded"
          >
            {preview?.id || preview?.uid ? "update client" : "create client"}
          </button>
        </div>
      </form>

      {/**Loading state */}
      {loading && <ProcessLoading />}
    </motion.div>
  );
});

export default React.memo(CrudClient);
