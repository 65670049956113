
import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import L from "leaflet";
import * as Select from "@radix-ui/react-select";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import polyline from "@mapbox/polyline";

// Importing Leaflet CSS
import "leaflet/dist/leaflet.css";

// Define types
type TravelTime = {
  personal_car: number;
  public_transport: number;
  route: string;
};

type Distance = {
  id: string;
  name: string;
  distance: number;
  latitude: number;
  longitude: number;
  travelTime: TravelTime;
};

type Person = {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
  distances: Distance[];
};

type Data = {
  employees: Person[];
  clients: Person[];
};

// Data (unchanged, using the provided data)
const data: Data = {
  "employees": [
    {
      "id": "8NaowB3vXuzzq9SQcDpa",
      "name": "Demo 3 Employee",
      "latitude": 52.91255,
      "longitude": -1.54614,
      "distances": [
        {
          "id": "7LiDNX3SSId9UH6KusK7",
          "name": "Demo 3 Client",
          "distance": 2.924171926,
          "latitude": 52.91157,
          "longitude": -1.47605,
          "travelTime": {
            "personal_car": 0.15,
            "public_transport": 0.5,
            "route": "im}aIb~lHAg@Mk@GIKCM?UHk@LSDe@Cg@IYMSOFc@DcAEuDFwC?UP@fIp@hFb@hCR|ALtAJYsD_@{Ew@qLWqDa@gGK{BWgKGgBSyH?g@J}BFyBF_I@_@Gk@YwAAc@Jm@HMPMFATDNLRXNRRJP@RCNIM{@Ce@Ga@[oC[mDkAwNSwB\\Kt@St@Q`D}@fAe@l@]~@w@lAsAn@{@t@iAx@}Av@oBb@uA~@uD~AaIRsA`@iDZwAES?]FYDCUqBWqBm@kFu@cHGsAKyCGaDJ}DL_FA{AI{@Y{ByAoFgAaD{@mCmBqFw@aCQc@d@k@XY|AqA`Ay@|@y@tDwD`BiBR]~AuC`AwBbBsE|CaJlCaI`@oANu@PiB?qBIgBSkCGw@X}D?SUkAE_DCw@_@yBSq@[aCE?GEGM@QW]cCkAmCsA{BuAwAy@u@i@_D_By@Uk@WWYKQGMSm@QaAm@{EcAcJC?EACI?K@CcAkCeAiCWc@GIUM}EsBuAk@[rBcFoBQE"
          }
        },
        {
          "id": "erEKBEsrEHZINDMmKjFbXyCz8ry1",
          "name": "Linda Angell",
          "distance": 7.55587136,
          "latitude": 52.80883,
          "longitude": -1.6029,
          "travelTime": {
            "personal_car": 0.21,
            "public_transport": 1.33,
            "route": "im}aIb~lHAg@Mk@GIKCM?UHk@LSDe@Cg@IYMSOFc@DcAEuDFwC?UP@fIp@hFb@hCRxDZ|CTtBT~@DVEt@SzAm@~@Yf@Kb@QlAy@|A{ACKBQDEJ?FF?Bj@g@PULSbA_Bt@}AhAkB~@cApAeAhA_A^Sp@[xAYXEXEB^@dAAdAK|BMdBApABjADx@jBSnBWdC_@|D[HAZELCND|@BpBV`AZn@`@t@j@Zj@T|@Ln@nAvH\\zBPv@NZNPJDTAx@Sj@U|@i@tA_Az@q@`@e@tAmB^WZIN?NDr@j@LFRDxAAp@AdBJzBNx@Fn@Jn@L~@V`@Rr@f@jEnDr@j@f@X^J`@?TKPIFKH]BUB_@CGESI_@Cy@R_C^oBLWNKb@URAZFbAb@\\XZd@Lb@Hn@h@nATTdBpA`CfBrCxBjAdAh@f@~BlCRVLXlAhAlDpDlBxBpCfD~LzPnRhXjEfGlD~EfJrMhIfLbI~K~QrWlNbSdI|LtDbF|DdFvDbFjCnDf@z@hDxEh@v@zAvBH[`@gBb@sBV_APWPKNGf@SEqBB{AJmAr@{DfAcFjAgGLiCAw@KuC[_FQcDCsA?uAAwAWgJIaE}@oKg@yE@g@A??AAAAC?I@IDADB@FfASjH}AlBe@|AWp@]`@g@b@}@l@w@t@o@tA_BlCaDtBaC~@aAx@w@vHqGdCuBp@o@zDqE^c@P_@hAqCtAeD|@cB`@s@\\g@TOp@c@NIrCoA^k@DQ@C?CDc@UmFCkAFe@Xq@`@_@dAw@AEAM@INQHBHZVLp@v@V\\b@x@fBlEt@pAd@lARt@PnBFrAR`CFd@|AlHp@zCjBxHbCzJnAbFzFnUjDrNb@`BnC~H|B|FVl@j@bAjBhDfAbC|@fCp@`Dj@zCj@rDf@pDX|A\\fBVlBl@`F\\nCBJh@bBp@nB|@bCzAzHl@hDNz@Dn@@n@Av@SbBCvAFbAZvBf@dBTxAXrCj@hK`@lF@nBKlEc@pIWfF?t@DXHx@XfBxAvEh@vAZn@Tb@^f@z@v@jDbC`Al@dDtB|CtBXR`@h@Vj@Tn@\\tA`@hBF^LT|@pAnBzBp@f@nA`AfBjAjDjB~DlBjDbCpCrBjBxAv@j@`C`AvChAVLHFNu@Rw@ZcA|@cCt@mBdAeBzCqFp@cBVaAv@yC^cAH[`@w@r@aBX{@f@iA\\i@dAw@`@QLE"
          }
        },
        {
          "id": "HPsmvDUgpKwU3V7gwFyF",
          "name": "Demo 2 Client",
          "distance": 33.078063814,
          "latitude": 52.58598,
          "longitude": -2.12328,
          "travelTime": {
            "personal_car": 1.01,
            "public_transport": 2.43,
            "route": "im}aIb~lHOsAw@CeBNaAWKs@?yFFwCPSfIp@hFb@hCRlMfAlCSfFeB|DyDrAeAbEiHzEiEjDiAXE\\X?jCYbF@|CDx@jBStFw@fE]ZEnGx@`CxBb@lB~BjN`Ap@xFsCrDeEz@a@rAp@nKd@zFlA~FvEzBpAhAUPi@KqBNyDl@gCr@a@pCbAr@xBdDvDtG`FdJvJlLrM~LzPpi@dv@tkAfbB`|@vnA~^zi@lGfOxCfL|Hz[fJb`@hH|TlFjLlHdMnJtPbY~i@jIhMzLzNnG~GbLnPvThe@`BxFxApJlAjRrBpPf@xBxEdLzLjNz\\h_@vM`N~KxFzFpA|Gd@xLp@lHxBnG|DdFtE~LlJjNlGjKhDvDnB~DvDxCrDjFzGjGhGxHhCpJdCrHlFlTbZnJpMdNzNxWbYfNdPvBlDnDzItDpKnCrEb^rf@ff@tp@|X~`@hc@|l@vg@zo@dGnGzEjI~Q~XrVf\\fQjWpi@~s@n_B|wB`JtKzHxGhSxKlLnFtRbJxIdFdH|EhQhOdLxLpN`Rb\\li@zIrLlIvHvM`I|_@xT|QfKbFtDlFnHzDlIdD|MlBrQbAfLfAhDdB\\p@jA~@pArB|@|E^zGVt@kATaHEmAu@yBkBa@sAnAeB~Fy@`CoBnJ{CtM}FjUaDrLiHt\\iBjM_CxV{@hQ]hVXpX|Cte@nJ|l@dDv[Jn\\aCfZoBvKaDfLcE`KgJ|QaP~b@yI`^eCdN}CrSoD|_@sAb[DnRpApTvDvYtBn[A`Oo@vNsA`MgFh\\kCh]K`T`@vRbAfc@GlR}Aj_@mAtMkFr_@gM`t@iEbUkKd]iCbJyFrWwBfVWxVhArVzBhXnBnk@r@|c@hBrRzAfIxEbPtIvUfCvIfE~SdAjHbBxLbAlC|BjCpD|BrD`BjIhBrCpAzBa@jB^h@tCO`Io@jEl@lKpAvGtAzDpHfRbNl^xDjF|GxD|FvD`GrFxOhObGjFhB`@vBIbCAdAVrBt@fBHhCoGh@YtARj@r@p@~CvAdG~HtK|@FfD|B|KvBjBHfB~@zHnCzG`@fHQrEk@`IgChKiCjG{@~Cv@fIhGlEzGtJ`LdS|O`B~@LId@RJj@~BhCjJpGfIfI~BfD`DfEzAt@fLuAxAKjDrBfExDvFzBbBp@?EBILB?TnWzf@hBzGvAdJxFjYrBvMhB~G`D|E~B`CpJnAlADt@_@VkC`@{FlAyElDsE|CiB~JgClEGr@_Al@w@t@El@jAJrBYzAk@f@cAnBKnAYd@a@Ge@c@k@s@gB@{Cj@]z@"
          }
        },
        {
          "id": "8HKD3j9fD7JkCit9CZBx",
          "name": "Demo 1 Client",
          "distance": 33.483819077,
          "latitude": 52.58677,
          "longitude": -2.13767,
          "travelTime": {
            "personal_car": 0.59,
            "public_transport": 2.58,
            "route": "im}aIb~lHOsAw@CeBNaAWKs@?yFFwCPSfIp@hFb@hCRlMfAlCSfFeB|DyDrAeAbEiHzEiEjDiAXE\\X?jCYbF@|CDx@jBStFw@fE]ZEnGx@`CxBb@lB~BjN`Ap@xFsCrDeEz@a@rAp@nKd@zFlA~FvEzBpAhAUPi@KqBNyDl@gCr@a@pCbAr@xBh@nAzBfBtG`FdJvJlLrM~LzPpi@dv@tkAfbB`|@vnA~^zi@lGfOxCfL|Hz[fJb`@hH|TlFjLlHdMnJtPbY~i@jIhMzLzNnG~GbLnPvThe@`BxFxApJlAjRrBpPf@xBxEdLzLjNz\\h_@vM`N|BzA`H|CzFpA|Gd@xLp@lHxBnG|DdFtE~LlJjNlGjKhDvDnB~DvDxCrDjFzGjGhGxHhCpJdCrHlFlTbZnJpMdNzNxWbYfNdPvBlDnDzItDpKnCrEb^rf@ff@tp@|X~`@zP`VlQzUdN~PpXz]dGnGzEjI~Q~XrVf\\fQjWpi@~s@n_B|wB`JtKzHxGhSxKlLnFtRbJxIdFdH|EhQhOdLxLpN`Rb\\li@zIrLlIvHvM`I|_@xT|QfKbFtDlFnHzDlIdD|MlBrQbAfLfAhDdB\\p@jA~@pArB|@|E^zGVt@kATaHEmAu@yBeAe@e@BsAnAeB~Fy@`CoBnJ{CtM}FjUaDrLiHt\\iBjM_CxV{@hQ]hVXpX|Cte@jF~]bC|MdDv[Jn\\aCfZoBvKaDfLcE`KgJ|QoGnOqGnRyI`^eCdN}CrSoD|_@sAb[DnRpApTvDvYlAfMf@fMA`Oo@vNsA`MgFh\\kCh]K`T`@vRbAfc@GlR}Aj_@mAtMkFr_@gM`t@iEbUkKd]iCbJyFrWwBfVWxVhArVzBhXpA|W\\pRr@|c@hBrRzAfIxEbPtIvUfCvIfE~SdAjHbBxLbAlC|BjCpD|BrD`BjIhBrCpAzBa@jB^h@tCO`Io@jEl@lKpAvGtAzDpHfRbNl^xDjF|GxD|FvD`GrFxOhObGjFhB`@vBIbCAdAVrBt@fBHhCoGh@YtARj@r@p@~CYvG{@fG?dGrA`_@dCdh@t@zc@Cbs@n@|WTbNN~Ed@zBdCpAvH}@|L?xIZnDz@nDfBdIlCli@fO^?DPfCjAnSrF|JfD`IhAbCMzEwBhEcCd@O\\TfAl@xEDfKyCpDy@hDSnCUnGpBnB@xBk@r@{@|@HfKwBhJEjCAx@a@f@gAn@Lf@rAvA`BlG~BdKdEjAc@XR~ApAhJfB|@X|@Vo@dAw@bAi@r@bClJv@jDT~@nBg@`@Tn@`@hAtA|@bCH`@"
          }
        },
        {
          "id": "qCBCLe3u1ySAOaLKAN8RO1uAIP52",
          "name": "Debbie Edwards",
          "distance": 33.547198919,
          "latitude": 52.58687,
          "longitude": -2.13986,
          "travelTime": {
            "personal_car": 0.59,
            "public_transport": 3.01,
            "route": "im}aIb~lHOsAw@CeBNaAWKs@?yFFwCPSfIp@hFb@hCRlMfAlCSfFeB|DyDrAeAbEiHzEiEjDiAXE\\X?jCYbF@|CDx@jBStFw@fE]ZEnGx@`CxBb@lB~BjN`Ap@xFsCrDeEz@a@rAp@nKd@zFlA~FvEzBpAhAUPi@KqBNyDl@gCr@a@pCbAr@xBdDvDtG`FdJvJlLrM~LzPpi@dv@tkAfbB`|@vnA~^zi@lGfOxCfL|Hz[fJb`@hH|TlFjLlHdMnJtPbY~i@jIhMzLzNnG~GbLnPvThe@`BxFxApJlAjRrBpPf@xBxEdLzLjNz\\h_@vM`N~KxFzFpA|Gd@xLp@lHxBnG|DdFtE~LlJjNlGjKhDvDnB~DvDxCrDjFzGjGhGxHhCpJdCrHlFlTbZnJpMdNzNxWbYfNdPvBlDnDzItDpKnCrEb^rf@ff@tp@|X~`@hc@|l@vg@zo@dGnGzEjI~Q~XrVf\\fQjWpi@~s@n_B|wB`JtKzHxGhSxKlLnFtRbJxIdFdH|EhQhOdLxLpN`Rb\\li@zIrLlIvHvM`I|_@xT|QfKbFtDlFnHzDlIdD|MlBrQbAfLfAhDdB\\p@jA~@pArB|@|E^zGVt@kATaHEmAu@yBkBa@sAnAeB~Fy@`CoBnJ{CtM}FjUaDrLiHt\\iBjM_CxV{@hQ]hVXpX|Cte@nJ|l@dDv[Jn\\aCfZoBvKaDfLcE`KgJ|QaP~b@yI`^eCdN}CrSoD|_@sAb[DnRpApTvDvYtBn[A`Oo@vNsA`MgFh\\kCh]K`T`@vRbAfc@GlR}Aj_@mAtMkFr_@gM`t@iEbUkKd]iCbJyFrWwBfVWxVhArVzBhXnBnk@r@|c@hBrRzAfIxEbPtIvUfCvIfE~SdAjHbBxLbAlC|BjCpD|BrD`BjIhBrCpAzBa@jB^h@tCO`Io@jEl@lKpAvGtAzDpHfRbNl^xDjF|GxD|FvD`GrFxOhObGjFhB`@vBIbCAdAVrBt@fBHhCoGh@YtARj@r@p@~CYvG{@fG?dGrA`_@dCdh@t@zc@Cbs@n@|WTbNN~Ed@zBdCpAvH}@vWZnDz@nDfBdIlCli@fO^?DPfCjAnSrF|JfD`IhAbCMzEwBhEcCd@O\\TfAl@xEDfKyCpDy@hDSnCUnGpBnB@xBk@r@{@|@HfKwBhJEjCAx@a@f@gAn@Lf@rAvA`BlG~BdKdEjAc@XR~ApAhJfB|@X|@Vo@dAw@bAi@r@bClJv@jDT~@nBg@`@Tn@`@hAtA|@bCVbDEjDc@p@"
          }
        }
      ]
    },
    {
      "id": "BjVditD7KCIwlQ3EQ42I",
      "name": "Demo 2 Employee",
      "latitude": 52.824237,
      "longitude": -1.644658,
      "distances": [
        {
          "id": "erEKBEsrEHZINDMmKjFbXyCz8ry1",
          "name": "Linda Angell",
          "distance": 2.045553332,
          "latitude": 52.80883,
          "longitude": -1.6029,
          "travelTime": {
            "personal_car": 0.1,
            "public_transport": 0.59,
            "route": "oflaI~e`Ib@APu@t@sBRa@x@oAp@w@rC_DtBqCn@m@fAy@dDiBf@Y^KtA[tA_@h@Ql@W\\KfAU~Ae@vBa@`Bc@`@Sl@e@nHsHnBqB~AeBd@s@AA?G?G?MDMFGHAFBFHBNbBo@bD{ArFaC|DgBtA{@p@g@lAwAn@}@n@iAXm@j@{AbBuGP{@Hy@HqAFcBL{KDoAa@OeA]a@c@K]Cm@Dc@La@RW^SPC`@ALA`@a@v@_HbBkOd@_FF[Vc@t@cA~@_BVi@X_ALg@NuAbAeZjAw]PoENsC^wF\\wERqBp@kFG@CBK\\a@zBYnB_@dDUlASt@gAjBi@n@QTq@h@sB|@a@RqDbBCcAQsA_@iBQ{@Qc@e@iAoAaCyAuCM@IK?UBEOMCDCBEAEE?CM?W@w@P"
          }
        },
        {
          "id": "7LiDNX3SSId9UH6KusK7",
          "name": "Demo 3 Client",
          "distance": 9.276447659,
          "latitude": 52.91157,
          "longitude": -1.47605,
          "travelTime": {
            "personal_car": 0.24,
            "public_transport": 1.29,
            "route": "oflaI~e`Ib@APu@t@sBRa@x@oAp@w@rC_DtBqCn@m@fAy@dDiBf@Y^KtA[tA_@h@Ql@W\\KfAU~Ae@vBa@`Bc@`@Sl@e@nHsHnBqB~AeBD[FS@QA]I[gAcAw@{@oCiDqJuMiCwD{EsGwHsKYa@KOOEWQIQIDK?OIKSCSAGO_@I]GMEO_ByBkAaBkA_BaB}ByJaNcDmE{B_DqHuKCEK?QEY[A?A?CACCGMAM?GUm@EQg@s@qAeBoCcDwAyAgA{@WS{@_@m@OaDk@{Ci@cAUmCs@gAWeDeAoBk@}@Ks@@WRgAZiBj@o@L_@Ae@K[OYUk@w@e@sAc@iC[eCIyCKgBkCuKmAgFsCoL_CwJ{@gDeAoDoAcDW}@a@{@sAcC{@uA{BiDcD}EsEyGqHcLkBiC_CmDiI_MaJsMmHqKgGuI{AwBaBuBoCwDyE}F_BwBoC{DuDsFyJ{NwDqFmEkGyNqSeDsEePcU}FuHmD_FiBsC{D}FmCwDeGsI{S}YiCkDgCaDwGgHgEyDeFaEaBgAuCoBaHsDyCyA_Bo@oDuA_W_KsJwDsCuA{@a@oCuAgDiB_Ak@eCyAk@]cHuE{FiEaFyD{FyEwJ}IeMgMoKkKmDoDoGyGWC{BoBaAq@q@Y}AYc@EqAQgAa@_@[_@_@OUYg@Uo@Qq@O_AMqACqBJcBPoA^uAz@cBnBmCp@iAZo@h@}Ad@{BPiBBs@Ku@Ic@Aw@B[Dc@Zu@b@eAZaB@cAG{BMgCUoB_@yBe@wBsAgEeCsHEk@a@uAMk@Ag@nAUdAUz@S^GTIl@_@~AyB|AsB~@aAtAaAj@a@j@k@d@{@h@uAd@{B^wBd@yAP]`@m@vBqClAuAl@w@zBkC|AqA`Ay@|@y@tDwD`BiBR]~AuC`AwBbBsE|CaJlCaI`@oANu@PiB?qBIgBSkCGw@X}D?SUkAE_DCw@_@yBSq@[aCE?GEGM@QW]cCkAmCsA{BuAwAy@u@i@_D_By@Uk@WWYKQGMSm@QaAm@{EcAcJA?C?C?CG?K@E?AcAkCeAiCWc@GIUM}EsBuAk@[rBcFoBQE"
          }
        },
        {
          "id": "HPsmvDUgpKwU3V7gwFyF",
          "name": "Demo 2 Client",
          "distance": 25.961501751,
          "latitude": 52.58598,
          "longitude": -2.12328,
          "travelTime": {
            "personal_car": 0.56,
            "public_transport": 1.31,
            "route": "oflaI~e`Ib@APu@t@sB~BiDhGqH|GqE|GsBjJaCpD}A~KeLbC{C?OD[`@BJbBv@jBxSjY`HrIdExBLn@sAtFzAvCtKpPx@j@L\\zDbGtBxDdDfE|Ur[nMdQnCfF`AnFNhCCzD}@zL`@jET^B`Cr@hDlB|CpDvCrAx@hA\\lBt@lKzCzBj@x@z@t@pBXzCNlCr@|@rAvCx@t@jEhBbExBrB|A~DvDtBhCdDfEnErFdDrCjB~@`MrC|Bz@zExCjErEtKjO~H~K`J|Kn_@~`@hQpSfD`GjFbO`CpFpNbSrq@d_Ax[tc@zGzKpFnHdPlTvQjUnUrZdK`LlCvDhP|WpWp]`TzZlKvN|l@fx@vy@phArRhWxJtM~HzHrI`GbSnJjRzItLlGtGdE~NjLxOrOpM|OtTn]~NhVpD|ErDvDrK~H~^bTl^vSlGvGzEtItDtLlBtLlA~PpAfHXj@VRr@BXDVRxAhCrB|@jBVfHPdBL^a@`@{CB}Fu@yBeAe@e@By@h@}@jBaAxDy@`CsD`QcGrVyJn_@uFj[{AlMoA|PaAp^H`WZzKpArTz@zIjF~]fF|[`AvMVxMKtM}@rOsDjVaDfL{B|FmCtFqDdHgEfJcJhVqF`RmF|UgDxRqDzYwCdc@YdRH~Ip@|N~ClW|A`MbAfOPpKGrHo@vNwCpUcDxS{AvNw@`TJ~YfAn\\LjXa@vTq@pMqDh^qGxa@cG|[yChRqEdReLh_@{CdLwBpLqAtL_AnVXnTtBpXdBxVt@|Tf@fe@f@tLzBhRzCnMnGpQdIrVfE~S~@nGp@rGPjA|@nDnBjD~BjBbDrBfGbB~Dx@|Ap@t@^z@OxBSp@`@^z@@lF]~FYxBFbDbAdJ|AxF|FzNfN`_@xCvGzBhCvA`A~JvF~EjEjMzLfI|HnBzAhB`@dEMzAJxAn@nCd@l@i@lAeEv@y@r@Ir@Tj@r@rAhG~AfFpFdHdAj@PEX`@vC`B|DjApHz@`@K^L~@v@|Bv@`C`AzFp@jMSbFiA`DkArKkCvE}@pBQdAJjCpAfEnDpCtBrAfCvBpDrE|EhPvNfGbEZ?b@@xAtC~FnDtKpJdDbEnErGVD`@`@tALjEk@`Fw@d@?lA^|ArAfExDt@`@dHlC?E@EDGJHlCzFpH|MrEbJpDpIzAvHdEvVjC~Kp@vFvAvGbChF|BjChAlAnBHnIjAt@_@Tk@J{El@oEv@iCrA{BpCgCxF{BpDy@bDM~BWh@kAv@a@r@^\\lBEbBc@`AcAjAe@dCYd@a@Ge@c@k@s@gB@{Cj@Y\\C\\"
          }
        },
        {
          "id": "8HKD3j9fD7JkCit9CZBx",
          "name": "Demo 1 Client",
          "distance": 26.39584008,
          "latitude": 52.58677,
          "longitude": -2.13767,
          "travelTime": {
            "personal_car": 0.53,
            "public_transport": 1.46,
            "route": "oflaI~e`Ib@APu@t@sB~BiDhGqHvBgBdDiB|GsBjJaCpD}A~KeLbC{C?OD[`@BJbBv@jBxSjY`HrIdExBLn@sAtFzAvCtKpPx@j@L\\zDbGtBxDdDfE|Ur[nMdQnCfF`AnFNhCCzD}@zL`@jET^B`Cr@hDlB|CpDvCrAx@hA\\lBt@lKzCzBj@x@z@t@pBXzCNlCr@|@rAvCx@t@jEhBbExBrB|A~DvDtBhCdDfEnErFdDrCjB~@`MrC|Bz@zExCjErEtKjO~H~K`J|Kn_@~`@hQpSfD`GjFbO`CpFpNbSrq@d_Ax[tc@zGzKpFnHdPlTvQjUnUrZdK`LlCvDhP|WpWp]`TzZlKvN|l@fx@vy@phArRhWxJtM~HzHrI`GbSnJjRzItLlGtGdE~NjLxOrOpM|OtTn]~NhVpD|ErDvDrK~H~^bTl^vS|BjBnCjDzEtItDtLlBtLlA~PpAfHXj@VRr@BXDVRxAhCrB|@jBVfHPdBL^a@`@{CB}Fu@yBeAe@e@By@h@}@jBaAxDy@`CsD`QcGrVyJn_@uFj[{AlMoA|PaAp^H`WZzKpArTz@zIjF~]fF|[`AvMVxMKtM}@rOcArIoBvKaDfL{B|FmCtFqDdHgEfJcJhVqF`RmF|UgDxRqDzYwCdc@YdRH~Ip@|N~ClW|A`MbAfOPpKGrHo@vNwCpUcDxS{AvNw@`TJ~YfAn\\LjXa@vTq@pMqDh^qGxa@cG|[yChRqEdReLh_@{CdLwBpLqAtL_AnVXnTtBpXdBxVt@|Tf@fe@f@tLzBhRzCnMnGpQdIrVfE~S~@nGp@rGPjA|@nDnBjD~BjBbDrBfGbB~Dx@|Ap@t@^z@OxBSp@`@^z@@lF]~FYxBFbDbAdJ|AxF|FzNfN`_@xCvGzBhCvA`A~JvF~EjEjMzLfI|HnBzAhB`@dEMzAJxAn@nCd@l@i@lAeEv@y@r@Ir@Tj@r@p@~C?tCY`Cs@pDKnEdAr]rBz_@vAhn@B|d@EzPNx@RdOp@`\\d@zBx@t@jAZjAIjFs@tDCxNTnE^fBx@fL|Ez@BlI~B`]vJz@DNDh@t@dRzEnIfD`IfBjGd@dCo@|EeCpBiARUl@D`@d@zCb@rDc@|FeBxHaBzD?t@Ud@?jDtAxB\\fBQhB_AHUd@@nBSfE}@bEs@xDFfGEp@sAn@Ob@v@n@rA|@v@zAn@tIbD`FpBdAWJONHn@bApDx@vCf@rA`@|@X|@Vo@dAw@bAi@r@bClJv@jDT~@nBg@`@Tn@`@hAtAb@`Ab@bB"
          }
        },
        {
          "id": "qCBCLe3u1ySAOaLKAN8RO1uAIP52",
          "name": "Debbie Edwards",
          "distance": 26.463569519,
          "latitude": 52.58687,
          "longitude": -2.13986,
          "travelTime": {
            "personal_car": 0.54,
            "public_transport": 1.49,
            "route": "oflaI~e`Ib@APu@t@sB~BiDhGqHvBgBdDiB|GsBjJaCpD}A~KeLbC{C?OD[`@BJbBv@jBxSjY`HrIdExBLn@sAtFzAvCtKpPx@j@L\\zDbGtBxDdDfE|Ur[nMdQnCfF`AnFNhCCzD}@zL`@jET^B`Cr@hDlB|CpDvCrAx@hA\\lBt@lKzCzBj@x@z@t@pBXzCNlCr@|@rAvCx@t@jEhBbExBrB|A~DvDtBhCdDfEnErFdDrCjB~@`MrC|Bz@zExCjErEtKjO~H~K`J|Kn_@~`@hQpSfD`GjFbO`CpFpNbSrq@d_Ax[tc@zGzKpFnHdPlTvQjUnUrZdK`LlCvDhP|WpWp]`TzZlKvN|l@fx@vy@phArRhWxJtM~HzHrI`GbSnJjRzItLlGtGdE~NjLxOrOpM|OtTn]~NhVpD|ErDvDrK~H~^bTl^vS|BjBnCjDzEtItDtLlBtLlA~PpAfHXj@VRr@BXDVRxAhCrB|@jBVfHPdBL^a@`@{CB}Fu@yBeAe@e@By@h@}@jBaAxDy@`CsD`QcGrVyJn_@uFj[{AlMoA|PaAp^H`WZzKpArTz@zIjF~]fF|[`AvMVxMKtM}@rOcArIoBvKaDfL{B|FmCtFqDdHgEfJcJhVqF`RmF|UgDxRqDzYwCdc@YdRH~Ip@|N~ClW|A`MbAfOPpKGrHo@vNwCpUcDxS{AvNw@`TJ~YfAn\\LjXa@vTq@pMqDh^qGxa@cG|[yChRqEdReLh_@{CdLwBpLqAtL_AnVXnTtBpXdBxVt@|Tf@fe@f@tLzBhRzCnMnGpQdIrVfE~S~@nGp@rGPjA|@nDnBjD~BjBbDrBfGbB~Dx@|Ap@t@^z@OxBSp@`@^z@@lF]~FYxBFbDbAdJ|AxF|FzNfN`_@xCvGzBhCvA`A~JvF~EjEjMzLfI|HnBzAhB`@dEMzAJxAn@nCd@l@i@lAeEv@y@r@Ir@Tj@r@p@~C?tCY`Cs@pDKnEdAr]rBz_@vAhn@B|d@EzPNx@RdOp@`\\d@zBx@t@jAZjAIjFs@tDCxNTnE^fBx@fL|Ez@BlI~B`]vJz@DNDh@t@dRzEnIfD`IfBjGd@dCo@|EeCpBiARUl@D`@d@zCb@rDc@|FeBxHaBzD?t@Ud@?jDtAxB\\fBQhB_AHUd@@nBSfE}@bEs@xDFfGEp@sAn@Ob@v@n@rA|@v@zAn@tIbD`FpBdAWJONHn@bApDx@vCf@rA`@|@X|@Vo@dAw@bAi@r@bClJv@jDT~@nBg@`@Tn@`@hAtAb@`Aj@fCChEB~@c@p@"
          }
        }
      ]
    },
    {
      "id": "IS3J0uFdumXVmaDaR49V",
      "name": "Demo 1 Employee",
      "latitude": 52.59255,
      "longitude": -2.14826,
      "distances": [
        {
          "id": "qCBCLe3u1ySAOaLKAN8RO1uAIP52",
          "name": "Debbie Edwards",
          "distance": 0.52816535,
          "latitude": 52.58687,
          "longitude": -2.13986,
          "travelTime": {
            "personal_car": 0.02,
            "public_transport": 0.14,
            "route": "k__`I~pbLt@kFx@mGX?xA@Ay@@mCJ{Bd@{CLi@VVzAdAn@Zn@TXDb@?h@GVId@a@f@m@d@aAnCyG`@eAb@kA|@cBt@sAt@kA"
          }
        },
        {
          "id": "8HKD3j9fD7JkCit9CZBx",
          "name": "Demo 1 Client",
          "distance": 0.598380273,
          "latitude": 52.58677,
          "longitude": -2.13767,
          "travelTime": {
            "personal_car": 0.02,
            "public_transport": 0.16,
            "route": "k__`I~pbLt@kFx@mGX?xA@Ay@@mCJ{Bd@{CLi@VVzAdAn@Zn@TXDb@?h@GVId@a@f@m@d@aAnCyG`@eAb@kA|@cBt@sAxA}BE]@a@Di@BaAAq@KoA"
          }
        },
        {
          "id": "HPsmvDUgpKwU3V7gwFyF",
          "name": "Demo 2 Client",
          "distance": 1.143944011,
          "latitude": 52.58598,
          "longitude": -2.12328,
          "travelTime": {
            "personal_car": 0.09,
            "public_transport": 0.32,
            "route": "k__`I~pbLt@kFx@mGX?xA@Ay@@mCJ{Bd@{CLi@VVzAdAn@Zn@TXDb@?h@GVId@a@f@m@d@aAnCyG`@eAb@kA|@cBt@sAxA}Bf@Un@WlBa@^Mj@[VMRIRBFBZwBj@oDFy@?i@Aq@GcAMwAGUCOSUe@g@IQI[@i@JcANYROVEbAv@\\C^MdD}@jEmAjD{@l@QLI\\_@Ri@F[CYGcA@]FWFMHGDCJEFYDS@QCgAMaDEwAHeCFeFBsCCqBEm@?kB?aACoAIu@Mw@Oo@_@}@[m@aA_Be@o@_AgAk@i@u@q@u@g@QISCa@ASEYPIV_@`@MTK^If@Af@Yd@KJUSUQOQk@s@[Gk@@_@F{Cj@UDCVC\\"
          }
        },
        {
          "id": "erEKBEsrEHZINDMmKjFbXyCz8ry1",
          "name": "Linda Angell",
          "distance": 27.319197386,
          "latitude": 52.80883,
          "longitude": -1.6029,
          "travelTime": {
            "personal_car": 0.57,
            "public_transport": 2.06,
            "route": "k__`I~pbLt@kFx@mG_ESmDEiAk@_B{@cBiBkBqE{AoHiE}IgEwGe@HqCmGc@_AqDeCC@EC}DqCkBmDiAkJMwBZ}CjAyDOgAcFp@_GqAq@ZOMyC]}CVaIjBsGdBqEOaAz@g@Ay@k@aBh@{H~C_DG_Fw@aLoDaFoBcH{A{GcBcCiAec@gMy@h@Q`@i@UEc@}CuB_HuCaEgAmK_@aHFqG`@}D|@qBxFcAt@_AEw@s@]cAEaDh@qFXwPb@ah@Aol@c@aY{Cwp@yBeTgAaSWsAy@uAcD{EwAiAsA_@cB?kCH_BSsGmFgHuGyMqM}FaEkH}DeEgFuDsJsLm[eGsOoAyFmA{KuAsB_AgDq@}Gq@{BsA}@{ImBaEuAgFkCiCgBiEeE{@{@e@A_@P{@jBDpBfAzB`BHjA_BZkEg@sN}@yIyBsMeE}NyDmKyGoRmCeMwAiLaAqTk@ch@{AqZsAmP}@}Kq@oPR_W~B{WzEkUtAuE`Lmb@hLki@`DeQzI{l@xBeWbAs[Ku^kAo]MaNPyO\\_HjBsSrEmYrAuKv@gMDiXi@aK{CuWqCaWk@cXnAm^vDgb@zEw[bJma@zM_`@pNgZ|CoH~DeNnDoUhAiTAoQqAqTyCmSwGqa@qCk\\y@gW?oZlAs\\lBqUtBiPpGmY~H}YlAsJBcDk@uDm@kCwAiCcAiEg@iEo@iSkAiKmDaO{FaMqE}FgQqKu]kSkQcKyJsH_I_JgVy`@yPeWeKcMcKiK}UiRuUmMq^qPqKqGmGmFqNeQ}Va]oo@u{@gv@adA_d@yn@kTyYwLcSsDgFoG_HsYk_@ic@ek@wPaWqXo_@_bAetAgCuF{CcJsEkJaNkPqb@ud@{JsLoMyQcJyLiD_DqEkCkGcBsG{AyCgBcCaCkFmGgD_EuCiDgFyDsEeAkFcAqA\\}@d@kAm@a@cB\\wEp@qB@yBm@cF@i@tAwAhBiA|AiBJuCo@wKe@ePAaNxAgVQwPRaKa@iJYaAc@_CcBkKwCcLkGsOiDmD}CeBuCmCwHsKuJwKqG{MMEKYvAsDnCaMrAgL~Eih@HqEIWHy@f@_Ef@eE|@o@bFYdFw@lB}@@YBCSaEdAgJtAgK[oCoBoCyCkE_BwGi@{Bw@gA_B_@gBV{ENcAECFKCAGi@@mCRqAYkAmAg@qCXcJ[oHeAkEc@_AOKG[e@uCuBoCgC{@eA_DLsD`@eCd@uC@iCe@yB{@cAcAQeAZy@PmBu@O@SG]t@iAnIqC~QwAdKeApD_BxBgE|B}CvAYEa@oDuAwEsDcHWQDUUEq@Gy@P"
          }
        },
        {
          "id": "7LiDNX3SSId9UH6KusK7",
          "name": "Demo 3 Client",
          "distance": 35.762386534,
          "latitude": 52.91157,
          "longitude": -1.47605,
          "travelTime": {
            "personal_car": 1.03,
            "public_transport": 2.06,
            "route": "k__`I~pbLt@kFx@mG_ESmDEiAk@_B{@cBiBkBqE{AoHiE}IgEwGe@HqCmGc@_AqDeCC@EC}DqCkBmDiAkJMwBZ}CjAyDOgAcFp@_GqAq@ZOMyC]}CVaIjBsGdBqEOaAz@g@Ay@k@aBh@{H~C_DG_Fw@aLoDeOkE{GcBcCiAec@gMy@h@Q`@i@UEc@}CuB_HuCaEgAoTWqG`@}D|@qBxFcAt@_AEw@s@]cAEaDh@qFXwPb@ah@Aol@c@aY{Cwp@yBeTgAaSqAiDcD{EwAiAsA_@cB?kFIsGmFgHuGyMqMiP_KeEgFuDsJsLm[eGsOoAyFmA{KuAsB_AgDq@}Gq@{BsA}@{ImBaEuAgFkCsImHaB}@_@P{@jBDpBfAzB`BHjA_BZkEg@sN}@yIyBsM_Kk[yGoRmCeMwAiLaAqTk@ch@{AqZsAmP}@}Kq@oPR_W~B{WzEkUtAuE`Lmb@hLki@`DeQzI{l@xBeWbAs[Ku^kAo]B{^hCs\\rEmYjC}YDiXi@aK{CuWqCaWk@cXnAm^vDgb@zEw[bJma@zM_`@pNgZ|CoH~DeNnDoUhAiTAoQqAqTyCmSwGqa@qCk\\y@gW?oZlAs\\lBqUtBiPpGmY~H}YlAsJBcDk@uDeCuGkBsKo@iSkAiKmDaO{FaMqE}FgQqKu]kSkQcKyJsH_I_JgVy`@yPeWeKcMcKiK}UiRuUmMq^qPqKqGmGmFqNeQ}Va]oo@u{@gv@adA_d@yn@kTyYwLcSsDgFoG_HsYk_@ic@ek@wPaWqXo_@_bAetAcHyQsEkJaNkPqb@ud@{JsLoMyQcJyLiD_DqEkCkGcBsG{AyCgBcCaCkFmGgD_EuCiDgFyDyJwDiH}BuIuEeTeQ}IkE_HsAwDUoFOkIgAoJsDwGaFkMuNga@wc@_EiFaD}G{CsMwCy_@sAaI{BeHmRaa@eH_LkFsGwSiVoHcLqL}TuWwf@_J}O_J}ToNqj@kHqZkFeRuAmDkGcKiT{[mRaYsVe^wYi`@{w@uiAst@idAiPiUkP}Q{MmKsPeJsq@uXiPcJaRuM{K}I}XeXmYuYgG_E{GsAiByBeAsFFuEp@eDjDqFvBwEv@eFSeEd@uB~@gD@cAUcG{AaJ_FgPo@uClAi@hB_@v@QbDcDrFwF|BiC~BsJtBqDbMcNtJyJtCmF`JaWnBcGb@iEe@cKHmFQwF}AsH]}@{OyIyEuBoAcAe@_B{B}QA?GAAU@CiCuGoByAcDuAuAk@[rBcFoBQE"
          }
        }
      ]
    },
    {
      "id": "XwuJcItPaSLTZFIZ9Aa9",
      "name": "MARTHA JOHNS",
      "latitude": 52.56424,
      "longitude": -2.15776,
      "distances": [
        {
          "id": "qCBCLe3u1ySAOaLKAN8RO1uAIP52",
          "name": "Debbie Edwards",
          "distance": 1.736731945,
          "latitude": 52.58687,
          "longitude": -2.13986,
          "travelTime": {
            "personal_car": 0.09,
            "public_transport": 0.32,
            "route": "iny_IpmdLa@w@m@aCq@gCAWHU`@c@pEiE~EwEFK@GAa@g@aDlCuAd@[t@q@Gk@a@w@kBmC}DcFw@cAsA{AeBgBqBwBcEkF_DqDiBmB}BkCyBsBuB}BqB{BmFmHkB{Be@g@iBmBkAcBk@{@o@q@oAiAwAqAc@g@c@_@oAkA_@WoAs@gAu@eCgBqAgA{@i@y@e@uBcAs@]mA{@qAcAoCgBoBqAm@_@KDSFIBSHMHOTe@n@QNWNiA`@_B`@}F|Aw@Pe@JK@WCa@FMDMDSVm@lBMPUHi@k@u@JqAZaAHu@AOEa@VKHMVEb@Bz@n@xCo@f@s@j@FJJf@H`@JnA@p@C`AEh@A`@D\\c@p@"
          }
        },
        {
          "id": "8HKD3j9fD7JkCit9CZBx",
          "name": "Demo 1 Client",
          "distance": 1.772771463,
          "latitude": 52.58677,
          "longitude": -2.13767,
          "travelTime": {
            "personal_car": 0.09,
            "public_transport": 0.32,
            "route": "iny_IpmdLa@w@m@aCq@gCAWHU`@c@pEiE~EwEFK@GAa@g@aDlCuAd@[t@q@Gk@a@w@kBmC}DcFw@cAsA{AeBgBqBwBcEkF_DqDiBmB}BkCyBsBuB}BqB{BmFmHkB{Be@g@iBmBkAcBk@{@o@q@oAiAwAqAc@g@c@_@oAkA_@WoAs@gAu@eCgBqAgA{@i@y@e@uBcAs@]mA{@qAcAoCgBoBqAm@_@KDSFIBSHMHOTe@n@QNWNiA`@_B`@}F|Aw@Pe@JK@WCa@FMDMDSVm@lBMPUHi@k@u@JqAZaAHu@AOEa@VKHMVEb@Bz@n@xCo@f@s@j@FJJf@H`@"
          }
        },
        {
          "id": "HPsmvDUgpKwU3V7gwFyF",
          "name": "Demo 2 Client",
          "distance": 2.088427931,
          "latitude": 52.58598,
          "longitude": -2.12328,
          "travelTime": {
            "personal_car": 0.1,
            "public_transport": 0.28,
            "route": "iny_IpmdLa@w@m@aCq@gCAWHU`@c@pEiE~EwEFK@GAa@g@aDlCuAd@[t@q@Gk@a@w@kBmC}DcFw@cAsA{AeBgBqBwBcEkF_DqDiBmB}BkCyBsBuB}BqB{BmFmHkB{Be@g@iBmBkAcBk@{@o@q@oAiAwAqAc@g@c@_@oAkA_@WoAs@gAu@eCgBqAgA{@i@y@e@uBcAs@]mA{@qAcAoCgBoBqAm@_@KDSFIBOBKEIEGIK]Gi@GcA@]FWFMHGDCJEFYDS@QCgAMaDEwAHeCFeFBsCCqBEm@?kB?aACoAIu@Mw@Oo@_@}@[m@aA_Be@o@_AgAk@i@u@q@u@g@QISCa@ASEYPIV_@`@MTK^If@Af@Yd@KJUSUQOQk@s@[Gk@@_@F{Cj@UDCVC\\"
          }
        },
        {
          "id": "erEKBEsrEHZINDMmKjFbXyCz8ry1",
          "name": "Linda Angell",
          "distance": 28.766370445,
          "latitude": 52.80883,
          "longitude": -1.6029,
          "travelTime": {
            "personal_car": 1.05,
            "public_transport": 2.08,
            "route": "iny_IpmdLcCyI|FcGhFkFi@cErDqBt@q@i@cBiHqJkC_DwE_FcJ}KaJmJuMgPcLcN_JgIkLcIsOoJoBqAmAQ{@l@oAnAiDbA}F|AaCZqAj@qAhCi@k@u@JqAZwBFyCoBs@qAqAyHwA{M_E}O}@cDwAk@{HeAkCYa@_B}BaDmCsG{BsNmGyZeDaR}JsS_MyTKIoDwAmGsDcEsDaBc@eC^yGbAkCAi@`@?mA}FwIeFuFkI_HsFeDgAAc@S[}A{FqEmJ{HoKyKkEyHyIeHkCqAwDDqLrC{I`CcFhAwFVsIS}FcB}Bw@kARODSIEM{C{@eG_AwEkB}@HW_@sIcLiAe@_CfCcALw@_@k@mAyDkFsAiAmDe@aF@gFsDyPePiFcFyFiEyHcEsEiFcMa\\cJqUwC}JoAmLwAiC{@sB}@kIg@{B_A}@cIgBmLsEiIiGeCeCs@R_A`B@hBz@~BfBZtAaBZkDa@eN{@cJwByMyGaU{IaVkD_OuA_KiAeQSyS[gVuAq[wCi^u@uQHmTzBgYfEoTrFoRrMai@fKkh@xIuk@~ByVpAe\\FySyAwi@IuZnBe]xEg[bBcM~@sMPgWk@kM{Gal@aAsY`Ao]vCu^hGac@fHk\\xE}OzHeT|LmWjCyF`FiOnEsWjAqRDgRcAwSeC_R}Gga@eD}]aAmWGmXlAu`@jEkd@hHq\\pFmStCmONqE]wCs@kDyAsCiAqE{@yN_AmPyAiJgEkNqDeHiDyEmJsGgQ}Jqb@oVuMmJ{C}CkI{Kq[uh@_Q}TeLqLqJoIwVoPg]cPeJiEmKeGeH}FwGmHuMmQi\\yc@yo@q{@{q@a_AuRcYaPaToQiWsKkQeGoGoLyOqW_\\gb@_k@cQgWo_@gh@mj@cv@{IqNaDoJoEwJoIqKoQkSi^_`@kZcb@}C_DqFkD}E}A{H_BaH_FuFwGkLqMmE{BgCg@{E_A_BNy@j@mAa@i@cBTmEl@oAN}Bk@mGE[n@kA`FsD\\sCe@{Iq@sRCaKV_J`A{KQeRT_J]_L]yA{EkWmIyT{CcDwCaB}CoCuMgQmEqEiGsMs@w@E_@L[v@iBvC{LrDm]`CaWTsFMy@No@bAeJv@y@zDWjE_@|DsAAKDWU_Cx@qJbBkLYyCcAcByDiF{D_NwAq@iBNkEXoBECDKGMI_FPoAaAw@yBNgJWuIy@iEo@mAQIUiCuBiDwCcAkAyCb@yGt@}GWcC}@_BeA[kATi@TmBc@g@OIBGGgBxJiFj^i@bCqBzCkKtFyAaJoEaJWIOc@mBJ"
          }
        },
        {
          "id": "7LiDNX3SSId9UH6KusK7",
          "name": "Demo 3 Client",
          "distance": 37.31332855,
          "latitude": 52.91157,
          "longitude": -1.47605,
          "travelTime": {
            "personal_car": 1.12,
            "public_transport": 2.19,
            "route": "iny_IpmdLcCyI|FcGhFkFi@cErDqBt@q@i@cBiHqJkC_DwE_FcJ}KaJmJuMgPcLcN_JgIkLcIsOoJoBqAmAQ{@l@oAnAiDbA}F|AaCZqAj@qAhCi@k@u@JqAZwBFyCoBs@qAqAyHwA{M_E}O}@cDwAk@{HeAkCYa@_B}BaDmCsG{BsNmGyZeDaR}JsS_MyTKIoDwAmGsDcEsDaBc@eC^yGbAkCAi@`@?mA}FwIeFuFkI_HsFeDgAAc@S[}A{FqEmJ{HoKyKkEyHyIeHkCqAwDDqLrC{I`CcFhAwFVsIS}FcB}Bw@kARODSIaDiAeG_AwEkB}@HW_@sIcLiAe@_CfCcALcBmByDkFsAiAmDe@aF@gFsDyPePcNmLyHcEsEiFcMa\\cJqUwC}JoAmLwAiC{@sB}@kIg@{B_A}@cIgBmLsEiIiGeCeCs@R_A`B@hBz@~BfBZtAaBZkDa@eN{@cJwByMyGaU{IaVkD_OuA_KiAeQSyS[gVuAq[wCi^u@uQHmTzBgYfEoTrFoRrMai@fKkh@xIuk@~ByVpAe\\FySyAwi@IuZnBe]xEg[bDw[PgWk@kM{Gal@aAsY`Ao]vCu^hGac@fHk\\tOce@|LmWjCyF`FiOnEsWjAqRDgRcAwScLgt@eD}]aAmWGmXlAu`@jEkd@hHq\\pFmStCmONqE]wCs@kDyAsCiAqE{@yN_AmPyAiJgEkNqDeHiDyEmJsGgQ}Jqb@oVuMmJ{C}CkI{Kq[uh@_Q}TwWaWwVoPg]cPsVoMeH}FwGmHuMmQi\\yc@yo@q{@{q@a_Awc@en@oQiWsKkQeGoGoLyOqW_\\gb@_k@cQgWo_@gh@mj@cv@{IqNaDoJoEwJoIqKoQkSi^_`@kZcb@}C_DqFkDyO}DaH_FuFwGkLqM{FgDaF_BuHaCcHmDiRqOgIgFyCkAcMiB_Nu@_MaEcHcFm[w]sR_TwDsEgDeGuDsNuAcOeA_PuAwIoBqGcGsM}IaRcGaKkNoP}L}NoHaLm[qm@mG{KyIgOmIiRcMsd@qLuf@{EiQaG_My`@sl@kTo[un@c|@ouB{xCkPiRwNmLsMoHwSqIm_@sOyPkJiQ_MsH_GcS{Qg]e]cL{J_HuAoCgCaAiEIcEh@yDjG{KzAsGK}DFwB|AaGIgDeBwM}EyOs@aDh@e@|Bg@n@OrCqBdEmFhE{DbCuJ~A}C~GqIzOoOjDeGlDiJvG{Rp@mEOwFSmDVoD_@qHqBiJkYwOiBoK_AuHGO@IcCkGiAoAeHwC[rBaCaAsBs@"
          }
        }
      ]
    }
  ],
  "clients": [
    {
      "id": "7LiDNX3SSId9UH6KusK7",
      "name": "Demo 3 Client",
      "latitude": 52.91157,
      "longitude": -1.47605,
      "distances": [
        {
          "id": "erEKBEsrEHZINDMmKjFbXyCz8ry1",
          "name": "Linda Angell",
          "distance": 8.863857315,
          "latitude": 52.80883,
          "longitude": -1.6029,
          "travelTime": {
            "personal_car": 0.25,
            "public_transport": 2,
            "route": "_i}aI`h_HQrAa@vASM]IuAOcA?eDZkB^_A^g@b@{@x@Lb@Xn@\\`AHh@M\\e@p@uAvBWn@g@hAOXEXv@dAd@f@pAtB`@l@h@z@p@fAtAjC|BbFNf@Rv@NbA`@zCJb@v@nDTz@h@vAt@bBZh@jAxBn@lBZrBr@|F^lDN|Ch@hI`@jD`@zBrAhEzBrGdBbFvAdEj@bBk@p@}DhEkAxAe@`AWr@Qr@o@jD]pAk@xAc@r@_@b@kBtAiAdAmBxB_AtAg@l@o@\\SJeAXaB\\MBQXGRCV?X`@tApAnEtAxDjAlDp@rCl@`FRxDTtCf@|@Pt@?`AIn@eA|BUfAQ|@kAjLOfEDzCBpALpAXnBXxAb@`Bn@fBz@|Av@bANTTt@|BvB~B`CxAzAxHzH`F~EpHvHlHhHrF|ElEpDzFlErF~DjGdEtD|BjHzDdBz@fEnBzEnBvFvBbJtDzJxDdE`BtCtAnCxAfBbAfC|A^VfAt@lH|FxClClDpDdE|EfB|BvFzHdH~JdQjVvGjJfJrMdE~F~FfIpIpLxXn`@nHzK~CvEvC~DxAnB`FrGbFxGf@z@hDxEh@v@zAvBH[`@gBj@mCNe@PWPKNGf@SEqB?s@Bg@JmAr@{Dl@qCvAcHLu@Fo@BqCKuC[_FQcDCsA?uAIsEQoGAgAEuAKsA[oDUkCg@yE@g@A??AAACI@IDED@BD?BpE}@nGyA|AWn@[b@i@b@}@l@w@t@o@v@_ApA{AvBgCpByB~A_B|LgKp@o@hCyCpA{ApDwItAiCHM\\g@TOp@c@JGvCqA^k@DQ@CDg@UmFCkALs@Rc@`@_@dAw@AEAM@IHKDEHBHZVLhAtAb@x@`AfC|@nB\\f@d@lARt@PnBNrCRfBnChMnC|K|F~U|AfGzBdJxAjGhAfEnC~HxBtFZt@bBvCr@tAp@vA~@`CRp@Rz@hA`G|@lGlA|Gb@fDr@bGN~@h@bBb@pAj@vA^hAR~@fAzFl@hDTjB@hAQbBGnA?d@FbAZvBRr@Rp@TxAXrCN|Cf@`ITvC@nBKlEGvAm@hLEv@?t@NrAXfBbAfD~@fCp@rA^f@^\\~AlAbCbB`BbAjCbBtCpB`@h@Vj@`@rAz@zDd@x@d@l@nBzB`BnAfAx@jE`CpAn@r@\\xAt@|AdAxDrCpA`AvB`B`C`AnBt@~@`@HFNu@Rw@z@iCx@yBXq@Ta@vCeFr@oAp@cBnA{E^cAH[`@w@r@aBX{@f@iA\\i@dAw@`@QLE"
          }
        },
        {
          "id": "HPsmvDUgpKwU3V7gwFyF",
          "name": "Demo 2 Client",
          "distance": 35.236085297,
          "latitude": 52.58598,
          "longitude": -2.12328,
          "travelTime": {
            "personal_car": 1.05,
            "public_transport": 1.31,
            "route": "_i}aI`h_Hs@jDq@WyCOeDZkB^gBbA{@x@Lb@v@pBCfA{BhD_AxBOXp@~AbEfGdGvL`BbJvBbIlEtJnApJ|C|\\lKf[j@bBk@p@}DhEiCnEkCjKgJlJwC`DyAd@aCz@Kj@hExN|B`Il@`Fh@nIx@rBIpBmBbGkAjLOfEV~HfCrKrB`DbDbErOxO`Y`Y|T|QtTbOpKvFvu@~Z~KrGtJrHfI~HlHzI|OzTjk@jx@jk@hx@`eAzyAjSbZdNdSnP|WjEpM`EjPtL`g@xGfWnFtNpPnZdJrPfMbWdLbShG|I~KnMlGbHfNpT~Qda@`BpGnAxJdB`W~A`KpBvGrF`JrLnMd]r_@nKfKnDtBlHfC`HbA|ENlMlAzH`D`SjPrGfExOfG~IfD~EhDpCfDxCvDzEfGlFtEnE|A~Dr@vDbA|EhCzDjDbVn\\dJrLnMbNl^p`@`JbMnF`OrCzGfCxDj]re@pj@fv@rU`]nPfUdd@zk@lI`LtLvNvDhElHfM`RfXjVb\\~_@vi@ndB|}BtT`ZzJ|KbJ`Hfb@pShNrGzIhF~JjH~MvLjLbMvNpR|\\pj@hJlLjHdGjMvH~f@jYvKhGvFhFxEjHtEfMvB|KtAnQbApIpAdCxAPfAtBnAhAlC`@|FT~DBn@yCHwFoAiDwBD{@lA}BvIyC~LmLne@cD~LsGl[}Eld@gA~YKnO\\~VnAxUrChXnIzf@fCh[FnR[jKeD|YaCjK{DhLyBzEuKvTqFdNqIhX_G`WsC|OqDfX{Cz_@y@rU`@nYfBlRdDvVz@bK`@dOOnPwAjRkAbIaFf\\kBfZE~T|@t[f@x\\QhRuBp`@cHbi@_N~v@iFbV_K~[iDbMsEtWsAbSCrWxE`r@xAvi@p@d`@rBvRvBnK`Rzi@tBtI|DpUf@zEhArGhBrD|AxAlDzB|FtB`H|AnC`AvBg@rAp@ZjEQfHk@xEdAvLvC|JbJpUbM`\\rEhFxHbEbNlLxPdPfFrD`FAlDd@zBr@rAQ`AcDdAeBj@QvAb@xA`FrAxF|C|EvDrD`@AJP~EhClKhBx@K^F~C`BjGrBrHVtGW`F_AnIaCjLuC|DYnC`AxIzGbEdHjKhLhS|O~@Dd@Zt@jB~AhA`DhBzIrHbI`K~CtDrAb@vL_BdCZrHvGlI`D@ILCBRtL`UjJhRxDlSfGbZpBtM~BvGbDfEj@n@bBd@tIpAhAE`@i@LsDj@mFhBsFhDkD`EmBvEoAlD]jCEn@gAt@o@p@Jf@rABvBmAbCcA~B[lAw@Yc@g@_Bc@u@JeCf@Y\\C\\"
          }
        },
        {
          "id": "8HKD3j9fD7JkCit9CZBx",
          "name": "Demo 1 Client",
          "distance": 35.6666954,
          "latitude": 52.58677,
          "longitude": -2.13767,
          "travelTime": {
            "personal_car": 1.03,
            "public_transport": 1.46,
            "route": "_i}aI`h_Hs@jDq@WyCOeDZkB^gBbA{@x@Lb@v@pBCfA{BhD_AxBOXp@~AbEfGdGvL`BbJvBbIlEtJnApJ|C|\\lKf[j@bBk@p@}DhEiCnEkCjKgJlJwC`DyAd@aCz@Kj@hExN|B`Il@`Fh@nIx@rBIpBmBbGkAjLOfEV~HfCrKrB`DbDbErOxO`Y`Y|T|QtTbOpKvFvu@~Z~KrGtJrHfI~HlHzI|OzTjk@jx@jk@hx@`eAzyAjSbZdNdSnP|WjEpM`EjPtL`g@xGfWnFtNpPnZdJrPfMbWdLbShG|I~KnMlGbHfNpT~Qda@`BpGnAxJdB`W~A`KpBvGrF`JrLnMd]r_@nKfKnDtBlHfC`HbA|ENlMlAzH`D`SjPrGfExOfG~IfD~EhDpCfDxCvDzEfGlFtEnE|A~Dr@vDbA|EhCzDjDbVn\\dJrLnMbNl^p`@`JbMnF`OrCzGfCxDj]re@pj@fv@rU`]nPfUdd@zk@lI`LtLvNvDhElHfM`RfXjVb\\~_@vi@ndB|}BtT`ZzJ|KbJ`Hfb@pShNrGzIhF~JjH~MvLjLbMvNpR|\\pj@hJlLjHdGjMvH~f@jYvKhGvFhFxEjHtEfMvB|KtAnQbApIpAdCxAPfAtBnAhAlC`@|FT~DBn@yCHwFoAiDwBD{@lA}BvIyC~LmLne@cD~LsGl[}Eld@gA~YKnO\\~VnAxUrChXnIzf@fCh[FnR[jKeD|YaCjK{DhLyBzEuKvTqFdNqIhX_G`WsC|OqDfX{Cz_@y@rU`@nYfBlRdDvVz@bK`@dOOnPwAjRkAbIaFf\\kBfZE~T|@t[f@x\\QhRuBp`@cHbi@_N~v@iFbV_K~[iDbMsEtWsAbSCrWxE`r@xAvi@p@d`@rBvRvBnK`Rzi@tBtI|DpUf@zEhArGhBrD|AxAlDzB|FtB`H|AnC`AvBg@rAp@ZjEQfHk@xEdAvLvC|JbJpUbM`\\rEhFxHbEbNlLxPdPfFrD`FAlDd@zBr@rAQ`AcDdAeBj@QvAb@z@rBRlD}@pG[lEJhLvAb\\fCnl@h@pb@@rm@p@f_@PbKTbCr@vA~@l@vAD|HaAnWf@dJvDlEdBlAL~RtFvUfGNJDVtFjB~QlFdLbD~Fl@pCc@vEaC|CkBl@EVXvBp@bEOjK{C|Dy@dDCfB[d@RjGfBjBMtB}@Xc@~A@lKyBpIDbCAv@s@d@w@l@Vf@vAvBhBzHtCnI~Cn@i@ZRT`@pB|@zJtB|@Vo@dAw@bAi@r@fAhErBnIT~@nBg@`@TnA`A|@tAr@dC"
          }
        },
        {
          "id": "qCBCLe3u1ySAOaLKAN8RO1uAIP52",
          "name": "Debbie Edwards",
          "distance": 35.733803468,
          "latitude": 52.58687,
          "longitude": -2.13986,
          "travelTime": {
            "personal_car": 1.03,
            "public_transport": 1.49,
            "route": "_i}aI`h_Hs@jDq@WyCOeDZkB^gBbA{@x@Lb@v@pBCfA{BhD_AxBOXp@~AbEfGdGvL`BbJvBbIlEtJnApJ|C|\\lKf[j@bBk@p@}DhEiCnEkCjKgJlJwC`DyAd@aCz@Kj@hExN|B`Il@`Fh@nIx@rBIpBmBbGkAjLOfEV~HfCrKrB`DbDbErOxO`Y`Y|T|QtTbOpKvFvu@~Z~KrGtJrHfI~HlHzI|OzTjk@jx@jk@hx@`eAzyAjSbZdNdSnP|WjEpM`EjPtL`g@xGfWnFtNpPnZdJrPfMbWdLbShG|I~KnMlGbHfNpT~Qda@`BpGnAxJdB`W~A`KpBvGrF`JrLnMd]r_@nKfKnDtBlHfC`HbA|ENlMlAzH`D`SjPrGfExOfG~IfD~EhDpCfDxCvDzEfGlFtEnE|A~Dr@vDbA|EhCzDjDbVn\\dJrLnMbNl^p`@`JbMnF`OrCzGfCxDj]re@pj@fv@rU`]nPfUdd@zk@lI`LtLvNvDhElHfM`RfXjVb\\~_@vi@ndB|}BtT`ZzJ|KbJ`Hfb@pShNrGzIhF~JjH~MvLjLbMvNpR|\\pj@hJlLjHdGjMvH~f@jYvKhGvFhFxEjHtEfMvB|KtAnQbApIpAdCxAPfAtBnAhAlC`@|FT~DBn@yCHwFoAiDwBD{@lA}BvIyC~LmLne@cD~LsGl[}Eld@gA~YKnO\\~VnAxUrChXnIzf@fCh[FnR[jKeD|YaCjK{DhLyBzEuKvTqFdNqIhX_G`WsC|OqDfX{Cz_@y@rU`@nYfBlRdDvVz@bK`@dOOnPwAjRkAbIaFf\\kBfZE~T|@t[f@x\\QhRuBp`@cHbi@_N~v@iFbV_K~[iDbMsEtWsAbSCrWxE`r@xAvi@p@d`@rBvRvBnK`Rzi@tBtI|DpUf@zEhArGhBrD|AxAlDzB|FtB`H|AnC`AvBg@rAp@ZjEQfHk@xEdAvLvC|JbJpUbM`\\rEhFxHbEbNlLxPdPfFrD`FAlDd@zBr@rAQ`AcDdAeBj@QvAb@z@rBRlD}@pG[lEJhLvAb\\fCnl@h@pb@@rm@p@f_@PbKTbCr@vA~@l@vAD|HaAnWf@dJvDlEdBlAL~RtFvUfGNJDVtFjB~QlFdLbD~Fl@pCc@vEaC|CkBl@EVXvBp@bEOjK{C|Dy@dDCfB[d@RjGfBjBMtB}@Xc@~A@lKyBpIDbCAv@s@d@w@l@Vf@vAvBhBzHtCnI~Cn@i@ZRT`@pB|@zJtB|@Vo@dAw@bAi@r@fAhErBnIT~@nBg@`@TnA`A|@tAr@dCHbEe@zC"
          }
        }
      ]
    },
    {
      "id": "8HKD3j9fD7JkCit9CZBx",
      "name": "Demo 1 Client",
      "latitude": 52.58677,
      "longitude": -2.13767,
      "distances": [
        {
          "id": "qCBCLe3u1ySAOaLKAN8RO1uAIP52",
          "name": "Debbie Edwards",
          "distance": 0.092584279,
          "latitude": 52.58687,
          "longitude": -2.13986,
          "travelTime": {
            "personal_car": 0,
            "public_transport": 0.01,
            "route": "c{}_I~o`LHx@BdBMbBBTBRc@p@"
          }
        },
        {
          "id": "HPsmvDUgpKwU3V7gwFyF",
          "name": "Demo 2 Client",
          "distance": 0.607079467,
          "latitude": 52.58598,
          "longitude": -2.12328,
          "travelTime": {
            "personal_car": 0.06,
            "public_transport": 0.18,
            "route": "c{}_I~o`LHx@BdBMbBBTBRVOn@UjBc@p@St@a@ZOLARFfAgHFqA?g@GqAKqAOy@i@k@U]K]?[HmABMJQNOTGF?HD\\XZVJAd@KlCs@lFyAdDy@l@OPKZ]R_@FUDUCUAQEe@?g@DUPYTMN{@?]MmCEkACkBBm@DwABkBF}DAmCEk@AcC@_@C{AG_AKm@_@}Aa@y@k@eAaAuAe@o@eAgA{@s@o@g@a@Qa@Aa@GUNKVWV[j@Kf@Ef@ATOZUTs@o@g@i@KQ[Gy@BgAP{Bd@Gp@?B"
          }
        },
        {
          "id": "erEKBEsrEHZINDMmKjFbXyCz8ry1",
          "name": "Linda Angell",
          "distance": 27.174417943,
          "latitude": 52.80883,
          "longitude": -1.6029,
          "travelTime": {
            "personal_car": 0.57,
            "public_transport": 1.51,
            "route": "c{}_I~o`LUiAj@w@n@g@o@yCQcAEgAB]a@_A_@a@We@s@eCiA{Jm@wFsBcIsBcJYeAiA}@kMyAqCyEkC}E_BeHo@wFaC_KkEaWwA}HsDgJiMwU}EeJE?cJ{DgEyDkDsByAJgLtA}@GARWVY}@eBmDiGmImKiJkDqByAcAkA[_@J_@k@Go@Um@eDaCyJ_IaEsDcFiFcCsD{AyCwBsByIoG}CWqF|@sKjCcJrCsNd@yFe@wCiAo@W{Ae@gANIDGBI?QUi@_@yIsAoEaBkAQc@?w@{A{IgKi@JqAhBaAb@y@Ou@y@oDuFmAqAyAo@aH@iBa@oB{AgI}HkM{L_FkE_KwFsEkEyCwGgNa_@}F{N}AyFcAeJaAgEuAaBgAwIgAgEoBy@aEu@sEqAsD{AkHqEwFuFu@LcAtAEvAd@|Bv@x@|@HnAaAh@yCYqN{@_KgBeKmB_JwBgHaIaTkFaR{AiJqAaOy@cl@gAa[_BeT}B{\\GqSlAoVpDeV|CkLdLeb@nHg\\`GsZdIgg@nDw\\jAaUZeYKoMy@kWg@g[x@kYlCmVtAkIjDeWz@yOFoH_@mQwCsY_C}QsAkWX{XrC{b@bGud@zFyYpKo^vL}Y|M_ZxDsN~AqJhBiTTgRk@sRuBsRyFa\\}CwUkAwN_AaSYaWhAag@tA{Q|AiOzFkZpEuPzDkPz@uEZoEIyBqBqIiBaFk@sDm@qQkAiMoC{MaFeMsE}GwCoCoEoCuLyGcOwIuU_N_NcJiIqIyOuV{Vy`@eUaXqV{SqVuNk`@qQyGoDyJqHiOqQmRmWco@k{@ww@weAad@_o@gQqUwRqZeCeEaGsGiS{Wkh@sp@wQ{WcTgZshAm|AeCoEgCqHoEuK}IuLqa@ad@kMeNoN}RaKkN_EoE}DsCuFyBmHsAqBw@qDgCiF}FsDmEuFsG_GcEiBSyFgAwAIgAv@oAIs@yADuDhA_Fa@mGQm@T_A`FkDr@sB@uBgAoSQsND}JbAoKP{FUkLRmPu@eJU}AwCqPkHqTgEwGiC_B{CsBoEoF_GuIoFqFmFuK_BgCMUPu@tDeNjAoHfFwi@j@oHIyA@{@XsAd@_Gr@aBzJq@zEcB?Y@CGm@D{FdCkPBeCs@wCwDsEoBqEcBwH{@y@wAK_Fh@yCMCFK?eELsAe@qAkBQyCLaCGgLUuBwAoEQEIU@_@q@mCo@qAeDoAsA{BSoBVgD`AgGE_Dq@wBcAo@u@AoCf@_Bs@Q?e@|@eEtZgAhGu@rF{A`D{@dAgEzBqDbBCcAq@}DiEyJ}@iAOq@S@}AN"
          }
        },
        {
          "id": "7LiDNX3SSId9UH6KusK7",
          "name": "Demo 3 Client",
          "distance": 35.6666954,
          "latitude": 52.91157,
          "longitude": -1.47605,
          "travelTime": {
            "personal_car": 1.04,
            "public_transport": 1.52,
            "route": "c{}_I~o`LUiAj@w@n@g@o@yCQcAEgAB]a@_A_@a@We@s@eCiA{Jm@wFsBcIsBcJYeAiA}@kMyAqCyEkC}E_BeHo@wFaC_KkEaWwA}HsDgJiMwU}EeJE?cJ{DgEyDkDsByAJgLtA}@GARWVY}@eBmDiGmImKiJkDqByAcAkA[_@J_@k@Go@{DoD{PsNgJ}K{AyCwBsByIoG}CWqF|@sKjCcJrCsNd@yFe@wCiAo@W{Ae@gANIDGBI?QUi@_@yIsAoEaBkAQc@?w@{A{IgKi@JqAhBaAb@y@Ou@y@oDuFmAqAyAo@aH@iBa@oB{AgI}HkM{L_FkE_KwFsEkEyCwGgNa_@}F{N}AyFcAeJaAgEuAaBgAwIgAgEeOaEsD{AkHqEwFuFu@LcAtAEvAd@|Bv@x@|@HnAaAh@yCYqN{@_KgBeKmB_JwBgHaIaTkFaR{AiJqAaOy@cl@gAa[_BeT}B{\\GqSlAoVpDeV|CkLdLeb@nHg\\`GsZdIgg@nDw\\jAaUZeYKoMy@kWg@g[x@kYlCmVtAkIjDeWbAiY_@mQwCsY_C}QsAkWX{XrC{b@bGud@zFyYpKo^vL}Y|M_ZxDsN~AqJhBiTTgRk@sRuBsRyFa\\}CwUkAwN_AaSYaWhAag@tA{Q|AiOzFkZpEuPzDkPz@uEPiIqBqIiBaFk@sDm@qQkAiMoC{MaFeMsE}GgJ_Hy\\qRuU_N_NcJiIqIyOuV{Vy`@eUaXqV{SqVuNk`@qQyGoDyJqHiOqQmRmWco@k{@ww@weAad@_o@gQqUwRqZeCeEaGsGiS{Wkh@sp@{f@cs@shAm|AeCoEgCqHoEuK}IuLqa@ad@kMeNoN}RaQ{T}DsCuFyBmHsAqBw@qDgCiF}FsDmEuFsG_GcE}CmA_PoFkJwFqIiHkIoGyEyB}JqBiK[sJ}AuDqAaJ}FkJaK{Za]cLeMgD_FoE{L{BkQcAwPmAgJwAyFeP_^oHeN}GgJsRsTwIcMuHwMeXuh@qRq]uHsSsEiQqK{c@cH_YwFaNaOwTwZqd@kTk[_[_b@gs@scAsv@}fA{RuXeMwOuGsGwJwHqIeFkNoGih@{SaO}HsRwM_QiNqg@}f@gNkMoCgAuBS_C{@}@cAiAaD[iERgDdAsDzD}F~AoEh@eFU}CfAoDXwEg@aIqGmUaAcEA_@zBu@hCm@`E{EjFwEhBqCnAeGpAkDnLiNfFsEjIqKhIwTxBuGtAsGc@yKXyEWmDyAiLSc@kIyEkNmHw@sAgCyRG[EACSmDmIyBaAiEgB[rBaCaAsBs@"
          }
        }
      ]
    },
    {
      "id": "HPsmvDUgpKwU3V7gwFyF",
      "name": "Demo 2 Client",
      "latitude": 52.58598,
      "longitude": -2.12328,
      "distances": [
        {
          "id": "8HKD3j9fD7JkCit9CZBx",
          "name": "Demo 1 Client",
          "distance": 0.607079467,
          "latitude": 52.58677,
          "longitude": -2.13767,
          "travelTime": {
            "personal_car": 0.06,
            "public_transport": 0.16,
            "route": "{u}_Ifu}K]|DKtAAr@@p@Bn@S@WPYPCHCF]P_@NSLGPABADCFGBICCCSAe@J{AXmCj@KHQPSZIVI^Eh@HvAJ|@h@zBx@`C|@pC`@tBTnBd@fECFCVK|@@^O?e@Ca@C_AO]IMO_@GkAjBy@bAKL^xAvArFxAjGnBg@NHj@^f@^`@d@p@lAZ~@Nt@"
          }
        },
        {
          "id": "qCBCLe3u1ySAOaLKAN8RO1uAIP52",
          "name": "Debbie Edwards",
          "distance": 0.699663746,
          "latitude": 52.58687,
          "longitude": -2.13986,
          "travelTime": {
            "personal_car": 0.06,
            "public_transport": 0.17,
            "route": "{u}_Ifu}K]|DKtAAr@@p@Bn@S@WPYPCHCF]P_@NSLGPABADCFGBICCCSAe@J{AXmCj@KHQPSZIVI^Eh@HvAJ|@h@zBx@`C|@pC`@tBTnBd@fECFCVK|@@^O?e@Ca@C_AO]IMO_@GkAjBy@bAKL^xAvArFxAjGnBg@NHj@^f@^`@d@p@lAZ~@RdAHrA?z@En@Gr@BTBRc@p@"
          }
        },
        {
          "id": "erEKBEsrEHZINDMmKjFbXyCz8ry1",
          "name": "Linda Angell",
          "distance": 26.710253806,
          "latitude": 52.80883,
          "longitude": -1.6029,
          "travelTime": {
            "personal_car": 0.58,
            "public_transport": 1.34,
            "route": "{u}_Ifu}Kk@fI@p@Op@u@l@uAv@ITELGBICgHjAkDt@mB?uJiAaA[m@yBgD}EmBsGo@eEw@uF}DqQwC_RwBqImFsKwNgXu@cAIKeHmC}F{EkDsBe@?aFv@kEj@uAM[`@YLS]@i@aDyFeCeD{IsIkIqFiBgA_@Dc@AU{@[eAqHoFaRyPcFyHaBiCiCcBoEwDoCaA}DXoFjAsJbCwCfAaF~@eMNcCOqCu@yB}@{Ae@gANIDMBIAMYiGkAsDy@mE_BYHc@OaEqFgDsEkAGoB`C}ACcGqIiBqAeAWcC@wBHiBa@cGkF{HcH_NyM{OqJyDkFeEyK}L{[gF{MqAwGmAeKuAaB{@{Dy@qHs@}AoBy@{ImBaE{AkFyCkBqAmEoEi@e@e@Bo@b@i@~AJtBpAtB`BGdAkBNaHk@{LaAwI}D_SqHsTiHwS}B_L_BiNy@yTu@ej@uDak@}AmZTaWpCcZxEwSdBcGdKe`@jLqi@bDsQ~Fi_@pB{P`BgS~@i^So\\mAq^No]`@oIlCmVxD}UfAsJz@yOCqT{@sN_D{VeCaW_@wXxA}]dEmc@pEeY|DuR|EoQhL{[nNaZnD_JxDsNzCsT`AwTKsQsA_SaEsWuGqc@qB{Xq@qWLu[rAiZnBiUdCcQzFqVtBwHzDkPvAeL{@_I{BgFmB}Lk@cRsAeLeDyM_BcEiEyHsFeG_OsI{_@yTiPmJaJiHeImJ{Uo`@{QiXsJgLoKqKeUoQcWcNm^oP_KoGyFcFwNqQio@y{@mp@s|@y]oe@aPoUug@mr@{KcRsEaGkHeIqZo`@ePsSsP}TkOkUmmAsbBsMyQ_H}QkEuIgRyTs^i`@sK{MmN{R}HaK}CkCwEgCiG{AqGeBqDgCoBqB{E{FwE{FoBuB_GcEgFw@mDq@qAb@aA^gAw@]oBf@uEn@cCIiCi@qDT_AlAeArCeBr@sBq@{Oa@cP@wPtAkSMcRJuJa@yIUc@i@mCaCiN_EwMsFsLeHwE_DcDoHoKmJiKiGoMICK[Fc@bBgE~B{KrAmL|E_h@A}Et@gHh@_D~@e@lGYpFiAn@k@?KBKGo@D{FrA_Kp@kDBeCs@wCwBiCcCsDaB_IiByCwAKyAReCTaCMW@CFE@GOgBL{AJsAe@qAkBQyCV_Ig@_JwAoEI?MME]BOq@mCsBeCeC_Ao@wASoB~@mIXaBE_Dq@wBcAo@}B^gAD{Ay@GHSIeG|_@}AxK{A`D{@dAgEzBqDbBCcAq@}DiAiDoDuGSWQSq@Iw@P"
          }
        },
        {
          "id": "7LiDNX3SSId9UH6KusK7",
          "name": "Demo 3 Client",
          "distance": 35.236085297,
          "latitude": 52.91157,
          "longitude": -1.47605,
          "travelTime": {
            "personal_car": 1.04,
            "public_transport": 1.35,
            "route": "{u}_Ifu}Kk@fI@p@Op@u@l@uAv@ITELGBICgHjAkDt@mB?uJiAaA[m@yBgD}EmBsGo@eEw@uF}DqQwC_RwBqImFsKwNgXu@cAIKeHmC}F{EkDsBe@?aFv@kEj@uAM[`@YLS]@i@aDyFeCeD{IsIkIqFiBgA_@Dc@AU{@[eAqHoFaRyPcFyHaBiCiCcBoEwDoCaA}DXoFjAsJbCwCfAaF~@eMNuGeAyB}@{Ae@gANIDMBIAMY}LeCmE_BYHc@OaEqFgDsEkAGoB`C}ACcGqIiBqAeAWcC@wBHiBa@cGkF{HcH_NyM{OqJyDkFeEyK}L{[gF{MqAwGmAeKuAaB{@{Dy@qHs@}AoBy@{ImBaE{AkFyCyHaHi@e@e@Bo@b@i@~AJtBpAtB`BGdAkBNaHk@{LaAwI}D_SqHsTiHwS}B_L_BiNy@yTu@ej@uDak@}AmZTaWpCcZxEwSdBcGdKe`@jLqi@bDsQpJeq@`BgS~@i^So\\mAq^No]`@oIlCmVxD}UfAsJz@yOCqT{@sN_D{VeCaW_@wXxA}]dEmc@pEeY|DuR|EoQhL{[nNaZnD_JxDsNzCsT`AwTKsQsA_SaEsWuGqc@qB{Xq@qWLu[rAiZnBiUdCcQzFqVtBwHzDkPvAeL{@_I{BgFmB}Lk@cRsAeLeDyM_BcEiEyHsFeG_OsI{_@yTiPmJaJiHeImJ{Uo`@{QiXcWyXeUoQcWcNm^oP_KoGyFcFwNqQio@y{@mp@s|@y]oe@aPoUug@mr@{KcRsEaGkHeIqZo`@ePsSsP}TkOkUmmAsbBsMyQ_H}QkEuIgRyTs^i`@sK{MmN{R}HaK}CkCwEgCiG{AqGeBqDgCoBqB{E{FwE{FoBuB_GcEeKmDwHoCkJwF}SyPgIeDoGeAcDMeFMsJ}AyH_D{GkFeMmNgc@_f@gD_FuC}G_CaL}C}`@sAkH}C{I_Ry_@mHeLoEmFoG}GqLcO}GqKwMiWkXeg@{IoPuHsSuN}k@eG_WmFyQwCoGcGgJiRcYeS_Z{Vo^cYo_@qVq^c`AasA{Uu\\kOcTgQoRwLiJqQmJ_ZmLqYeMcPqJeN}JoMmKsX{WgXeX}CgCoCgAuBS_C{@kBsCw@{GRgDdAsDzD}F~AoEh@eFU}CfAoDXwEg@aIwA}GsEkOi@gCh@[pAY~@ShAY`E{EjFwEhBqCpB_JxBkDdMaNlJuJ|CaGdJsWdBsFXmEa@iK@sE[gHuA}F_@y@}PkJ{EuBe@o@g@mBwBkQE?EQ@IkCyG}CoBiEgB[rBaCaAsBs@"
          }
        }
      ]
    },
    {
      "id": "erEKBEsrEHZINDMmKjFbXyCz8ry1",
      "name": "Linda Angell",
      "latitude": 52.80883,
      "longitude": -1.6029,
      "distances": [
        {
          "id": "7LiDNX3SSId9UH6KusK7",
          "name": "Demo 3 Client",
          "distance": 8.863857315,
          "latitude": 52.91157,
          "longitude": -1.47605,
          "travelTime": {
            "personal_car": 0.27,
            "public_transport": 1.42,
            "route": "_fiaIhbxH_@Jk@^i@b@[f@QZcAnCcAtB_@hAuAfF[|@aAjBoC|Eo@hAwB|Fu@xCcAe@mDsAuBgAy@q@}AmA}BcBcAu@wBuAkB}@gFmCoBsAsAaAa@[gAqAq@u@u@iAO_@Ou@a@gBWcA_@aAc@m@u@i@kAy@}CsBkC_BaEyCk@k@w@sAw@qBcA}Cg@sBSmBEk@B_A\\qGVkELgE?yDi@mHUyDa@}FQmAQy@Wy@M{@[{BAy@FuANkA@gACw@Ku@oAaH{@sEa@mAi@sAeA{C[}AqAuKcAwF_AoGiAmGq@yCu@kBeA_CyB{Du@}AUm@aE{KgAgD]qAi@wBsCwL_BmG_G{UmDsNkBoIs@qDOoAKeBIkBY_BQi@c@}@iA{B}A}D_AkAa@c@OGADCLIDG?IIuAfA]`@Qp@Ef@V|F@|@CLGX_@j@mBx@g@Tm@`@g@Ze@r@s@rA_BlDi@rAkAlC{@bAsDbE_A|@uIfHaCzByAzA{AdBsCfD[^q@v@]Vg@h@i@x@i@`AON{@`@sAR}@ToDx@oE|@c@L?BCFIACG?C][C?EA?O@C@SSeCSoAAe@RaHJsGToGT{FTsGBmCDmMJ}YWqEOyAcAaI_@eFMgCaAiRW_H?}BDuBJ{LFuCAsBYiEiB_Nq@_Gm@oFg@wCeAmGs@kFYiJCeFBeCP_DPsBZoCZ_E\\sJC_H?[s@mAg@_AGSwBmB_CaBuAc@}@C[Ha@Te@f@eArAq@d@oG~B_EtBaBv@kChAoAl@cCn@wAZi@Ns@Xi@`@]p@k@~AaAu@}@q@iBqAYYWq@Mk@Sq@_AqC{@gBaBgCqBqCiBaCsDcEoAqAyCiC{BkBc@g@eAkAg@s@k@{@yAmCi@cAu@}@kFcDmEqDiCmBsA_AmAiAy@mAoBaD[i@CDMBICIQAIQ?g@AaAi@kI{Eq@k@u@[sEy@kAe@sBs@sCqA}CqAqFiDmCmAsBq@kCi@{Ac@uBq@}@o@qCqCkAq@u@_@{AcAsByAiAs@w@]g@OoAW]S_@Wq@g@_BqAiDsCeCeBuFcDkBiA{AeAcCcBiCyBkB_Ao@_AuA}BmCuD{@e@GIE@E?A?GCKOW}AE]?mAGwBMcAe@kB]aCGAIMASKSQQgFeCeAm@oDuBu@i@i@WuBgA_@KeAa@e@o@Qc@UiAiA{Im@oFA@CAEG?I@IkCyGc@m@yBaAiEgB[rBaCaAsBs@"
          }
        },
        {
          "id": "HPsmvDUgpKwU3V7gwFyF",
          "name": "Demo 2 Client",
          "distance": 26.710253806,
          "latitude": 52.58598,
          "longitude": -2.12328,
          "travelTime": {
            "personal_car": 1,
            "public_transport": 1.29,
            "route": "_fiaIhbxHnBa@ZTNXrAhDtBjEbAhETvCrEwBvD}BpB{CbByKp@}C~Ea]ZWB\\tBbA~Bm@bAPz@bAd@xBAhCgAzGMrD\\|A~AhBnARhAbAfBzDNF`@zBlAhIItLGhBf@pCjAlApAXvDY?EBEF?hAXzEOfBW~A^`BbE~AvGxCjEnBnCZnCuAfKeAfJR`EBABAD@HRGVO?mF|AuJt@o@hBK`IFl@e@nB}Dpa@{Df]iC|KfBfGjDbHz@`AxF~F~F|IlE|E`HnErEpIhEhMfBzHxBtLZLGhA`@hJS`KPvPyAfVf@f_@n@vKKtC}AhBiBhAu@hAEd@XlCNvCb@nBlAvBz@pAhBz@~CtAtDdC~EhDpCfDxCvDzEfGlFtEnE|A~Dr@vDbA|EhCzDjDbVn\\dJrLnMbNl^p`@bH`J|@`BnF`OrCzGfCxDj]re@pj@fv@rU`]`e@rn@rOnRlI`LtLvNvDhElHfM`RfXjVb\\tOhUni@|s@~_BnxBzJ|KbJ`Hfb@pShNrGzIhF~JjH~MvLjLbMvNpR|\\pj@hJlLjHdGjMvH~f@jYvKhGvFhFxEjHtEfMvB|KtAnQbApIpAdCxAPfAtBnAhAlC`@|FT~DBn@yCHwFU{Ay@mAaAQu@V{@lA}BvIiAfEoAvFaGzVkDrMcD~LsGl[}BhQ_BbRgA~YKnO\\~VnAxUrChXnIzf@~AjOf@|JFnR[jKeD|YaCjK{DhLyBzEuKvTqFdNqIhX_G`WsC|OqDfX{Cz_@y@rU`@nYfBlRdDvVz@bK`@dOOnPwAjRkAbIaFf\\kBfZE~T|@t[f@x\\QhRuBp`@cHbi@_N~v@iFbV_K~[iDbMsEtWsAbSCrWxE`r@dAzXRzOp@d`@rBvRvBnK`Rzi@tBtI|DpUf@zEhArGhBrD|AxAlDzBxBdAbCn@`H|AnC`AvBg@rAp@ZjEQfHk@xEdAvLvC|JfXrr@rEhFxHbExFhEhFbFxPdPfFrD`FAlDd@zBr@rAQ`AcDdAeBj@QvAb@z@rB\\lBrAxF|C|EvDrD`@AJP~EhClKhBx@K^F~C`BjGrBrHVtGW`F_AnIaCjLuC|DYnC`AxIzGbEdHjKhLhS|O~@Dd@Zt@jB~AhA`DhBzIrHbI`KhC`DTRrAb@vL_BdCZrHvGlI`D?EDGJB?TvLzTjJhRtB~IbAlHfGbZpBtM~BvGbDfEj@n@bBd@tIpAhAE`@i@LsDj@mFhBsFhDkD`EmBvEoAlD]jCEn@gAt@o@p@Jf@rABvBmAbCcA~B[lAw@Yc@g@W]gAEu@JeCf@Y\\C\\"
          }
        },
        {
          "id": "8HKD3j9fD7JkCit9CZBx",
          "name": "Demo 1 Client",
          "distance": 27.174417943,
          "latitude": 52.58677,
          "longitude": -2.13767,
          "travelTime": {
            "personal_car": 0.58,
            "public_transport": 1.43,
            "route": "_fiaIhbxHnBa@ZTNXrAhDtBjEbAhETvCrEwBvD}BpB{CbByKp@}C~Ea]ZWB\\tBbA~Bm@bAPz@bAd@xBAhCgAzGMrD\\|A~AhBnARhAbAfBzDNF`@zBlAhIItLGhBf@pCjAlApAXvDY?EBEF?hAXzEOfBW~A^`BbE~AvGxCjEnBnCZnCuAfKeAfJR`EBABAD@HRGVO?mF|AuJt@o@hBK`IFl@e@nB}Dpa@{Df]iC|KfBfGjDbHz@`AxF~F~F|IlE|E`HnErEpIhEhMfBzHxBtLZLGhA`@hJS`KPvPyAfVf@f_@n@vKKtC}AhBiBhAu@hAEd@XlCNvCb@nBlAvBz@pAhBz@~CtAtDdC~EhDpCfDxCvDzEfGlFtEnE|A~Dr@vDbA|EhCzDjDbVn\\dJrLnMbNl^p`@bH`J|@`BnF`OrCzGfCxDj]re@pj@fv@rU`]`e@rn@rOnRlI`LtLvNvDhElHfM`RfXjVb\\tOhUni@|s@~_BnxBzJ|KbJ`Hfb@pShNrGzIhF~JjH~MvLjLbMvNpR|\\pj@hJlLjHdGjMvH~f@jYvKhGvFhFxEjHtEfMvB|KtAnQbApIpAdCxAPfAtBnAhAlC`@|FT~DBn@yCHwFU{Ay@mAaAQu@V{@lA}BvIiAfEoAvFaGzVkDrMcD~LsGl[}BhQ_BbRgA~YKnO\\~VnAxUrChXnIzf@~AjOf@|JFnR[jKeD|YaCjK{DhLyBzEuKvTqFdNqIhX_G`WsC|OqDfX{Cz_@y@rU`@nYfBlRdDvVz@bK`@dOOnPwAjRkAbIaFf\\kBfZE~T|@t[f@x\\QhRuBp`@cHbi@_N~v@iFbV_K~[iDbMsEtWsAbSCrWxE`r@dAzXRzOp@d`@rBvRvBnK`Rzi@tBtI|DpUf@zEhArGhBrD|AxAlDzBxBdAbCn@`H|AnC`AvBg@rAp@ZjEQfHk@xEdAvLvC|JfXrr@rEhFxHbExFhEhFbFxPdPfFrD`FAlDd@zBr@rAQ`AcDdAeBj@QvAb@z@rBRlDKbCq@lC[lEJhLvAb\\fCnl@h@pb@@rm@p@f_@PbKTbCr@vA~@l@vAD|HaAnWf@dJvDlEdBlAL~RtFfUnG^BDVtFjBbIfBzGdCdLbD~Fl@pCc@vEaC|CkBl@EVXvBp@bEOjK{C|Dy@dDCfB[pHzBjBMtB}@Xc@~A@lKyBpIDbCAv@s@d@w@l@Vf@vAvBhBzHtCnI~Cn@i@ZRT`@pB|@zJtB|@Vo@dAw@bAi@r@fAhErBnIT~@nBg@`@TnA`A|@tAr@dC"
          }
        },
        {
          "id": "qCBCLe3u1ySAOaLKAN8RO1uAIP52",
          "name": "Debbie Edwards",
          "distance": 27.245875608,
          "latitude": 52.58687,
          "longitude": -2.13986,
          "travelTime": {
            "personal_car": 0.58,
            "public_transport": 1.46,
            "route": "_fiaIhbxHnBa@ZTNXrAhDtBjEbAhETvCrEwBvD}BpB{CbByKp@}C~Ea]ZWB\\tBbA~Bm@bAPz@bAd@xBAhCgAzGMrD\\|A~AhBnARhAbAfBzDNF`@zBlAhIItLGhBf@pCjAlApAXvDY?EBEF?hAXzEOfBW~A^`BbE~AvGxCjEnBnCZnCuAfKeAfJR`EBABAD@HRGVO?mF|AuJt@o@hBK`IFl@e@nB}Dpa@{Df]iC|KfBfGjDbHz@`AxF~F~F|IlE|E`HnErEpIhEhMfBzHxBtLZLGhA`@hJS`KPvPyAfVf@f_@n@vKKtC}AhBiBhAu@hAEd@XlCNvCb@nBlAvBz@pAhBz@~CtAtDdC~EhDpCfDxCvDzEfGlFtEnE|A~Dr@vDbA|EhCzDjDbVn\\dJrLnMbNl^p`@bH`J|@`BnF`OrCzGfCxDj]re@pj@fv@rU`]`e@rn@rOnRlI`LtLvNvDhElHfM`RfXjVb\\tOhUni@|s@~_BnxBzJ|KbJ`Hfb@pShNrGzIhF~JjH~MvLjLbMvNpR|\\pj@hJlLjHdGjMvH~f@jYvKhGvFhFxEjHtEfMvB|KtAnQbApIpAdCxAPfAtBnAhAlC`@|FT~DBn@yCHwFU{Ay@mAaAQu@V{@lA}BvIiAfEoAvFaGzVkDrMcD~LsGl[}BhQ_BbRgA~YKnO\\~VnAxUrChXnIzf@~AjOf@|JFnR[jKeD|YaCjK{DhLyBzEuKvTqFdNqIhX_G`WsC|OqDfX{Cz_@y@rU`@nYfBlRdDvVz@bK`@dOOnPwAjRkAbIaFf\\kBfZE~T|@t[f@x\\QhRuBp`@cHbi@_N~v@iFbV_K~[iDbMsEtWsAbSCrWxE`r@dAzXRzOp@d`@rBvRvBnK`Rzi@tBtI|DpUf@zEhArGhBrD|AxAlDzBxBdAbCn@`H|AnC`AvBg@rAp@ZjEQfHk@xEdAvLvC|JfXrr@rEhFxHbExFhEhFbFxPdPfFrD`FAlDd@zBr@rAQ`AcDdAeBj@QvAb@z@rBRlDKbCq@lC[lEJhLvAb\\fCnl@h@pb@@rm@p@f_@PbKTbCr@vA~@l@vAD|HaAnWf@dJvDlEdBlAL~RtFfUnG^BDVtFjBbIfBzGdCdLbD~Fl@pCc@vEaC|CkBl@EVXvBp@bEOjK{C|Dy@dDCfB[pHzBjBMtB}@Xc@~A@lKyBpIDbCAv@s@d@w@l@Vf@vAvBhBzHtCnI~Cn@i@ZRT`@pB|@zJtB|@Vo@dAw@bAi@r@fAhErBnIT~@nBg@`@TnA`A|@tAr@dCHbEGjA]nA"
          }
        }
      ]
    },
    {
      "id": "qCBCLe3u1ySAOaLKAN8RO1uAIP52",
      "name": "Debbie Edwards",
      "latitude": 52.58687,
      "longitude": -2.13986,
      "distances": [
        {
          "id": "8HKD3j9fD7JkCit9CZBx",
          "name": "Demo 1 Client",
          "distance": 0.092584279,
          "latitude": 52.58677,
          "longitude": -2.13767,
          "travelTime": {
            "personal_car": 0,
            "public_transport": 0.01,
            "route": "_|}_I~z`Lb@q@CSCULcBCeBIy@"
          }
        },
        {
          "id": "HPsmvDUgpKwU3V7gwFyF",
          "name": "Demo 2 Client",
          "distance": 0.699663746,
          "latitude": 52.58598,
          "longitude": -2.12328,
          "travelTime": {
            "personal_car": 0.06,
            "public_transport": 0.19,
            "route": "_|}_I~z`Lb@q@VOn@UjBc@p@SpAq@LARFfAgHFqAAuAIeAGo@G_@GYi@k@]k@EWBy@Ju@PYRKJCF?HD\\XZVJAd@Kh@O~DgAdBe@tCw@tA[^MZYPWNe@DUCUAQE}@B[FWPQNGJc@B_@McDIwDHeCJiHAmCEk@?cDG}BK}@Oy@Sq@a@y@k@eAq@aAu@cAyAyA{@u@m@_@OEa@Aa@GGDMHKVg@j@KVKf@Ef@ATOZUTk@e@Y]UUKQ[Gy@BYFiDn@Gt@"
          }
        },
        {
          "id": "erEKBEsrEHZINDMmKjFbXyCz8ry1",
          "name": "Linda Angell",
          "distance": 27.245875608,
          "latitude": 52.80883,
          "longitude": -1.6029,
          "travelTime": {
            "personal_car": 0.58,
            "public_transport": 1.53,
            "route": "_|}_I~z`LjBwAjBc@eBuIqAuGAeBw@yAQWw@uBkAmJq@qGaAuEcD{MU}@cAiAgMcBy@_@SiAcAwAkCiEgBeHsBoM{D}QcAqGsAmIuBeHuL{TkI}OE@uIqDsHwGaBc@c@FwF|@_E`@oAO?TUZ_@w@FSaAcBqGeJcKiJcEiCwDoB]N_@a@Cm@qAiBsJgHqGuFwFyF}BgD}AuCoBeCgIiG_Dw@kGz@iKhCaIfCsEj@gHP{Ga@{HoCaBXQQCMkIyAoIgCa@FeKaNg@CgAnAmAdAeB_@}ByDmBmC{BsAuGAkAI}Au@uHcHeNwMuG_GiHeEcGiEmCyE_Oo`@iGsO_BaFy@uFoAiJ{AaBaCwO_A}@mEcAuFuAeEcByFgDcHwG}@AaA~@QpAX`Cf@~@z@^zAe@v@aC?wIaAeO}AyJk@uDsDwM{GeRkG}RkB}J}A}Ok@{UOgRu@aYaBsVcCo[]cPl@}WdDaYzDyObIkYbJa`@|Naw@~Gai@fA}Ox@_b@IwNaBsl@`@qZtAwQxBwObEsXrAqWOkSkBeUyC_UeBiVAiYbBc\\xFch@nEaWnEoRxE}OnLc[tMiX`FiOzBqK`CkTf@{P[wT{AsQaF_ZqCgQgC{VmAmU]uR^y_@jBi[rAaO`DoSpFoTdEuOxBuKj@iIqAcI{BgF{@kEo@eQkAmN}BqL_EaLsF_Jw@gAaFwDyMqHqO_Jqb@aWgKsJ_FsGgIaNsWab@oQmT_MuLeJoHkUsNq^qPgKeF}IgGsGkGoGcIaR{Ven@iz@cY__@e^mf@_e@cp@uRiWgQcXqC_FuG}HuHcJgH{JyZw_@sL}Oqf@mr@qiAw}AmDwFoBkFoD}JkFcJmd@qg@kL}LiNyQoLkP{FeHqDsCuFeCcHsA{F}BsEmEqFwGgGkHmBeBmE{BiGkAmCa@eAp@iAJ}@iAOcCTaCl@oAFeGi@aDn@kA|AiAbCiB\\sC}@qP[qNAmHV_J`A{KIuGLoT]_LUcAAo@yBuLgB{HiEiMsEqIwCuBiCyAmE}E_G}IyF_G{@aAkDcHeCyEMSLy@|BcHpBsKzDq`@nB_TKgBC{@R{@f@{Fl@uBjB_@bGWtFkB?WQgCx@qJlAeHTeCYyCeE}FgBgD}A_Im@gAwAq@kEf@wEKAHK@mDHcAGoAaAw@yBC}BRmKqA{JeAaBA_@O_Bu@aB_AgAoA]gAe@e@w@e@aBAeClAwHLyBWcC}@_BeA[uBj@mBc@c@SGFICa@f@gEd[uCvPqBzCwD|BsEvBUwCcAiEuBkEgBsCIa@YKcBH"
          }
        },
        {
          "id": "7LiDNX3SSId9UH6KusK7",
          "name": "Demo 3 Client",
          "distance": 35.733803468,
          "latitude": 52.91157,
          "longitude": -1.47605,
          "travelTime": {
            "personal_car": 1.04,
            "public_transport": 1.53,
            "route": "_|}_I~z`LjBwAjBc@eBuIqAuGAeBiAqBw@uBkAmJsBgNcD{MU}@cAiAgMcBy@_@wAaDkCiEgBeHsBoM_GoZsAmIuBeHuL{TkI}OE@uIqDsHwGeC[wL~AoAO?TUZ_@w@y@wBqGeJcKiJcEiCwDoB]N_@a@Cm@qAiBeS}OuJaL}AuCoBeCgIiG_Dw@kGz@iKhCaIfCsEj@gHP{Ga@{HoCaBXQQCMkIyAoIgCa@FeKaNg@CgAnAmAdAeB_@}ByDmBmC{BsAuGAiD_AuHcHeNwMuG_GiHeEcGiEmCyE_Oo`@iGsO_BaFy@uFoAiJ{AaBaCwO_A}@mEcAuFuAeEcByFgDcHwG}@AaA~@QpAX`Cf@~@z@^zAe@v@aC?wIaAeO}AyJk@uDsDwM{GeRkG}RkB}J}A}Ok@{UOgRu@aYaBsVcCo[]cPl@}WdDaYzDyObIkYbJa`@|Naw@~Gai@fA}Ox@_b@IwNaBsl@`@qZtAwQxBwObEsXrAqWOkSkBeUyC_UeBiVAiYbBc\\xFch@nEaWnEoRxE}OnLc[tMiX`FiOzBqK`CkTf@{P[wT{AsQaF_ZqCgQgC{VmAmU]uR^y_@jBi[rAaO`DoSpFoTdEuOxBuKj@iIqAcI{BgF{@kEo@eQkAmN}BqL_EaLsF_JyG_Gk^qSqb@aWgKsJgPuVsWab@oQmT_MuLeJoHkUsNq^qPgKeF}IgGsGkGoGcIaR{Ven@iz@cY__@e^mf@_e@cp@uRiWgQcXqC_FuG}H}Q_Vmh@up@qf@mr@qiAw}AmDwFoBkFoD}JkFcJmd@qg@kL}LiNyQkTqYqDsCuFeCcHsA{F}BeMeNgGkHiJmGwOaFcHmDuKiIsEgEgIgFyJqCkLm@wGk@mEeA}J{EeHyGm]}_@}QwSgDeGaB}E}BwM_BkVaAgIsAeGcOq\\sGkMuGuJwQmSwIoLcJiOuXoi@_OcWmIiRaFcPiNqk@gFgTgFuPkN}To]gh@uPmVoOsTgNoQaTyZk^ah@{^kg@yT_\\y_@wh@{PuPkNmJmMgGuc@gQ_TiKiTsN{KsIcS{QqWkW}KuK{D_DaDu@}B_@yA_A_BiDa@kHh@yDdC{EdC_EzAsGK}DFwB|AaGIgDeBwMeBuFcD{KGi@h@e@lDw@l@OdBaBdEmFhE{D~@cCjBcI|KiNxDcDxIqJvCcGtEsMjFwPH}E]wER_GWcBGeEs@oDs@{CI]yFwCkNyHeBeAO_@c@sBaB_NQqAEACSgDgIiIoD[rBaCaAsBs@"
          }
        }
      ]
    }
  ]
}

const AutoScheduler  = React.memo(() =>{
  const [selectedType, setSelectedType] = useState<"employee" | "client">(
    "employee"
  );
  const [selectedId, setSelectedId] = useState<string>(data.employees[0].id);
  const [mapCenter, setMapCenter] = useState<[number, number]>([52.7, -1.8]);
  const [mapZoom, setMapZoom] = useState(9);

  useEffect(() => {
    setSelectedId("");
  }, [selectedType]);

  useEffect(() => {
    if (selectedId) {
      const item =
        selectedType === "employee"
          ? data.employees.find((e) => e.id === selectedId)
          : data.clients.find((c) => c.id === selectedId);

      if (item) {
        setMapCenter([item.latitude, item.longitude]);
        setMapZoom(10);
      }
    }
  }, [selectedId, selectedType]);

  const renderMarkers = () => {
    if (!selectedId) return null;

    const item =
      selectedType === "employee"
        ? data.employees.find((e) => e.id === selectedId)
        : data.clients.find((c) => c.id === selectedId);

    if (!item) return null;

    const selectedIcon = L.divIcon({
      className:
        "bg-primary text-zinc-600 rounded-full w-8 h-8 bg-white drop-shadow border border-zinc-200 flex items-center justify-center font-bold",
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256"><path d="M240,208H224V136l2.34,2.34A8,8,0,0,0,237.66,127L139.31,28.68a16,16,0,0,0-22.62,0L18.34,127a8,8,0,0,0,11.32,11.31L32,136v72H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Zm-88,0H104V160a4,4,0,0,1,4-4h40a4,4,0,0,1,4,4Z"></path></svg>`,
      iconSize: [24, 24],
        iconAnchor: [12, 24],
    });

    const markers = [
      <Marker
        key={selectedId}
        position={[item.latitude, item.longitude]}
        icon={selectedIcon}
      >
        <Popup>
          <strong>{item.name}</strong>
        </Popup>
      </Marker>,
    ];

    const lines: [number, number][][] = [];

    item.distances.forEach((d) => {
      const icon = L.divIcon({
        className:
          "bg-secondary text-zinc-600 rounded-full w-8 h-8 bg-white drop-shadow border border-zinc-200 flex items-center justify-center font-bold",
        html:'<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>',
        iconSize: [24, 24],
        iconAnchor: [12, 24],
      });

      markers.push(
        <Marker key={d.id} position={[d.latitude, d.longitude]} icon={icon}>
          <Popup>
            <strong>{d.name}</strong>
            <br />
            Distance: {d.distance.toFixed(2)} miles
            <br />
            Travel By Car: {d.travelTime.personal_car} HH:MM
            <br />
            Travel By Public Transport: {d.travelTime.public_transport} HH:MM
          </Popup>
        </Marker>
      );

      // Decode the route
      const decodedRoute = polyline.decode(d.travelTime.route);
      lines.push(decodedRoute.map((point) => [point[0], point[1]]));
    });

    return (
      <>
        {markers}
        {lines.map((line, index) => (
          <Polyline key={index} positions={line} smoothFactor={4} stroke color="#0ea5e9" />
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="p-4 bg-background">
        <div className="flex space-x-4">
          <Select.Root
            value={selectedType}
            onValueChange={(value: "employee" | "client") =>
              setSelectedType(value)
            }
          >
            <Select.Trigger className="inline-flex items-center justify-center rounded px-[15px] text-[13px] leading-none h-[35px] gap-[5px] bg-white text-violet11 shadow-[0_2px_10px] shadow-black/10 hover:bg-mauve3 focus:shadow-[0_0_0_2px] focus:shadow-black data-[placeholder]:text-violet9 outline-none">
              <Select.Value placeholder="Select a type..." />
              <Select.Icon className="text-violet11">
                <ChevronDownIcon />
              </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
              <Select.Content className="overflow-hidden bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
                <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
                  <ChevronUpIcon />
                </Select.ScrollUpButton>
                <Select.Viewport className="p-[5px]">
                  <Select.Item
                    value="employee"
                    className="text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1"
                  >
                    <Select.ItemText>Employee</Select.ItemText>
                  </Select.Item>
                  <Select.Item
                    value="client"
                    className="text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1"
                  >
                    <Select.ItemText>Client</Select.ItemText>
                  </Select.Item>
                </Select.Viewport>
                <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
                  <ChevronDownIcon />
                </Select.ScrollDownButton>
              </Select.Content>
            </Select.Portal>
          </Select.Root>

          <Select.Root value={selectedId} onValueChange={setSelectedId}>
            <Select.Trigger className="inline-flex items-center justify-center rounded px-[15px] text-[13px] leading-none h-[35px] gap-[5px] bg-white text-violet11 shadow-[0_2px_10px] shadow-black/10 hover:bg-mauve3 focus:shadow-[0_0_0_2px] focus:shadow-black data-[placeholder]:text-violet9 outline-none">
              <Select.Value placeholder="Select a person..." />
              <Select.Icon className="text-violet11">
                <ChevronDownIcon />
              </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
              <Select.Content className="overflow-hidden bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
                <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
                  <ChevronUpIcon />
                </Select.ScrollUpButton>
                <Select.Viewport className="p-[5px]">
                  {(selectedType === "employee"
                    ? data.employees
                    : data.clients
                  ).map((item) => (
                    <Select.Item
                      key={item.id}
                      value={item.id}
                      className="text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1"
                    >
                      <Select.ItemText>{item.name}</Select.ItemText>
                    </Select.Item>
                  ))}
                </Select.Viewport>
                <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
                  <ChevronDownIcon />
                </Select.ScrollDownButton>
              </Select.Content>
            </Select.Portal>
          </Select.Root>
        </div>
      </div>
      <div className="flex-1">
        <MapContainer
          center={mapCenter}
          zoom={mapZoom}
          style={{ height: "100%", width: "100%" }}
          
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url={`https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/{z}/{x}/{y}?access_token=${import.meta.env.VITE_MAP_BOX}`}
          />
          {renderMarkers()}
        </MapContainer>
      </div>
    </div>
  );
})
export default AutoScheduler;