import React, { createContext, useContext, useState } from "react";
import ConfirmModal from "./confirmmodal";
import { AnimatePresence } from "framer-motion";

interface ModalConfig {
  title: string;
  description?: string;
  labels: { confirm: string; cancel: string };
  onCancel: () => void;
  onConfirm: () => void;
}

interface ModalContextType {
  modalConfig: ModalConfig | null;
  openConfirmModal: (config: ModalConfig) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export function Modal2Provider({ children }: { children: React.ReactNode }) {
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);

  const openConfirmModal = (config: ModalConfig) => {
    setModalConfig(config);
  };

  const closeModal = () => {
    setModalConfig(null);
  };

  return (
    <ModalContext.Provider
      value={{ modalConfig, openConfirmModal, closeModal }}
    >
      <AnimatePresence>
        {children}
        {modalConfig && <ConfirmModal {...modalConfig} onClose={closeModal} />}
      </AnimatePresence>
    </ModalContext.Provider>
  );
}

export function useModals(): ModalContextType {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModals must be used within a ModalProvider");
  }
  return context;
}
