import { FormFieldType, FormSection, StepFormType } from "../../types/forms_types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface InitialStateType {
  forms: any;
  activeForm:StepFormType | null;
  currentSection:FormSection|null;
  activeField:null | FormFieldType
}

const initialState: InitialStateType = {
  forms: null,
  activeForm:null,
  currentSection:null,
  activeField:null,
};

export const FormsSlice = createSlice({
  name: "forms_data",
  initialState,
  reducers: {
    updateLocalFormsData: (state, action: PayloadAction<any>) => {
      state.forms = action.payload;
    },
    setActiveForm: (state, action: PayloadAction<any>) => {
      state.activeForm = action.payload;
    },
    setSection: (state, action: PayloadAction<any>) => {
      state.currentSection = action.payload;
    },
    setActiveField: (state, action: PayloadAction<any>) => {
      state.activeField = action.payload;
    },
  },
});

export const {
    updateLocalFormsData,
    setActiveForm,
    setSection,
    setActiveField
} = FormsSlice.actions;

export default FormsSlice.reducer;
