import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestType } from "../../types/requests";

interface InitialStateType {
  requests: RequestType[];
}

const initialState: InitialStateType = {
  requests: [],
};

export const RequestsSlice = createSlice({
  name: "requests_data",
  initialState,
  reducers: {
    loadRequests: (state, action: PayloadAction<RequestType[]>) => {
      state.requests = action.payload;
    },
  },
});

export const { loadRequests } = RequestsSlice.actions;

export default RequestsSlice.reducer;
