import { limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { loadShifts } from "../../redux/slices/schedule_slice";
import { scheduleRef } from "../functions";

const ScheduleData = () => {
  const user = useSelector((state: RootState) => state.session_data.user);
  const dispatch = useDispatch();
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const dates = useSelector(
    (state: RootState) => state.schedule_data?.dashboard_dates
  );

  //Fetch Data And Store It Locally
  useEffect((): any => {
    let unsubscribe: () => void;

    if (user?.uid && session_role?.role === "ground staff" && dates) {
      unsubscribe = onSnapshot(
        query(
          scheduleRef,
          where("assigned_to.uid", "==", user?.uid),
          where("start_date", ">=", dates.start),
          where("start_date", "<=", dates.end + 86400000),
          where("published", "==", true),
          orderBy("start_date", "desc"),
          limit(10000)
        ),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          const data = snapshot.docs
            .map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
            ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));

          dispatch(loadShifts(data));
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, user, session_role?.name, dates]);

  //Compoent ===========================
  return <></>;
};

export default ScheduleData;
