import { Link } from "react-router-dom";
import { ChevronRight, Menu } from "lucide-react";
import React, { ReactNode } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { useClickOutside } from "@mantine/hooks";

const Smallnav = ({
  options,
}: {
  options: {
    name: string;
    description: string;
    path: string;
    icon: ReactNode;
  }[];
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const menuRef = useClickOutside(() => setIsOpen(false));

  // JSX Code ========================
  return (
    <React.Fragment>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        variant="outline"
        className="p-0 h-8 w-20 text-base flex gap-2 md:hidden drop-shadow-sm border-zinc-300 bg-zinc-50"
      >
        <Menu className="h-4 w-4 fill-zinc-200" />{" "}
        <span className="text-zinc-600 text-xss capitalize font-bold">
          Menu
        </span>
      </Button>

      {/**Sidenav */}
      <AnimatePresence>
        {isOpen && (
          <motion.aside
            ref={menuRef}
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            className="border border-zinc-200 drop-shadow bg-white hover:bg-white rounded-lg p-6 flex 
              flex-col justify-between gap-1 overflow-hidden inset-4 top-14 bottom-8 fixed z-[999]"
          >
            <ul className="mt-4 w-full h-fit grid gap-1">
              {options.map((opt) => {
                return (
                  <Link
                    key={opt.name}
                    to={opt.path}
                    onClick={() => setIsOpen(false)}
                    className={`flex items-center justify-between space-x-2 text-lg font-bold w-full h-9 rounded-md
                    px-3 transition-all ${
                      location.pathname === opt.path ||
                      (opt.path !==
                        `/${
                          location.pathname?.split("/").filter(Boolean)[0]
                        }` &&
                        location.pathname.includes(opt.path))
                        ? "bg-cyan-700/10 text-sky-500 shadow border-l-4 border-sky-500"
                        : "text-zinc-600 hover:bg-cyan-700/10"
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      {opt.icon}
                      <span className="text-xs capitalize font-semibold">
                        {opt.name}
                      </span>
                    </div>
                    <ChevronRight className="regular_icon" />
                  </Link>
                );
              })}
            </ul>
          </motion.aside>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};

export default Smallnav;
