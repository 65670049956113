import { onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { visitsRef } from "../functions";
import { loadRoutesData } from "@/redux/slices/schedule_slice";

const RoutesData = () => {
  const { currentUser }: any = useAuth();
  const dispatch = useDispatch();

  //Fetch Data And Store It Locally
  useEffect((): any => {
    if (!currentUser?.uid) return;

    const snapshot = onSnapshot(
      query(visitsRef, orderBy("date", "desc")),
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        dispatch(
          loadRoutesData(
            snapshot.docs.map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      }
    );

    return () => {
      snapshot();
    };
  }, [dispatch, currentUser]);

  //Compoent ===========================
  return <></>;
};

export default RoutesData;
