import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";

//Config Firebase ==================================
export const firebaseConfig = {
  apiKey: "AIzaSyCeQu8IDbX23O14Q-ILrDjuevwghPdBiOM",
  authDomain: "careconnek.firebaseapp.com",
  projectId: "careconnek",
  storageBucket: "careconnek.appspot.com",
  messagingSenderId: "176461685544",
  appId: "1:176461685544:web:93e5c0e96fd9682438a61d",
  measurementId: "G-Q6NZZN7679",
};
// initializeApp() is called before getAuth() for sure
// Detect if it is dev mode
declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string;
  }
}

if (typeof window !== "undefined" && window.location.hostname === "localhost") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = import.meta.env
    .VITE_APP_CHECK_KEY as string;
}

// initializeApp() is called before getAuth() for sure
export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
let AppCheckInstance = null;

if (typeof window !== "undefined" && !AppCheckInstance) {
  import("firebase/app-check").then(async (firebaseAppCheck) => {
    const captachp = import.meta.env.VITE_APP_CHECK_KEY as string;
    AppCheckInstance = firebaseAppCheck.initializeAppCheck(app, {
      provider: new firebaseAppCheck.ReCaptchaEnterpriseProvider(captachp),
      isTokenAutoRefreshEnabled: true,
    });
    //console.log("AppCheckInstance", AppCheckInstance);
  });
}

// init services for firestore =========================
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache(
    /*settings*/ {
      tabManager: persistentMultipleTabManager(),
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    }
  ),
  ignoreUndefinedProperties: true,
});

//Get Current Logged User Related Data
export const auth = getAuth();
