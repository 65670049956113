import { Home } from "lucide-react";
import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidenav = React.memo(
  ({
    options,
  }: {
    options: {
      name: string;
      description: string;
      path: string;
      icon: ReactNode;
    }[];
  }) => {
    const location = useLocation();

    // JSX Code ========================
    return (
      <aside
        className="w-[230px] h-full shrink-0 bg-white border-r border-zinc-200 p-5 hidden md:flex 
        flex-col gap-2 overflow-hidden"
      >
        <Link
          to="/"
          className="w-full h-9 bg-zinc-100 drop-shadow-sm shrink-0 flex items-center 
           justify-start gap-2 px-3 text-zinc-600 hover:opacity-80 transition-all
            rounded-md border border-zinc-200"
        >
          <Home className="h-4 w-4 fill-zinc-300" />
          <span className="text-xs font-semibold text-zinc">Home</span>
        </Link>

        <hr className="my-1 w-full h-0 border-0 border-b border-zinc-300 border-dashed" />

        {/**Options */}
        <ul className="w-full h-fit grid gap-1">
          {options.map((opt) => {
            return (
              <Link
                key={opt.name}
                to={opt.path}
                className={`flex items-center space-x-2 text-lg font-bold w-full h-9 rounded-md
                    px-3 transition-all ${
                      location.pathname === opt.path ||
                      (opt.path !==
                        `/${
                          location.pathname?.split("/").filter(Boolean)[0]
                        }` &&
                        location.pathname.includes(opt.path))
                        ? "bg-cyan-700/10 text-sky-500 shadow border-l-4 border-sky-500"
                        : "text-zinc-600 hover:bg-cyan-700/10"
                    }`}
              >
                {opt.icon}
                <span className="text-xs capitalize font-semibold">
                  {opt.name}
                </span>
              </Link>
            );
          })}
        </ul>
      </aside>
    );
  }
);

export default Sidenav;
