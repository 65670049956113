import { AnimatePresence, motion } from "framer-motion";
import Admin from "./admin/admin";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Ground from "./ground stuff/ground";
import Loader from "../../components/misc/loader";
import { circleVariants } from "../../utils/reusable_objects";

const Home = () => {
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );

  //Component =============
  return (
    <AnimatePresence>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={circleVariants}
        className="h-fit w-full overflow-hidden"
      >
        {session_role?.permissions?.["dashboard type"] === "admin" ? (
          <Admin />
        ) : session_role?.permissions?.["dashboard type"] === "ground staff" ? (
          <Ground />
        ) : (
          <Loader />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default Home;
