import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TaskType } from "../../types/care_types";

interface InitialStateType {
  newTask:TaskType | null
}

const initialState: InitialStateType = {
  newTask: null,
};

export const TasksSlice = createSlice({
  name: "tasks_data",
  initialState,
  reducers: {
    setNewTask: (state, action: PayloadAction<TaskType|null>) => {
      state.newTask = action.payload;
    },
  },
});

export const { setNewTask } = TasksSlice.actions;

export default TasksSlice.reducer;
