import { getAuth, signOut } from "firebase/auth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../redux/store";
import packageJson from "../../../package.json";
import { clearAllData } from "../../utils/reusable_functions";
import { useMemo, useState } from "react";

const UpdateAlert = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const system_data = useSelector(
    (state: RootState) => state.session_data.system_data
  );
  const web_v = useMemo(() => {
    return (
      system_data?.find((data: any) => data?.id === "versions")
        ?.web_app_version || null
    );
  }, [system_data]);

  //Component =======
  return (
    <div
      className={`${
        web_v !== packageJson.version && web_v ? "" : "hidden"
      } fixed z-[99999] top-0 bottom-0 left-0 right-0 bg-black/80 flex justify-center
       items-end md:items-center backdrop-blur-md md:p-6`}
    >
      <div
        className="w-full md:w-[25rem] h-[14rem] rounded-xl bg-white p-8 overflow-hidden flex flex-col
         items-center justify-between border-t md:border-4 border-zinc-300"
      >
        <h1 className="text-xs uppercase font-bold text-slate-700 ">
          New Update available 🚀 (v
          {web_v})
        </h1>
        <p className="text-xs font-medium text-slate-600 text-center">
          A new update for the web app is available. Click the button below to
          refresh and apply the update.
        </p>
        <div className="w-ful flex justify-center items-center gap-6">
          <button
            onClick={() => {
              setLoading(true);
              setTimeout(() => {
                signOut(auth).then(() => {
                  clearAllData();
                  navigate("/logIn");
                  setLoading(false);
                  // window.location.reload();
                });
              }, 3000);
            }}
            type="button"
            className="w-36 h-9 rounded-full box-shadow-half bg-zinc-800
           text-xxs uppercase text-white font-bold disabled:cursor-not-allowed
            hover:bg-zinc-700 transition-all flex items-center justify-center group"
          >
            {!loading ? (
              <span className="flex group-focus:hidden">update now</span>
            ) : (
              <div className="h-4 w-4 rounded-full border-2 border-l-sky-500 animate-spin" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateAlert;
