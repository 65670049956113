import React from "react";
import MapboxAutocomplete from "react-mapbox-autocomplete";

const api: string = import.meta.env.VITE_MAP_BOX as string

interface AutocompleteProps {
  value: any;
  onChange: (result: any) => void;
  className: string;
}

const SearchAutocomplete: React.FC<AutocompleteProps> = ({
  value,
  onChange,
  className,
}) => {
  function suggestionSelect(result: any, lat: any, long: any, text: any) {
    onChange({
      latitude: lat,
      longitude: long,
      address: result,
      state: text,
    });
  }

  //Component =========================
  return (
    <>
      {!value ? (
        <div className="flex flex-col h-full w-full pt-14">
        <MapboxAutocomplete
          publicKey={api}
          inputClass={`${className} react-mapbox-ac-input`}
          onSuggestionSelect={suggestionSelect}
          value={value || ""}
          resetSearch={false}
          placeholder="Search Address..."
          required
        /></div>
      ) : (
        <div className="w-full flex items-center justify-between gap-4 pr-4 overflow-hidden bg-inherit rounded">
          <input
            readOnly
            disabled
            value={value || ""}
            type="search"
            className={className}
          />
          <div className="h-fit w-fit shrink-0 px-2 absolute right-2 z-10 bg-inherit">
          <button
            onClick={() => {
              onChange({
                latitude: "",
                longitude: "",
                address: "",
                state: "",
              });
            }}
            className="h-fit w-fit px-4 py-1.5 rounded-full bg-zinc-50 border border-red-500/50 text-xxs
             text-red-500 !leading-none uppercase font-bold shrink-0"
          >
            clear
          </button></div>
        </div>
      )}
    </>
  );
};

export default SearchAutocomplete;
