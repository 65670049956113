import { motion } from "framer-motion";
import React from "react";
import { createPortal } from "react-dom";
interface ModalConfig {
  title: string;
  description?: string;
  labels: { confirm: string; cancel: string };
  onCancel: () => void;
  onConfirm: () => void;
  onClose?: () => void;
}

interface ConfirmModalProps extends ModalConfig {
  onClose: () => void;
}

const ConfirmModal = ({
  title,
  description,
  labels,
  onCancel,
  onConfirm,
  onClose,
}: ConfirmModalProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  //Component
  return (
    <React.Fragment>
      {createPortal(
        <motion.div
          initial={{ y: 20 }}
          exit={{ y: 20 }}
          animate={{ y: 0 }}
          className="fixed inset-0 z-[99999999] bg-black/80 backdrop-blur-md flex justify-center
          items-end md:items-center md:p-6 overflow-hidden overflow-y-auto"
        >
          <motion.div
            className="w-full md:w-[25rem] pb-10 md:pb-6 p-6 rounded-t-xl md:rounded-t-lg md:rounded-b-lg bg-white
         relative flex flex-col gap-4 z-[99999]"
          >
            <h2 className="text-xs uppercase font-bold text-zinc-800">
              {title}
            </h2>
            <p className="text-xs text-zinc-700">
              {description ||
                `This action is destructive and can not be reversed. Are you sure you want to proceed ?`}
            </p>
            <div className="mt-2 shrink-0 w-full h-10 flex items-center gap-4 overflow-hidden">
              <button
                className="w-1/2 h-10 rounded bg-zinc-50 border border-zinc-200 text-xxs 
            uppercase font-bold text-zinc-800 hover:shadow transition-all"
                onClick={onCancel}
              >
                {labels.cancel}
              </button>
              <button
                disabled={loading}
                className="w-1/2 h-10 rounded bg-sky-500 text-xxs uppercase font-bold text-white
             hover:shadow transition-all flex items-center justify-center gap-2 disabled:cursor-not-allowed
              disabled:opacity-80"
                onClick={() => {
                  onConfirm();
                  setLoading(true);

                  setTimeout(() => {
                    setLoading(false);
                    onClose();
                  }, 30000);
                }}
              >
                {loading && (
                  <div className="h-4 w-4 rounded-full border-2 border-sky-300 border-l-white animate-spin" />
                )}
                <span className="font-bold">{labels.confirm}</span>
              </button>
            </div>
          </motion.div>
        </motion.div>,
        document.body
      )}
    </React.Fragment>
  );
};

export default ConfirmModal;
