import { Outlet, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { circleVariants, nav_links } from "../../utils/reusable_objects";
import Sidenav from "./shell/sidenav";
import SmNav from "./shell/sm_nav";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { UserType } from "../../types/session_type";
import Topnav from "./top nav/top-nav";

export type NavType = {
  name: string;
  path: string;
  access: any;
  icon?: React.ReactNode;
  nested_routes?: { name: string; path: string }[];
};

const Appshell = () => {
  const location = useLocation();
  const user: UserType = useSelector(
    (state: RootState) => state.session_data.user
  );
  const session_role: any = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const filteredNav: any = useMemo(() => {
    const results =
      [...nav_links]
        ?.map((nav: NavType) =>
          !(
            !session_role?.permissions?.["can view care page"] &&
            nav.name === "care"
          ) &&
          !(
            !session_role?.permissions?.["can the user view reports"] &&
            nav.name === "reports"
          ) &&
          !(
            !session_role?.permissions?.[
              "can the user view and manage forms"
            ] && nav.name === "forms"
          ) &&
          !(
            !session_role?.permissions?.[
              "can the user view and manage lists"
            ] && nav.name === "lists"
          ) &&
          // !(
          //   !session_role?.permissions?.["can the user view and manage lists"] &&
          //   !session_role?.permissions?.["can the user view and manage forms"] &&
          //   !session_role?.permissions?.["can the user view reports"] &&
          //   nav.name === "people"
          // ) &&
          !(
            !session_role?.permissions?.[
              "can the user view and manage inventory list"
            ] && nav.name === "inventory"
          ) &&
          !(
            !session_role?.permissions?.["can the user view wiki docs"] &&
            nav.name === "wiki"
          ) &&
          !(
            !session_role?.permissions?.[
              "can the user view and manage auto scheduler"
            ] && nav.name === "DC scheduler"
          ) &&
          !(
            session_role?.permissions?.["dashboard type"] !== "ground staff" &&
            nav.name === "profile"
          )
            ? {
                ...nav,
                path:
                  nav.name === "profile"
                    ? `${nav.path}${user?.["first name"]}?id=${
                        user?.id || user?.uid
                      }`
                    : nav.path,
              }
            : false
        )
        ?.filter(Boolean) || [];
    return results;
  }, [session_role?.permissions, user]);

  //Component ===============
  return (
    <AnimatePresence>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={circleVariants}
        className="fixed top-0 right-0 bottom-0 left-0 bg-white overflow-hidden
         flex flex-col"
      >
        <Topnav />
        <main
          className="w-full h-full rounded-t-3xl md:rounded-t-none flex flex-col lg:flex-row
           overflow-hidden bg-zinc-100 drop-shadow-2xl border-t border-zinc-200 md:border-zinc-200"
        >
          <Sidenav filteredNav={filteredNav} />
          <SmNav filteredNav={filteredNav} />
          <motion.div
            className={`w-full lg:w-[calc(100%-14rem)] ${
              location.pathname.includes("/settings") ||
              location.pathname.includes("/people")
                ? "h-full"
                : "h-[calc(100%-5.75rem)] lg:h-full"
            } pt-5`}
          >
            <div className="h-full w-full overflow-hidden overflow-y-scroll p-5 pt-0">
              <Outlet />
            </div>
          </motion.div>
        </main>
      </motion.div>
    </AnimatePresence>
  );
};

export default Appshell;
