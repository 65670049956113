import { motion} from "framer-motion";
import { circleVariants } from "../../utils/reusable_objects";


const ProcessLoading = () => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={circleVariants}
      className="fixed top-0 left-0 right-0 bottom-0 z-[99999] bg-black/80 flex 
         items-center justify-center"
    >
      <div className="h-16 w-16 border-4 border-zinc-200 border-l-sky-600 rounded-full animate-spin"></div>
    </motion.div>
  );
};

export default ProcessLoading;
