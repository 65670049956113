import React from "react";
import SubMenuTemplate from "../sub-menus-template/sub-menu-template";
import withPermission from "@/components/auth/withPermission";

import {
  AlertCircle,
  MessageSquare,
  FileText,
  BookOpen,
  Award,
  TrendingUp,
} from "lucide-react"; // Import Lucid icons

const options = [
  {
    name: "incidents",
    path: "/reports",
    description: "Manage incidents",
    icon: <AlertCircle className="regular_icon" />,
  },
  {
    name: "complaints",
    path: "/reports/complaints",
    description: "Manage complaints",
    icon: <MessageSquare className="regular_icon" />,
  },
  {
    name: "notes",
    path: "/reports/notes",
    description: "Manage notes",
    icon: <FileText className="regular_icon" />,
  },
  {
    name: "Training",
    path: "/reports/training",
    description: "Manage training",
    icon: <BookOpen className="regular_icon" />,
  },
  {
    name: "competencies",
    path: "/reports/competencies",
    description: "Manage competencies",
    icon: <Award className="regular_icon" />,
  },
  {
    name: "performance",
    path: "/reports/performance",
    description: "Manage performance",
    icon: <TrendingUp className="regular_icon" />,
  },
];

const ReportsLayout = withPermission(() => {
  //JSX Code ========================
  return (
    <React.Fragment>
      <SubMenuTemplate options={options} />
    </React.Fragment>
  );
});

export default ReportsLayout;
