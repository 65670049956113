import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  arrayRemove,
  arrayUnion,
  setDoc,
  increment,
  writeBatch,
  getDocs,
  // getDocs,
  // query,
  // where,
  // increment,
  // setDoc,
  //arrayUnion,
  //deleteField,
} from "firebase/firestore";
import { auth, db } from "./firebase_config";
import {
  CustomListDataFieldType,
  MasterListDataFieldType,
} from "../types/master_list_type";
import {
  InventoryLogsTypes,
  MasterInventoryLogsType,
  MasterInventoryRequestType,
  MasterInventoryType,
} from "../types/inventory_types";
import {
  LogsTypes,
  MedicatioLogType,
  MedicationType,
} from "../types/medicine_types";
import { CareDocType, TaksLogsType, TaskType } from "../types/care_types";
import {
  PayRunEmployeeType,
  PayRunType,
  ShiftType,
} from "../types/schedule_types.d";
import {
  dateToLondonMilliseconds,
  replaceUndefinedWithEmptyString,
} from "../utils/reusable_functions";
import { v4 as uuid } from "uuid";
import { WikiType } from "../types/wiki";
import { AutoScheduleSettingsType } from "@/types/auto-scheduler";

// collection ref
//const usersRef: any = org && collection(db, `companies/${org}/users`);
export const usersRef: any = collection(db, `all_users`);
export const masterListsRef: any = collection(db, `masterlists`);
export const customListsRef: any = collection(db, `custom_added_lists`);
export const formsRef: any = collection(db, `forms`);
export const assessmentsRef: any = collection(db, `assessments`);
export const carePlansRef: any = collection(db, `care_plans`);
export const incidentsRef: any = collection(db, `incidents`);
export const complaintsRef: any = collection(db, `complaints`);
export const careRef: any = collection(db, `care_management`);
export const clientsRef: any = collection(db, `clients`);
export const scheduleRef: any = collection(db, `schedule`);
export const inventoryRef: any = collection(db, `inventory`);
export const requestsRef: any = collection(db, `requests`);
export const visitsRef: any = collection(db, `visits`);
export const rolesRef: any = collection(db, `roles`);
export const employeesRef: any = collection(db, `employees_docs`);
export const performanceMetricsRef: any = collection(
  db,
  `performance_evaluations`
);
export const leavesRef: any = collection(db, `leaves`);
export const systemRef: any = collection(db, `system_data`);
//export const aiRef: any = collection(db, `connek_assistant/{uid}/chats`);

// ===================== User Functions =========================
export const addUser = async (obj: any) => {
  const docRef = await addDoc(masterListsRef, obj);
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

//Update MasterList
export const updateUser = async (obj: any) => {
  const docRef = doc(db, `all_users`, obj?.id);
  await updateDoc(docRef, obj);
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

// ===================== MasterList Functions =========================
export const addMasterList = async (obj: any) => {
  const docRef = await addDoc(masterListsRef, obj);
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

//Update MasterList
export const updateMasterList = async (obj: any) => {
  const docRef = doc(db, `masterlists`, obj?.id);
  await updateDoc(docRef, obj);
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Update MasterList Value
export const updateMasterListValue = async (obj: {
  id: string;
  value: MasterListDataFieldType[];
  last_updated_by: any;
  last_edited: number;
}) => {
  const docRef = doc(db, `masterlists`, obj?.id);
  await updateDoc(docRef, {
    data: obj?.value,
    last_updated_by: obj?.last_updated_by,
    last_edited: obj?.last_edited,
  });
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

export const deleteMasterListValue = async (obj: {
  id: string;
  value: MasterListDataFieldType;
  last_updated_by: any;
  last_edited: number;
}) => {
  const docRef = doc(db, `masterlists`, obj?.id);
  await updateDoc(docRef, {
    data: arrayRemove(obj?.value),
    last_updated_by: obj?.last_updated_by,
    last_edited: obj?.last_edited,
  }).then(async () => {
    const docSnap = await getDoc(docRef);
    return { id: docSnap.id, ...docSnap.data() };
  });
};

//Delete Master Lists
export const deleteMasterList = async (id: string) => {
  const docRef = doc(db, `masterlists`, id);
  await deleteDoc(docRef);
};

//Update Custom LinkedList
export const replaceCustomMasterListValue = async (obj: {
  id: string;
  parent_id: string;
  object_to_replace: any;
  object_to_add: any;
  last_updated_by: any;
  resumes_ids: string[];
  form_field: string;
}) => {
  try {
    const customListDocRef = doc(db, `custom_added_lists`, obj?.id);
    const parentListDocRef = doc(db, `masterlists`, obj?.parent_id);

    // Remove the object_to_replace from the custom list
    await updateDoc(customListDocRef, {
      data: arrayRemove(obj?.object_to_replace),
    });

    // Add the object_to_add to the parent list
    await updateDoc(parentListDocRef, {
      data: arrayUnion(obj?.object_to_add),
    });

    // Update form_field in resume documents
    for (const item of obj?.resumes_ids) {
      const itemDocRef = doc(db, `my_resume`, item);
      await updateDoc(itemDocRef, {
        [obj?.form_field]: obj?.object_to_add?.value,
      });
    }

    const docSnap = await getDoc(customListDocRef);
    return { id: docSnap.id, ...docSnap.data() };
  } catch (error) {
    // Handle errors here (e.g., log, notify user, etc.)
    console.error("Error in replaceCustomMasterListValue:", error);
    throw error; // Rethrow the error for higher-level handling
  }
};

//Delete Custom Value
export const deleteCustomListValue = async (obj: {
  id: string;
  value: CustomListDataFieldType;
  last_updated_by: any;
  last_edited: number;
}) => {
  const docRef = doc(db, `custom_added_lists`, obj?.id);
  await updateDoc(docRef, {
    data: arrayRemove(obj?.value),
    last_updated_by: obj?.last_updated_by,
    last_edited: Date.now(),
  }).then(async () => {
    const docSnap = await getDoc(docRef);
    return { id: docSnap.id, ...docSnap.data() };
  });
};

//Delete Master Lists
export const deleteCustomMasterList = async (id: string) => {
  const docRef = doc(db, `custom_added_lists`, id);
  await deleteDoc(docRef);
};

// ==================== Step Forms and Forms Management ======================
// Add Form
export const addForm = async (obj: any) => {
  const new_doc = {
    ...obj,
    last_updated_by: {
      ...obj?.last_updated_by,
      name: obj?.last_updated_by?.name || auth?.currentUser?.displayName || "",
      email: obj?.last_updated_by?.email || auth?.currentUser?.email || "",
      uid: obj?.last_updated_by?.uid || auth?.currentUser?.uid || "",
    },
    created_by: {
      ...obj?.last_updated_by,
      name: obj?.created_by?.name || auth?.currentUser?.displayName || "",
      email: obj?.created_by?.email || auth?.currentUser?.email || "",
      uid: obj?.created_by?.uid || auth?.currentUser?.uid || "",
    },
  };

  const docRef = await addDoc(formsRef, {
    ...new_doc,
  });
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Update Form
export const updateForm = async (obj: any) => {
  const new_doc = {
    ...replaceUndefinedWithEmptyString(obj),
    last_updated_by: {
      ...obj?.last_updated_by,
      name: obj?.last_updated_by?.name || auth?.currentUser?.displayName || "",
      email: obj?.last_updated_by?.email || auth?.currentUser?.email || "",
      uid: obj?.last_updated_by?.uid || auth?.currentUser?.uid || "",
    },
  };

  const docRef = doc(db, `forms`, obj?.id);
  await updateDoc(docRef, {
    ...new_doc,
  });
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Delete Form
export const deleteForm = async (id: string) => {
  const docRef = doc(db, `forms`, id);
  return await deleteDoc(docRef);
};

// ===================== Incidents Functions =========================
// Add Incidents
export const addIncident = async (obj: any) => {
  return await addDoc(incidentsRef, obj);
};

//Update Incidents
export const updateIncident = async (obj: any) => {
  const docRef = doc(db, `incidents`, obj?.id);
  return await updateDoc(docRef, obj);
};

//Delete Complaint
export const deleteIncident = async (id: string) => {
  const docRef = doc(db, `incidents`, id);
  return await deleteDoc(docRef);
};

// ===================== Complaints Functions =========================
// Add Complaints
export const addComplaint = async (obj: any) => {
  return await addDoc(complaintsRef, obj);
};

//Update Complaint
export const updateComplaint = async (obj: any) => {
  const docRef = doc(db, `complaints`, obj?.id);
  return await updateDoc(docRef, obj);
};

//Delete Complaint
export const deleteCompliant = async (id: string) => {
  const docRef = doc(db, `complaints`, id);
  return await deleteDoc(docRef);
};

// ===================== assessments Functions =========================
// Add Assessments
export const addAssesment = async (obj: any, user?: any) => {
  return await addDoc(assessmentsRef, {
    ...obj,
    by: {
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName,
    },
  });
};

//Update assessments
export const updateAssessments = async (obj: any, user?: any) => {
  const docRef = doc(db, `assessments`, obj?.id);
  return await updateDoc(docRef, {
    ...obj,
    by: {
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName,
    },
  });
};

//Delete Assessment
export const deleteAssessments = async (id: string) => {
  const docRef = doc(db, `assessments`, id);
  return await deleteDoc(docRef);
};

//======================= Care Plans =========================
// Add Care Plan
export const addCarePlan = async (obj: any, user?: any) => {
  return await addDoc(carePlansRef, {
    ...obj,
    by: {
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName,
    },
  });
};

//Update Care Plan
export const updateCarePlan = async (obj: any, user?: any) => {
  const docRef = doc(db, `care_plans`, obj?.id);
  return await updateDoc(docRef, {
    ...obj,
    updated_by: {
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName,
    },
  });
};

//Delete Care Plan
export const deleteCarePlan = async (id: string) => {
  const docRef = doc(db, `care_plans`, id);
  return await deleteDoc(docRef);
};

// ===================== Care Functions =========================
// Add Assessments
export const addCare = async (obj: any, user?: any) => {
  await setDoc(doc(careRef, obj?.client?.uid), {
    ...obj,
    last_updated: {
      date: Date.now(),
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName || "",
    },
  });
  const docRef = await doc(db, "care_management", obj?.client?.uid);
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

//Update Care Doc
export const updateCare = async (obj: any, user?: any, med_obj?: any) => {
  const docRef = doc(db, `care_management`, obj?.id);
  await updateDoc(docRef, {
    ...obj,
    last_updated: {
      date: Date.now(),
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName || "",
    },
  });

  if (med_obj) {
    const tasksCollectionRef = collection(
      db,
      `care_management/${obj.id}/tasks`
    );
    const batch = writeBatch(db); // Create a batch

    // Fetch and delete tasks in the batch
    const querySnapshot = await getDocs(tasksCollectionRef);
    querySnapshot.docs.forEach((doc) => {
      const taskData = doc.data();
      if (taskData.is_medication?.id === med_obj.id) {
        batch.delete(doc.ref);
      }
    });
    // Commit the batch
    await batch.commit();
  }
  // Get the updated document
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Add or Remove Shifts
export const updateShifts = async (
  care_id: string,
  shift_obj: ShiftType,
  action: "remove" | "add",
  user?: any
) => {
  const docRef = doc(db, `care_management`, care_id);
  await updateDoc(docRef, {
    shifts:
      action === "remove" ? arrayRemove(shift_obj) : arrayUnion(shift_obj),
    last_updated: {
      date: Date.now(),
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName || "",
    },
  });

  // Get the updated document
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Add Care Med
export const addMedication = async (
  obj: any,
  med_obj: MedicationType,
  user?: any
) => {
  const docRef = doc(db, `care_management`, obj?.id);
  console.log(user?.id);
  await updateDoc(docRef, {
    ...obj,
    medications: arrayUnion({
      ...med_obj,
      last_updated: {
        ...(obj?.last_updated || {}),
        date: dateToLondonMilliseconds(),
      },
      date: dateToLondonMilliseconds(),
    }),
    last_updated: {
      ...(obj?.last_updated || {}),
      date: Date.now(),
    },
  });

  // Get the updated document
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Update Stock
export const updateStockForMed = async (
  log_obj: LogsTypes,
  care_doc: CareDocType,
  med_prev: MedicationType,
  user?: any
) => {
  const docRef = doc(db, `care_management`, log_obj?.parent_id);
  await updateDoc(docRef, {
    medications: [
      ...(care_doc?.medications?.filter(
        (data: MedicationType) => data?.id !== med_prev?.id
      ) || []),
      {
        ...med_prev,
        in_stock:
          log_obj?.type === "addition"
            ? Number(med_prev?.in_stock) + Number(log_obj?.quantity)
            : Number(med_prev?.in_stock) - Number(log_obj?.quantity),
      },
    ],
    last_updated: {
      date: Date.now(),
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName || "",
    },
  });
  await addDoc(collection(db, `care_management/${log_obj?.parent_id}/logs`), {
    ...log_obj,
    id: uuid(),
  });

  // Get the updated document
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Medication Logs
export const medicationAdminstrationLogs = async (
  log_obj: MedicatioLogType,
  parent_id: string,
  stockData: LogsTypes,
  care_doc: CareDocType,
  med_prev: MedicationType
) => {
  const docRef = doc(db, `care_management`, parent_id);
  await addDoc(collection(db, `medication_admin_logs`), {
    ...log_obj,
    log_date: dateToLondonMilliseconds(),
  });

  await updateStockForMed(stockData as LogsTypes, care_doc, med_prev);

  // Get the updated document
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//delete Care Med
export const deleteMedication = async (
  obj: any,
  med_obj: MedicationType,
  user?: any
) => {
  const careManagementDocRef = doc(db, `care_management`, obj?.id);
  const tasksCollectionRef = collection(db, `care_management/${obj.id}/tasks`);
  const batch = writeBatch(db); // Create a batch

  // Update care management document
  batch.update(careManagementDocRef, {
    ...obj,
    medications: arrayRemove(med_obj),
    last_updated: {
      date: Date.now(),
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName || "",
    },
  });

  // Fetch and delete tasks in the batch
  const querySnapshot = await getDocs(tasksCollectionRef);
  querySnapshot.docs.forEach((doc) => {
    const taskData = doc.data();
    if (taskData.is_medication?.id === med_obj.id) {
      batch.delete(doc.ref);
    }
  });

  // Commit the batch
  await batch.commit();

  // Get the updated care management document
  const docSnap = await getDoc(careManagementDocRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Archive Care Med
export const archiveMedication = async (
  obj: any,
  med_obj: MedicationType,
  user?: any
) => {
  const careManagementDocRef = doc(db, `care_management`, obj?.id);
  const tasksCollectionRef = collection(db, `care_management/${obj.id}/tasks`);
  const batch = writeBatch(db); // Create a batch

  // Remove Med object from the medications array
  batch.update(careManagementDocRef, {
    ...obj,
    medications: arrayRemove(med_obj),
    last_updated: {
      date: Date.now(),
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName || "",
    },
  });

  // Add updated Med object to the medications array
  batch.update(careManagementDocRef, {
    ...obj,
    medications: arrayUnion({ ...med_obj, archived: !med_obj?.archived }),
    last_updated: {
      date: Date.now(),
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName || "",
    },
  });

  // Fetch and delete tasks in the batch
  const querySnapshot = await getDocs(tasksCollectionRef);
  querySnapshot.docs.forEach((doc) => {
    const taskData = doc.data();
    if (taskData.is_medication?.id === med_obj.id) {
      batch.delete(doc.ref);
    }
  });

  // Commit the batch
  await batch.commit();

  // Get the updated care management document
  const docSnap = await getDoc(careManagementDocRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Delete Care Doc
export const deleteCare = async (id: string) => {
  const docRef = doc(db, `care_management`, id);
  return await deleteDoc(docRef);
};

//Add Care Plan
export const AddOrRemoveCarePlan = async (
  obj: any,
  action: "remove" | "add",
  doc_id: string
) => {
  const docRef = doc(db, `care_management`, doc_id);
  await updateDoc(docRef, {
    care_plans: action === "add" ? arrayUnion(obj) : arrayRemove(obj),
    last_updated: {
      date: Date.now(),
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: auth?.currentUser?.displayName || "",
    },
  });

  // Get the updated document
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};
// ===================== Log Notes =========================
//Add Notes
export const addNote = async (obj: any, parent_id: string) => {
  const docRef = await addDoc(
    collection(db, `care_management/${parent_id}/notes_data`),
    obj
  );
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

//Update Notes
export const updateNote = async (obj: any, parent_id: string) => {
  const docRef = doc(db, `care_management/${parent_id}/notes_data`, obj?.id);
  await updateDoc(docRef, obj);
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};

//Delete Note
export const deleteNote = async (id: string, parent_id: string) => {
  const docRef = doc(db, `care_management/${parent_id}/notes_data`, id);
  return await deleteDoc(docRef);
};
// ===================== Log Tasks =========================
//Add Task
export const addTask = async (
  parent_id: string,
  task: TaskType | any,
  user?: any
) => {
  const docRef = await addDoc(
    collection(db, `care_management/${parent_id}/tasks`),
    {
      ...task,
      user: user || {},
    }
  );

  // Get the updated document
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Remove Task
export const removeTask = async (parent_id: string, id: string) => {
  const docRef = doc(db, `care_management/${parent_id}/tasks`, id);
  return await deleteDoc(docRef);
};

//Update Task
export const updateTask = async (obj: any, parent_id: string) => {
  const docRef = doc(db, `care_management/${parent_id}/tasks`, obj?.id);
  await updateDoc(docRef, obj);
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};

//Mark Task
export const markTask = async (id: string, parent_id: string, action: any) => {
  const docRef = doc(db, `care_management/${parent_id}/tasks`, id);
  await updateDoc(docRef, {
    logs:
      action?.status === "pending"
        ? arrayRemove({ ...action, status: "complete" })
        : arrayUnion({
            ...action,
            date: Date.now(),
            status: action?.status,
          }),
  });

  // Get the updated document
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

// Add Task Log
export const addTasksLog = async (parent_id: string, log: TaksLogsType) => {
  const docRef = collection(db, `care_management/${parent_id}/tasks_logs`);
  return await addDoc(docRef, log);
};

//Remove Task Log
export const deleteTasksLog = async (parent_id: string, id: string) => {
  const docRef = doc(db, `care_management/${parent_id}/tasks_logs`, id);
  return await deleteDoc(docRef);
};

// ===================== Log Charts =========================
//Add chart_log
export const addChartLog = async (chart_log: any) => {
  const docRef = await addDoc(
    collection(db, `care_management/${chart_log?.client_id}/chart_logs`),
    { ...chart_log, date: chart_log?.date || Date.now() }
  );

  // Get the updated document
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

//Remove chart_log
export const deleteChartLog = async (parent_id: string, id: string) => {
  const docRef = doc(db, `care_management/${parent_id}/chart_logs`, id);
  return await deleteDoc(docRef);
};

// ===================== Log Charts =========================
//Add body_map
export const addBodyMap = async (body_map: any) => {
  const docRef = await addDoc(
    collection(db, `care_management/${body_map?.client_id}/body_maps`),
    body_map
  );

  // Get the updated document
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

export const updateBodyMap = async (body_map: any) => {
  const docRef = doc(
    db,
    `care_management/${body_map?.client_id}/body_maps`,
    body_map?.id
  );
  return await updateDoc(docRef, body_map);
};

//Remove body_map
export const deleteBodyMaps = async (parent_id: string, id: string) => {
  const docRef = doc(db, `care_management/${parent_id}/body_maps`, id);
  return await deleteDoc(docRef);
};

// ===================== Clients =========================
//Update client
export const updateClient = async (obj: any, user?: any) => {
  const docRef = doc(db, `clients`, obj?.id);
  return await updateDoc(docRef, {
    ...obj,
    last_updated: {
      date: Date.now(),
      uid: auth?.currentUser?.uid || "",
      email: auth?.currentUser?.email,
      name: user?.username || auth?.currentUser?.displayName,
    },
  }).then(async () => {
    return await updateCare({ id: obj?.id, client: obj });
  });
};

//Delete Client
export const deleteClient = async (id: string) => {
  const docRef = doc(db, `clients`, id);
  return await deleteDoc(docRef);
};

// ==================== Schedule Functions =================
//Add Notes
export const addSchedule = async (obj: any) => {
  const docRef = await addDoc(collection(db, `schedule`), obj);
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

//Update Schedule
export const updateSchedule = async (obj: any) => {
  const docRef = doc(db, `schedule`, obj?.id);
  await updateDoc(docRef, obj);
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return await updatedDoc.data();
};

// Update multiple schedules as a paid
export const markAsPaid = async (
  roll_docs: PayRunEmployeeType[],
  payroll_id: string,
  payroll_obj: PayRunType
) => {
  const batchSize = 500; // Maximum batch size
  const shifts: string[] =
    roll_docs?.map((data: PayRunEmployeeType) => data?.payed_shifts)?.flat() ||
    [];

  const batches: any[] = [];

  // Split the array into smaller batches
  for (let i = 0; i < shifts.length; i += batchSize) {
    const batch = shifts.slice(i, i + batchSize);
    batches.push(batch);
  }

  // Execute batches sequentially
  for (const batchShifts of batches) {
    const batch = writeBatch(db);

    // Update documents in the "schedule" collection
    batchShifts.forEach((id: string) => {
      const docRef = doc(db, "schedule", id);
      batch.update(docRef, { paid: true, payroll_id: payroll_id });
    });

    // Commit the batch operation
    await batch.commit();
  }

  // Create document in the "payrolls" collection
  const payrollDocRef = doc(db, "payrolls", payroll_id);
  const payrollData = {
    ...payroll_obj,
    payroll_id: payroll_id,
    commitedAt: Date.now(),
    status: "completed",
  };

  await setDoc(payrollDocRef, payrollData); // Set the data for the new document

  return `Documents updated and payroll document created with ID: ${payroll_id}`;
};

// Update multiple schedules
export const updateMultipleSchedules = async (docIds: string[]) => {
  const batch = writeBatch(db);

  docIds.forEach((id) => {
    const docRef = doc(db, "schedule", id);
    batch.update(docRef, { published: true, status: "pending" });
  });

  return await batch.commit(); // Perform the batch update
};

//Delete bulk schedules
export const deleteBulkSchedules = async (docIds: string[]) => {
  const batch = writeBatch(db);

  docIds.forEach((id) => {
    const docRef = doc(db, "schedule", id);
    batch.delete(docRef);
  });

  return await batch.commit(); // Perform the batch update
};

// Buld delete visits
export const deleteBulkVisits = async (docIds: string[]) => {
  const batch = writeBatch(db);

  docIds.forEach((id) => {
    const docRef = doc(db, "visits", id);
    batch.delete(docRef);
  });

  return await batch.commit(); // Perform the batch update
};

//Delete Schedule
export const deleteSchedule = async (id: string) => {
  const docRef = doc(db, `schedule`, id);
  return await deleteDoc(docRef);
};

// ===============  Inventory ============================
//add item
export const addItem = async (obj: any) => {
  const docRef = await addDoc(collection(db, `inventory`), {
    ...obj,
    lastUpdatedAt: Date.now(),
    createdAt: Date.now(),
  });
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

//Update item
export const updateItem = async (obj: any) => {
  const docRef = doc(db, `inventory`, obj?.id);
  await updateDoc(docRef, {
    ...obj,
    lastUpdatedAt: Date.now(),
  });
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};

//Delete item
export const deleteItem = async (id: string) => {
  const docRef = doc(db, `inventory`, id);
  return await deleteDoc(docRef);
};

//Update Logs
export const updateLogs = async (
  parent_id: string,
  log_obj: InventoryLogsTypes
) => {
  const docRef = doc(db, `inventory`, parent_id);
  await updateDoc(docRef, {
    in_stock: increment(Number(log_obj?.quantity)),
    lastUpdatedAt: Date.now(),
  });
  return await addDoc(collection(db, `inventory/${parent_id}/logs`), log_obj);
};

//Add Master Inventory
export const addMasteryInventoryItem = async (obj: MasterInventoryType) => {
  const docRef = await addDoc(collection(db, `master_inventory`), obj);
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

export const addStockOrder = async (obj: MasterInventoryRequestType) => {
  const docRef = await addDoc(collection(db, `stock_orders`), obj);
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

export const updateStockOrder = async (obj: MasterInventoryRequestType) => {
  const docRef = doc(db, `stock_orders`, obj?.id as string);
  await updateDoc(docRef, {
    ...obj,
    lastUpdatedAt: Date.now(),
  });
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};

export const addStockOrderLogs = async (
  obj: MasterInventoryLogsType,
  parent_id: string
) => {
  const docRef = doc(db, `stock_orders`, parent_id as string);
  await updateDoc(docRef, {
    in_stock: obj.balance_after,
    logs: arrayUnion(obj),
    lastUpdatedAt: Date.now(),
  });
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};

export const deleteMasterItem = async (id: string) => {
  const docRef = doc(db, `master_inventory`, id);
  return await deleteDoc(docRef);
};

//Update item
export const updateMasteryInventoryItem = async (obj: any) => {
  const docRef = doc(db, `master_inventory`, obj?.id);
  await updateDoc(docRef, {
    ...obj,
    lastUpdatedAt: Date.now(),
  });
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};

//Delete item
export const deleteMasteryInventoryItem = async (id: string) => {
  const docRef = doc(db, `master_inventory`, id);
  return await deleteDoc(docRef);
};

//Add  A log
export const addMasterInventoryLog = async (obj: MasterInventoryLogsType) => {
  await updateDoc(doc(db, `master_inventory`, obj?.parent_id), {
    in_stock: obj.balance_after,
    lastUpdatedAt: Date.now(),
  });

  const docRef = await addDoc(
    collection(db, `master_inventory/${obj.parent_id}/logs`),
    obj
  );
  const docSnap = await getDoc(docRef);
  //Return the updated document
  return { id: docSnap.id, ...docSnap.data() };
};

// ========================== Roles =================
//add role
export const addRole = async (obj: any) => {
  const docRef = await addDoc(collection(db, `roles`), {
    ...obj,
    lastUpdatedAt: Date.now(),
    createdAt: Date.now(),
  });
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

//Update item
export const updateRole = async (obj: any) => {
  const docRef = doc(db, `roles`, obj?.id);
  await updateDoc(docRef, {
    ...obj,
    lastUpdatedAt: Date.now(),
  });
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};

//Delete item
export const deleteRole = async (id: string) => {
  const docRef = doc(db, `roles`, id);
  return await deleteDoc(docRef);
};

// ===================== AI Chat ===========================
export const initiateChat = async (obj: any) => {
  const docRef = await addDoc(collection(db, `connek_assistant`), obj);
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

// ====================== Employee Form =======================
//Delete Invites
export const deleteInvite = async (id: string) => {
  const docRef = doc(db, `invites`, id);
  return await deleteDoc(docRef);
};

export const addEmployeeDoc = async (obj: any, inv?: string) => {
  const docRef = await addDoc(collection(db, `employees_docs`), obj);
  const docSnap = await getDoc(docRef);
  docSnap.id && inv && (await deleteInvite(inv));
  return { id: docSnap.id, ...docSnap.data() };
};

//Update Employee Docs
export const updateEmployeeDoc = async (obj: any) => {
  const docRef = doc(db, `employees_docs`, obj?.id);
  await updateDoc(docRef, obj);
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return await updatedDoc.data();
};

//Add References
export const addReferenceToEmployeeDoc = async (id: string, obj: any) => {
  const docRef = doc(db, `employees_docs`, id);
  await updateDoc(docRef, {
    references_data: arrayUnion(obj),
    ref_ids: arrayUnion(obj?.id),
  });
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return await updatedDoc.data();
};

//Remove Reference
export const removeReferenceToEmployeeDoc = async (id: string, obj: any) => {
  const docRef = doc(db, `employees_docs`, id);
  await updateDoc(docRef, {
    references_data: arrayRemove(obj),
    ref_ids: arrayRemove(obj?.id),
  });
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return await updatedDoc.data();
};

//Delete Employee Docs
export const deleteEmployeeDoc = async (id: string) => {
  const docRef = doc(db, `employees_docs`, id);
  return await deleteDoc(docRef);
};

// ==================== Perfomance Mng Functions =================
//Add PrfMng
export const addPrfMng = async (obj: any, path: string) => {
  const docRef = await addDoc(collection(db, path), {
    ...obj,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  });
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

//Update PrfMng
export const updatePrfMng = async (obj: any, path: string) => {
  const docRef = doc(db, path, obj?.id);
  await updateDoc(docRef, {
    ...obj,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  });
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return await updatedDoc.data();
};

//Delete PrfMng
export const deletePrfMng = async (id: string, path: string) => {
  const docRef = doc(db, path, id);
  return await deleteDoc(docRef);
};

// ========================== Leave =================
//add role
export const addLeaveApplication = async (obj: any) => {
  const docRef = await addDoc(collection(db, `leaves`), {
    ...obj,
    lastUpdatedAt: Date.now(),
  });
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

//Update item
export const updateLeaveApplication = async (obj: any) => {
  const docRef = doc(db, `leaves`, obj?.id);
  await updateDoc(docRef, {
    ...obj,
    lastUpdatedAt: Date.now(),
  });
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return { id: updatedDoc.id, ...updatedDoc.data() };
};

//Delete item
export const deleteLeaveApplication = async (id: string) => {
  const docRef = doc(db, `leaves`, id);
  return await deleteDoc(docRef);
};

// ========================== Expenses Management =================
//add role
export const addExpense = async (obj: any) => {
  const docRef = await addDoc(collection(db, `expenses`), obj);
  const docSnap = await getDoc(docRef);
  //Add Custom List
  return { id: docSnap.id, ...docSnap.data() };
};

//Update item
export const updateExpense = async (obj: any) => {
  const docRef = doc(db, `expenses`, obj?.id);
  await updateDoc(docRef, obj);
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};

//Delete item
export const deleteExpense = async (id: string) => {
  const docRef = doc(db, `expenses`, id);
  return await deleteDoc(docRef);
};

// ========================== Expenses Management =================
//add role
export const addWiki = async (obj: WikiType) => {
  const docRef = await addDoc(collection(db, `wiki`), obj);
  const docSnap = await getDoc(docRef);

  return { id: docSnap.id, ...docSnap.data() };
};

//Update item
export const updateWiki = async (obj: any) => {
  const docRef = doc(db, `wiki`, obj?.id);
  await updateDoc(docRef, obj);
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};

//Delete item
export const deleteWiki = async (id: string) => {
  const docRef = doc(db, `wiki`, id);
  return await deleteDoc(docRef);
};

// ======================= Add Evaluation =======================

export const addEvaluation = async (obj: any) => {
  const docRef = await addDoc(collection(db, `performance_evaluations`), obj);
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

export const deleteEvaluation = async (id: string) => {
  const docRef = doc(db, `performance_evaluations`, id);
  return await deleteDoc(docRef);
};

// ==========================Requests =================
//add role
export const addRequestSettings = async (obj: WikiType) => {
  const docRef = await addDoc(collection(db, `requests_settings`), obj);
  const docSnap = await getDoc(docRef);

  return { id: docSnap.id, ...docSnap.data() };
};

//Update item
export const updateRequestsAreas = async (value: any) => {
  const docRef = doc(db, `system_data`, "area_settings");
  await updateDoc(docRef, { areas: value });
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};

//Scheduler settings
export const updateSchedulerSettings = async (
  value: AutoScheduleSettingsType
) => {
  const docRef = doc(db, `system_data`, "scheduler_settings");
  await updateDoc(docRef, value as any);
  // Get the updated document
  const updatedDoc = await getDoc(docRef);
  return updatedDoc.data();
};
