import React, { useMemo, useState } from "react";
import { ScheduleEventType } from "../../../../types/schedule_types";
import {
  removeDuplicates,
  toPureString,
} from "../../../../utils/reusable_functions";
import mapboxgl from "mapbox-gl";
import { debounce } from "moderndash";
import { TbMapSearch } from "react-icons/tb";
import Map from "./map";
import Shifts from "./shifts";
import { AnimatePresence, motion } from "framer-motion";
import { HiUsers } from "react-icons/hi2";

mapboxgl.accessToken = import.meta.env.VITE_MAP_BOX as string;

type Props = {
  schedule_data: ScheduleEventType[];
};

const Locations = ({ schedule_data }: Props) => {
  const [menu_open, setOpenMenu] = useState<boolean>(true);
  const [active_client, setActiveClient] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  // Debounced setActiveClient
  const debouncedSetActiveClient = useMemo(
    () => debounce(setActiveClient, 300), // Adjust debounce time as needed
    []
  );

  const locations_info = useMemo(() => {
    const locations_data = removeDuplicates(
      schedule_data
        ?.filter((data: ScheduleEventType) =>
          toPureString(JSON.stringify(data))?.includes(toPureString(search))
        )
        ?.map((data: ScheduleEventType) => ({
          client_name: `${data?.client?.["first name"]} ${data?.client?.["last name"]}`,
          client: data?.client,
          client_id: data?.client?.id,
          location: {
            latitude: Number(data?.client?.latitude),
            longitude: Number(data?.client?.longitude),
          },
        })),
      ["client_id"]
    );
    const results =
      locations_data
        ?.map((data: any) => ({
          ...data,
          shifts: schedule_data?.filter(
            (shift: ScheduleEventType) => shift?.client?.id === data?.client_id
          ),
        }))
        ?.filter((data: any) =>
          active_client ? data?.client_id === active_client : true
        ) || [];
    return results;
  }, [schedule_data, active_client, search]);

  //JSX Code =============
  return (
    <div
      className="mt-4 w-full h-96 lg:h-[45rem] rounded-md border border-zinc-200 bg-white
       p-2 md:p-4 flex md:gap-4 overflow-hidden shrink-0 relative"
    >
      {/**Menu */}
      <button
        onClick={() => setOpenMenu(true)}
        className="h-10 w-10 rounded-md bg-zinc-800 flex md:hidden items-center justify-center 
        text-xl text-white absolute right-4 lg:right-6 top-4 lg:top-6 z-50 shadow-xl drop-shadow-2xl"
      >
        <TbMapSearch />
      </button>

      {/**Search */}
      <AnimatePresence>
        <motion.div
          initial={{ left: "100%", right: "-100%", width: 0, padding: 0 }}
          exit={{ left: "100%", right: "-100%", width: 0, padding: 0 }}
          animate={
            (menu_open && innerWidth <= 762) || innerWidth > 762
              ? {
                  left: 0,
                  right: 0,
                  width: innerWidth <= 762 ? "100%" : "320px",
                  position: innerWidth <= 762 ? "absolute" : "relative",
                  padding: innerWidth <= 762 ? "16px" : 0,
                }
              : { left: "100%", right: "-100%", width: 0, padding: 0 }
          }
          className="w-full lg:w-80 h-full rounded shrink-0 inset-y-0 overflow-hidden z-50 bg-white"
        >
          {/**Locations */}
          <label
            htmlFor="search_client"
            className="w-full h-10 shrink-0 rounded bg-zinc-50 border border-zinc-300 flex
           items-center gap-1 px-3 overflow-hidden hover:border-sky-300 transition-all"
          >
            <TbMapSearch className="text-xl shrink-0 text-zinc-500" />
            <input
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              type="search"
              placeholder="Who are you looking for ?"
              className="bg-transparent border-0 placeholder:text-zinc-500 text-zinc-800 text-xss w-full"
            />
          </label>

          <div className="text-xxs  shrink-0 my-3 px-1 flex items-center gap-2 overflow-hidden">
            <span className="uppercase font-bold text-zinc-800">Clients</span>
            <hr className="w-full h-0 border-b border-zinc-300 border-dashed" />
          </div>

          {/**Clients List */}
          {locations_info?.length >= 1 ? (
            <ul className="w-full h-full flex flex-col gap-1 overflow-hidden overflow-y-scroll no-scrollbar">
              {locations_info?.map((data: any, index: number) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      debouncedSetActiveClient(data?.client_id);
                      setOpenMenu(false);
                    }}
                    className={`w-full h-14 shrink-0 hover:bg-sky-50 flex items-center justify-between gap-2
                  px-2 cursor-pointer border border-zinc-200 rounded transition-all`}
                  >
                    <img
                      onError={(e) => {
                        if (e?.currentTarget) {
                          e.currentTarget.src = "/images/default_profile.png";
                        }
                      }}
                      src={
                        data?.client?.profile || "/images/default_profile.png"
                      }
                      alt="pro"
                      className="h-9 w-9 rounded-full shrink-0 bg-zinc-50 object-cover object-center border border-sky-500"
                    />
                    <div className="w-full flex flex-col overflow-hidden">
                      <span className="text-xs font-bold text-zinc-800 whitespace-nowrap truncate">
                        {data?.client_name}
                      </span>
                      <span className="text-xs text-zinc-500 whitespace-nowrap truncate">
                        {data?.client?.full_address || "No address"}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="w-full h-full flex flex-col items-center pt-20 p-4">
              <HiUsers className="text-[4rem] text-zinc-300 shrink-0" />
              <p className="text-xs text-zinc-500 text-center font-medium px-1">
                All clients with upcoming or active shifts will be shown here .
                You can adjust your search or dates on your top right
              </p>
            </div>
          )}

          {/**Shifts of active client */}
          <AnimatePresence>
            {active_client && locations_info?.[0]?.shifts && (
              <Shifts
                debouncedSetActiveClient={debouncedSetActiveClient}
                shifts={[...(locations_info?.[0]?.shifts||[])]?.sort((a:ScheduleEventType,b:ScheduleEventType)=>a.start_date-b.start_date)}
              />
            )}
          </AnimatePresence>
        </motion.div>
      </AnimatePresence>

      {/**Map */}
      <Map
        filtered_locations={locations_info || []}
        active_client={active_client}
      />
    </div>
  );
};

export default React.memo(Locations);
