import React from "react";

import { Bolt, BookOpenText, CalendarRange, LandPlot, Mail, Route } from "lucide-react";
import SubMenuTemplate from "../sub-menus-template/sub-menu-template";
import withPermission from "@/components/auth/withPermission";

const options = [
  {
    name: "Schedule",
    path: "/scheduler",
    description: "Manage your schedule",
    icon: <CalendarRange className="regular_icon" />,
  },
  {
    name: "Routes",
    path: "/scheduler/routes",
    description: "Visualize your routes",
    icon: <Route className="regular_icon" />,
  },
  {
    name: "Distances",
    path: "/scheduler/distances",
    description: "Manage your distances",
    icon: <LandPlot className="regular_icon" />,
  },
  {
    name: "Refferrals",
    path: "/scheduler/requests",
    description: "Manage your refferrals",
    icon: <Mail className="regular_icon" />,
  },
  {
    name: "Settings",
    path: "/scheduler/settings",
    description: "Manage your settings",
    icon: <Bolt className="regular_icon" />,
  },
  {
    name: "Documentation",
    path: "/scheduler/docs",
    description: "Read the documentation",
    icon: <BookOpenText className="regular_icon" />,
  },
];

const SchedulerLayout = withPermission(() => {
  //JSX Code ========================
  return (
    <React.Fragment>
      <SubMenuTemplate options={options} />
    </React.Fragment>
  );
});

export default SchedulerLayout;
