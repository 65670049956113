import { auth } from "../firebase/firebase_config";
import { FaUserNurse, FaFileContract } from "react-icons/fa6";
import { BsPersonFillCheck } from "react-icons/bs";
import { RiPassValidFill } from "react-icons/ri";
//import { TbLayoutGrid, TbCreditCard } from "react-icons/tb";
import {
  HeartHandshake,
  Archive,
  ChartArea,
  LayoutList,
  LayoutPanelLeft,
  Users,
  BookOpen,
  CalendarFold,
  TextCursorInput,
} from "lucide-react";

export const nav_links = [
  {
    name: "home",
    path: "/",
    access: "",
    nested_routes: [],
    icon: (
      <LayoutPanelLeft className="menu_icons" />
    ),
  },
  {
    name: "profile",
    path: "/people/employee/",
    access: "",
    nested_routes: [],
    icon: <Users className="text-2xl md:text-lg shrink-0" />,
  },
  {
    name: "care",
    path: "/care",
    access: "",
    nested_routes: [],
    icon: (
      <HeartHandshake className="menu_icons" />
    ),
  },
  {
    name: "people",
    path: "/people",
    access: "",
    // nested_routes: [
    //   {
    //     name: "employees",
    //     path: "/people",
    //     access: "",
    //     icon: <TbLayoutGrid className="text-2xl md:text-lg shrink-0" />,
    //   },
    //   {
    //     name: "leave",
    //     path: "/people/leave",
    //     access: "",
    //     icon: <TbLayoutGrid className="text-2xl md:text-lg shrink-0" />,
    //   },
    //   {
    //     name: "timesheet",
    //     path: "/people/timesheet",
    //     access: "",
    //     icon: <TbLayoutGrid className="text-2xl md:text-lg shrink-0" />,
    //   },
    //   {
    //     name: "payroll",
    //     path: "/people/payroll",
    //     access: "",
    //     icon: <TbLayoutGrid className="text-2xl md:text-lg shrink-0" />,
    //   },
    //   {
    //     name: "expenses",
    //     path: "/people/expenses",
    //     access: "",
    //     icon: <TbCreditCard className="text-2xl md:text-lg shrink-0" />,
    //   },
    // ],
    icon: <Users className="menu_icons" />,
  },
  {
    name: "reports",
    path: "/reports",
    access: "",
    icon: <ChartArea className="menu_icons" />,
  },
  {
    name: "forms",
    path: "/forms",
    access: "",
    icon: <TextCursorInput className="menu_icons" />,
  },
  {
    name: "lists",
    path: "/lists",
    access: "",
    icon: <LayoutList className="menu_icons" />,
  },
  {
    name: "inventory",
    path: "/inventory",
    access: "",
    icon: <Archive className="menu_icons" />,
  },
  {
    name: "wiki",
    path: "/wiki",
    access: "",
    icon: <BookOpen className="menu_icons" />,
  },
  {
    name: "DC scheduler",
    path: "/scheduler",
    access: "",
    icon: (
      <CalendarFold className="menu_icons" />
    ),
  },
];

export const settings_links = [
  {
    name: "account settings",
    group: "personal settings",
    path: "/settings",
    access: [],
  },
  {
    name: "security",
    group: "personal settings",
    path: "/settings/security",
    access: [],
  },
  {
    name: "users",
    group: "users management",
    path: "/settings/users",
    access: ["admin"],
  },
  {
    name: "roles & permissions",
    group: "users management",
    path: "/settings/roles_permissions",
    access: [],
  },
];

export const upcoming_audits = [
  {
    address: "123 Main St, City A, Country X",
    date: 1679808000000, // Represents 2023-03-26
    agent_name: "John Doe",
  },
  {
    address: "456 Elm St, City B, Country Y",
    date: 1679721600000, // Represents 2023-03-25
    agent_name: "Jane Smith",
  },
  {
    address: "789 Oak St, City C, Country Z",
    date: 1679635200000, // Represents 2023-03-24
    agent_name: "Michael Johnson",
  },
  {
    address: "567 Pine Ave, City D, Country X",
    date: 1679548800000, // Represents 2023-03-23
    agent_name: "Emily Brown",
  },
  {
    address: "890 Maple Rd, City E, Country Y",
    date: 1679462400000, // Represents 2023-03-22
    agent_name: "David Lee",
  },
  {
    address: "345 Cedar Ln, City F, Country Z",
    date: 1679376000000, // Represents 2023-03-21
    agent_name: "Sophia Wilson",
  },
  {
    address: "234 Birch St, City G, Country X",
    date: 1679289600000, // Represents 2023-03-20
    agent_name: "Daniel Martinez",
  },
  {
    address: "678 Oakwood Dr, City H, Country Y",
    date: 1679203200000, // Represents 2023-03-19
    agent_name: "Olivia Taylor",
  },
  {
    address: "901 Willow Ave, City I, Country Z",
    date: 1679116800000, // Represents 2023-03-18
    agent_name: "Liam Anderson",
  },
  {
    address: "789 Elmwood Rd, City J, Country X",
    date: 1679030400000, // Represents 2023-03-17
    agent_name: "Ava Clark",
  },
];

export const formInputTypes = [
  "text",
  "textarea",
  "rich textarea",
  "date",
  "time",
  "number",
  "email",
  "url",
  "file",
  "dropdown",
  "multi-select",
  "group",
  "signature",
  "body-map",
  "address",
];

export const formBooleanOptions = [
  "is_linked",
  "allow_create",
  "searchable",
  "required",
  "clear data based on linked field",
  "hide if data source is empty",
  "expand group by default",
  "target",
  "is expandable",
  "can calculate",
  "hide stat card",
  "display fraction",
];

export const circleVariants = {
  initial: {
    clipPath: "circle(0% at 50% 50%)",
    opacity: 0,
  },
  animate: {
    clipPath: "circle(100% at 50% 50%)",
    opacity: 1,
    transition: {
      duration: 0.4,
    },
  },
  exit: {
    clipPath: "circle(0% at 50% 50%)",
    opacity: 0,
    transition: {
      duration: 0.4,
    },
  },
};

export const assesments_forms = [
  {
    type: "assessment",
    name: "initial assessment",
    data: [
      {
        section: "assessment detials",
        description: "Basic yet crucia detail about this assessment",
        index: 0,
        fields: [
          {
            index: 0,
            name: "agent name",
            description:
              "The name of the stuff or agent conducting the assessment",
            required: true,
            type: "text",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 1,
            name: "agent phone number",
            description:
              "Working phone of the stuff or agent conducting the assessment",
            required: true,
            type: "number",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 2,
            name: "agent email",
            description:
              "Working email of the stuff or agent conducting the assessment",
            required: true,
            type: "email",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 3,
            name: "date of the assessment",
            description: "",
            required: true,
            type: "date",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
        ],
      },
      {
        section: "client information detials",
        description: "information about the client",
        index: 1,
        fields: [
          {
            index: 0,
            name: "previous medical records",
            description: "Upload previous medical records",
            required: true,
            type: "file",
            accept: ".pdf",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 1,
            name: "client profile picture",
            description: "Upload clear client picture",
            required: false,
            type: "file",
            accept: ".jpg, .jpeg, .png, .gif, .webp",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 2,
            name: "client name",
            description: "The patient's full name",
            required: true,
            type: "text",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 3,
            name: "nick name",
            description: "preferred name",
            required: true,
            type: "text",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 4,
            name: "client date of birth",
            description: "",
            required: true,
            type: "date",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 5,
            name: "gender",
            description: "",
            required: true,
            type: "dropdown",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            data: ["male", "female", "other"],
          },
          {
            index: 6,
            name: "full address",
            description: "Full address including postal address",
            required: true,
            type: "text",
            max: 300,
            min: 10,
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 7,
            name: "client email",
            description: "",
            required: true,
            type: "email",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 8,
            name: "client phone",
            description: "",
            required: true,
            type: "number",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 9,
            name: "ethnicity",
            description: "",
            required: true,
            type: "dropdown",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            data: [
              "asian",
              "black",
              "white",
              "hispanic",
              "latino",
              "native american",
              "indigenous",
              "mixed race",
              "other",
            ],
          },
          {
            index: 10,
            name: "race",
            description: "",
            required: true,
            type: "dropdown",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            data: [
              "asian",
              "black",
              "white",
              "hispanic",
              "latino",
              "native american",
              "indigenous",
              "mixed race",
              "other",
            ],
          },
          {
            index: 11,
            name: "disabilities",
            description:
              "Select yes if you have any disablities and fill the input next with a specific disability",
            required: false,
            type: "dropdown",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            data: ["yes", "no"],
          },
          {
            index: 12,
            name: "disabilitie(s) details",
            description: "Explain your disability",
            required: true,
            type: "textarea",
            max: 300,
            min: 10,
            is_linked: true,
            linked_field: "disabilities",
            linked_value: "yes",
          },
        ],
      },
      {
        section: "initial assessment",
        description:
          "significant other(s) or any other responsible familiy member(s)",
        index: 2,
        fields: [
          {
            index: 0,
            name: "next of keen information",
            description: "",
            required: false,
            type: "group",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            limit: 10,
            blueprint: {
              index: 0,
              fields: [
                {
                  index: 0,
                  name: "name",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 1,
                  name: "relationship",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 2,
                  name: "full address",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 3,
                  name: "phone number",
                  description: "",
                  required: true,
                  type: "number",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 4,
                  name: "email address",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
              ],
            },
            group: [
              {
                index: 0,
                fields: [
                  {
                    index: 0,
                    name: "name",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 1,
                    name: "relationship",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 2,
                    name: "full address",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 3,
                    name: "phone number",
                    description: "",
                    required: true,
                    type: "number",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 4,
                    name: "email address",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        section: "commission information",
        description: "More specific details about the client",
        index: 3,
        fields: [
          {
            index: 0,
            name: "Previous medication",
            description: "",
            required: false,
            type: "group",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            limit: 10,
            blueprint: {
              index: 0,
              fields: [
                {
                  name: "name of medication",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "dosage",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "frequeny",
                  description: "",
                  required: true,
                  type: "number",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "routine",
                  description: "",
                  required: true,
                  type: "date",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "what is it for",
                  description: "",
                  required: true,
                  type: "textarea",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
              ],
            },
            group: [
              {
                index: 0,
                fields: [
                  {
                    name: "name of medication",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "dosage",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "frequeny",
                    description: "",
                    required: true,
                    type: "number",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "routine",
                    description: "",
                    required: true,
                    type: "date",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "what is it for",
                    description: "",
                    required: true,
                    type: "textarea",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        section: "consent details",
        description: "declaration and signed documents",
        index: 4,
        fields: [
          {
            index: 0,
            name: "signatures of individuals involved",
            description: "",
            required: true,
            type: "group",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            limit: 10,
            blueprint: {
              index: 0,
              fields: [
                {
                  name: "name",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "initials",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "date",
                  description: "",
                  required: true,
                  type: "date",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "role",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
              ],
            },
            group: [
              {
                index: 0,
                fields: [
                  {
                    name: "name",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "initials",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "date",
                    description: "",
                    required: true,
                    type: "date",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "role",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                ],
              },
            ],
          },
          {
            index: 0,
            name: "signed copy of the assessment",
            description: "Upload signed copy of the assessment",
            required: true,
            type: "file",
            accept: ".pdf",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
        ],
      },
    ],
  },
  {
    type: "assessment",
    name: "risk assessment",
    data: [
      {
        section: "assessment detials",
        description: "Basic yet crucia detail about this assessment",
        index: 0,
        fields: [
          {
            index: 0,
            name: "agent name",
            description:
              "The name of the stuff or agent conducting the assessment",
            required: true,
            type: "text",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 1,
            name: "agent phone number",
            description:
              "Working phone of the stuff or agent conducting the assessment",
            required: true,
            type: "number",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 2,
            name: "agent email",
            description:
              "Working email of the stuff or agent conducting the assessment",
            required: true,
            type: "email",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 3,
            name: "date of the assessment",
            description: "",
            required: true,
            type: "date",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
        ],
      },
      {
        section: "client information detials",
        description: "information about the client",
        index: 1,
        fields: [
          {
            index: 0,
            name: "previous medical records",
            description: "Upload previous medical records",
            required: true,
            type: "file",
            accept: ".pdf",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 1,
            name: "client profile picture",
            description: "Upload clear client picture",
            required: false,
            type: "file",
            accept: ".jpg, .jpeg, .png, .gif, .webp",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 2,
            name: "client name",
            description: "The patient's full name",
            required: true,
            type: "text",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 3,
            name: "nick name",
            description: "preferred name",
            required: true,
            type: "text",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 4,
            name: "client date of birth",
            description: "",
            required: true,
            type: "date",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 5,
            name: "gender",
            description: "",
            required: true,
            type: "dropdown",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            data: ["male", "female", "other"],
          },
          {
            index: 6,
            name: "full address",
            description: "Full address including postal address",
            required: true,
            type: "text",
            max: 300,
            min: 10,
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 7,
            name: "client email",
            description: "",
            required: true,
            type: "email",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 8,
            name: "client phone",
            description: "",
            required: true,
            type: "number",
            is_linked: false,
            linked_field: "",
            linked_value: "",
          },
          {
            index: 9,
            name: "ethnicity",
            description: "",
            required: true,
            type: "dropdown",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            data: [
              "asian",
              "black",
              "white",
              "hispanic",
              "latino",
              "native american",
              "indigenous",
              "mixed race",
              "other",
            ],
          },
          {
            index: 10,
            name: "race",
            description: "",
            required: true,
            type: "dropdown",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            data: [
              "asian",
              "black",
              "white",
              "hispanic",
              "latino",
              "native american",
              "indigenous",
              "mixed race",
              "other",
            ],
          },
          {
            index: 11,
            name: "disabilities",
            description:
              "Select yes if you have any disablities and fill the input next with a specific disability",
            required: false,
            type: "dropdown",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            data: ["yes", "no"],
          },
          {
            index: 12,
            name: "disabilitie(s) details",
            description: "Explain your disability",
            required: true,
            type: "textarea",
            max: 300,
            min: 10,
            is_linked: true,
            linked_field: "disabilities",
            linked_value: "yes",
          },
        ],
      },
      {
        section: "next of keen details",
        description:
          "significant other(s) or any other responsible familiy member(s)",
        index: 2,
        fields: [
          {
            index: 0,
            name: "next of keen information",
            description: "",
            required: true,
            type: "group",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            limit: 10,
            blueprint: {
              index: 0,
              fields: [
                {
                  index: 0,
                  name: "name",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 1,
                  name: "relationship",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 2,
                  name: "full address",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 3,
                  name: "phone number",
                  description: "",
                  required: true,
                  type: "number",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 4,
                  name: "email address",
                  description: "",
                  required: false,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
              ],
            },
            group: [
              {
                index: 0,
                fields: [
                  {
                    index: 0,
                    name: "name",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 1,
                    name: "relationship",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 2,
                    name: "full address",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 3,
                    name: "phone number",
                    description: "",
                    required: true,
                    type: "number",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 4,
                    name: "email address",
                    description: "",
                    required: false,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        section: "commission information",
        description: "More specific details about the client",
        index: 3,
        fields: [
          {
            index: 0,
            name: "Previous medication",
            description: "",
            required: false,
            type: "group",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            limit: 10,
            blueprint: {
              index: 0,
              fields: [
                {
                  name: "name of medication",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "dosage",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "frequeny",
                  description: "",
                  required: true,
                  type: "number",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "routine",
                  description: "",
                  required: true,
                  type: "date",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "what is it for",
                  description: "",
                  required: true,
                  type: "textarea",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
              ],
            },
            group: [
              {
                index: 0,
                fields: [
                  {
                    name: "name of medication",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "dosage",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "frequeny",
                    description: "",
                    required: true,
                    type: "number",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "routine",
                    description: "",
                    required: true,
                    type: "date",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "what is it for",
                    description: "",
                    required: true,
                    type: "textarea",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        section: "consent details",
        description: "declaration and signed documents",
        index: 4,
        fields: [
          {
            index: 0,
            name: "signatures of individuals involved",
            description: "",
            required: true,
            type: "group",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            limit: 10,
            blueprint: {
              index: 0,
              fields: [
                {
                  name: "name",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "date",
                  description: "",
                  required: true,
                  type: "date",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "role",
                  description: "",
                  required: true,
                  type: "textarea",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
              ],
            },
            group: [
              {
                index: 0,
                fields: [
                  {
                    name: "name",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "date",
                    description: "",
                    required: true,
                    type: "date",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "role",
                    description: "",
                    required: true,
                    type: "textarea",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const formsv1 = [
  {
    name: "initial assessment form",
    type: "assessment",
    description:
      "Start your healthcare platform journey with our initial assessment form. Identify potential risks from the outset, laying the foundation for a secure and reliable healthcare environment.",
    cover_images:
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F3d-casual-life-medical-history-pills.png?alt=media&token=662d443d-56a3-442c-bca4-f98a908ea5a8",
    last_edited: 1693981453065,
    form: [
      {
        section: "next of keen details",
        description:
          "significant other(s) or any other responsible familiy member(s)",
        index: 0,
        fields: [
          {
            index: 0,
            name: "next of keen information",
            description: "",
            required: true,
            type: "group",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            limit: 10,
            blueprint: {
              index: 0,
              fields: [
                {
                  index: 0,
                  name: "name",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 1,
                  name: "relationship",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 2,
                  name: "full address",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 3,
                  name: "phone number",
                  description: "",
                  required: true,
                  type: "number",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  index: 4,
                  name: "email address",
                  description: "",
                  required: false,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
              ],
            },
            group: [
              {
                index: 0,
                fields: [
                  {
                    index: 0,
                    name: "name",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 1,
                    name: "relationship",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 2,
                    name: "full address",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 3,
                    name: "phone number",
                    description: "",
                    required: true,
                    type: "number",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    index: 4,
                    name: "email address",
                    description: "",
                    required: false,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        section: "commission information",
        description: "More specific details about the client",
        index: 1,
        fields: [
          {
            index: 0,
            name: "Previous medication",
            description: "",
            required: false,
            type: "group",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            limit: 10,
            blueprint: {
              index: 0,
              fields: [
                {
                  name: "name of medication",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "dosage",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "frequeny",
                  description: "",
                  required: true,
                  type: "number",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "routine",
                  description: "",
                  required: true,
                  type: "date",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "what is it for",
                  description: "",
                  required: true,
                  type: "textarea",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
              ],
            },
            group: [
              {
                index: 0,
                fields: [
                  {
                    name: "name of medication",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "dosage",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "frequeny",
                    description: "",
                    required: true,
                    type: "number",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "routine",
                    description: "",
                    required: true,
                    type: "date",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "what is it for",
                    description: "",
                    required: true,
                    type: "textarea",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        section: "consent details",
        description: "declaration and signed documents",
        index: 2,
        fields: [
          {
            index: 0,
            name: "signatures of individuals involved",
            description: "",
            required: true,
            type: "group",
            is_linked: false,
            linked_field: "",
            linked_value: "",
            limit: 10,
            blueprint: {
              index: 0,
              fields: [
                {
                  name: "name",
                  description: "",
                  required: true,
                  type: "text",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "date",
                  description: "",
                  required: true,
                  type: "date",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
                {
                  name: "role",
                  description: "",
                  required: true,
                  type: "textarea",
                  is_linked: false,
                  linked_field: "",
                  linked_value: "",
                },
              ],
            },
            group: [
              {
                index: 0,
                fields: [
                  {
                    name: "name",
                    description: "",
                    required: true,
                    type: "text",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "date",
                    description: "",
                    required: true,
                    type: "date",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                  {
                    name: "role",
                    description: "",
                    required: true,
                    type: "textarea",
                    is_linked: false,
                    linked_field: "",
                    linked_value: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   name: "risk assessment form",
  //   type: "assessment",
  //   description: `Complete our healthcare platform's risk assessment form to proactively identify and mitigate potential
  //    risks. Ensure the safety and security of patient data and medical services with this essential evaluation tool.`,
  //   cover_images:
  //     "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2Fblack_doc.png?alt=media&token=4cf46bbf-4491-4db4-8a68-f606872f8ea9",
  //   last_edited: 1693981453065,
  //   form: [],
  // },
  // {
  //   name: "care plan form",
  //   type: "care",
  //   description:
  //     "Streamline the process of creating personalized care plans for patients with our user-friendly care plan creation form. Ensure individualized, high-quality healthcare by easily documenting patient-specific needs and goals.",
  //   cover_images:
  //     "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2Fcare_lady.png?alt=media&token=757286b9-9524-4133-9e8d-1d3d014955aa",
  //   last_edited: 1693981453065,
  //   form: [],
  // },
];

export const dataArray = [
  {
    location: "123 Main St",
    clocked_id_staff: ["John Smith", "Jane Doe"],
    expected_staff: ["Alice Brown", "David Lee", "Emily White"],
  },
  {
    location: "456 Elm St",
    clocked_id_staff: ["Sarah Wilson", "Robert Davis", "Linda Martinez"],
    expected_staff: ["James Anderson", "Susan Clark", "William Turner"],
  },
  {
    location: "789 Oak St",
    clocked_id_staff: ["Karen Hall", "Daniel Moore", "Laura Garcia"],
    expected_staff: ["Joseph Walker", "Patricia Allen", "Christopher Harris"],
  },
  {
    location: "101 Pine St",
    clocked_id_staff: ["Mark King", "Jennifer Taylor"],
    expected_staff: ["Mary Wright", "Charles Baker", "Nancy Adams"],
  },
  {
    location: "202 Maple St",
    clocked_id_staff: ["Elizabeth Parker"],
    expected_staff: ["George White", "Betty Hall", "Steven Young"],
  },
  {
    location: "303 Cedar St",
    clocked_id_staff: ["Margaret Hill", "Kevin Allen"],
    expected_staff: ["Andrew Wilson", "Helen Harris"],
  },
  {
    location: "404 Birch St",
    clocked_id_staff: ["Dorothy Martin", "Jason Moore", "Donna Garcia"],
    expected_staff: ["Sandra Thomas", "Kenneth Jackson", "Deborah Adams"],
  },
  {
    location: "505 Walnut St",
    clocked_id_staff: ["Paul Clark", "Carol Lewis", "Frank Taylor"],
    expected_staff: ["Lisa Parker"],
  },
  {
    location: "606 Spruce St",
    clocked_id_staff: ["Ronald Wilson", "Harry Smith"],
    expected_staff: ["Betty Martinez", "Timothy King", "Sharon Hall"],
  },
  {
    location: "707 Poplar St",
    clocked_id_staff: ["Michelle Turner", "Stephen Harris", "Deborah Jackson"],
    expected_staff: ["Brian Davis", "Pamela White"],
  },
];

export const demoHealthStatsWeekly = [
  {
    name: "Pulse Rate",
    weeklyData: [
      { day: "Sun", value: 72 },
      { day: "Mon", value: 75 },
      { day: "Tue", value: 74 },
      { day: "Wed", value: 76 },
      { day: "Thu", value: 73 },
      { day: "Fri", value: 70 },
      { day: "Sat", value: 71 },
    ],
  },
  {
    name: "Blood Pressure",
    weeklyData: [
      { day: "Sun", value: 120 },
      { day: "Mon", value: 122 },
      { day: "Tue", value: 118 },
      { day: "Wed", value: 124 },
      { day: "Thu", value: 121 },
      { day: "Fri", value: 119 },
      { day: "Sat", value: 123 },
    ],
  },
  {
    name: "Respiratory Rate",
    weeklyData: [
      { day: "Sun", value: 16 },
      { day: "Mon", value: 15 },
      { day: "Tue", value: 16 },
      { day: "Wed", value: 17 },
      { day: "Thu", value: 16 },
      { day: "Fri", value: 15 },
      { day: "Sat", value: 16 },
    ],
  },
  {
    name: "Body Temperature",
    weeklyData: [
      { day: "Sun", value: 37.0 },
      { day: "Mon", value: 36.9 },
      { day: "Tue", value: 37.1 },
      { day: "Wed", value: 37.2 },
      { day: "Thu", value: 36.8 },
      { day: "Fri", value: 37.0 },
      { day: "Sat", value: 36.9 },
    ],
  },
  {
    name: "Oxygen Saturation",
    weeklyData: [
      { day: "Sun", value: 98 },
      { day: "Mon", value: 97 },
      { day: "Tue", value: 98 },
      { day: "Wed", value: 97 },
      { day: "Thu", value: 98 },
      { day: "Fri", value: 97 },
      { day: "Sat", value: 98 },
    ],
  },
  {
    name: "Body Mass Index (BMI)",
    weeklyData: [
      { day: "Sun", value: 25.5 },
      { day: "Mon", value: 25.6 },
      { day: "Tue", value: 25.4 },
      { day: "Wed", value: 25.7 },
      { day: "Thu", value: 25.3 },
      { day: "Fri", value: 25.5 },
      { day: "Sat", value: 25.6 },
    ],
  },
];

export const medicationDataHeaders = [
  "medication",
  "dose",
  "type",
  "route",
  "in stock",
];
//PRN can be type

export const medicationData = [
  {
    medication: "Morphine Sulphate",
    type: "tablets",
    units: "mg",
    dose: 60,
    route: "Oral",
    times: [8, 12, 18, 20, 24],
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Laboriosam voluptatum excepturi repellat perspiciatis beatae
    doloribus deleniti libero reiciendis, eligendi obcaecati.`,
    "Prescribed for": "Pain relief",
  },
  {
    medication: "Fexofenadine",
    type: "tablets",
    units: "mg",
    dose: 180,
    route: "Oral",
    times: [8, 12, 18, 20],
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Laboriosam voluptatum excepturi repellat perspiciatis beatae
    doloribus deleniti libero reiciendis, eligendi obcaecati.`,
    "Prescribed for": "Antihistamine",
  },
  {
    medication: "Morphine",
    type: "liquid",
    units: "ml",
    dose: 30,
    route: "Injections",
    times: [8, 12, 18, 20, 24],
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Laboriosam voluptatum excepturi repellat perspiciatis beatae
    doloribus deleniti libero reiciendis, eligendi obcaecati.`,
    "Prescribed for": "Pain relief",
  },
  {
    medication: "Midazolam",
    type: "liquid",
    units: "ml",
    dose: 10,
    route: "Injections",
    times: [8, 12, 18, 20],
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Laboriosam voluptatum excepturi repellat perspiciatis beatae
    doloribus deleniti libero reiciendis, eligendi obcaecati.`,
    "Prescribed for": "Pain relief",
  },
  {
    medication: "Hypromellose 0.3%",
    type: "liquid",
    units: "drop",
    dose: 1,
    route: "Eye drops",
    times: [8, 12, 18, 20],
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Laboriosam voluptatum excepturi repellat perspiciatis beatae
    doloribus deleniti libero reiciendis, eligendi obcaecati.`,
    "Prescribed for": "Artificial tears",
  },
  {
    medication: "Paracetamol",
    type: "tablets",
    units: "mg",
    dose: 500,
    route: "Oral",
    times: [6, 8, 10, 12, 14, 16, 18, 20],
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Laboriosam voluptatum excepturi repellat perspiciatis beatae
    doloribus deleniti libero reiciendis, eligendi obcaecati.`,
    "Prescribed for": "Pain, Fever",
  },
];

export const personal_info_summary = [
  { name: "height (cm)", value: 178 },
  { name: "weight (kg)", value: 52 },
  { name: "blood type", value: "A+" },
  { name: "race", value: "white" },
];

export const rejectionReportData = [
  {
    day: "01",
    paracetamol: 5,
    aspirin: 3,
    ibuprofen: 2,
    amoxicillin: 1,
    morphine: 0,
  },
  {
    day: "02",
    paracetamol: 4,
    aspirin: 1,
    ibuprofen: 3,
    amoxicillin: 0,
    morphine: 2,
  },
  {
    day: "03",
    paracetamol: 3,
    aspirin: 2,
    ibuprofen: 0,
    amoxicillin: 2,
    morphine: 1,
  },
  {
    day: "04",
    paracetamol: 6,
    aspirin: 2,
    ibuprofen: 1,
    amoxicillin: 0,
    morphine: 4,
  },
  {
    day: "05",
    paracetamol: 2,
    aspirin: 1,
    ibuprofen: 0,
    amoxicillin: 1,
    morphine: 1,
  },
  {
    day: "06",
    paracetamol: 3,
    aspirin: 0,
    ibuprofen: 1,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "07",
    paracetamol: 4,
    aspirin: 0,
    ibuprofen: 2,
    amoxicillin: 2,
    morphine: 1,
  },
  {
    day: "08",
    paracetamol: 2,
    aspirin: 1,
    ibuprofen: 1,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "09",
    paracetamol: 1,
    aspirin: 0,
    ibuprofen: 0,
    amoxicillin: 2,
    morphine: 1,
  },
  {
    day: "10",
    paracetamol: 3,
    aspirin: 1,
    ibuprofen: 2,
    amoxicillin: 0,
    morphine: 2,
  },
  {
    day: "11",
    paracetamol: 2,
    aspirin: 0,
    ibuprofen: 0,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "12",
    paracetamol: 4,
    aspirin: 2,
    ibuprofen: 1,
    amoxicillin: 1,
    morphine: 0,
  },
  {
    day: "13",
    paracetamol: 0,
    aspirin: 0,
    ibuprofen: 1,
    amoxicillin: 0,
    morphine: 3,
  },
  {
    day: "14",
    paracetamol: 1,
    aspirin: 2,
    ibuprofen: 0,
    amoxicillin: 0,
    morphine: 2,
  },
  {
    day: "15",
    paracetamol: 3,
    aspirin: 1,
    ibuprofen: 0,
    amoxicillin: 1,
    morphine: 0,
  },
  {
    day: "16",
    paracetamol: 0,
    aspirin: 0,
    ibuprofen: 0,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "17",
    paracetamol: 2,
    aspirin: 1,
    ibuprofen: 1,
    amoxicillin: 2,
    morphine: 0,
  },
  {
    day: "18",
    paracetamol: 0,
    aspirin: 0,
    ibuprofen: 0,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "19",
    paracetamol: 1,
    aspirin: 0,
    ibuprofen: 0,
    amoxicillin: 0,
    morphine: 1,
  },
  {
    day: "20",
    paracetamol: 0,
    aspirin: 1,
    ibuprofen: 0,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "21",
    paracetamol: 3,
    aspirin: 0,
    ibuprofen: 1,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "22",
    paracetamol: 0,
    aspirin: 0,
    ibuprofen: 0,
    amoxicillin: 3,
    morphine: 1,
  },
  {
    day: "23",
    paracetamol: 4,
    aspirin: 1,
    ibuprofen: 2,
    amoxicillin: 0,
    morphine: 1,
  },
  {
    day: "24",
    paracetamol: 1,
    aspirin: 0,
    ibuprofen: 0,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "25",
    paracetamol: 0,
    aspirin: 0,
    ibuprofen: 0,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "26",
    paracetamol: 1,
    aspirin: 0,
    ibuprofen: 1,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "27",
    paracetamol: 3,
    aspirin: 0,
    ibuprofen: 2,
    amoxicillin: 1,
    morphine: 0,
  },
  {
    day: "28",
    paracetamol: 0,
    aspirin: 1,
    ibuprofen: 0,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "29",
    paracetamol: 2,
    aspirin: 0,
    ibuprofen: 1,
    amoxicillin: 0,
    morphine: 0,
  },
  {
    day: "30",
    paracetamol: 0,
    aspirin: 0,
    ibuprofen: 0,
    amoxicillin: 3,
    morphine: 0,
  },
];

export const daily_care_plan_stats_collection = [
  {
    id: "dw2222",
    subject_name: "pulse rate",
    icon_image: "/images/stats/pulse.png",
    description: "Ensure my privacy and dignity are always maintained.",
    updates_interval: "everyday",
    last_updated: 1695974152665,
    tracked_items: [
      { name: "pulse rate", measurement_units: "bpm", calculation: "average" },
      { name: "pulse rates", measurement_units: "bpm", calculation: "total" },
    ],
    stats: [
      {
        name: "pulse rate",
        measurement_units: "bpm",
        value: 75,
        date: 1695974152665,
        note: "",
        user: { name: "user", email: "user@mail.com", uid: "wewdew" },
      },
      {
        name: "pulse rates",
        measurement_units: "bpm",
        value: 45,
        date: 1695974152665,
        note: "",
        user: { name: "user", email: "user@mail.com", uid: "wewdew" },
      },
    ],
  },
  {
    id: "0mrindfd",
    subject_name: "skin integrity",
    icon_image: "/images/stats/skin.png",
    description: "Ensure my privacy and dignity are always maintained.",
    updates_interval: "everyday",
    last_updated: 1695974152665,
    tracked_items: [
      { name: "pulse rate", measurement_units: "bpm", calculation: "sum" },
    ],
    stats: [
      {
        name: "pulse rate",
        measurement_units: "bpm",
        value: 64,
        date: 1695974152665,
        note: "",
        user: { name: "user", email: "user@mail.com", uid: "wewdew" },
      },
    ],
  },
  {
    id: "0mrindfd",
    subject_name: "skin integrity",
    icon_image: "/images/stats/skin.png",
    description: "Ensure my privacy and dignity are always maintained.",
    updates_interval: "everyday",
    last_updated: 1695974152665,
    tracked_items: [
      { name: "pulse rate", measurement_units: "bpm", calculation: "sum" },
    ],
    stats: [
      {
        name: "pulse rate",
        measurement_units: "bpm",
        value: 64,
        date: 1695974152665,
        note: "",
        user: { name: "user", email: "user@mail.com", uid: "wewdew" },
      },
    ],
  },
  {
    id: "0mrindfd",
    subject_name: "skin integrity",
    icon_image: "/images/stats/skin.png",
    description: "Ensure my privacy and dignity are always maintained.",
    updates_interval: "everyday",
    last_updated: 1695974152665,
    tracked_items: [
      { name: "pulse rate", measurement_units: "bpm", calculation: "sum" },
    ],
    stats: [
      {
        name: "pulse rate",
        measurement_units: "bpm",
        value: 64,
        date: 1695974152665,
        note: "",
        user: { name: "user", email: "user@mail.com", uid: "wewdew" },
      },
    ],
  },
];

export const ethnicities = [
  "african",
  "asian",
  "caucasian",
  "hispanic",
  "latino",
  "native american",
  "pacific islander",
  "middle eastern",
  "indigenous",
  "multiracial",
  "biracial",
  "european",
  "african american",
  "indian",
  "arab",
  "jewish",
  "afro-latino",
  "chinese",
  "japanese",
  "korean",
  "vietnamese",
  "filipino",
  "thai",
  "mexican",
  "puerto rican",
  "cuban",
  "dominican",
  "salvadoran",
  "guatemalan",
  "honduran",
  "nicaraguan",
  "costa rican",
  "panamanian",
  "colombian",
  "venezuelan",
  "peruvian",
  "ecuadorian",
  "chilean",
  "argentinian",
  "brazilian",
  "uruguayan",
  "paraguayan",
  "bolivian",
  "native hawaiian",
  "samoan",
  "tongan",
  "fijian",
  "maori",
  "inuit",
  "aleut",
  "cherokee",
  "navajo",
  "black",
  "white",
];

export const religions = [
  "christianity",
  "islam",
  "hinduism",
  "buddhism",
  "sikhism",
  "judaism",
  "bahá'í",
  "shinto",
  "taoism",
  "jainism",
  "zoroastrianism",
  "paganism",
  "baha'i",
  "confucianism",
  "rastafarianism",
  "shamanism",
  "wicca",
  "druidry",
  "unitarian universalism",
  "secular humanism",
  "agnosticism",
  "atheism",
];

export const months = [
  { month: 0, month_string: "Jan" },
  { month: 1, month_string: "Feb" },
  { month: 2, month_string: "Mar" },
  { month: 3, month_string: "Apr" },
  { month: 4, month_string: "May" },
  { month: 5, month_string: "Jun" },
  { month: 6, month_string: "Jul" },
  { month: 7, month_string: "Aug" },
  { month: 8, month_string: "Sep" },
  { month: 9, month_string: "Oct" },
  { month: 10, month_string: "Nov" },
  { month: 11, month_string: "Dec" },
];

export const years = [
  2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035,
  2036, 2037, 2038, 2039, 2040,
];

export const random_colors = [
  "#1E2A38", // Dark Blue
  "#2E3713", // Dark Olive Green
  "#3C2127", // Dark Maroon
  "#1F3A2F", // Dark Green
  "#281F2D", // Dark Purple
  "#3A2720", // Dark Brown
  "#1B3A3E", // Dark Cyan
  "#2F1E16", // Dark Chocolate
  "#2C2F39", // Dark Slate
  "#3A1D2B", // Dark Magenta
  "#302A1F", // Dark Bronze
  "#1E3A28", // Dark Teal
];

export const relationships = [
  "client",
  "myself",
  "brother",
  "sister",
  "mother",
  "father",
  "son",
  "daughter",
  "grandmother",
  "grandfather",
  "grandson",
  "granddaughter",
  "aunt",
  "uncle",
  "nephew",
  "niece",
  "cousin",
  "partner",
  "spouse",
  "fiancé",
  "fiancée",
  "girlfriend",
  "boyfriend",
  "best friend",
  "colleague",
  "roommate",
  "acquaintance",
  "guardian",
  "ward",
];

export const drugAdministrationRoutes = [
  {
    route: "oral",
    description:
      "Administered by mouth and absorbed through the gastrointestinal tract.",
    bypassMetabolism: false,
  },
  {
    route: "topical",
    description:
      "Most often topical medication means application to body surfaces such as the skin or mucous membranes",
    bypassMetabolism: false,
  },
  {
    route: "peg",
    description:
      "Administered through a percutaneous endoscopic gastrostomy tube, directly into the stomach.",
    bypassMetabolism: false,
  },
  {
    route: "NG tube",
    description:
      "Administered through a nasogastric tube, directly into the stomach or small intestine.",
    bypassMetabolism: false,
  },
  {
    route: "optic",
    description:
      "Administered through the eyes, such as eye drops or ointments, for local or systemic effects.",
    bypassMetabolism: false,
  },
  {
    route: "sublingual",
    description:
      "Placed under the tongue and absorbed directly into the bloodstream from the blood vessels under the tongue.",
    bypassMetabolism: true,
  },
  {
    route: "buccal",
    description:
      "Placed between the gum and cheek, absorbed through the buccal mucosa.",
    bypassMetabolism: true,
  },
  {
    route: "intravenous",
    description:
      "Given directly into a vein as a direct injection or infusion, with immediate absorption.",
    bypassMetabolism: true,
  },
  {
    route: "intramuscular",
    description:
      "Injected into a muscle, absorbed through the muscular tissue.",
    bypassMetabolism: false,
  },
  {
    route: "subcutaneous",
    description:
      "Injected under the skin, absorbed through the subcutaneous tissue.",
    bypassMetabolism: false,
  },
  {
    route: "inhalation",
    description:
      "Breathed into the lungs, with absorption through lung tissue.",
    bypassMetabolism: false,
  },
  {
    route: "nasal",
    description:
      "Administered through the nose, absorbed through the nasal mucosa.",
    bypassMetabolism: false,
  },
  {
    route: "rectal",
    description:
      "Inserted into the rectum, absorbed through the rectal mucosa, may bypass lower parts of the gastrointestinal metabolism.",
    bypassMetabolism: true,
  },
  {
    route: "vaginal",
    description:
      "Administered into the vagina, absorbed through the vaginal mucosa.",
    bypassMetabolism: false,
  },
  {
    route: "cutaneous",
    description:
      "Applied to the skin, for local effect or absorbed through skin tissue for systemic effect.",
    bypassMetabolism: false,
  },
  {
    route: "otic",
    description: "Administered in the ear, absorbed through the ear tissue.",
    bypassMetabolism: false,
  },
  {
    route: "ocular",
    description: "Administered in the eye, absorbed through the eye tissue.",
    bypassMetabolism: false,
  },
  {
    route: "transdermal",
    description:
      "Through a patch on the skin, absorbed through the skin tissue, providing a slow, controlled release of medication.",
    bypassMetabolism: false,
  },
];

export const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

export const permission_blue_print = [
  {
    name: "dashboard page",
    index: 0,
    description:
      "these permissions will help you control what data can be viewed on the dashboard or accessed",
    data: [
      {
        permission: "dashboard view",
        description: "which dashboard type you want the user to see",
        type: "select",
        source: ["admin", "ground staff", "office", "hr department"],
        value: "admin",
      },
    ],
  },
  {
    name: "care page",
    index: 1,
    description:
      "these permissions will help you control what data can be viewed on the care page or accessed",
    data: [
      {
        permission: "can access care page",
        description:
          "is the user allowed access the care page, disabling this will hide everything in the care page including the menu option(s)",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view assessments",
        description: "is the user allowed to see clients assessments",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view all assessments",
        description:
          "is the user allowed to see all clients assessments or the ones the dealing with (if unchecked by default the user will have access to their client's assesments",
        type: "boolean",
        value: true,
      },
      {
        permission: "can add new assessments",
        description: "is the user allowed to add assessment",
        type: "boolean",
        value: true,
      },
      {
        permission: "can edit assessments",
        description:
          "is the user allowed to edit assessment that are already addeded",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view client's care management doc/profile",
        description:
          "is the user allowed to see the sub page with care plans, medication, notes etc but you can still narrow down the permissions below",
        type: "boolean",
        value: true,
      },
      {
        permission: "can add client's care management doc/profile",
        description:
          "is the user allowed to add sub page or doc with care plans, medication, notes etc but you can still narrow down the permissions below",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view client's care summary",
        description:
          "is the user allowed to see a summary about the client care data / details",
        type: "boolean",
        value: true,
      },
      {
        permission: "can edit or update client's care objective",
        description:
          "is the user allowed to update, record, or monitor client objectives like temparature, behaviour etc",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view client's care plan(s)",
        description: "is the user allowed to see cleint's care plan(s)",
        type: "boolean",
        value: true,
      },
      {
        permission: "can add/edit client's care plan(s)",
        description:
          "is the user allowed to update or edit existing cleint's care plan(s)",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view client's medication prescriptions",
        description:
          "is the user allowed to see cleint's medication list or subscriptions",
        type: "boolean",
        value: true,
      },
      {
        permission: "can add or update client's medication prescriptions",
        description:
          "is the user allowed to add, update, or delete cleint's medication list / record or subscriptions",
        type: "boolean",
        value: true,
      },
      {
        permission: "can record client's medication administration client",
        description:
          "is the user allowed to add, update, or delete medication adminstration",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view client's medication records charts",
        description:
          "is the user allowed to view medication charts like Marcharts",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view client's complete schedule",
        description:
          "is the user allowed to view a complete schedule regardless of shifts (all scheduled users/slots), if unchecked the user will only slots where they are present",
        type: "boolean",
        value: true,
      },
      {
        permission: "can manage client's schedule",
        description:
          "is the user allowed to view a add, update, or delete schedule",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view tasks under client",
        description: "is the user allowed to view a list of client's tasks",
        type: "boolean",
        value: true,
      },
      {
        permission: "can add or update tasks under client",
        description: "is the user allowed to add or update client's tasks",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view client's incidents",
        description: "is the user allowed to view client's incidents",
        type: "boolean",
        value: true,
      },
      {
        permission: "can add / record client's incidents",
        description: "is the user allowed to add or update client's incidents",
        type: "boolean",
        value: true,
      },
      {
        permission: "can add view client's notes",
        description:
          "is the user allowed to view/see notes under client, when adding a folder you can also restrict view based on role",
        type: "boolean",
        value: true,
      },
      {
        permission: "can add notes folder(s) under a client",
        description:
          "is the user allowed to add or update client's notes folders",
        type: "boolean",
        value: true,
      },
      {
        permission: "can add notes under a client",
        description: "is the user allowed to add or update client's notes",
        type: "boolean",
        value: true,
      },
      {
        permission: "can add or update client(s) general profiles",
        description: "is the user allowed to add or update client's profile",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view client(s) general profiles",
        description:
          "is the user allowed to view client's profile, if disabled or unchecked the user will pnly have access to clients they are dealing or associated with",
        type: "boolean",
        value: true,
      },
      {
        permission: "can view rates",
        description: "is the user allowed to view client's rates",
        type: "boolean",
        value: true,
      },
      {
        permission: "can manage client(s) rates",
        description:
          "is the user allowed to add, update, or delete client's rates",
        type: "boolean",
        value: true,
      },
    ],
  },
  {
    name: "peoples page",
    index: 2,
    description:
      "these permissions will help you control what data can be viewed or accessed",
    data: [
      {
        permission: "can access people's page",
        description:
          "is the user allowed access the people page, disabling this will hide everything in the care page including the menu option(s)",
        type: "boolean",
        value: true,
      },
    ],
  },
  {
    name: "reports page",
    index: 3,
    description:
      "these permissions will help you control what data can be viewed or accessed",
    data: [
      {
        permission: "can access reports page",
        description:
          "is the user allowed access the reports page, disabling this will hide everything in the care page including the menu option(s)",
        type: "boolean",
        value: true,
      },
    ],
  },
  {
    name: "forms management page",
    index: 4,
    description:
      "these permissions will help you control who can build forms like care plans, incidents forms etc",
    data: [
      {
        permission: "can access forms management page",
        description:
          "is the user allowed access the forms management page, disabling this will hide everything in the care page including the menu option(s)",
        type: "boolean",
        value: true,
      },
    ],
  },
  {
    name: "lists page",
    index: 5,
    description:
      "these permissions will help you control who can update data list used in forms and other areas within the app",
    data: [
      {
        permission: "can access lists page",
        description:
          "is the user allowed access the lists page, disabling this will hide everything in the care page including the menu option(s)",
        type: "boolean",
        value: true,
      },
    ],
  },
  {
    name: "chat page",
    index: 6,
    description:
      "these permissions will help you control who can use the internal chat",
    data: [
      {
        permission: "can access chat page",
        description:
          "is the user allowed access the chat page, disabling this will hide everything in the care page including the menu option(s)",
        type: "boolean",
        value: true,
      },
    ],
  },
  {
    name: "settings page",
    index: 7,
    description:
      "these permissions will help you control who can use the settings",
    data: [
      {
        permission: "can access settings page",
        description:
          "is the user allowed access the settings page, disabling this will hide everything in the care page including the menu option(s)",
        type: "boolean",
        value: true,
      },
      {
        permission: "can access profile settings",
        description:
          "is the user allowed access their profile settings like updating password, name etc",
        type: "boolean",
        value: true,
      },
      {
        permission: "can access users and roles settings",
        description:
          "is the user allowed to manage users like adding new users, updating permisions and roles",
        type: "boolean",
        value: true,
      },
      {
        permission: "can access inventory settings",
        description:
          "is the user allowed inventory like adding medicines names, ppes and so on",
        type: "boolean",
        value: true,
      },
    ],
  },
];

export type permisions_v2_blueprint_type = {
  name: string;
  index: number;
  description: string;
  section: string;
  value: string | boolean;
  type: string;
  source?: string[];
};

export const permissions_v2: permisions_v2_blueprint_type[] = [
  {
    index: 0,
    name: "dashboard type",
    description: "which dashboard type you want the user to see",
    section: "dashboard",
    type: "select",
    source: ["admin", "ground staff", "client"],
    value: "admin",
  },
  {
    index: 1,
    name: "can view care page",
    description:
      "is the user allowed to access the care page and see all clients list",
    section: "care",
    type: "boolean",
    value: true,
  },
  {
    index: 2,
    name: "can manage clients",
    description: "is the user allowed to add, update, or delete clients",
    section: "care",
    type: "boolean",
    value: true,
  },
  {
    index: 2555,
    name: "allow user to manage assigned clients only",
    description:
      "is the user allowed to add, update, or delete clients assigned to them only i.e nurse",
    section: "care",
    type: "boolean",
    value: false,
  },
  {
    index: 4,
    name: "can view client's care profile",
    description: "is the user allowed see the client's profile page",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 6,
    name: "can the user view client's notes",
    description:
      "is the user allowed see the client's notes page (remember you can still restrict access to specific folders under notes)",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 7,
    name: "can the user add new notes under client's profile",
    description:
      "is the user allowed to add new notes under the client's notes",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 7777,
    name: "can the user update notes under client's profile",
    description:
      "is the user allowed to edit or delete other users notes, the user can still edit or delete their own notes",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 8,
    name: "can the user add notes folders under a client",
    description:
      "is the user allowed add, update, or delete notes folders under a client",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 9,
    name: "can the user view client's tasks",
    description: "is the user allowed to view client's tasks page",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 9994,
    name: "limit task to user shift",
    description: "Limit the tasks to the user shift only",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 10,
    name: "can the user add tasks under a client's profile",
    description:
      "is the user allowed to add, delete or edit tasks under a client",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 11,
    name: "can the user mark tasks as completed",
    description: "is the user allowed to complete tasks under a client",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 12,
    name: "can the user view client's schedule",
    description: "is the user allowed to view client's schedule page",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 13,
    name: "can the user manage client's schedule",
    description:
      "is the user allowed to add, update, assign employees under client's schedule page",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 14,
    name: "can the user view client's care plans",
    description: "is the user allowed view client's care plan page",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 15,
    name: "can the user add new client's care plan",
    description: "is the user allowed to add a new care plan",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 16,
    name: "can the user edit or delete client's care plan",
    description: "is the user allowed to edit or deleting existing care plan",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 17,
    name: "can the user view client's medication",
    description: "is the user allowed to view medication under a client",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 18,
    name: "can the user add or edit client's medication",
    description: "is the user allowed to add or edit medication under a client",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 188,
    name: "can the user add medication stock",
    description: "is the user allowed to add medication stock under a client",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 19,
    name: "can the user view client's daily charts",
    description: "is the user allowed to view client's daily charts",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 20,
    name: "can the user add new logs on daily charts",
    description:
      "is the user allowed to add new records or logs to the daily charts",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 21,
    name: "can the user view body maps",
    description: "is the user allowed to view client's body maps",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 22,
    name: "can the user manage new body map",
    description:
      "is the user allowed to add , delete or edit client's body maps",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 23,
    name: "can the user view incidetns",
    description: "is the user allowed to view client's incidetns",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 24,
    name: "can the user manage new incident",
    description:
      "is the user allowed to add , delete or edit client's incidetns",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 25,
    name: "can the user view assessments",
    description: "is the user allowed to view client's assessments",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 26,
    name: "can the user manage new assessment",
    description:
      "is the user allowed to add , delete or edit client's assessments",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 46,
    name: "can the user view complaints",
    description: "is the user view client's complaints",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 4034,
    name: "can the user manage complaints",
    description: "is the user manage client's complaints",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 4032929294,
    name: "can the user view client inventory",
    description: "is the user allowed to view inventory",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  {
    index: 4032929254,
    name: "can the user manage client inventory",
    description: "is the user allowed to manage inventory",
    section: "care profile",
    type: "boolean",
    value: true,
  },
  // {
  //   index: 56,
  //   name: "can the user manage ratings",
  //   description: "is the user add, delete, or edit client's ratings",
  //   section: "care profile",
  //   type: "boolean",
  //   value: true,
  // },
  {
    index: 27,
    name: "can the user view employees",
    description:
      "is the user allowed view employees, if unchecked the user will only see themselves",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 28,
    name: "can the user manage employees",
    description:
      "is the user allowed add, delete, edit status, request updates or approve employees",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 900,
    name: "can the user manage employees right to work",
    description:
      "is the user allowed add, delete, updates employees right to work",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 901,
    name: "can the user manage employees DBS details",
    description:
      "is the user allowed add, delete, updates employees DBS details",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 902,
    name: "can the user manage employees references",
    description:
      "is the user allowed add, delete, updates employees references",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 911,
    name: "can the user manage employees NMC details",
    description:
      "is the user allowed add, delete, updates employees NMC details",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 903,
    name: "can the user manage employees health questionnaire",
    description:
      "is the user allowed add, delete, updates employees health questionnaire",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 904,
    name: "can the user manage employees contracts",
    description: "is the user allowed add, delete, updates employees contracts",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 905,
    name: "can the user manage employees disciplicary",
    description:
      "is the user allowed add, delete, updates employees disciplicary",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 906,
    name: "can the user manage employees competencies",
    description:
      "is the user allowed add, delete, updates employees competencies",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 906,
    name: "can the user manage employees training",
    description: "is the user allowed add, delete, updates employees training",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 9077,
    name: "can the user view employees performances",
    description:
      "is the user allowed to view employees performances, if disabled the user will only see themselves",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 907,
    name: "can the user manage employees performances",
    description:
      "is the user allowed add, delete, updates employees performances",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 29,
    name: "can the user view employees leave",
    description:
      "is the user allowed view leaves, if unchecked the user will only see themselves under their profile",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 30,
    name: "can the user manage employees leave",
    description: "is the user allowed manage (approve, decline, delete) leaves",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 31,
    name: "can the user view timesheet",
    description: "is the user allowed to see all employees timesheet",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 32,
    name: "can the user view payroll",
    description: "is the user allowed to see all employees payroll",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 92,
    name: "can the user access expenses",
    description: "is the user allowed to view and manage expenses",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 9992,
    name: "limit user access to their own expenses",
    description: "allow the use to only have access their own expenses",
    section: "people",
    type: "boolean",
    value: true,
  },
  {
    index: 33,
    name: "can the user view reports",
    description: "is the user allowed to see all reports",
    section: "reports",
    type: "boolean",
    value: true,
  },
  {
    index: 34,
    name: "can the user view and manage forms",
    description: "is the user allowed to view and manage forms",
    section: "forms",
    type: "boolean",
    value: true,
  },
  {
    index: 35,
    name: "can the user view and manage lists",
    description: "is the user allowed to view and manage lists",
    section: "lists",
    type: "boolean",
    value: true,
  },
  {
    index: 36,
    name: "can the user view and manage users auth accounts",
    description: "is the user allowed to view and manage users auth accounts",
    section: "settings",
    type: "boolean",
    value: true,
  },
  {
    index: 37,
    name: "can the user view and manage roles and permissions",
    description: "is the user allowed to view and manage roles and permissions",
    section: "settings",
    type: "boolean",
    value: true,
  },
  {
    index: 38,
    name: "can the user view and manage inventory list",
    description: "is the user allowed to view and manage inventory list",
    section: "settings",
    type: "boolean",
    value: true,
  },
  {
    index: 453132,
    name: "can the user view wiki docs",
    description: "is the user allowed to view wiki docs",
    section: "wiki",
    type: "boolean",
    value: true,
  },
  {
    index: 453232,
    name: "can the user add new wiki docs",
    description: "is the user allowed to add or delete wiki docs",
    section: "wiki",
    type: "boolean",
    value: true,
  },
  {
    index: 4532932,
    name: "can the user view and manage care requests",
    description: "is the user allowed to add or edit, delete care requests",
    section: "scheduler",
    type: "boolean",
    value: true,
  },
  {
    index: 45930902,
    name: "can the user view and manage auto scheduler",
    description: "is the user allowed to add or edit, delete care auto scheduler",
    section: "scheduler",
    type: "boolean",
    value: false,
  },
];

export const test_notes = [
  {
    folder: "daily notes",
    color: "#FFDDC1", // Example color code for "daily notes" folder
    note: "Administered prescribed medication to Patient 101",
    date: 1699265476293, // represents a specific date and time in milliseconds
    user: {
      username: "carer one",
      email: "carer one@healthcare.com",
      uid: "cg-001",
    },
  },
  {
    folder: "observations",
    color: "#C1E1FF", // Example color code for "observations" folder
    note: "Checked vital signs for Patient 202, all stable",
    date: 1699265529721, // represents a specific date and time in milliseconds
    user: {
      username: "staff two",
      email: "staff two@healthcare.com",
      uid: "cg-002",
    },
  },
  {
    folder: "daily notes",
    color: "#FFDDC1", // Reusing the same color code for "daily notes" folder
    note: "Assisted Patient 303 with physical therapy exercises",
    date: 1699265529721, // represents a specific date and time in milliseconds
    user: {
      username: "carer three",
      email: "carer three@healthcare.com",
      uid: "cg-003",
    },
  },
  {
    folder: "remarks",
    color: "#D1FFC1", // Example color code for "remarks" folder
    note: "Patient 404 expressed discomfort, adjusted bed and provided comfort",
    date: 1699265524721, // represents a specific date and time in milliseconds
    user: {
      username: "carer four",
      email: "carer four@healthcare.com",
      uid: "cg-004",
    },
  },
  {
    folder: "remarks",
    color: "#D1FFC1", // Example color code for "remarks" folder
    note: "Patient 404 expressed discomfort, adjusted bed and provided comfort",
    date: 1699265524721, // represents a specific date and time in milliseconds
    user: {
      username: "carer four",
      email: "carer four@healthcare.com",
      uid: "cg-004",
    },
  },
  // ... more notes
];

export const test_tasks = [
  {
    task: "Review patient records",
    due_hour: "09:00", // 9 AM
  },
  {
    task: "Assist with patient admissions",
    due_hour: "10:00", // 10 AM
  },
  {
    task: "Prepare medication chart",
    due_hour: "12:00", // 12 PM Noon
  },
  {
    task: "Attend healthcare compliance webinar",
    due_hour: "13:00", // 1 PM
  },
  {
    task: "Conduct patient follow-up calls",
    due_hour: "14:00", // 2 PM
  },
  {
    task: "Team meeting about patient care strategies",
    due_hour: "15:00", // 3 PM
  },
  {
    task: "Prepare next day's medical procedure schedule",
    due_hour: "16:00", // 4 PM
  },
  {
    task: "Check inventory of medical supplies",
    due_hour: "17:00", // 5 PM
  },
  {
    task: "Update patient status reports",
    due_hour: "20:00", // 8 PM
  },
  {
    task: "Administer evening medication",
    due_hour: "21:00", // 9 PM
  },
  {
    task: "Organize patient appointments for the next day",
    due_hour: "18:00", // 6 PM
  },
  {
    task: "Disinfect common areas",
    due_hour: "22:00", // 10 PM
  },
  {
    task: "Finish documentation of day's activities",
    due_hour: "23:00", // 11 PM
  },
  // ... more tasks
];

export const testSummary = `
## **Medication Schedule**
- **Paracetamol**: 2 tablets at 9 AM
- **Aspirin**: 2 tablets to ease pain as required

## Observations and Recommendations
- **Temperature and Mood**: Pay close attention to Debby’s temperature and mood throughout the day. This is based on remarks from Dr. Sands during yesterday's consultation, noting a slight resistance from Debby.
- **Fluid Intake**: Ensure Debby stays well-hydrated. Encourage her to drink water regularly.

## Tasks and Reminders
- **Change Blanket**: The blanket was last changed on October 15th. Ensure to change it today to maintain hygiene and comfort.

## Notes from Dr. Sands (Yesterday's Closing Remarks)
- Keep a vigilant eye on Debby’s overall well-being, particularly her temperature, mood, and fluid intake. She showed a bit of resistance, so it might be helpful to approach her in a gentle and reassuring manner.
- Encourage Debby to communicate her feelings and discomforts, if any, so that appropriate adjustments can be made to her care plan.
`;

export const new_client_obj = {
  profile: "",
  title: "",
  "first name": "",
  "last name": "",
  "preferred name": "",
  "date of birth": "",
  ethnicity: "",
  religion: "",
  gender: "",
  about: "",
  "NHS number": "",
  "GP details": "",
  full_address: "",
  status: "active",
  "weekly allocated hours": 0,
  "primary contact": "",
  "emergency contact": "",
  "key contact name": "",
  "key contact relationship": "",
  "key contact phone number": "",
  competencies: "",
  email: "",
  role: "client",
  createdAt: Date.now(),
  "assigned nurse": "",
  "preferred visitors gender": "",
  "email for alerts": "",
  "daily charts": [],
  "peg feeding instructions": "",
  last_updated: {
    date: Date.now(),
    uid: auth?.currentUser?.uid,
    email: auth?.currentUser?.email,
    name: auth?.currentUser?.displayName,
  },
};

// Clear Datd based on linked values
export const clear_data_on_linked = (
  steps: any,
  field: any,
  setValues: (item: any) => void
) => {
  //Fetch All related fields
  const relatedFields = [...steps]
    ?.find((section: any) =>
      section?.fields?.some((fld: any) => fld?.name === field?.name)
    )
    ?.fields?.filter(
      (fld: any) =>
        field?.index < fld?.index && fld?.["clear data based on linked field"]
    );
  console.log(relatedFields?.length);

  //Loop through every linked value and clear the valuer
  if (relatedFields) {
    for (const item of relatedFields) {
      setValues((prev: any) => ({
        ...prev,
        [item?.name]: item?.type === "group" ? null : "",
      }));
    }
  }
};

export const demo_shedule: any = [
  {
    id: "1",
    client_uid: "c-123",
    title: "Morning Care",
    description: "Assisting with morning routine",
    createdAt: 1700989200000, // Nov 2023
    updatedAt: 1700989200000, // Nov 2023
    updated_by: {
      username: "johndoe",
      uid: "u-123",
      email: "johndoe@example.com",
    },
    created_by: {
      username: "janedoe",
      uid: "u-456",
      email: "janedoe@example.com",
    },
    start_date: 1700989200000, // 8 AM, Nov 2023
    end_date: 1701000000000, // 11 AM, Nov 2023
    month_year: "nov 2023",
    duration: 10800,
    break: 900,
    color: "blue",
    assigned_to: {
      uid: "c-789",
      email: "carer one@example.com",
      username: "carer one",
      rate_per_hour: 30,
    },
    status: "pending",
  },
  {
    id: "2",
    client_uid: "c-456",
    title: "Afternoon Walk",
    description: "Daily afternoon walk in the park",
    createdAt: 1669429200000,
    updatedAt: 1669515600000,
    updated_by: {
      username: "marksmith",
      uid: "u-789",
      email: "marksmith@example.com",
    },
    created_by: {
      username: "lucyjones",
      uid: "u-012",
      email: "lucyjones@example.com",
    },
    start_date: 1701010800000, // 2 PM, Nov 2023
    end_date: 1701018000000, // 4 PM, Nov 2023
    month_year: "nov 2023",
    duration: 7200,
    break: 600,
    color: "green",
    assigned_to: {
      uid: "c-101",
      email: "staff two@example.com",
      username: "staff two",
      rate_per_hour: 28,
    },
    status: "clocked_in",
  },
  {
    id: "2",
    client_uid: "c-456",
    title: "Afternoon Walk",
    description: "Daily afternoon walk in the park",
    createdAt: 1669429200000,
    updatedAt: 1669515600000,
    updated_by: {
      username: "marksmith",
      uid: "u-789",
      email: "marksmith@example.com",
    },
    created_by: {
      username: "lucyjones",
      uid: "u-012",
      email: "lucyjones@example.com",
    },
    start_date: 1701090000000, // 12 AM, Nov 2023
    end_date: 1701115200000, // 9 AM, Nov 2023
    month_year: "nov 2023",
    duration: 7200 * 7,
    break: 600,
    color: "green",
    assigned_to: {
      uid: "c-101",
      email: "staff two@example.com",
      username: "staff two",
      rate_per_hour: 28,
    },
    status: "pending",
  },
  {
    id: "3",
    client_uid: "c-789",
    title: "Evening Medication",
    description: "Administering evening medication",
    createdAt: 1669429200000,
    updatedAt: 1669515600000,
    updated_by: {
      username: "emilywhite",
      uid: "u-345",
      email: "emilywhite@example.com",
    },
    created_by: {
      username: "davidsmith",
      uid: "u-678",
      email: "davidsmith@example.com",
    },
    start_date: 1701032400000, // 6 PM, Nov 2023
    end_date: 1700949600000, // 7 PM, Nov 2023
    month_year: "nov 2023",
    duration: 3600,
    break: 300,
    color: "red",
    assigned_to: {
      uid: "c-202",
      email: "carer three@example.com",
      username: "carer three",
      rate_per_hour: 32,
    },
    status: "clocked_out",
  },
  {
    id: "4",
    client_uid: "c-012",
    title: "Physical Therapy",
    description: "Guided physical therapy session",
    createdAt: 1669429200000,
    updatedAt: 1669515600000,
    updated_by: {
      username: "sarahbrown",
      uid: "u-901",
      email: "sarahbrown@example.com",
    },
    created_by: {
      username: "michaelgreen",
      uid: "u-234",
      email: "michaelgreen@example.com",
    },
    start_date: 1701068400000, // 8 PM, Nov 2023
    end_date: 1701090000000, // 10 PM, Nov 2023
    month_year: "nov 2023",
    duration: 7200,
    break: 600,
    color: "purple",
    assigned_to: {
      uid: "c-303",
      email: "carer four@example.com",
      username: "carer four",
      rate_per_hour: 25,
    },
    status: "swapped",
  },
  {
    id: "5",
    client_uid: "c-234",
    title: "Overnight Monitoring",
    description: "Monitoring patient throughout the night",
    createdAt: 1669429200000,
    updatedAt: 1669515600000,
    updated_by: {
      username: "danielgrey",
      uid: "u-567",
      email: "danielgrey@example.com",
    },
    created_by: {
      username: "amandawhite",
      uid: "u-890",
      email: "amandawhite@example.com",
    },
    start_date: 1701090000000, // 12 AM, Nov 2023
    end_date: 1701115200000, // 9 AM, Nov 2023
    month_year: "nov 2023",
    duration: 39600,
    break: 1800,
    color: "yellow",
    assigned_to: {
      uid: "c-404",
      email: "staff five@example.com",
      username: "staff five",
      rate_per_hour: 27,
    },
    status: "missed",
  },
];

export const mock_schedule_stats = [
  { name: "Total Assignees", value: 13, unit: "" },
  { name: "Average Shift Duration", value: 5.75, unit: "hours" },
  { name: "Hours Worked", value: 74.75, unit: "hours" },
  { name: "Estimated Payout", value: 1286.6, unit: "GBP" },
  { name: "Missed Shifts", value: 3, unit: "" },
];

export const peoples_page = [
  {
    name: "right to work",
    description:
      "verify employee eligibility and legal right to work includes legal documents",
    icon: <RiPassValidFill className="text-xl text-zinc-800" />,
    is_trackable: true,
    is_checked: true,
  },
  {
    name: "dbs report",
    description: "manage disclosure and barring service checks",
    icon: <BsPersonFillCheck className="text-lg text-zinc-800" />,
    is_trackable: true,
    is_checked: true,
  },
  {
    name: "references",
    description: "manage and review employee reference information",
    is_trackable: true,
    is_checked: false,
  },
  {
    name: "nmc clearance",
    description: "nursing and midwifery council registration and standards",
    icon: <FaUserNurse className="text-lg text-zinc-800" />,
    is_trackable: true,
    is_checked: false,
  },
  // {
  //   name: "health questionnaire",
  //   description: "collect and manage employee health information",
  //   icon: <RiQuestionnaireFill className="text-lg text-zinc-800" />,
  //   is_trackable: true,
  //   is_checked: false,
  // },
  {
    name: "contracts",
    description: "manage employee contract details and documents",
    icon: <FaFileContract className="text-lg text-zinc-800" />,
    is_trackable: true,
    is_checked: false,
  },
  {
    name: "disciplinary",
    description: "record and manage disciplinary actions and procedures",
    icon: <FaFileContract className="text-lg text-zinc-800" />,
    is_trackable: false,
    is_checked: false,
  },
  {
    name: "competencies",
    description: "manage employee competecnies and skills",
    icon: <FaFileContract className="text-lg text-zinc-800" />,
    is_trackable: false,
    is_checked: false,
  },
];

export const demo_employees = [
  {
    "last name": "Smith",
    "first name": "John",
    profile_image: `https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2940&auto=format&fit=
      crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
    start_date: 1702483478245,
    "what was your conviction ?": "",
    gender: "male",
    "date of birth": "2023-12-16",
    "home address": "98 leo street brachenhurst 1490",
    ethnicity: "black",
    "are you disabled": "no",
    "do you have any criminal record ?": "no",
    email: "sands@mail.com",
    "mobile number": "0032323232",
    "alternative number": "0032323232",
    "emergency contacts": [
      {
        index: 0,
        values: [
          {
            name: "full name",
            value: "Sands",
          },
          {
            name: "relationship",
            value: "brother",
          },
          {
            name: "contact number",
            value: "0032323232",
          },
          {
            name: "alternative contact number",
            value: "0032323232",
          },
        ],
      },
      {
        index: 1,
        values: [
          {
            name: "full name",
            value: "Jack Sister",
          },
          {
            name: "relationship",
            value: "sister",
          },
          {
            name: "contact number",
            value: "0032323232",
          },
          {
            name: "alternative contact number",
            value: "0032323232",
          },
        ],
      },
    ],
    "are you a british national": "yes",
    "do you have a driver's license": "yes",
    "id copy":
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F1702483655999_Image%202023-12-01%20at%206.34%E2%80%AFAM.jpg?alt=media&token=3ef6471f-3439-45f8-a663-f7120714e35c",
    "do you have a car": "yes",
    "driver's license copy":
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F1702483661962_Pasted%20Graphic.png?alt=media&token=5c38d022-931f-4d79-9fc7-88567437e6b5",
    "employment history": [
      {
        index: 0,
        values: [
          {
            name: "organisation name",
            value: "Company One",
          },
          {
            name: "position held",
            value: "Nurse",
          },
          {
            name: "start date",
            value: "2023-12-15",
          },
          {
            name: "end date",
            value: "2023-12-29",
          },
          {
            name: "key responsibilities",
            value: [
              "Add some cashback. When in Japan? Spend i",
              "Cashback. When in Japan? Spend i",
              "Some cashback. When in Japan? Spend i",
            ],
          },
        ],
      },
    ],
    education: [
      {
        index: 0,
        values: [
          {
            name: "type",
            value: "university",
          },
          {
            name: "institution name",
            value: "wsu",
          },
          {
            name: "date obtained",
            value: "2023-12-20",
          },
          {
            name: "qualification obtained",
            value: "bcs in something",
          },
        ],
      },
      {
        index: 1,
        values: [
          {
            name: "type",
            value: "college",
          },
          {
            name: "institution name",
            value: "Quick Bootcamp",
          },
          {
            name: "qualification obtained",
            value: "Degree One",
          },
          {
            name: "date obtained",
            value: "2023-12-27",
          },
        ],
      },
    ],
    reference: [
      {
        index: 0,
        values: [
          {
            name: "fullname",
            value: "Sands",
          },
          {
            name: "company name",
            value: "NP Gabela",
          },
          {
            name: "referees position",
            value: "Mnager",
          },
          {
            name: "work email",
            value: "their@mail.com",
          },
          {
            name: "telephone number",
            value: "00343040033",
          },
        ],
      },
    ],
    training: [
      { name: "NVQ 2", completed: true, date: 1582604400000 },
      { name: "Manual Handling", completed: true, date: 1598918400000 },
      { name: "Basic Life Support", completed: true, date: 1601510400000 },
      { name: "SOVA", completed: true, date: 1612137600000 },
      { name: "Infection Control", completed: true, date: 1614816000000 },
      { name: "Person Centered Care/Dementia", completed: false, date: null },
      { name: "Basic Food Hygiene", completed: false, date: null },
      // ... other trainings
    ],
    status: "approved",
  },
  {
    "last name": "Doe",
    "first name": "Jane",
    profile_image: `https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=2864&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
    start_date: 1702483478245,
    "what was your conviction ?": "",
    gender: "male",
    "date of birth": "2023-12-16",
    "home address": "98 leo street brachenhurst 1490",
    ethnicity: "black",
    "are you disabled": "no",
    "do you have any criminal record ?": "no",
    email: "sands@mail.com",
    "mobile number": "0032323232",
    "alternative number": "0032323232",
    "emergency contacts": [
      {
        index: 0,
        values: [
          {
            name: "full name",
            value: "Sands",
          },
          {
            name: "relationship",
            value: "brother",
          },
          {
            name: "contact number",
            value: "0032323232",
          },
          {
            name: "alternative contact number",
            value: "0032323232",
          },
        ],
      },
      {
        index: 1,
        values: [
          {
            name: "full name",
            value: "Jack Sister",
          },
          {
            name: "relationship",
            value: "sister",
          },
          {
            name: "contact number",
            value: "0032323232",
          },
          {
            name: "alternative contact number",
            value: "0032323232",
          },
        ],
      },
    ],
    "are you a british national": "yes",
    "do you have a driver's license": "yes",
    "id copy":
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F1702483655999_Image%202023-12-01%20at%206.34%E2%80%AFAM.jpg?alt=media&token=3ef6471f-3439-45f8-a663-f7120714e35c",
    "do you have a car": "yes",
    "driver's license copy":
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F1702483661962_Pasted%20Graphic.png?alt=media&token=5c38d022-931f-4d79-9fc7-88567437e6b5",
    "employment history": [
      {
        index: 0,
        values: [
          {
            name: "organisation name",
            value: "Company One",
          },
          {
            name: "position held",
            value: "Nurse",
          },
          {
            name: "start date",
            value: "2023-12-15",
          },
          {
            name: "end date",
            value: "2023-12-29",
          },
          {
            name: "key responsibilities",
            value: [
              "Add some cashback. When in Japan? Spend i",
              "Cashback. When in Japan? Spend i",
              "Some cashback. When in Japan? Spend i",
            ],
          },
        ],
      },
    ],
    education: [
      {
        index: 0,
        values: [
          {
            name: "type",
            value: "university",
          },
          {
            name: "institution name",
            value: "wsu",
          },
          {
            name: "date obtained",
            value: "2023-12-20",
          },
          {
            name: "qualification obtained",
            value: "bcs in something",
          },
        ],
      },
      {
        index: 1,
        values: [
          {
            name: "type",
            value: "college",
          },
          {
            name: "institution name",
            value: "Quick Bootcamp",
          },
          {
            name: "qualification obtained",
            value: "Degree One",
          },
          {
            name: "date obtained",
            value: "2023-12-27",
          },
        ],
      },
    ],
    reference: [
      {
        index: 0,
        values: [
          {
            name: "fullname",
            value: "Sands",
          },
          {
            name: "company name",
            value: "NP Gabela",
          },
          {
            name: "referees position",
            value: "Mnager",
          },
          {
            name: "work email",
            value: "their@mail.com",
          },
          {
            name: "telephone number",
            value: "00343040033",
          },
        ],
      },
    ],
    training: [
      { name: "NVQ 3", completed: true, date: 1577836800000 },
      {
        name: "Health and Safety (Inc COSHH)",
        completed: true,
        date: 1580515200000,
      },
      { name: "Fire Safety Awareness", completed: true, date: 1583020800000 },
      { name: "Medication", completed: true, date: 1585699200000 },
      {
        name: "Mental Capacity ACT/DOLS",
        completed: true,
        date: 1588291200000,
      },
      { name: "Restraint Training", completed: false, date: null },
      { name: "Other", completed: false, date: null },
      // ... other trainings
    ],
    status: "pending",
  },
  {
    "last name": "Brown",
    "first name": "Michael",
    profile_image: `https://images.unsplash.com/photo-1531123414780-f74242c2b052?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
    start_date: 1702483478245,
    "what was your conviction ?": "",
    gender: "male",
    "date of birth": "2023-12-16",
    "home address": "98 leo street brachenhurst 1490",
    ethnicity: "black",
    "are you disabled": "no",
    "do you have any criminal record ?": "no",
    email: "sands@mail.com",
    "mobile number": "0032323232",
    "alternative number": "0032323232",
    "emergency contacts": [
      {
        index: 0,
        values: [
          {
            name: "full name",
            value: "Sands",
          },
          {
            name: "relationship",
            value: "brother",
          },
          {
            name: "contact number",
            value: "0032323232",
          },
          {
            name: "alternative contact number",
            value: "0032323232",
          },
        ],
      },
      {
        index: 1,
        values: [
          {
            name: "full name",
            value: "Jack Sister",
          },
          {
            name: "relationship",
            value: "sister",
          },
          {
            name: "contact number",
            value: "0032323232",
          },
          {
            name: "alternative contact number",
            value: "0032323232",
          },
        ],
      },
    ],
    "are you a british national": "yes",
    "do you have a driver's license": "yes",
    "id copy":
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F1702483655999_Image%202023-12-01%20at%206.34%E2%80%AFAM.jpg?alt=media&token=3ef6471f-3439-45f8-a663-f7120714e35c",
    "do you have a car": "yes",
    "driver's license copy":
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F1702483661962_Pasted%20Graphic.png?alt=media&token=5c38d022-931f-4d79-9fc7-88567437e6b5",
    "employment history": [
      {
        index: 0,
        values: [
          {
            name: "organisation name",
            value: "Company One",
          },
          {
            name: "position held",
            value: "Nurse",
          },
          {
            name: "start date",
            value: "2023-12-15",
          },
          {
            name: "end date",
            value: "2023-12-29",
          },
          {
            name: "key responsibilities",
            value: [
              "Add some cashback. When in Japan? Spend i",
              "Cashback. When in Japan? Spend i",
              "Some cashback. When in Japan? Spend i",
            ],
          },
        ],
      },
    ],
    education: [
      {
        index: 0,
        values: [
          {
            name: "type",
            value: "university",
          },
          {
            name: "institution name",
            value: "wsu",
          },
          {
            name: "date obtained",
            value: "2023-12-20",
          },
          {
            name: "qualification obtained",
            value: "bcs in something",
          },
        ],
      },
      {
        index: 1,
        values: [
          {
            name: "type",
            value: "college",
          },
          {
            name: "institution name",
            value: "Quick Bootcamp",
          },
          {
            name: "qualification obtained",
            value: "Degree One",
          },
          {
            name: "date obtained",
            value: "2023-12-27",
          },
        ],
      },
    ],
    reference: [
      {
        index: 0,
        values: [
          {
            name: "fullname",
            value: "Sands",
          },
          {
            name: "company name",
            value: "NP Gabela",
          },
          {
            name: "referees position",
            value: "Mnager",
          },
          {
            name: "work email",
            value: "their@mail.com",
          },
          {
            name: "telephone number",
            value: "00343040033",
          },
        ],
      },
    ],
    training: [
      { name: "Manual Handling", completed: false, date: null },
      {
        name: "Health and Safety (Inc COSHH)",
        completed: true,
        date: 1593561600000,
      },
      { name: "Fire Safety Awareness", completed: false, date: null },
      { name: "Basic Life Support", completed: true, date: 1596240000000 },
      { name: "SOVA", completed: false, date: null },
      { name: "Infection Control", completed: true, date: 1598918400000 },
      { name: "Person Centered Care/Dementia", completed: false, date: null },
      // ... other trainings
    ],
    status: "pending",
  },
  {
    "last name": "employee",
    "first name": "one",
    profile_image: `https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2940&auto=format&fit=
    crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
    start_date: 1702483478245,
    "what was your conviction ?": "",
    gender: "male",
    "date of birth": "2023-12-16",
    "home address": "98 leo street brachenhurst 1490",
    ethnicity: "black",
    "are you disabled": "no",
    "do you have any criminal record ?": "no",
    email: "sands@mail.com",
    "mobile number": "0032323232",
    "alternative number": "0032323232",
    "emergency contacts": [
      {
        index: 0,
        values: [
          {
            name: "full name",
            value: "Sands",
          },
          {
            name: "relationship",
            value: "brother",
          },
          {
            name: "contact number",
            value: "0032323232",
          },
          {
            name: "alternative contact number",
            value: "0032323232",
          },
        ],
      },
      {
        index: 1,
        values: [
          {
            name: "full name",
            value: "Jack Sister",
          },
          {
            name: "relationship",
            value: "sister",
          },
          {
            name: "contact number",
            value: "0032323232",
          },
          {
            name: "alternative contact number",
            value: "0032323232",
          },
        ],
      },
    ],
    "are you a british national": "yes",
    "do you have a driver's license": "yes",
    "id copy":
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F1702483655999_Image%202023-12-01%20at%206.34%E2%80%AFAM.jpg?alt=media&token=3ef6471f-3439-45f8-a663-f7120714e35c",
    "do you have a car": "yes",
    "driver's license copy":
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F1702483661962_Pasted%20Graphic.png?alt=media&token=5c38d022-931f-4d79-9fc7-88567437e6b5",
    "employment history": [
      {
        index: 0,
        values: [
          {
            name: "organisation name",
            value: "Company One",
          },
          {
            name: "position held",
            value: "Nurse",
          },
          {
            name: "start date",
            value: "2023-12-15",
          },
          {
            name: "end date",
            value: "2023-12-29",
          },
          {
            name: "key responsibilities",
            value: [
              "Add some cashback. When in Japan? Spend i",
              "Cashback. When in Japan? Spend i",
              "Some cashback. When in Japan? Spend i",
            ],
          },
        ],
      },
    ],
    education: [
      {
        index: 0,
        values: [
          {
            name: "type",
            value: "university",
          },
          {
            name: "institution name",
            value: "wsu",
          },
          {
            name: "date obtained",
            value: "2023-12-20",
          },
          {
            name: "qualification obtained",
            value: "bcs in something",
          },
        ],
      },
      {
        index: 1,
        values: [
          {
            name: "type",
            value: "college",
          },
          {
            name: "institution name",
            value: "Quick Bootcamp",
          },
          {
            name: "qualification obtained",
            value: "Degree One",
          },
          {
            name: "date obtained",
            value: "2023-12-27",
          },
        ],
      },
    ],
    reference: [
      {
        index: 0,
        values: [
          {
            name: "fullname",
            value: "Sands",
          },
          {
            name: "company name",
            value: "NP Gabela",
          },
          {
            name: "referees position",
            value: "Mnager",
          },
          {
            name: "work email",
            value: "their@mail.com",
          },
          {
            name: "telephone number",
            value: "00343040033",
          },
        ],
      },
    ],
    training: [
      { name: "Manual Handling", completed: false, date: null },
      {
        name: "Health and Safety (Inc COSHH)",
        completed: true,
        date: 1593561600000,
      },
      { name: "Fire Safety Awareness", completed: false, date: null },
      { name: "Basic Life Support", completed: true, date: 1596240000000 },
      { name: "SOVA", completed: false, date: null },
      { name: "Infection Control", completed: true, date: 1598918400000 },
      { name: "Person Centered Care/Dementia", completed: false, date: null },
      // ... other trainings
    ],
    status: "approved",
  },
  {
    "last name": "employee",
    "first name": "two",
    profile_image: `https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
    address: "789 Pine Road, Smalltown",
    start_date: 1702483478245,
    "what was your conviction ?": "",
    gender: "male",
    "date of birth": "2023-12-16",
    "home address": "98 leo street brachenhurst 1490",
    ethnicity: "black",
    "are you disabled": "no",
    "do you have any criminal record ?": "no",
    email: "sands@mail.com",
    "mobile number": "0032323232",
    "alternative number": "0032323232",
    "emergency contacts": [
      {
        index: 0,
        values: [
          {
            name: "full name",
            value: "Sands",
          },
          {
            name: "relationship",
            value: "brother",
          },
          {
            name: "contact number",
            value: "0032323232",
          },
          {
            name: "alternative contact number",
            value: "0032323232",
          },
        ],
      },
      {
        index: 1,
        values: [
          {
            name: "full name",
            value: "Jack Sister",
          },
          {
            name: "relationship",
            value: "sister",
          },
          {
            name: "contact number",
            value: "0032323232",
          },
          {
            name: "alternative contact number",
            value: "0032323232",
          },
        ],
      },
    ],
    "are you a british national": "yes",
    "do you have a driver's license": "yes",
    "id copy":
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F1702483655999_Image%202023-12-01%20at%206.34%E2%80%AFAM.jpg?alt=media&token=3ef6471f-3439-45f8-a663-f7120714e35c",
    "do you have a car": "yes",
    "driver's license copy":
      "https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/uploads%2F1702483661962_Pasted%20Graphic.png?alt=media&token=5c38d022-931f-4d79-9fc7-88567437e6b5",
    "employment history": [
      {
        index: 0,
        values: [
          {
            name: "organisation name",
            value: "Company One",
          },
          {
            name: "position held",
            value: "Nurse",
          },
          {
            name: "start date",
            value: "2023-12-15",
          },
          {
            name: "end date",
            value: "2023-12-29",
          },
          {
            name: "key responsibilities",
            value: [
              "Add some cashback. When in Japan? Spend i",
              "Cashback. When in Japan? Spend i",
              "Some cashback. When in Japan? Spend i",
            ],
          },
        ],
      },
    ],
    education: [
      {
        index: 0,
        values: [
          {
            name: "type",
            value: "university",
          },
          {
            name: "institution name",
            value: "wsu",
          },
          {
            name: "date obtained",
            value: "2023-12-20",
          },
          {
            name: "qualification obtained",
            value: "bcs in something",
          },
        ],
      },
      {
        index: 1,
        values: [
          {
            name: "type",
            value: "college",
          },
          {
            name: "institution name",
            value: "Quick Bootcamp",
          },
          {
            name: "qualification obtained",
            value: "Degree One",
          },
          {
            name: "date obtained",
            value: "2023-12-27",
          },
        ],
      },
    ],
    reference: [
      {
        index: 0,
        values: [
          {
            name: "fullname",
            value: "Sands",
          },
          {
            name: "company name",
            value: "NP Gabela",
          },
          {
            name: "referees position",
            value: "Mnager",
          },
          {
            name: "work email",
            value: "their@mail.com",
          },
          {
            name: "telephone number",
            value: "00343040033",
          },
        ],
      },
    ],
    training: [
      { name: "Manual Handling", completed: false, date: null },
      {
        name: "Health and Safety (Inc COSHH)",
        completed: true,
        date: 1593561600000,
      },
      { name: "Fire Safety Awareness", completed: false, date: null },
      { name: "Basic Life Support", completed: true, date: 1596240000000 },
      { name: "SOVA", completed: false, date: null },
      { name: "Infection Control", completed: true, date: 1598918400000 },
      { name: "Person Centered Care/Dementia", completed: false, date: null },
      // ... other trainings
    ],
    status: "inactive",
  },
];

export const demo_leave_data = [
  {
    start_date: 1704232800000,
    end_date: 1706659200000,
    days: 15,
    status: "approved",
    name: "Marry Williams",
    note: "Need to take care of personal matters.",
    type: "sick",
  },
  {
    start_date: 1715040000000,
    end_date: 1716422400000,
    days: 16,
    status: "pending",
    name: "George Bluth",
    note: "Home renovation project.",
    type: "annual",
  },
  {
    start_date: 1731974400000,
    end_date: 1732233600000,
    days: 2,
    status: "approved",
    name: "Fiona Gallagher",
    note: "Going on a short trip.",
    type: "annual",
  },
  {
    start_date: 1731196800000,
    end_date: 1732492800000,
    days: 15,
    status: "approved",
    name: "Bob Smith",
    note: "Recovering from illness.",
    type: "sick",
  },
  {
    start_date: 1704067200000,
    end_date: 1706054400000,
    days: 1,
    status: "approved",
    name: "Fiona Gallagher",
    note: "Recovering from illness.",
    type: "annual",
  },
  {
    start_date: 1709337600000,
    end_date: 1711756800000,
    days: 456,
    status: "pending",
    name: "Chris Green",
    note: "Family vacation planned.",
    type: "annual",
  },
  {
    start_date: 1706745600000,
    end_date: 1708041600000,
    days: 334,
    status: "declined",
    name: "George Bluth",
    note: "Attending a family event.",
    type: "sick",
  },
  {
    start_date: 1712707200000,
    end_date: 1712966400000,
    days: 186,
    status: "approved",
    name: "John Doe",
    note: "Attending a wedding.",
    type: "annual",
  },
  {
    start_date: 1704067200000,
    end_date: 1704499200000,
    days: 116,
    status: "approved",
    name: "John Doe",
    note: "Going on a short trip.",
    type: "sick",
  },
  {
    start_date: 1713398400000,
    end_date: 1714089600000,
    days: 157,
    status: "approved",
    name: "Marry Williams",
    note: "Childcare responsibilities.",
    type: "sick",
  },
  {
    start_date: 1733011200000,
    end_date: 1734307200000,
    days: 497,
    status: "approved",
    name: "Bob Smith",
    note: "Attending a wedding.",
    type: "sick",
  },
  {
    start_date: 1704412800000,
    end_date: 1706659200000,
    days: 54,
    status: "approved",
    name: "Alice Johnson",
    note: "Need to take care of personal matters.",
    type: "sick",
  },
  {
    start_date: 1717286400000,
    end_date: 1717459200000,
    days: 31,
    status: "approved",
    name: "John Doe",
    note: "Need to take care of personal matters.",
    type: "annual",
  },
  {
    start_date: 1711929600000,
    end_date: 1713571200000,
    days: 359,
    status: "pending",
    name: "Hoan Lou",
    note: "Family vacation planned.",
    type: "annual",
  },
  {
    start_date: 1710028800000,
    end_date: 1710374400000,
    days: 123,
    status: "pending",
    name: "George Bluth",
    note: "Taking time off for mental health.",
    type: "annual",
  },
  {
    start_date: 1733097600000,
    end_date: 1735516800000,
    days: 399,
    status: "pending",
    name: "George Bluth",
    note: "Attending a wedding.",
    type: "annual",
  },
  {
    start_date: 1722470400000,
    end_date: 1723852800000,
    days: 367,
    status: "approved",
    name: "John Doe",
    note: "Taking time off for mental health.",
    type: "sick",
  },
  {
    start_date: 1722816000000,
    end_date: 1729900800000,
    days: 142,
    status: "approved",
    name: "Chris Green",
    note: "Home renovation project.",
    type: "sick",
  },
  {
    start_date: 1730505600000,
    end_date: 1731196800000,
    days: 7,
    status: "approved",
    name: "Chris Green",
    note: "Home renovation project.",
    type: "sick",
  },
  {
    start_date: 1725408000000,
    end_date: 1725753600000,
    days: 29,
    status: "pending",
    name: "George Bluth",
    note: "Recovering from illness.",
    type: "sick",
  },
];

export const workHoursData = [
  { date: "Jan 1", hr: 20, nurse: 28, carer: 52 },
  { date: "Jan 2", hr: 18, nurse: 30, carer: 50 },
  { date: "Jan 3", hr: 15, nurse: 25, carer: 45 },
  { date: "Jan 4", hr: 22, nurse: 32, carer: 48 },
  { date: "Jan 5", hr: 20, nurse: 28, carer: 46 },
  { date: "Jan 6", hr: 18, nurse: 30, carer: 44 },
  { date: "Jan 7", hr: 16, nurse: 26, carer: 42 },
  { date: "Jan 8", hr: 20, nurse: 28, carer: 52 },
  { date: "Jan 9", hr: 21, nurse: 29, carer: 51 },
  { date: "Jan 10", hr: 19, nurse: 27, carer: 49 },
  { date: "Jan 11", hr: 17, nurse: 31, carer: 47 },
  { date: "Jan 12", hr: 22, nurse: 33, carer: 53 },
  { date: "Jan 13", hr: 20, nurse: 30, carer: 50 },
  { date: "Jan 14", hr: 18, nurse: 28, carer: 48 },
  { date: "Jan 15", hr: 16, nurse: 26, carer: 46 },
  { date: "Jan 16", hr: 20, nurse: 28, carer: 52 },
  { date: "Jan 17", hr: 21, nurse: 29, carer: 51 },
  { date: "Jan 18", hr: 19, nurse: 27, carer: 49 },
  { date: "Jan 19", hr: 17, nurse: 31, carer: 47 },
  { date: "Jan 20", hr: 22, nurse: 33, carer: 53 },
  { date: "Jan 21", hr: 20, nurse: 30, carer: 50 },
  { date: "Jan 22", hr: 18, nurse: 28, carer: 48 },
  { date: "Jan 23", hr: 16, nurse: 26, carer: 46 },
  { date: "Jan 24", hr: 20, nurse: 28, carer: 52 },
  { date: "Jan 25", hr: 21, nurse: 29, carer: 51 },
  { date: "Jan 26", hr: 19, nurse: 27, carer: 49 },
  { date: "Jan 27", hr: 17, nurse: 31, carer: 47 },
  { date: "Jan 28", hr: 22, nurse: 33, carer: 53 },
  { date: "Jan 29", hr: 20, nurse: 30, carer: 50 },
  { date: "Jan 30", hr: 18, nurse: 28, carer: 48 },
  { date: "Jan 31", hr: 16, nurse: 26, carer: 46 },
];

// const dates = [
//   { start_date: "2024-01-3", end_date: "2024-01-31" },
//   { start_date: "2024-05-07", end_date: "2024-05-23" },
//   { start_date: "2024-11-19", end_date: "2024-11-22" },
//   { start_date: "2024-11-10", end_date: "2024-11-25" },
//   { start_date: "2024-01-01", end_date: "2024-01-24" },
//   { start_date: "2024-03-02", end_date: "2024-03-30" },
//   { start_date: "2024-02-01", end_date: "2024-02-16" },
//   { start_date: "2024-04-10", end_date: "2024-04-13" },
//   { start_date: "2024-01-01", end_date: "2024-01-06" },
//   { start_date: "2024-04-18", end_date: "2024-04-26" },
//   { start_date: "2024-12-01", end_date: "2024-12-16" },
//   { start_date: "2024-01-05", end_date: "2024-01-31" },
//   { start_date: "2024-06-02", end_date: "2024-06-04" },
//   { start_date: "2024-04-01", end_date: "2024-04-20" },
//   { start_date: "2024-03-10", end_date: "2024-03-14" },
//   { start_date: "2024-12-02", end_date: "2024-12-30" },
//   { start_date: "2024-08-01", end_date: "2024-08-17" },
//   { start_date: "2024-08-05", end_date: "2024-10-26" },
//   { start_date: "2024-11-02", end_date: "2024-11-10" },
//   { start_date: "2024-09-04", end_date: "2024-09-08" },
// ];

// console.log(
//   demo_leave_data.map((data: any, index: number) => ({
//     ...data,
//     start_date: new Date(dates?.[index]?.start_date).getTime(),
//     end_date: new Date(dates?.[index]?.end_date).getTime(),
//   }))
// );

export const medication_admin_legeds = [
  {
    legend: "y",
    explanation: "yes",
    deduct: true,
  },
  {
    legend: "r",
    explanation: "refused",
    deduct: true,
  },
  {
    legend: "h",
    explanation: "hospital",
    deduct: true,
  },
  {
    legend: "n",
    explanation: "nausea / vomit",
    deduct: true,
  },
  {
    legend: "x",
    explanation: "not given",
    deduct: false,
  },
  {
    legend: "f",
    explanation: "family",
    deduct: true,
  },
  {
    legend: "s",
    explanation: "sleeping",
    deduct: false,
  },
  {
    legend: "sa",
    explanation: "self administered",
    deduct: true,
  },
];

export const body_map_parts = [
  "abdomen",
  "back",
  "chin",
  "full chest",
  "left ankle",
  "left back ankle",
  "left back foot",
  "left back knee",
  "left back thigh",
  "left back calf",
  "left back hamstring",
  "left back buttock",
  "left back arm",
  "left back elbow",
  "left back forearm",
  "left back hand",
  "left back shoulder",
  "left back chest",
  "left chest",
  "left ear",
  "left eye",
  "left finger 1",
  "left finger 2",
  "left finger 3",
  "left finger 4",
  "left foot",
  "left foot thumb",
  "left hand",
  "left knee",
  "left lower arm",
  "left palm",
  "left pulm",
  "left shoulder",
  "left sholder",
  "left thigh",
  "left toe 1",
  "left toe 2",
  "left toe 3",
  "left toe 4",
  "left upper arm",
  "left wrist",
  "left leg",
  "left eye",
  "left cheek",
  "loin",
  "mouth",
  "neck",
  "neck back",
  "nose",
  "pelvis",
  "right ankle",
  "right back ankle",
  "right back foot",
  "right back knee",
  "right back thigh",
  "right back calf",
  "right back hamstring",
  "right back buttock",
  "right back arm",
  "right back elbow",
  "right back forearm",
  "right back hand",
  "right back shoulder",
  "right back chest",
  "right chest",
  "right ear",
  "right eye",
  "right finger 1",
  "right finger 2",
  "right finger 3",
  "right finger 4",
  "right foot",
  "right foot thumb",
  "right hand",
  "right knee",
  "right lower arm",
  "right palm",
  "right pulm",
  "right shoulder",
  "right sholder",
  "right thigh",
  "right toe 1",
  "right toe 2",
  "right toe 3",
  "right toe 4",
  "right upper arm",
  "right wrist",
  "right leg",
  "right cheek",
  "head",
  "head back",
];

export const body_map_parts_short = [
  "head",
  "head back",
  "face",
  "neck",
  "neck back",
  "left shoulder",
  "right shoulder",
  "left upper arm",
  "left lower arm",
  "left hand",
  "right upper arm",
  "right lower arm",
  "right hand",
  "chest",
  "back",
  "abdomen",
  "left thigh",
  "left leg",
  "left foot",
  "right thigh",
  "right leg",
  "right foot",
];

export const skin_care_legends = [
  {
    legend: "Healthy and intact",
    value: "H",
    action: "No action",
  },
  {
    legend: "Red blanching",
    value: "RB",
    action: "Report to the nurse",
  },
  {
    legend: "Red non-blanching",
    value: "RNB",
    action: "Report to the nurse and implement pressure relief.",
  },
  {
    legend: "Deep tissue injury",
    value: "DTI",
    action: "Report to nurse and implement pressure relief.",
  },
  {
    legend: "Unstageable",
    value: "U",
    action: "Report to the nurse and implement pressure relief.",
  },
  {
    legend: "Wound grade if pressure ulcer",
    value: "G1-4",
    action: "Nurse to review and grade.",
  },
  {
    legend: "Dressing insitu",
    value: "D",
    action: "Skin inspection at each dressing change",
  },
];

export const passwordRequirements = [
  "Minimum of 8 characters",
  "At least one special character (e.g. @, #, $, %, .)",
  "At least one uppercase/capital character",
  "At least one number",
];

export const care_plan_example = `
Client Name
CREATION DATE - THU MAY 30 2024 16:59:20 GMT+0200
LAST UPDATED ON - FRI JUN 21 2024 12:23:12 GMT+0200
client
CLINICAL HISTORY
Client Clinical History

1
CURRENT CONDITION
I have osteoporosis of the spine with certain complications.

I have a urethral catheter in situ.

I have swallowing difficulties

2
PAST MEDICAL HISTORY
Osteoporosis of the spine with certain complications. Migraines Clinical depression.

3
MEDICAL HISTORY FILE (OPTIONAL)
Click the blue button or link below to preview or download the file

pdf_preview
CLICK TO PREVIEW
4
ALLERGIES
Intolerance to Oxycodone

5
HOSPITAL ADMISSIONS IN THE LAST 12 MONTHS
no

6
IF YES, PLEASE GIVE DETAILS OF THE LENGTH OF STAY
N/A

7
IS ANY ADVANCE DIRECTIVE IN PLACE, INCLUDING DNAR?
DNAR

8
INTERVENTION REQUIRED
Maintaining Safety,Maintaining Skin Integrity,Washing and Dressing,Maintaining Fluid and Nutrition,Social Inclusion/ Activities,Domestic Tasks and Personal Care,Pain and Comfort,Medication Administration

9
GP NAME
Dr Johal

11
GP ADDRESS
Russell House Surgery, Wolverhampton Road, Codsall WV8 1EP

ABOUT CLIENT AND MY FAMILY
About Client And My Family (Including Cultural, Religious Beliefs And Sexuality)

1
ABOUT CLIENT AND MY FAMILY
I am a mother to my two children, a wife to my husband Bryn, and a grandmother and great-grandmother. Bryn is my main carer, however because of both our health needs, we now have assistance from care staff 24/ hours a day 7 days a week. Bryn is my next of kin and all approaches to care, and care regimes, should be agreed upon with both Bryn and me before implementation.



Bryn does my shopping, and he plans and prepares my meals with the help of care staff. I eat healthy but struggle at times due to coughing issues. I communicate verbally although I am no longer as articulate as I was before, I believe this is because of my medication. I am bed-bound and I do not transfer to the chair due to the pain I experience during transfers.



I wear reading glasses and hearing aids in both ears.



I direct my care and plan my activities i.e., Listening to the radio, my audio books, using my Tablet, writing, reading. I struggle more now to read and write independently due to changes in my eyesight and there has been deterioration in the last 2-3 months. I use the iPad, which is limited to an hour over a day as I like to look at YouTube. 



I prefer FEMALE CARERS ONLY



I want the staff to respect my private time with Bryn and only come to my room when called.



I have a call bell, that I ring to alert care staff when I need their assistance.



I feel relaxed when I get massages from care staff.



I usually go to bed after 10 pm.



I use pads and incontinence sheet on the bed, and I have a catheter in situ.



My privacy and dignity, are always maintained by my care staff.

CONTACTS
Please List Below Other People Involved In Care / Social / Education/Work, Including Next Of Kin/Power Of Attorney (In Finance And/Or Health And Welfare

1
CONTACTS
NAME
Bryn Edwards

RELATIONSHIP
spouse

PHONE NUMBER
00000000

EMAIL
example@mail.com

CARE PLAN OBJECTIVES
What Are The Objectives Of The Plan E.G Domestic Tasks And Personal Care Or Mobilising

1
OBJECTIVE
NAME OF OBJECTIVE
MAINTAINING SAFETY

GOAL
I need assistance to maintain a safe and secure environment due to:

motor loss
Minimise the risk of infection.

PROBLEM
I have Osteoporosis of the spine and always need the assistance of one staff member during care both day and night. An additional member of staff, who comes to do pop-in visits between 11-4 pm for extra support with personal care.


At least one member of staff should be fully competent in my care.


I have a Dyna- Foam pressure relieving mattress, Dyna-form mercury advance pump and a low-profile bed.


The electric airflow mattress on my bed must be checked daily and plugged into an electrical supply. (Telephone number for emergency repair on the pump) 


Please do not turn /reposition me overnight, unless I specifically request to be turned for itching and bowel care. I move halfway to resolve itching and all the way over onto my side for personal care. 
 

I really dislike being turned, as it sets off all my pains. This is especially during personal care. Being moved causes a lot of pain and it stays and continues for a while before it subsides after the personal care/ movement has been completed and I am lying on my back.


I know I should be turned every 4 hours during the day but due to the pain it causes, it becomes unbearable for me. I have the capacity and understand the implications of not being turned. Staff should ensure that it is recorded. That all turns, or repositioning are to be recorded on my daily repositioning chart. During each turn and manoeuvre, staff are to check my skin for signs of redness or breaks.


I have pillows under my head, on my back, and both sides of my bed and at the foot of my bed. Staff must ensure that these are always on when I am in bed and must only be taken down when administering care. I have a pillow in between my legs when I am being turned to give my legs support.


I like to have personal care around 11:00 am and or later if opening my bowels sit in my bed for the rest of the day.

ACTION
Care staff should ensure that the environment is safe and free of obstacles in my room.


Care staff are responsible for maintaining the cleanliness and tidiness of my room and any areas they use.


Care staff will explain any interventions and obtain my consent before proceeding.


Care staff will assist me in maintaining a comfortable room temperature and environment


Any required care should be conducted with the assistance of two staff members to ensure my safety when they manoeuvre me.


Care staff to always maintain my privacy and dignity. Curtains and bedroom doors should be closed during care. 


My bed should be checked every 12 months next check is due 10/2024.


Care staff to ensure my confidentiality is always maintained. My information is to be shared with people involved in my care.


Care staff to ensure full Health & Safety and Risk Assessments are carried out and reviewed monthly, findings acted upon and documented.


Care staff to ensure that daily, weekly & annual checks of all medical equipment are carried out and documented. i.e., catheter supplies


Care staff to maintain security and safety of the domestic environment as per my wishes. Ensuring windows and doors are locked at night.


Care staff to maintain good practice in all aspects of domestic hygiene.


Care staff to follow my Care Plan, clinical guidelines & procedures for all aspects of care.


Care staff to follow ‘Handwashing’ guidelines before & after all care interventions.


Care staff are to wear appropriate personal protective equipment (PPE).


3 Angels Complex Care Nurse to ensure competency training and assessments are completed.


All staff must ensure a professional and accurate handover is carried out at the start of every shift. 


All staff are responsible for maintaining accurate records and must document all care in the notes.


If there are any clinical concerns, staff are to inform Compton and 3 Angels Complex Care Nurse as soon as possible.


Care staff should reposition, and document any positional changes and also any refusals. 
 


REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
COMMUNICATION

GOAL
To help ensure that I can express myself as clearly as possible.



To help ensure that others do not misinterpret me. 


PROBLEM
I communicate verbally, however I can be in a lot of pain and unable to voice out and may need to use my hands to signal what is happening.



My vision in my right eye and my hearing in my right ear are no longer good. (Currently awaiting audiology home visit)



The hearing aids have helped. 

 I was last reviewed on the 30.09.2023 by the audiologist. 



I was last reviewed by the opticians on 05.04.2023.



I have full capacity and I communicate verbally although I am no longer as articulate as I was once before.



At times words get stuck in my head and I may take longer to say something.



I can communicate with my mobile phone, email, and with my iPad.


ACTION
Ask for my permission before undertaking any activities with me or doing anything, I can direct my care needs if asked.


Please inform me of all procedures before carrying them out.


I need care staff to speak clearly and slowly and wait for my response.  


If I do not respond I may want more time to respond. The care staff needs to be PATIENT with me.


Always maintain eye contact with me and speak clearly avoiding slang words and phrases. Never talk over me.


I can make my own decisions and choices, and these must always be presented to me. 


At times, I may not be ready for care. During these times, it is important that staff respect my wishes and withdraw from the situation and revisit 10-15 minutes later when my mood may have improved, and I may change my mind. 

REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
PSYCHOLOGICAL AND EMOTIONAL WELLBEING

GOAL
To ensure that my psychological and emotional well-being are maintained.



That I am given the opportunity to express how I am feeling.


PROBLEM
I have periods where I feel very low and can often feel that things are too much for me with the ongoing pain and not being able to do what I could previously. I can be tearful at times and can become anxious and distressed.



I can also laugh and joke with the care staff. I like to communicate with staff when they come to see me in my room. 



I have previously been diagnosed by my GP as having Clinical Depression, which has been diagnosed for many years. I have periods of ups and downs. 



I don't like talking about myself and I can struggle. I would rather forget the past and concentrate on the future. 



I was previously prescribed sertraline for my low mood however I rarely used it due to the number of tablets I take. 



I now take lorazepam when I feel anxious or distressed. 


ACTION
Care staff to support me and also allow me the opportunity to express how I am feeling. 


Care staff should document how I am feeling and also if I have received any PRN medication and its effect.


I no longer take any medication to help with my clinical depression.


If I am still feeling low for more than 7 days beyond what is normal for me or I am expressing suicidal thoughts even though I cannot act upon those thoughts, care staff should ask Bryn to contact the GP for me to be seen and to see if there is any therapy that could be offered or if needed medication. 


I can take the Lorazepam 0.5mg as required I can have a maximum of 1.5mg in 24 hours to help when I feel anxious or distressed.



REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
BREATHING AND MAINTAINING AIRWAYS

GOAL
To maintain my health and reduce the risk of chest infections.


PROBLEM
I can maintain my airway and breathe independently. 



I have osteoporosis of the spine and my posture makes it difficult to breathe normally at times.



I can be very wheezy and use a salbutamol inhaler with a spacer to alleviate this.



I am coughing on clear fluids, and this has resulted in me having chest infections.



﻿I have been seen by SALT team and declined the use of thickener in my fluids.



I have been prescribed short courses of Furosemide to help with my breathlessness and the fluid buildup in my chest. 



I no longer wish to have clinical observations taken. If I am unwell, or staff are concerned about my well-being they are to contact Compton Rapid response team. 


ACTION
Care staff to assist me by administering my inhaler when I am wheezy and unable to breathe properly.


Care staff to observe for signs of a chest infection or respiratory distress.
Paleness or bluish tinge to lips or skin
Chest pain
Noisy or laboured breathing
If I am anxious or distressed
Decreased level of consciousness or confusion
Care staff to notify Bryn of any concerns.


Care staff can monitor me for signs of wheezing and excess breathlessness and notify Bryn.


I can have a 14 day course of Furosemide to help remove any excess fluid build up. This will aid my breathing difficulty. If after the course has finished, or there is still no improvement or I have deteriorated the GP or Compton nurses should be contacted to review me. 


The district nurses can also be contacted to see if I can be given any medication subcutaneously to help relieve my symptoms.


I am now Feed At Risk as I declined the use of thickeners in my fluids to reduce the risk of choking or aspiration when I am coughing whilst drinking normal clear fluids.

REVIEW DATE
2024-08-15

REVIEWED BY
User Name

NAME OF OBJECTIVE
MAINTAINING FLUID AND NUTRITION

GOAL
To help ensure that my diet is as healthy, sufficient, and appealing, to me, as possible.



To help ensure that I continue to eat a nutritious diet.



To help keep me safe when I am eating






PROBLEM
I plan my meals with the assistance of my husband Bryn and my care staff.



My food is prepared by care staff or Bryn and brought to me on a tray with my cutlery.



I eat small portions of healthy meals that need to be chopped into smaller pieces.



I can eat Independently.



I have a limited diet due to my selective preferences for food.



 I eat all my food (In small portions) on good days and struggle on other days.



I can request certain meals and drinks i.e., coffee.

 

I enjoy chip shop fish and chips and peas very small amount on a friday evening.



Following on from a SALT review in December/ January 2024. I am to be Fed At Risk.



Due to my deterioration, on days where I have low energy level and weakness, I may need assistance from care staff to assist me with my meals.


ACTION
Care staff to prepare my meals as advised by Bryn and SALT recommendations.


Care staff are to respect my choices and at the same time ensure my safety.


Care staff to respect my private times with Bryn during mealtimes. 


Care staff are to encourage me to follow SALT recommendations.
   

Care Staff are to offer me fluids throughout the day. 


On the days where I have a lack of energy and weakness, I may require care staff to assist me with my meals. The care staff should ensure I have a napkin on and also give me small pieces of food on my cutlery and allow me time to chew and swallow the food.


Staff should ensure that they do not rush me during my meal and also allow me to eat my meal without disturbances or distractions


My diet is limited to my selected tastes.   


I am a palliative patient and choose not to be weight monitored.



I do not want to have thickened fluids, even if this is a recommendation of Salt due to coughing on diet and fluid, Staff should monitor and report any additional changes and a referral can be made back to SALT.



It has been decided between myself, My GP and the SALT team that I will eat at risk, I have the capacity, to make my own decisions and understand the risks and complications explained to me around aspirations. However, I do not wish to have thickened fluids or a feeding Tube currently. This was completed 04.04.2024 by SALT






REVIEW DATE
2024-08-15

REVIEWED BY
User Name

NAME OF OBJECTIVE
BOWEL MOVEMENT ELIMINATION

GOAL
To help maintain my dignity and privacy.



To help me maintain a regular bowel movement and reduce the risk of chronic constipation.



To monitor my bowels and contact other professionals when required.


PROBLEM
I suffer from constipation, and I am on several laxative medications. 



I open my bowels roughly every 2 days with the help of laxatives.



﻿I find it difficult to get a happy medium type 4 with type as occasionally it can be type 7. 



If I have not opened my bowels within 5 days of using laxatives then I should be offered a Suppository. 


ACTION
Care staff to document the type and amount of faeces passed in the Care Diary and record on the Bowel chart using the Bristol Stool Chart. 


All supporting medication is administered as prescribed to aid and support successful bowel care.


I have laxatives prescribed by my GP who oversees and manages my bowel care regime.


I routinely open my bowels on alternate days after the laxatives. This is given first thing in the morning by the staff on duty.


If I have not opened my bowels then trained and competent staff should administer a Suppository as per PRN Protocol and document in my notes and also the bowel chart and inform 3 Angels Complex Care nurse. 


If there is still no bowel movement after the suppository, Advice should be sought by Care staff. staff should advise Bryn to contact my GP.




Care staff to check all pressure areas and skin condition, tell me if I am developing any problems and if there are any issues. These must be recorded in my daily notes and a body map completed. The Complex Nurse Specialist must be informed, and District  Nurses updated.


If I appear to be constipated, I must be given an extra 200-300 ml of water on each of these days to help to loosen my stools. I also have been prescribed suppositories (Please follow GP Instructions)


Clinical waste should be disposed of in yellow bags. Care staff will maintain infection control local protocols when disposing of this waste safely.


Care staff will observe if my abdomen is bloated/distended and report their concerns to the Complex Nurse Specialist and GP.

REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
ELIMINATION OF URINE

GOAL
To help ensure that I maintain good personal hygiene and health.



To reduce the risk of urinary infections.


PROBLEM
I have a urethral catheter in situ. I have times where I have sediment build up in the catheter tubing and also experience bypassing of the catheter.



I use continence pads with Inco sheets underneath, whilst on my bed. These need to be changed regularly.



I am bed bound.



My hygiene would suffer if I do not receive support around toileting. This makes me prone to repeated illness and infection.



I will not allow male staff to care for me. 



Care staff to wash hands (as per guidelines) and wear appropriate PPE for personal care. 



Care staff to observe for signs of infection.



Care staff to dispose of incontinence pads in line with local clinical waste guidelines.


ACTION
Care staff to ensure that I always have a spare Catheter in the house for emergencies. (If my catheter comes out in an emergency, care staff to contact District Nurses as soon as it is safe to do so)


Care staff to always ensure I have an adequate supply of pads, catheter bags, and wipes.  


Care staff should help me with the following toileting tasks:
Emptying my catheter bags and recording urine output.
Changing my pads
Looking out for signs of infection


Regular checks are required to see if I need changing.


Care staff are to inform me of each intervention and gain my consent before proceeding.


Privacy and dignity are to be maintained during continence care. ALWAYS make sure the bedroom blinds are drawn before starting any type of personal care. I do not want people outside to be able to see into my bedroom during this time.


﻿All staff must use effective hand washing techniques and wear relevant PPE before attending to my catheter care. 


All staff must wear all relevant PPE and adhere to infection prevention guidelines.


Catheter last changed 29.05.2024


Urine to remain clear and free from infection. If urine is dark, then staff are to encourage me to drink more fluids.


I can get sore skin/ moisture damage – please ensure my skin is washed and dried thoroughly every time I open my bowels or when my catheter bypasses.


Care staff will administer and monitor my intake of fluid and ensure that I stay hydrated. As this will help to prevent UTIs. Care staff are to check my pad every time I am repositioned and change the pad when it is soiled. 


Care staff are to Record in my daily notes, each time I pass urine whether it was “normal” or if staff suspect UTI or change in urine output, they will contact the 3 Angels Complex Nurse Specialist for advice/ GP , Compton nurses for review.


If urine output has reduced, has an offensive smell is discoloured or very dark, is cloudy or has particles present, if I feel unwell or have a high or abnormal temperature above 30 degrees – Inform 3 Angels nurses, District nurses or Compton nurses as soon as it is safe to do so.
 


REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
DOMESTIC TASKS AND PERSONAL CARE

GOAL
To help maximise my independence, presentation, personal hygiene, and self-esteem. 



I am to be washed and dressed promptly and follow my wishes.



The risk of infection is minimised.



The risk of skin damage due to reduced pressure relief is minimised.



Ensure that my comfort and dignity is always maintained.


PROBLEM
I require assistance from care staff with personal hygiene and skin care needs:

Bed bath
Dressing/ undressing (upper half/lower half)
Observation of skin and repositioning
All hygiene needs are met in a dignified manner and require full support from care staff to get washed, dressed, and ready for the day.


My hygiene will suffer if I do not receive encouragement and support to get washed.



I can brush my teeth independently. A toothbrush, towel, and bowl are to be brought in and held for me.

Last dental review 15.11.22 

I am having issues with my bridge as it broken.



I can direct my care.



My presentation would suffer if I do not receive encouragement and support to get washed, dressed, and brush my teeth.  



I will become socially isolated if my hygiene and presentation are neglected.  



I require staff to maintain my nail care by filing my finger nails when i request.



I have a podiatrist who maintains my foot and nail care every 8 weeks.

 

I have a sore on the right side of my lower mouth from the corner. Staff should apply my prescribed creams.


ACTION
I like to have my wash when I wake up every morning. This can vary depending on my night's sleep the previous night. It is usually around 1PM


I will use my bell to summon care staff when I am ready for my wash.


I will direct my care and the care staff should to respect my dignity and privacy.


I will choose my clothes and must be given choices to help to maintain my independence. 


Care staff to assist as required with dressing and undressing, ensure clothes fit well with no creases or seams present.


Observe skin condition -inform me, the 3 Angels Complex Care Nurses, and district nurses if any red, inflamed, discoloured, or broken areas are seen and record the findings on the body maps.
 

The care Staff needs to help me with the following personal care tasks: 
Get washed and dressed, including the application of deodorant.
Apply my creams. 
Cetraben- emollient for my body
Dermacol cool – for itchiness on buttocks
Fenbid cream- pain relief in the ribs area
Honey med- barrier cream
Medi- Derma Cream – Barrier cream


Aim to wash me in the morning before my day starts.  


Attending to dental hygiene – I need care staff to bring me a bowl, tumbler with cold water, towel, and my toothbrush and toothpaste. I can brush my teeth independently, every morning after my wash. 


I require care staff to maintain my fingernails by filing them to a short and neat level


Await information from GP regarding a Dentist who can do home visits. No dentist available in area to do Home Visits



Care staff to apply miconazole to side of mouth twice a day. seek advice from GP if no improvement within 3 weeks.



There is improvement to the sore on the side of my mouth. It is still present however there has been improvement.


REVIEW DATE
2024-08-29

REVIEWED BY
User Name

NAME OF OBJECTIVE
MAINTAINING SKIN INTEGRITY

GOAL
Ensure my privacy and dignity are always maintained. 



Ensure the risk of infection is minimised. 



Ensure my comfort is always maintained.




PROBLEM
I am bed bound and at risk of developing pressure sores.



I use deodorant and body spray. 



I can regulate my temperature to ensure that I am comfortable and not too hot or too cold.



 I am assisted by staff to apply oils and moisturisers to my body, face, arms, and legs to prevent dry skin.



All hygiene needs are met in a dignified manner. 



I have a sore on the right side of my mouth, the GP prescribed cream.






ACTION
All hygiene needs are met in a dignified manner.



I am repositioned every 4 hours during the day, and this is recorded on my position change chart. I do not want to be turned during the night unless I wake up and request this. 



I am continent of faeces but use incontinence pads, and staff to ensure personal hygiene is maintained throughout my 24-hour care period.



Staff are to ensure the risk of skin damage is minimised due to the sensory and motor loss I experience from my condition.



My skin is checked fully during change and at shift changeover. Any redness, breaks, or inflammation are recorded on my body map and reported to 3 Angels Complex Care Nurses.



Staff should check my skin daily, document any changes on the daily skin chart and report any concerns. 



Staff to apply the cream applied by the GP and sign the TMAR. 


REVIEW DATE
2024-08-28

REVIEWED BY
Sara

NAME OF OBJECTIVE
MOBILISING

GOAL
To help me move comfortably and safely.



Ensure my privacy and dignity are always maintained.






PROBLEM
I am bed bound now. I choose not to transfer due to the pain I suffer during transfer.



Staff to ensure the correct positioning of my spine limbs in bed.



I don't always want to be fully turned onto my side during pressure relief due to high levels of pain, staff can turn me 45 degrees.






ACTION
Staff to ensure I am repositioned appropriately throughout the day and night.



I am turned every 4 hours during the day, and this is recorded on my position change chart. I do not want to be turned during the night unless I wake up and request this. There are times due to the level of pain I am feeling that I don't want to be repositioned, staff should document this on the reposition chart, I have full capacity and I am aware of the implications if I don't have pressure care 4 hourly.



I have pillows on my bed, and these are always in place and only ever taken down during movement and personal care.



Follow the Moving and Handling Assessment for transfer procedures. 

Staff should raise the bed height that is comfortable to accommodate them during the personal care and moving and handling of Jen to ensure that they don't overstretch or injury themselves. Staff should explain to Jen why they are doing this before they proceed/



All equipment, including sliding sheets, will be checked daily and any issues reported to the company responsible for its upkeep and maintenance.

 

All transfers and moving and handling require two staff. 



I have a manual wheelchair with a pressure-relieving cushion that must be in place before all transfers. I no longer use my Wheelchair or hoist.



Wheelchair:  Assist Manual Chair- no longer used

Chair cushion: Invacare- no longer used

Bed:  low profile

Mattress: Dyna-Form Airpro Plus Settings - low

Hoist: None 

Sling: Size - medium- no longer used


REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
SOCIAL INCLUSION/ ACTIVITIES

GOAL
Staff to ensure I am assisted in maintaining social/family contact.



My safety is maintained inside the home.


PROBLEM
I like to listen to the radio and my audiobooks



I also enjoy reading and using my IPAD and my phone.



I enjoy interacting with the care staff.



I communicate with my family and friends via telephone, letters, and visits.



I do not leave the house any longer.


ACTION
Staff to include me in all aspects of my care.



Assist me as required with all social activities and interests.



Respect my right to spend time with family and friends as per my wishes.



Staff should give me privacy and space when I have visits from friends and family and should stay downstairs and only interact with me if I use my bell to request something from them.



Staff to respect my wishes of not wanting to transfer into the chair.


REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
HEALTHIER LIVES

GOAL
Enhance my functional mobility within the confines of bed. 



Alleviate and manage my pain levels. 



Implement strategies to support bone health and minimise complications.




PROBLEM
I require regular bed exercise to maintain joint flexibility and rubbing of my calves and feet to ease the tightening that I experience several times a day. 



I take calcium supplements as prescribed by my GP.



I have 3 monthly Vitamin B injections



I have a RESPECT FORM IN PLACE



I am on a Level 5 mince and moist diet and  level 0 regular fluids.



I am on regular prescribed medications



I have a catheter in place



I am nursed in bed.



As my condition has deteriorated, I am beginning to sleep for longer periods of time during the day. 


ACTION
Care staff to assist me to engage in daily bed exercises to maintain joint flexibility. 

Care staff to follow a routine of gentle stretches and range-of-motion exercises. 



Manage Pain Effectively:

Bryn to assist me to ensure I Adhere to prescribed pain medications. Any concerns to be reported to my GP, Compton nurses and the District Nurses if needed.



Bryn to ensure I take medications as directed and communicate with healthcare providers regarding pain levels.



Maintain Bone Health:

Care staff to  ensure  I am compliant with prescribed calcium and vitamin D supplements, and I have regular  follow up with my GP, Compton, District Nurses , SALT,and other members of the MDT.





My Nutrition and Dietary Preferences:



Balanced Diet:

Care staff to ensure I Consume mince and moist foods, ensuring proper hydration with normal fluids as advised by SALT.

I am now feed at risk as this has been discussed with myself and my GP along with SALT.



My Physical Activity Plan:



Bed Exercises:

Care Staff to assist me to perform daily stretches and range-of-motion exercises to prevent stiffness. 

Staff to give my lower legs and feet regular rub downs with creams as this helps to allieviate the tighteness that I feel in my calves and feet.



My Monitoring and Emergency Plan:



Vital Signs Monitoring:

   Staff are to no longer check any clinical observations. If I am unwell staff are to report any significant changes promptly to my GP, Compton ,District Nurse  and 3 Angels Nurses.



Emergency Preparedness:

  All staff to be aware of emergency plans and have all emergency contacts for when required.



My Mental Health and Emotional Well-being:



Emotional Support:  All staff to ensure I receive emotional support for overall well-being. Staff to report any low mood to Bryn and he should seek advice from the GP

Care Staff to engage in regular conversations with me and  my loved ones.

  

Mental Stimulation:

 Care staff to assist me to plug in my story reading machine that I require for mental stimulation.



All staff to be trained in managing my catheter. To also liaise with district nurses if required.



District Nurses to maintain my catheter changes every 12 weeks. Last changed 29th May 2024 due to bypassing.






REVIEW DATE
2024-11-29

REVIEWED BY
User Name

NAME OF OBJECTIVE
RELIGIOUS BELIEFS

GOAL
To ensure that my choices are respected


PROBLEM
I do not have any religious or spiritual beliefs. 



I do not wish to have any involvement in religious or spiritual beliefs at the time of illness. 



When I die I wish to have a celebrant service. 






ACTION
I wish to have a celebrant service for my funeral.



This ensures that my life is celebrated with family and friends.



I desire that staff members support and respect my decisions.



Should I choose to adopt any religious beliefs during a time of illness or incapacity, it is my wish that care staff respect my decisions and choices, in conjunction with Bryn.



Care staff or Bryn should reach out to a local place of worship, like a church or temple, to request a clergy member to arrange a home visit.






REVIEW DATE
2024-08-29

REVIEWED BY
User Name

NAME OF OBJECTIVE
FINANCES

GOAL
To ensure that I have all the items that I require and all my needs are met.


PROBLEM
Bryn deals with my day to day finances.

I do not leave the house and am unable to go shopping. 



He is able to log on to online banking and deal with all expenses and bills. 



Bryn orders all shopping for my meals and items for my daily care.






ACTION
Staff to provide receipts for anything that they purchase on my behalf.


Staff are to let Bryn know if any items are running low so that he can buy them from the shops.
If there are any maintenance issues then staff should report it to Bryn so he can organise an expert to come and look at it, to fix or replace the problem. 



If staff see any issues or have any concerns then they should report it to the Nurse manager. 





REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
SLEEPING

GOAL
To help ensure that I have sufficient quota and quality of sleep. 



To help me to reach my full mental, emotional, and physical potential.






PROBLEM
I am to have one waking night staff on duty each night.



Ensure I am monitored throughout the night and all care is documented.



I will be cared for overnight in a sensitive manner. 



Staff to ensure the environment is quite dark and conducive to a restful night’s sleep. 



Staff to keep noise to a minimum overnight.



As my condition deteriorates I will begin sleeping more during the day.



This could be a light sleep or deep sleep. Staff should ensure that I am kept comfortable and be left to sleep. 

 





 










ACTION


I sleep in my bedroom. However, I still need close monitoring overnight. Assist me with medication if prescribed, monitor and document its effect.



Care Staff to position me as I wish to ensure comfort.



I like to go to sleep after my night care is concluded any time after 10pm.

 

I do not like to be disturbed during the night, but it is essential that the staff keep an eye on me during this period and my bedroom door is left ajar.



I have expressed a wish ‘not’ to be turned in for pressure area care during the night.



 Ensure the home is locked and secured overnight.



Staff will continue to observe my skin integrity during all care interventions and will inform the Complex Nurse Specialist and District Nurse if there are any issues.



I take nitrazepam as I require at night. 



If I have a deep sleep in the day, I may wake up with some disorientation, staff should offer me reassurance and orientate me with the time and also offer me something to eat and drink.




REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
PAIN AND COMFORT

GOAL
I am always comfortable.


PROBLEM
I have osteoporosis of the spine with complications, and this causes me a lot of pain.



I am prescribed Oxycodone oral solution liquid for breakthrough pain.



I am prescribed regular pain relief for the pain.



I take my medication orally.








ACTION


I will request for my pain relief for breakthrough pain.



Bryn will prepare the medication and bring it to me. Staff are to document on the Mar Chart and PRN protocols ‘F’ to indicate that family have prepared and administered medication for Jen.  



Consent is to be always sought, and dignity and privacy are to be respected. 



Care Staff to monitor my pain using the 1-10 Pain Scale. It is important for staff to use the pain scale while observing my facial expressions, whimpering, frowning, or crying. Observe my body language, and any physiological or physical changes i.e. temperature, vomiting. Am I perspiring more than normal? 




REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
MEDICATION ADMINISTRATION

GOAL
Safe administration of my medication.



﻿


PROBLEM
I have the Capacity and require assistance from staff and Bryn in administering my medication.



Bryn prepares and administers my medications to me.


ACTION
At present Bryn prepares and administers my medications to me. 



Care staff are to write “F” for Family administration on the Mar Chart and PRN protocol where he has prepared and administered my medications.



If and when at any point that Bryn is unavailable to give me my medication, Trained care staff should prepare my medication ensuring they follow the 6 rights of medication.

Right Medication
Right Time
Right Dose
Right Patient
Right Route
Right to refuse.


Care staff should explain to me every step of my medication administration.



I like to have my medication independently with a glass of water.



Due to the taste of some of my medications, I usually have a small piece of chocolate after the medication.



All medication administered is to be signed for in the MAR CHARTS and recorded in daily notes.



Care Staff to ensure my medication is ordered on time. (Should order when there is at least 10 days’ supply in stock) Staff to remind Bryn to order medication.



Medication should be stored in a locked cabinet.



Care staff are to label medication boxes and bottles with the opening dates and stock levels to be counted after each administration.




NAME OF OBJECTIVE
PLANNING FOR THE FUTURE

GOAL
Manage Spinal Osteoporosis



Collaborate with Medical Professionals



Address Curved Spine and Immobility



Cope with Swallowing Deterioration


PROBLEM


Spinal Osteoporosis:

I have difficulties and require collaborative assistance from my husband, health professionals, GP, District Nurses, Compton, my care staff for managing pain, enhancing comfort, and slowing down the progression of the condition.



Curved Spine and Immobility:

I  require assistance in addressing challenges associated with a curved spine and prolonged bed rest.



Swallowing Deterioration: 

I am a Feed At Risk on level 5 Minced and Moist Diet. I require assistance from my care staff and other health professionals i.e. SALT in coping with complications related to the deterioration in swallowing. 



I am bed-bound and use pillows to support a comfortable posture.



I have an airflow mattress.






ACTION
Managing Spinal Osteoporosis:

Pain Relief Measures:

 My Husband Bryn prepares and administers my medication to me and ensures that I adhere to prescribed pain management medications as directed by healthcare professionals. (GP, Compton and the District Nurse)

 Care staff to assist me in incorporating non-pharmacological pain relief techniques such as heat therapy (I TEND TO USE A SMALL ELECTRIC BLANKET ON MY FEET) and gentle exercises and gentle rubbing of my calves and feet. I am currently able to verbally direct my care. I can verbally communicate pain. 

Enhancing Comfort:

Care staff to assist me in utilising ergonomic pillows and cushions to support proper body alignment and reduce discomfort on my bed.



Slowing Progression:

My husband administers my medication. Care staff are to assist me to ensure I strictly adhere to prescribed medications aimed at slowing down the progression of spinal osteoporosis.



Collaborating with Medical Professionals:

Active Participation in Consultations:

Care staff to assist me to actively engage in discussions with Compton Hospice, district nurses, and other healthcare professionals to share insights into my condition and contribute to decision-making.



 My Husband and my care staff should ensure I have regular home visits for check-ups and consultations to monitor the progress of interventions and adjust the care plan accordingly.

Following Recommendations:

 My husband and my care staff are to assist me in implementing recommendations provided by healthcare professionals promptly and consistently. Ensuring that I Collaborate with the healthcare teams to address emerging complications and modify interventions as needed.



Addressing Curved Spine and Immobility:

Enhancing Comfort Techniques:

I will only be repositioned when I am ready due to the pain I experience during turns. Care staff are to encourage me to be repositioned every 4 hours to alleviate discomfort associated with prolonged bed rest.

Care staff to ensure that my mattress is in working order and is plugged in at all times, to  reduce the risk of pressure sores.



Minimising Complications:

Care staff are to assist me to do gentle range-of-motion exercises to prevent joint stiffness and contractures.



Coping with Swallowing Deterioration:

Specialised Strategies:

 I am now Feed At Risk on a level 5 minced and moist diet and normal fluids, as per SALT recommendation. My husband Bryn and the Care staff are to prepare my meals following the recommendations and serve me small portions at a time.



Care staff are to monitor my catheter, changing bags, emptying and recording findings in their daily notes. District Nurses continue to monitor and change my catheter when it's due. Care staff to liaise with them if they are concerned.



Care staff are to encourage me to keep regular contact with my family via regular visits, telephone or email.


REVIEW DATE
2024-08-28

REVIEWED BY
User Name

NAME OF OBJECTIVE
END OF LIFE

GOAL
Ensure I experience a dignified end-of-life.



Facilitate active involvement of my family in my care and decisions.



Spend my final days comfortably within the familiar surroundings of my home.



Manage my pain effectively to enhance my overall quality of life.



Receive emotional support to alleviate the emotional burden for both me and my loved ones.



Ensure strict adherence to my advance care directives.



Maintain open and honest communication about my end-of-life preferences with my care.


PROBLEM
I am not for life saving treatment. I have a DNAR in place and staff should respect this. 

Staff to focus on effective pain management and attend to emotional well-being.



I want to be cared for at home and I have a respect form in place.



Care Staff to encourage ongoing and open communication with my family members.



Create and foster a peaceful and supportive home environment.



I require regular assessment and adjust the pain medication for optimal pain management.



I regularly engage in conversations addressing both my emotional needs and those of my family.



I regularly review and update my advance care directives to reflect my current wishes.



I initiate and maintain discussions about my wishes and desires for the end of life.



I can Clearly communicate my spiritual or religious preferences to my care team.



I have Discussed funeral and memorial arrangements with my family.



I prioritise and plan visits and gatherings to celebrate and cherish time with loved ones.



I Share my thoughts, reflections, and final messages with those close to me.



I Initiate discussions about and facilitate coordination with  Compton hospice care providers.

I Participate in monthly reviews to assess and adjust my end-of-life care plan as needed.

I have started to sleep for longer periods during the day this is due to a deterioration in in my condition.


ACTION
If I die then staff are to contact my Gp to certify my death, If it is out of hours then to contact an ambulance and give my DNAR status. 

My staff collaborates closely with my healthcare providers to optimise my comfort during this time.



Staff to Share my end-of-life preferences and decisions openly and honestly with my family.



 I am able to Communicate regularly with my care team regarding my pain levels and preferences.



Staff to Encourage my family to share memories, thoughts, and provide mutual emotional support.



Staff to Seek spiritual or religious support in alignment with my beliefs.



My family to Consult with legal professionals to ensure all legal matters are in order.



My family are aware of my preferences following my death. Regarding having a celebrant service followed by my cremation. 



My funeral has been arranged with the Co-Op. My husband Bryn has all the details for when the time comes. 



3 Angels staff to Collaborate actively with hospice professionals to ensure comprehensive care.



Staff to engage me in the monthly review process to adapt my end-of-life care plan based on evolving needs and desires.



Staff are to complete end-of-life training to understand the requirements and my needs as my condition deteriorates.




REVIEW DATE
2024-08-28

REVIEWED BY
User Name

REVIEWS
Add Care Plan Objectives Review
`;

//KEY: R = REFUSED, H = HOSPITAL, N = NAUSEA/VOMIT, X = NOT GIVEN F=FAMILY, y = given

export const preferredTimes = {
  "lunch": {
      "to": "15:00",
      "from": "12:00",
      "hours_to_spend": 0.45,
      "staff_required": 2,
      "active": true
  },
  "tea": {
      "hours_to_spend": 0.45,
      "staff_required": 2,
      "from": "16:00",
      "to": "18:00",
      "active": true
  },
  "morning": {
      "hours_to_spend": 0.45,
      "to": "11:00",
      "staff_required": 2,
      "active": true,
      "from": "07:00"
  },
  "bed": {
      "active": true,
      "staff_required": 2,
      "to": "22:00",
      "from": "19:00",
      "hours_to_spend": 0.45
  }
}