export const new_invite = (formLink: string) => {
  return `<!DOCTYPE html>
    <html>
    <head>
        <title>Employee Details Required</title>
        <style>
            /* CSS styles will be here */
        </style>
    </head>
    <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
        <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
        <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
    
            <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
               employment Details Required
            </h1>
    
            <p style="color: #475569; font-size: 13px; line-height: 24px;">
                Hi, we hope you're doing great.
                <br/>
                You are receiving this email as an invitation to fill in <strong> 3 Angels Complex Care </strong> employmnt details. 
                These details will be used to create your profile, please make sure these details are accurate and recent.
            </p>
    
            <a href="${formLink}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                fill here
            </a>
    
            <p style="color: #475569; font-size: 13px; line-height: 24px; font-weight: 500; margin: 12px 0;">
                If this was a mistake, just ignore this email or reach out to our support at support@3agelscomplexcare.com
            </p>
    
            <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
    
            <p style="color: #666666; font-size: 12px; line-height: 18px;">
                You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
            </p>
    
            <p style="color: #666666; font-size: 12px; line-height: 24px;">
                Copyright@ All rights reserved.
            </p>
        </div>
    </body>
    </html>
    `;
};

export const reference_invite = (
  formLink: string,
  fullname: string,
  candidate_name: string
) => {
  return `<!DOCTYPE html>
    <html>
    <head>
        <title>Reference Informatiom Required</title>
        <style>
            /* CSS styles will be here */
        </style>
    </head>
    <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
        <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
        <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
    
            <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
            Reference Informatiom Required
            </h1>
    
            <p style="color: #475569; font-size: 13px; line-height: 24px;">
                Hi <span style="text-transform: capitalize;">${fullname}</span>, we hope you're doing great.
                <br/>
                You are receiving this email as an invitation to fill in <strong> 3 Angels Complex Care Reference Form </strong> as you were added as a reference by <span style="text-transform: capitalize;">${candidate_name}</span>. 
                These details will be used to verfiy their profile and credebility, please make sure these details are accurate and recent.
            </p>
    
            <a href="${formLink}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                fill here
            </a>
    
            <p style="color: #475569; font-size: 13px; line-height: 24px; font-weight: 500; margin: 12px 0;">
                If this was a mistake, just ignore this email or reach out to our support at support@3agelscomplexcare.com
            </p>
    
            <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
    
            <p style="color: #666666; font-size: 12px; line-height: 18px;">
                You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
            </p>
    
            <p style="color: #666666; font-size: 12px; line-height: 24px;">
                Copyright@ All rights reserved.
            </p>
        </div>
    </body>
    </html>
    `;
};

export const corrections_invite = (formLink: string, fullname: string) => {
  return `<!DOCTYPE html>
    <html>
    <head>
        <title>Employment Details Corrections Required</title>
        <style>
            /* CSS styles will be here */
        </style>
    </head>
    <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
        <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
        <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
    
            <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
            Employment Details Corrections Required
            </h1>
    
            <p style="color: #475569; font-size: 13px; line-height: 24px;">
                Hi <span style="text-transform: capitalize;">${fullname}</span>, we hope you're doing great.
                <br/>
                3 Angels Complex Care HR Team has reviewed your registration data and found some details that need to be
                 corrected or updated. Please click the link below to get started:
            </p>
    
            <a href="${formLink}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                fill here
            </a>
    
            <p style="color: #475569; font-size: 13px; line-height: 24px; font-weight: 500; margin: 12px 0;">
                If this was a mistake, just ignore this email or reach out to our support at support@3agelscomplexcare.com
            </p>
    
            <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
    
            <p style="color: #666666; font-size: 12px; line-height: 18px;">
                You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
            </p>
    
            <p style="color: #666666; font-size: 12px; line-height: 24px;">
                Copyright@ All rights reserved.
            </p>
        </div>
    </body>
    </html>
    `;
};

export const submision_invite_complete = (formLink: string, id: string) => {
  return `<!DOCTYPE html>
    <html>
    <head>
        <title>New Form Submission</title>
        <style>
            /* CSS styles will be here */
        </style>
    </head>
    <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
        <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
        <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
    
            <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
            New Form Submission
            </h1>
    
            <p style="color: #475569; font-size: 13px; line-height: 24px;">
                Hi <span style="text-transform: capitalize;">Team</span>,
                <br/>
                An employmnt document with the following name :${id} has been updated following the the request / invite .
                Please use the name above as a search query to find the specific employee.
            </p>
    
            <a href="${formLink}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                open app
            </a>
    
            <p style="color: #475569; font-size: 13px; line-height: 24px; font-weight: 500; margin: 12px 0;">
                If this was a mistake, just ignore this email or reach out to our support at support@3agelscomplexcare.com
            </p>
    
            <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
    
            <p style="color: #666666; font-size: 12px; line-height: 18px;">
                You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
            </p>
    
            <p style="color: #666666; font-size: 12px; line-height: 24px;">
                Copyright@ All rights reserved.
            </p>
        </div>
    </body>
    </html>
    `;
};

export const application_approved = (employee: string) => {
  return `<!DOCTYPE html>
      <html>
      <head>
          <title>Application Approved</title>
          <style>
              /* CSS styles will be here */
          </style>
      </head>
      <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
          <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
          <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
      
              <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
              Application Approved
              </h1>
      
              <p style="color: #475569; font-size: 13px; line-height: 24px;">
                  Hi <span style="text-transform: capitalize;">${employee}</span>,
                  <br/>
                  Your job application / registration at 3 Angels Complex Care has been approved. Our HR Team will
                   reach out for any required additional details or following steps.

                   <br/>
                   <br/>
                   Kind Regards,
                   #3 Angels Complex Care Team
              </p>
      
              <p style="color: #475569; font-size: 13px; line-height: 24px; font-weight: 500; margin: 12px 0;">
                  If this was a mistake, just ignore this email or reach out to our support at support@3agelscomplexcare.com
              </p>
      
              <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
      
              <p style="color: #666666; font-size: 12px; line-height: 18px;">
                  You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
              </p>
      
              <p style="color: #666666; font-size: 12px; line-height: 24px;">
                  Copyright@ All rights reserved.
              </p>
          </div>
      </body>
      </html>
      `;
};

export const healthQ_mail = (formLink: string) => {
  return `<!DOCTYPE html>
      <html>
      <head>
          <title>Health Questionnaire Update Request</title>
          <style>
              /* CSS styles will be here */
          </style>
      </head>
      <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
          <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
          <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
      
              <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
              Health Questionnaire Update Request
              </h1>
      
              <p style="color: #475569; font-size: 13px; line-height: 24px;">
                  Hi , we hope you're doing great.
                  <br/>
                  3 Angels Complex Care HR Team is requesting that you update / fill the health qustionnaire form.<br/>
                   Please click the link below to get started:
              </p>
      

              <a href="${formLink}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                  click here
              </a>
      
              <p style="color: #475569; font-size: 13px; line-height: 24px; font-weight: 500; margin: 12px 0;">
                  If this was a mistake, just ignore this email or reach out to our support at support@3agelscomplexcare.com
              </p>
      
              <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
      
              <p style="color: #666666; font-size: 12px; line-height: 18px;">
                  You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
              </p>
      
              <p style="color: #666666; font-size: 12px; line-height: 24px;">
                  Copyright@ All rights reserved.
              </p>
          </div>
      </body>
      </html>
      `;
};

export const schedule_update = (app_link: string) => {
  return `<!DOCTYPE html>
        <html>
        <head>
            <title>Schedule Update</title>
            <style>
                /* CSS styles will be here */
            </style>
        </head>
        <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
            <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
            <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
        
                <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
               Schedule Update
                </h1>
        
                <p style="color: #475569; font-size: 13px; line-height: 24px;">
                    Hello there,
                    <br/>
                   Your working schedule has been updated. Please check the app for the latest updates.
                     <br/>
                     <br/>
                     Kind Regards,
                     3 Angels Complex Care Team
                </p>
                <br/>
                
                <a href="${app_link}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                    check updates
                </a>
        
                <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
        
                <p style="color: #666666; font-size: 12px; line-height: 18px;">
                    You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
                </p>
        
                <p style="color: #666666; font-size: 12px; line-height: 24px;">
                    Copyright@ All rights reserved.
                </p>
            </div>
        </body>
        </html>
        `;
};

export const leave_status_change = (app_link: string) => {
  return `<!DOCTYPE html>
          <html>
          <head>
              <title>Leave Application Update</title>
              <style>
                  /* CSS styles will be here */
              </style>
          </head>
          <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
              <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
              <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
          
                  <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
                 Leave Application Update
                  </h1>
          
                  <p style="color: #475569; font-size: 13px; line-height: 24px;">
                      Hello there,
                      <br/>
                    Your leave application has been updated. Please check the app for the latest updates.
                       <br/>
                       <br/>
                       Kind Regards,
                       3 Angels Complex Care Team
                  </p>
                  <br/>
                  
                  <a href="${app_link}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                      check updates
                  </a>
          
                  <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
          
                  <p style="color: #666666; font-size: 12px; line-height: 18px;">
                      You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
                  </p>
          
                  <p style="color: #666666; font-size: 12px; line-height: 24px;">
                      Copyright@ All rights reserved.
                  </p>
              </div>
          </body>
          </html>
          `;
};

export const leave_application_noti = (
  app_link: string,
  name: string,
  start: string,
  end: string
) => {
  return `<!DOCTYPE html>
            <html>
            <head>
                <title>Leave Application</title>
                <style>
                    /* CSS styles will be here */
                </style>
            </head>
            <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
                <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
                <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
            
                    <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
                   Leave Application
                    </h1>
            
                    <p style="color: #475569; font-size: 13px; line-height: 24px;">
                        Hi Team,
                        <br/>
                      There is a new leave application from ${name}, for the Period of ${start}  - ${end}. Please check the app for the latest updates.
                         <br/>
                         <br/>
                         Kind Regards,
                         3 Angels Complex Care Team
                    </p>
                    <br/>
                    
                    <a href="${app_link}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                        check updates
                    </a>
            
                    <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
            
                    <p style="color: #666666; font-size: 12px; line-height: 18px;">
                        You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
                    </p>
            
                    <p style="color: #666666; font-size: 12px; line-height: 24px;">
                        Copyright@ All rights reserved.
                    </p>
                </div>
            </body>
            </html>
            `;
};

export const new_Account = (user_obj: any) => {
  return `<!DOCTYPE html>
        <html>
        <head>
            <title>New Stellify Account Created</title>
            <style>
                /* CSS styles will be here */
            </style>
        </head>
        <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
            <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
            <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
        
                <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
               New Stellify Account Created
                </h1>
        
                <p style="color: #475569; font-size: 13px; line-height: 24px;">
                    Hi ${user_obj?.["first name"]}, a new account has been created on your behalf.
                    <br/>
                    Please use the link below to set your password and get started or use the provided password :
                </p>
        
                
                <a href="app_reset_link" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                    click here
                </a>
        
                <p style="color: #475569; font-size: 13px; line-height: 24px; font-weight: 500; margin: 12px 0;">
                   Stellify is a product of 3 Angels Complex Care. If you have any questions, please reach out
                    to our support at : support@3angelscarecomplex.com
                </p>
        
                <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
        
                <p style="color: #666666; font-size: 12px; line-height: 18px;">
                    You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
                </p>
        
                <p style="color: #666666; font-size: 12px; line-height: 24px;">
                    Copyright @ 3 Angels Complex Care. All rights reserved.
                </p>
            </div>
        </body>
        </html>
        `;
};

export const inventory_request = (
  app_link: string,
  client: string,
  user: string
) => {
  return `<!DOCTYPE html>
            <html>
            <head>
                <title>Inventory Request</title>
                <style>
                    /* CSS styles will be here */
                </style>
            </head>
            <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
                <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
                <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
            
                    <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
                   Inventory Request
                    </h1>
            
                    <p style="color: #475569; font-size: 13px; line-height: 24px;">
                        Hello there,
                        <br/>
                     There's a new inventory request from ${client} by ${user}. Please check the app for the latest updates.
                         <br/>
                         <br/>
                         Kind Regards,
                         3 Angels Complex Care Team
                    </p>
                    <br/>
                    
                    <a href="${app_link}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                        check updates
                    </a>
            
                    <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
            
                    <p style="color: #666666; font-size: 12px; line-height: 18px;">
                        You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
                    </p>
            
                    <p style="color: #666666; font-size: 12px; line-height: 24px;">
                        Copyright@ All rights reserved.
                    </p>
                </div>
            </body>
            </html>
            `;
};

export const new_incident = (app_link: string, client_name: string) => {
  return `<!DOCTYPE html>
          <html>
          <head>
              <title>New Incident</title>
              <style>
                  /* CSS styles will be here */
              </style>
          </head>
          <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
              <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
              <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
          
                  <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
                 New Incident
                  </h1>
          
                  <p style="color: #475569; font-size: 13px; line-height: 24px;">
                      Hello there,
                      <br/>
                    A new incident has been reported for ${client_name}. Please check the app for the latest updates.
                       <br/>
                       <br/>
                       Kind Regards,
                       3 Angels Complex Care Team
                  </p>
                  <br/>
                  
                  <a href="${app_link}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                      check updates
                  </a>
          
                  <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
          
                  <p style="color: #666666; font-size: 12px; line-height: 18px;">
                      You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
                  </p>
          
                  <p style="color: #666666; font-size: 12px; line-height: 24px;">
                      Copyright@ All rights reserved.
                  </p>
              </div>
          </body>
          </html>
          `;
};

export const new_complaint = (app_link: string, client_name: string) => {
  return `<!DOCTYPE html>
            <html>
            <head>
                <title>New complaint</title>
                <style>
                    /* CSS styles will be here */
                </style>
            </head>
            <body style="font-family: sans-serif; background-color: #f3f4f6; margin: auto; padding: 10px;">
                <div style="border: 1px solid #e5e7eb; border-radius: 0.375rem; margin: 40px auto; padding: 20px; width: 465px; background-color: #ffffff;">
                <img src="https://firebasestorage.googleapis.com/v0/b/careconnek.appspot.com/o/logo%2Fcompany_logo.png?alt=media&token=ac457a54-eb7a-4fe4-8d13-aba898c3ad68" alt="logo" style="width: 80px; height: 50px; object-position: center;object-fit: cover;" />
            
                    <h1 style="color: #000; font-size: 18px; font-weight: bold; text-align: left; padding: 0; margin: 30px 0;">
                   New complaint
                    </h1>
            
                    <p style="color: #475569; font-size: 13px; line-height: 24px;">
                        Hello there,
                        <br/>
                      A new complaint has been reported for ${client_name}. Please check the app for the latest updates.
                         <br/>
                         <br/>
                         Kind Regards,
                         3 Angels Complex Care Team
                    </p>
                    <br/>
                    
                    <a href="${app_link}" style="display: inline-block; padding: 6px 24px; border-radius: 0.125rem; background-color: #0ea5e9; font-size: 0.6rem; color: #ffffff; text-transform: uppercase; font-weight: 600;text-decoration-line: none;">
                        check updates
                    </a>
            
                    <hr style="border: 1px solid #eaeaea; margin: 26px 0; width: 100%;">
            
                    <p style="color: #666666; font-size: 12px; line-height: 18px;">
                        You have received this email because you are either the designated primary contact for mandatory service communications or a designated recipient for this alert type.
                    </p>
            
                    <p style="color: #666666; font-size: 12px; line-height: 24px;">
                        Copyright@ All rights reserved.
                    </p>
                </div>
            </body>
            </html>
            `;
};
