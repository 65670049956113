import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";

interface WithPermissionProps {
  expectedPermission: string;
  expectedValue: boolean | string;
}

const withPermission = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const WithPermission: React.FC<P & WithPermissionProps> = (props) => {
    const navigaate = useNavigate();
    const { expectedPermission, expectedValue, ...rest } = props;
    const session_role = useSelector(
      (state: RootState) => state.session_data?.session_role
    );

    useEffect(() => {
      const permissionValue = session_role?.permissions?.[expectedPermission];

      if (session_role&&(permissionValue !== expectedValue)) {
        navigaate("/");
      }
    }, [session_role?.permissions, expectedPermission, expectedValue, navigaate]);

    return <WrappedComponent {...(rest as P)} />;
  };

  return WithPermission;
};

export default withPermission;
