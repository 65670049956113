import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateType {
  assessments: any;
}

const initialState: InitialStateType = {
  assessments: [],
};

export const AssessmentsSlice = createSlice({
  name: "assessments_data",
  initialState,
  reducers: {
    setAssessmentsData: (state, action: PayloadAction<any>) => {
      state.assessments = action.payload;
    },
  },
});

export const {
  setAssessmentsData,
} = AssessmentsSlice.actions;

export default AssessmentsSlice.reducer;
