import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = import.meta.env.VITE_MAP_BOX as string;

type Props = {
  filtered_locations: any;
  active_client: string;
};

const Map = ({ filtered_locations, active_client }: Props) => {
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markerRefs = useRef<mapboxgl.Marker[]>([]);

  //Render Map
  useEffect(() => {
    const initializeMap = async () => {
      const mapContainer = document.getElementById("admin_dash_map");
      if (mapContainer && !mapRef.current) {
        mapRef.current = new mapboxgl.Map({
          container: mapContainer,
          style: "mapbox://styles/mapbox/streets-v12",
          center:
            filtered_locations?.length >= 1
              ? [
                  filtered_locations?.[1]?.location.longitude,
                  filtered_locations?.[1]?.location.latitude,
                ]
              : [-2.12461, 52.58618],
          zoom: 8,
          //attributionControl: false,
        });
      }
      // Existing marker updates and cleanup logic here
      filtered_locations.forEach((data: any, index: number) => {
        if (markerRefs.current[index]) {
          markerRefs.current[index].setLngLat([
            data.location.longitude,
            data.location.latitude,
          ]);
        } else {
          const marker = new mapboxgl.Marker({ color: "black", rotation: 45 })
            .setLngLat([data.location.longitude, data.location.latitude])
            .addTo(mapRef.current!); // add to map
          markerRefs.current[index] = marker;
        }
      });

      // Remove extra markers
      markerRefs.current
        .splice(filtered_locations.length)
        .forEach((m) => m.remove());
    };

    initializeMap();

    // Zoom to active client's location
    if (active_client && mapRef.current) {
      const selectedClient = filtered_locations.find(
        (data: any) => data.client_id === active_client
      );
      if (selectedClient) {
        mapRef.current.flyTo({
          center: [
            selectedClient.location.longitude,
            selectedClient.location.latitude,
          ],
          zoom: 14, // Adjust zoom level as desired
          essential: true,
        });
      }
    }
  }, [filtered_locations]);

  //JSX Code ==================
  return (
    <div
      id={"admin_dash_map"}
      className="w-full h-full rounded bg-zinc-50 border border-zinc-300"
    />
  );
};

export default React.memo(Map);
