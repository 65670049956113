import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Loader from "../components/misc/loader";
import Fetcher from "../firebase/data/fetcher";
import UpdateAlert from "../components/misc/UpdateAlert";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Spotlight from "../components/spotlight/Spotlight";
import { usePostHog } from "posthog-js/react";

const PrivateRoutes = () => {
  const posthog = usePostHog();
  const { currentUser, loading }: any = useAuth();
  const user = useSelector((state: RootState) => state.session_data.user);

  React.useEffect(() => {
    if (user) {
      posthog?.identify(user?.uid || user?.id, {
        email: user?.email,
      });
      posthog?.group("company", "Stellify");
    }
  }, [posthog, user]);

  //Component ==============
  return loading ? (
    <Loader />
  ) : !loading && currentUser ? (
    <>
      <Fetcher />
      {user?.uid ? (
        <>
          <UpdateAlert />
          <Spotlight />
          <Outlet />
        </>
      ) : (
        <Loader />
      )}
    </>
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoutes;
