/* eslint-disable no-unsafe-optional-chaining */
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";

type Props = {
  setDatesRange: any;
  range: any;
  monthMap: any;
  overlapStartMonth: any;
  getDays: any;
  startSelected: any;
  setEnd: any;
  setStartSelect: any;
  startDate: any;
  setStartDate: any;
  end: any;
};

const LeftHalf = ({
  setDatesRange,
  range,
  monthMap,
  overlapStartMonth,
  getDays,
  startSelected,
  setEnd,
  setStartSelect,
  setStartDate,
  startDate,
  end,
}: Props) => {
  return (
    <div className="col-span-1 w-full gap-1 p-2 overflow-hidden">
      {/**Top Nav ==================== */}
      <div className="h-10 w-full flex items-center justify-between">
        <button
          type="button"
          onClick={() => {
            setDatesRange((prev: any) => ({
              ...prev,
              firstHalf: {
                month:
                  prev.firstHalf?.month > 0 ? prev.firstHalf?.month - 1 : 11,
                year:
                  prev.firstHalf?.month > 0
                    ? prev.firstHalf?.year
                    : prev.firstHalf?.year - 1,
              },
              secondHalf: {
                month:
                  prev.secondHalf?.month > 0 ? prev.secondHalf?.month - 1 : 11,
                year:
                  prev.secondHalf?.month > 0
                    ? prev.secondHalf?.year
                    : prev.secondHalf?.year - 1,
              },
            }));
          }}
          className="h-8 w-8 flex items-center justify-center bg-black-100 dark:bg-black-600 
        hover:opacity-80 transition-all rounded"
        >
          <TbChevronLeft />
        </button>
        <div
          className="text-xs font-sans font-semibold uppercase flex items-center justify-end gap-2 
           overflow-hidden whitespace-nowrap overflow-ellipsis tracking-wider"
        >
          <select
            onChange={(e) => {
              setDatesRange((prev: any) => ({
                ...prev,
                firstHalf: {
                  ...prev?.firstHalf,
                  month: Number(e.target.value),
                },
                secondHalf: {
                  ...prev?.secondHalf,
                  month:
                    Number(e.target.value) <= 10
                      ? Number(e.target.value) + 1
                      : 11,
                },
              }));
            }}
            name="month_prev"
            id="month_prev"
            className="selectDate"
          >
            <option value={range?.firstHalf?.month}>
              {monthMap?.[range?.firstHalf?.month]}
            </option>
            <option value={0}>jan</option>
            {[0, ...Array(11)]
              .map((_, i) => i + 1)
              ?.map((month: number) => {
                return (
                  <option key={month} value={month}>
                    {monthMap?.[month]}
                  </option>
                );
              })}
          </select>
          <select
            onChange={(e) => {
              setDatesRange((prev: any) => ({
                ...prev,
                firstHalf: {
                  ...prev?.firstHalf,
                  year: Number(e.target.value),
                },
                secondHalf: {
                  ...prev?.secondHalf,
                  year:
                    prev?.month >= 11
                      ? Number(e.target.value) + 1
                      : Number(e.target.value),
                },
              }));
            }}
            name="year_prev"
            id="year_prev"
            className="selectDate"
          >
            <option value={range?.firstHalf?.year}>
              {range?.firstHalf?.year}
            </option>
            {[
              ...[...Array(20)]
                .map((_, i) => i + 1)
                ?.map((num: number) => range?.firstHalf?.year - num),
              ...[...Array(20)]
                .map((_, i) => i + 1)
                ?.map((num: number) => range?.firstHalf?.year + num),
            ]
              ?.sort((a: number, b: number) => a - b)
              ?.map((year: number) => {
                return <option key={year}>{year}</option>;
              })}
          </select>
        </div>
        <button
          type="button"
          onClick={() => {
            setDatesRange((prev: any) => ({
              ...prev,
              firstHalf: {
                month:
                  prev.firstHalf?.month < 11 ? prev.firstHalf?.month + 1 : 0,
                year:
                  prev.firstHalf?.month < 11
                    ? prev.firstHalf?.year
                    : prev.firstHalf?.year + 1,
              },
              secondHalf: {
                month:
                  prev.secondHalf?.month < 11 ? prev.secondHalf?.month + 1 : 0,
                year:
                  prev.secondHalf?.month < 11
                    ? prev.secondHalf?.year
                    : prev.secondHalf?.year + 1,
              },
            }));
          }}
          className="h-8 w-8 flex items-center justify-center md:hidden bg-black-100 dark:bg-black-600
           hover:opacity-80 transition-all rounded bow-shadow-one"
        >
          <TbChevronRight />
        </button>
      </div>

      {/**Heding ===================== */}
      <div className="h-7 w-full grid grid-cols-7">
        {["su", "mo", "tu", "we", "th", "fr", "sa"].map((day) => {
          return (
            <div
              key={day + 1}
              className="col-span-1 h-full flex items-center justify-center text-xs capitalize font-bold"
            >
              {day}
            </div>
          );
        })}
      </div>
      {/**Day ===================== */}
      <div className="mt-3 h-[10rem] w-full grid grid-cols-7 grid-rows-6 gap-1">
        {/**Overlap ============= */}
        {overlapStartMonth()?.map((day: any) => {
          return (
            <div
              key={day + "h"}
              className="col-span-1 row-span-1 h-full flex items-center justify-center text-xs capitalize "
            ></div>
          );
        })}
        {/**Days ============= */}
        {getDays(range?.firstHalf?.year, range?.firstHalf?.month)?.map(
          (day: any) => {
            return (
              <button
                type="button"
                onClick={() => {
                  if (!startSelected) {
                    setStartDate(day.dayMilli);
                    setEnd(null);
                    setStartSelect(true);
                  } else if (startSelected && day?.dayMilli >= startDate) {
                    setEnd(day?.dayMilli);
                    setStartSelect(false);
                  } else if (day?.dayMilli < startDate) {
                    setStartDate(day?.dayMilli);
                    setEnd(null);
                    setStartSelect(true);
                  }
                }}
                data-date={day.dayMilli}
                key={day.dayMilli}
                className={`col-span-1 h-full row-span-1 flex items-center justify-center text-xs capitalize 
            transition-all outline-none focus:outline-none rounded-sm peer font-medium  ${
              new Date(day.dayMilli)?.toDateString() ===
                new Date()?.toDateString() &&
              new Date(day.dayMilli)?.toDateString() !==
                new Date(startDate)?.toDateString() &&
              new Date(day.dayMilli)?.toDateString() !==
                new Date(end)?.toDateString()
                ? "border border-black-600 dark:border-black-700"
                : new Date(day.dayMilli)?.toDateString() ===
                    new Date(startDate)?.toDateString() ||
                  new Date(day.dayMilli)?.toDateString() ===
                    new Date(end)?.toDateString()
                ? "bg-sky-500 dark:bg-black-900 text-white"
                : day.dayMilli >= startDate && day.dayMilli <= end
                ? "bg-zinc-200 dark:bg-black-600"
                : ""
            }`}
              >
                {day?.day}
              </button>
            );
          }
        )}
      </div>
    </div>
  );
};

export default LeftHalf;
