import { limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { leavesRef } from "../functions";
import { RootState } from "../../redux/store";
import { loadAllLeaves } from "../../redux/slices/leave_slice";

const LeaveData = () => {
  const { currentUser }: any = useAuth();
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const dispatch = useDispatch();

  //Fetch Data And Store It Locally
  useEffect(() => {
    let unsubscribe: () => void;

    if (
      currentUser?.uid &&
      session_role?.permissions?.["can the user view employees leave"]
    ) {
      unsubscribe = onSnapshot(
        query(leavesRef, orderBy("date", "desc"), limit(10000)),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            loadAllLeaves(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, currentUser, session_role?.permissions]);

  //Fetch Empoyee Data
  useEffect(() => {
    let unsubscribe: () => void;

    if (
      currentUser?.uid &&
      !session_role?.permissions?.["can the user view employees leave"]
    ) {
      unsubscribe = onSnapshot(
        query(
          leavesRef,
          where("user.uid", "==", currentUser?.uid),
          orderBy("date", "desc")
        ),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            loadAllLeaves(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, currentUser, session_role?.permissions]);

  //Compoent ===========================
  return <></>;
};

export default LeaveData;
