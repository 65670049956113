import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { limit, onSnapshot, query } from "firebase/firestore";
import { useEffect } from "react";
import { carePlansRef, careRef, clientsRef } from "../functions";
import { loadCareDocs, loadCarePlans } from "../../redux/slices/care_slice";
import { updateLocalClients } from "../../redux/slices/lists_slice";
import { RootState } from "../../redux/store";
import { ClientType } from "../../types/client_types";

const CareData = () => {
  const { currentUser }: any = useAuth();
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const dispatch = useDispatch();

  //Fetch Data And Store It Locally
  useEffect(() => {
    let unsubscribe: () => void;

    if (currentUser?.uid) {
      unsubscribe = onSnapshot(
        query(careRef, limit(10000)),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            loadCareDocs(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, currentUser]);

  //Fetch Data And Store It Locally
  useEffect(() => {
    let unsubscribe: () => void;

    if (currentUser?.uid) {
      unsubscribe = onSnapshot(
        query(clientsRef, limit(10000)),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          const data = snapshot.docs
            .map((doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            }))
            ?.filter((data: ClientType) =>
              session_role?.permissions?.[
                "allow user to manage assigned clients only"
              ]
                ? data?.["assigned nurse"]?.includes(currentUser?.uid)
                : true
            );
          dispatch(updateLocalClients(data));
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, currentUser, session_role]);

  //Fetch Care Plans
  useEffect(() => {
    let unsubscribe: () => void;

    if (currentUser?.uid) {
      unsubscribe = onSnapshot(
        query(carePlansRef, limit(10000)),
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          const data = snapshot.docs.map(
            (doc: { data: () => any; id: any }) => ({
              ...doc.data(),
              id: doc.id,
            })
          );
          dispatch(loadCarePlans(data));
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, currentUser, session_role]);

  //Compoent ===========================
  return <></>;
};

export default CareData;
