import React, { useMemo, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
//import { RoleType } from "../../../types/session_type";
import { RootState } from "../../../redux/store";
//import { FaUserShield } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { RiArrowRightUpLine } from "react-icons/ri";
import { crypt } from "../../../utils/reusable_functions";
import { toast } from "sonner";
import { v4 as uuid } from "uuid";
import { new_invite } from "../../../components/email/templates";
import { createPortal } from "react-dom";

type Props = {
  formdata: any;
  setFormData: any;
};

const InviteEmployee = ({ formdata, setFormData }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  // const all_roles: RoleType[] | [] = useSelector(
  //   (state: RootState) => state.session_data?.all_roles
  // );
  const forms = useSelector((state: RootState) => state.forms_data?.forms);
  const registration_form = useMemo(() => {
    const data = forms || [];
    return (
      [...data]?.find(
        (form: any) => form?.name?.toLowerCase() === "registration form"
      ) || {}
    );
  }, [forms]);

  //Handle Invite
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    const nrf = uuid();

    try {
      const res = await fetch(import.meta.env.VITE_NEWINVITE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formdata?.email,
          doc_id: registration_form?.id,
          invite_id: nrf,
          body: new_invite(
            `${window.location.origin}/new_employee_form/?invite_id=${nrf}`
          ),
        }),
      });

      if (!res.ok) {
        setLoading(false);
        toast.error("We ran into an error please retry");
        return new Error("We ran into an error");
      }

      setLoading(false);
      setFormData(null);
      toast.success("Invitation sent");
    } catch (error: any) {
      toast.error(
        error?.message?.replace(/firebase/gim, "") || "We ran into an error"
      );
    } finally {
      setLoading(false);
    }
  };

  //Component
  return (
    <React.Fragment>
      {createPortal(
        <motion.div
          initial={{ left: "100%", right: "-100%", opacity: 0.7 }}
          exit={{ left: "100%", right: "-100%", opacity: 0.7 }}
          animate={{ left: 0, right: 0, opacity: 1 }}
          className="fixed top-0 bottom-0 left-0 right-0 z-[9999999] bg-black/70 backdrop-blur-sm
      overflow-hidden flex items-center justify-end"
        >
          <motion.form
            onSubmit={handleSubmit}
            initial={{ left: "100%", right: "-100%", opacity: 0.7 }}
            exit={{ left: "100%", right: "-100%", opacity: 0.7 }}
            animate={{ left: 0, right: 0, opacity: 1 }}
            className="w-full md:w-[38rem] h-full bg-cream overflow-hidden flex flex-col p-4 md:p-8"
          >
            {/**Invite*/}
            <div className="w-full h-16 shrink-0 px-4 flex md:items-center md:justify-between flex-col md:flex-row">
              <h1 className="text-xl font-extrabold text-zinc-600 !leading-normal">
                Invite New Employee
              </h1>

              <Link
                onClick={() => {
                  localStorage.setItem(
                    "nrf",
                    crypt(
                      import.meta.env.VITE_MS_ID,
                      JSON.stringify(registration_form)
                    )
                  );
                }}
                to="/new_employee_form"
                className="text-xss uppercase font-bold text-sky-500 flex items-center gap-0.5 hover:underline transition-all"
              >
                <span className="font-bold">add manually</span>{" "}
                <RiArrowRightUpLine className="text-lg shrink-0" />
              </Link>
            </div>

            {/**Invitee email */}
            <label
              htmlFor="invite_email"
              className="w-full h-fit shrink-0 px-4 overflow-hidden"
            >
              <input
                onChange={(e) =>
                  setFormData((prev: any) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
                value={formdata?.email || ""}
                name="invite_email"
                id="invite_email"
                required
                type="email"
                autoComplete="off"
                placeholder="Employee email"
                className="bg-white w-full h-14 px-4 border border-zinc-200 shrink-0 placeholder:text-zinc-500
             text-zinc-700 text-xs font-medium focus:border-sky-300 transition-all"
              />
            </label>

            {/**Notice */}
            <p className="mt-6 text-xs font-semibold text-zinc-500 px-4">
              By clicking the 'Invite' button below, an email containing an
              invitation reference will be sent to the email address provided
              above. Please ensure the email is correct before proceeding to
              avoid spamming.
            </p>

            {/**Bottom Option */}
            <div className="mt-6 w-full h-16 shrink-0 flex items-center justify-between md:justify-end gap-2 px-4 overflow-hidden">
              <button
                disabled={loading}
                type="button"
                onClick={() => {
                  setFormData(null);
                }}
                className="h-9 w-32 rounded-sm border border-sky-500/40 bg-white text-xxs uppercase text-sky-500 font-bold
            disabled:cursor-not-allowed disabled:opacity-80"
              >
                cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="h-9 w-32 rounded-sm bg-sky-500 text-xxs text-white font-bold uppercase
             disabled:cursor-not-allowed disabled:opacity-80 flex items-center justify-center"
              >
                {loading ? (
                  <div className="h-5 w-5 rounded-full border-2 border-sky-400 border-l-white animate-spin"></div>
                ) : (
                  <span className="font-bold">invite</span>
                )}
              </button>
            </div>
          </motion.form>
        </motion.div>,
        document.body
      )}
    </React.Fragment>
  );
};

export default React.memo(InviteEmployee);
