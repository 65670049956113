import {
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { TbEye, TbEyeOff, TbLock, TbMail } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebase_config";
import { toast } from "sonner";
import Loader from "../../components/misc/loader";
import { useAuth } from "../../hooks/useAuth";
import { usePostHog } from "posthog-js/react";

const Login = () => { 
  const posthog = usePostHog();
  const { currentUser, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [revealPassword, setReveal] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  //Signin
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = {
      password: (form.elements.namedItem("password_signin") as HTMLInputElement)
        .value,
      email: (form.elements.namedItem("email_signin") as HTMLInputElement)
        .value,
    };

    if (!loading) {
      setLoading(true);
      const promise = new Promise((resolve, reject) => {
        try {
          setPersistence(auth, browserLocalPersistence);
          signInWithEmailAndPassword(auth, formData.email, formData.password)
            .then(() => {
              navigate("/");
              posthog?.capture("clicked_log_in");
              resolve("Logged in successfully");
            })
            .catch((error: any) => {
              reject(error);
            });
        } catch (error: any) {
          reject(error);
        } finally {
          setLoading(false);
        }
      });

      //Show Progress
      toast.promise(promise, {
        loading: (() => {
          setLoading(true);
          return "Please wait ...";
        })(),
        success: () => {
          return `Logged in successfully`;
        },
        error: (error: any) => {
          toast.error(error.message);
          setLoading(false);
          return `We ran into an error : ${error?.message}`;
        },
      });
    }
  };

  //Redirect if user is already logged in
  useEffect(() => {
    if (currentUser && !authLoading) {
      navigate("/");
    }
  }, [currentUser, authLoading, navigate]);

  //Component ============================
  return (
    <>
      {!loading ? (
        <div
          className="fixed top-0 bottom-0 right-0 left-0 bg-diamond bg-sky-50 flex items-center justify-center p-6 overflow-hidden"
        >
          <div
            className="w-full md:w-[27rem] h-fit p-6 md:px-8 md:py-10 md:bg-zinc-50 md:shadow flex flex-col items-center
             justify-center z-10 order-last lg:order-none rounded-xl md:rounded-lg overflow-hidden"
          >
            {/** ==================== Top Left logo ================= */}
            <img
              src="/logo/maskable_icon.png"
              alt="logo"
              className="h-12
               w-12 shadow bg-sky-500 p-1 rounded-full border-2 border-zinc-100"
            />

            {/** ==================== Heading ================= */}
            <h1 className="mt-2 text-sky-900 text-sm text-center uppercase font-extrabold">
              sign in
            </h1>
            <p className="text-sky-900/70 text-xs text-center font-medium mt-1">
              Please enter your credentials below to sign in
            </p>

            {/** ==================== Login form ================= */}
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="mt-3 w-full h-fit flex flex-col"
            >
              <label
                htmlFor="email_signin"
                className="flex items-center h-12 w-full bg-cream rounded relative border
               border-sky-200 focus-within:border-sky-500 focus-within:ring ring-sky-500/50 px-4
                gap-4 overflow-hidden transition-all"
              >
                <TbMail className="text-xl shrink-0 text-zinc-400" />
                <input
                  type="email"
                  name="email_signin"
                  id="email_signin"
                  placeholder="Email"
                  required
                  className="h-full w-full rounded-full border-none outline-none focus:outline-none
               placeholder:text-zinc-500 text-sm text-zinc-700 bg-inherit"
                />
              </label>
              <label
                htmlFor="password_signin"
                 className="flex items-center h-12 w-full bg-cream rounded relative mt-2 border
                border-sky-200 focus-within:border-sky-500 focus-within:ring ring-sky-500/50 px-4
               gap-4 overflow-hidden transition-all"
              >
                <TbLock className="text-xl shrink-0 text-zinc-400" />
                <input
                  type={revealPassword ? "text" : "password"}
                  name="password_signin"
                  id="password_signin"
                  placeholder="Password"
                  required
                  className="h-full w-full rounded-full border-none outline-none focus:outline-none
               placeholder:text-zinc-500 text-sm text-zinc-700 bg-inherit"
                />
                <button
                  onClick={() => setReveal((prev: boolean) => !prev)}
                  type="button"
                  className="shrink-0 text-xl text-zinc-500"
                >
                  {revealPassword ? <TbEyeOff /> : <TbEye />}
                </button>
              </label>

              {/** ==================== Rest Options ================= */}
              <div className="mt-2 w-full flex items-center justify-end overflow-hidden">
                <button
                  onClick={() => {
                    navigate("/forgot_password");
                  }}
                  type="button"
                  className="text-xs text-center pt-0.5 text-sky-900/60 font-medium whitespace-nowrap truncate
                   peer-checked:text-sky-500 transition-all mr-1 underline w-full"
                >
                  Forgot password ?
                </button>
              </div>

              {/** ==================== SignIn button ================= */}
              <button
                className="mt-4 h-12 w-full bg-sky-500 text-xs font-semibold text-white
                 uppercase rounded flex items-center justify-center gap-2 hover:gap-4 hover:bg-sky-800 
                 hover:shadow-lg transition-all duration-200"
              >
                <span className="font-bold">sign-in</span>
              </button>

              {/** ==================== Copyright ================= */}
              <span className="mt-4 text-center text-zinc-400 text-xs">
                &copy; Copyright {new Date().getFullYear()}. 3 Angels Complex
                Care
              </span>
            </form>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Login;
