import { Button } from "@/components/ui/button";
import { SearchIcon } from "lucide-react";
import Profile from "./profile";
import Notifications from "./notifications";
import { useDispatch } from "react-redux";
import { openSpotlight } from "@/redux/slices/spotlight";

const Topnav = () => {
  const dispatch = useDispatch();

  //JSX Content ===========================
  return (
    <nav className="h-14 shrink-0 px-5 flex items-center justify-between gap-4">
      <Profile />

      {/**Search */}
      <Button
        onClick={() => dispatch(openSpotlight(true))}
        variant={"outline"}
        className="h-8 w-full md:w-96 rounded-lg drop-shadow-sm bg-zinc-50 border-zinc-300
        justify-start items-center gap-2 text-zinc-500 px-2"
      >
        <SearchIcon size={18} className="text-zinc-400" />{" "}
        <span>What are you looking for ...</span>
      </Button>

      {/**Notifications */}
      <Notifications />
    </nav>
  );
};

export default Topnav;
