import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { TbArrowNarrowLeft } from "react-icons/tb";
import { useMemo, useState } from "react";
//import mapboxgl from "mapbox-gl";
//import turf from "@turf/turf";
import distance from "@turf/distance";
import { point } from "@turf/helpers";
import { setFixedBody } from "../../../redux/slices/session_slice";
import { ScheduleEventType } from "../../../types/schedule_types.d";
import { toast } from "sonner";
import { updateSchedule } from "../../../firebase/functions";
import { useNavigate } from "react-router-dom";

type Props = {
  clockMOdalOpen: ScheduleEventType;
  setClockModal: (item: ScheduleEventType | null) => void;
};

const ClockIn = ({ clockMOdalOpen, setClockModal }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const targetLocation = useMemo(
    () => ({
      latitude: clockMOdalOpen?.client?.latitude,
      longitude: clockMOdalOpen?.client?.longitude,
    }),
    [clockMOdalOpen?.client?.longitude, clockMOdalOpen?.client?.latitude]
  );
  const user = useSelector((state: RootState) => state?.session_data.user);
  const [passStatus, setPassStatus] = useState<
    "fresh" | "pending" | "passed" | "failed"
  >("fresh");

  //Check If addresses / location are a match
  const clockIn = () => {
    const is_android = /android/i.test(navigator.userAgent);
    //Get Latest Location
    // Define a function that returns a promise for the user's location
    const getUserLocation = async () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            console.log(error, "Failed");
            toast.error("We failed to locate you please turn on location");
            reject(error);
          }
        );
      });
    };

    //Create func
    const promise = async () => {
      const userLocation: any = !is_android && (await getUserLocation());
      try {
        if (userLocation || is_android) {
          setPassStatus("pending");
          const from = await point([
            targetLocation.longitude,
            targetLocation.latitude,
          ]);
          const to = await point([
            userLocation?.longitude || 0,
            userLocation?.latitude || 0,
          ]);
          const options = { units: "meters" };
          const calculatedDistance = await distance(from, to, options);
          setTimeout(() => {
            if (calculatedDistance < 50 || is_android) {
              setPassStatus("passed");
              const is_late = Date.now() > clockMOdalOpen?.start_date + 900000;

              const clock_data = {
                id: clockMOdalOpen?.id,
                clock_in_time: new Date().getTime(),
                clock_out_time: "",
                is_late: is_late,
                late_time: is_late
                  ? Date.now() > clockMOdalOpen?.start_date + 900000
                  : "",
                status: "clocked_in",
                user_agent: navigator.userAgent,
                distance: is_android ? 0 : calculatedDistance,
              };

              toast.promise(updateSchedule(clock_data), {
                loading: "Redirecting Please wait ...",
                success: (data: any) => {
                  localStorage.setItem("shift_id", JSON.stringify(data));
                  navigate("/care_profile");
                  setClockModal(null);
                  return "Clocked in";
                },
                error: (error: any) => {
                  return error?.message || "We ran into an error";
                },
              });
            } else {
              setPassStatus("failed");
              //throw new Error("We couldn't match your location, retry");
            }
          }, 3000);
        } else {
          toast.error("We failed to locate you please turn on location");
        }
      } catch (error) {
        setPassStatus("failed");
        console.log(error);
        //throw error;k
      }
    };

    promise();
  };

  //Component ===================
  return (
    <motion.div
      initial={{ opacity: 0.2, left: "100%", right: "-100%" }}
      exit={{ opacity: 0.2, left: "100%", right: "-100%" }}
      animate={{ opacity: 1, left: 0, right: 0 }}
      className="fixed inset-0 bg-black/60 clear-both backdrop-blur-md
      z-[99999999] flex items-center justify-center overflow-hidden overflow-y-auto md:py-10"
    >
      <div
        className="h-full md:h-[30rem] w-full md:w-[30rem] bg-white md:rounded p-6
          overflow-hidden flex flex-col items-center justify-center"
      >
        <h1 className="text-xl font-bold text-sky-500 text-center shrink-0">
          Good Day 👋, {user?.username}{" "}
        </h1>
        <h2 className="text-sm font-semibold text-zinc-500 text-center shrink-0">
          {passStatus === "pending"
            ? "Clocking in ..."
            : passStatus === "failed"
            ? "We ran into an error, please retry"
            : "Let's get to work."}
        </h2>

        {/**Show images based on pass status */}
        {passStatus === "fresh" ? (
          <>
            <img
              src="/logo/maskable_icon.png"
              alt="profile"
              className="mt-6 shrink-0 h-24 w-24 rounded-full bg-sky-500 text-white text-xl font-semibold
             uppercase flex items-center justify-center object-cover object-center border-4 border-sky-500"
            />
            {/* <div
          className="mt-4 shrink-0 h-20 w-20 rounded-full bg-sky-500 text-white text-xl font-semibold
           uppercase flex items-center justify-center"
        >
          {user?.username?.charAt(0)}
        </div> */}
          </>
        ) : passStatus === "pending" ? (
          <div
            className="mt-4 shrink-0 h-20 w-20 rounded-full bg-sky-500 text-white text-xl font-semibold
           uppercase flex items-center justify-center"
          >
            <img
              src={`/images/clock_in.gif`}
              alt="profile"
              className="h-20 w-20 rounded-full bg-sky-500 text-white text-xl font-semibold
           uppercase flex items-center justify-center object-cover object-center border-4 border-sky-500"
            />
          </div>
        ) : passStatus === "failed" ? (
          <div
            className="mt-4 shrink-0 h-20 w-20 rounded-full bg-red-600 text-white text-xl font-semibold
           uppercase flex items-center justify-center overflow-hidden p-2 border-4 border-red-400"
          >
            <img
              src={`/images/failure.png`}
              alt="profile"
              className="h-full w-full bg-red-600 text-white text-xl font-semibold
               uppercase flex items-center justify-center object-cover object-center"
            />
          </div>
        ) : (
          <div
            className="mt-4 shrink-0 h-20 w-20 rounded-full bg-sky-500 text-white text-xl font-semibold
               uppercase flex items-center justify-center"
          >
            <img
              src={`/images/location_correct.gif`}
              alt="profile"
              className="h-20 w-20 rounded-full bg-sky-500 text-white text-xl font-semibold
              uppercase flex items-center justify-center object-cover object-center border-4 border-sky-500"
            />
          </div>
        )}

        <h3 className="mt-4 shrink-0 text-xs font-semibold text-sky-500 text-center">
          {clockMOdalOpen?.client?.full_address}
        </h3>

        {/**Clock In BUtton */}
        <button
          onClick={() => {
            clockIn();
          }}
          disabled={passStatus === "pending" || passStatus === "passed"}
          className="mt-6 h-9 w-40 bg-sky-500 hover:bg-sky-700 transition-all text-white
            text-xxs uppercase font-semibold rounded-sm shrink-0 disabled:opacity-70 disabled:cursor-not-allowed"
        >
          {passStatus === "failed"
            ? "retry"
            : passStatus === "pending"
            ? "please wait ..."
            : passStatus === "passed"
            ? "redirecting"
            : "clock in now"}
        </button>
        {/* <button
          disabled={passStatus === "pending" || passStatus === "passed"}
          className="mt-2 h-9 w-40 bg-zinc-50 hover:bg-zinc-100 transition-all text-sky-500
            text-xxs uppercase font-semibold rounded-sm shrink-0 border border-sky-500/30
             disabled:opacity-70 disabled:cursor-not-allowed"
        >
          swap shift
        </button> */}

        {/**Close Modal */}
        <button
          disabled={passStatus === "pending" || passStatus === "passed"}
          onClick={() => {
            setClockModal(null);
            dispatch(setFixedBody(false));
          }}
          className="mt-8 h-9 w-fit flex items-center gap-1 hover:gap-2 transition-all text-sky-500
            text-xss uppercase font-semibold"
        >
          <TbArrowNarrowLeft className="text-xl shrink-0" />{" "}
          <span> cancel</span>
        </button>
      </div>
    </motion.div>
  );
};

export default ClockIn;
