import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavType } from "../app_layout";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Button } from "@/components/ui/button";
import { Cog } from "lucide-react";

const Sidenav = ({ filteredNav }: { filteredNav: NavType[] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );

  //HTML Content ===========================
  return (
    <aside
      className="w-[14rem] h-full shrink-0 hidden bg-white border-r border-zinc-200 lg:flex
       flex-col justify-between gap-3 relative p-5"
    >
      {/**Navigation */}
      <ul className="w-full h-fit flex flex-col">
        {filteredNav.map((nav: NavType) => {
          return (
            <li
              title={nav.name}
              key={nav.name}
              className={`w-full min-h-[2rem] h-fit shrink-0 flex flex-col items-center gap-2 ${
                (nav.path === "/" && location.pathname === "/") ||
                (location.pathname.includes(nav.path) &&
                  location.pathname !== "/" &&
                  nav.path !== "/")
                  ? ``
                  : ``
              } transition-all select-none cursor-pointer relative`}
            >
              <Button
                variant="ghost"
                onClick={() => {
                  navigate(nav.path);
                }}
                className={`${
                  location.pathname === nav.path
                    ? `text-sky-500 after:inset-0 after:absolute after:bg-sky-500/20 after:border-l-4
                     after:border-sky-500 after:rounded`
                    : "text-zinc-600 fill-transparent"
                } w-full h-8 flex items-center justify-start gap-2 relative p-0 px-3 hover:bg-transparent`}
              >
                {nav.icon ? nav.icon : ""}
                <span className="text-xss capitalize font-semibold leading-tight">
                  {nav.name}
                </span>
              </Button>

              {/**Nested Routers */}
              <AnimatePresence>
                {(nav.nested_routes?.length || 0) >= 1 && (
                  <motion.div
                    initial={{ height: 0, opacity: 0.3 }}
                    exit={{ height: 0, opacity: 0.3 }}
                    animate={{ height: "fit-content", opacity: 1 }}
                    transition={{ duration: 0.2 }}
                    className="ml-2 mb-2 w-4/5 h-fit flex flex-col shrink-0 border-l border-zinc-300"
                  >
                    {nav.nested_routes
                      ?.filter(
                        (tab: any) =>
                          !(
                            !session_role?.permissions?.[
                              "can the user view employees"
                            ] && tab.name === "employees"
                          ) &&
                          !(
                            !session_role?.permissions?.[
                              "can the user view employees leave"
                            ] && tab.name === "leave"
                          ) &&
                          !(
                            !session_role?.permissions?.[
                              "can the user view timesheet"
                            ] && tab.name === "timesheet"
                          ) &&
                          !(
                            !session_role?.permissions?.[
                              "can the user view payroll"
                            ] && tab.name === "payroll"
                          ) &&
                          !(
                            !session_role?.permissions?.[
                              "can the user access expenses"
                            ] && tab.name === "expenses"
                          )
                      )
                      ?.map((route: { name: string; path: string }) => {
                        return (
                          <Link
                            key={route.name}
                            to={route.path}
                            className={`w-full h-7 text-xxs capitalize font-semibold  flex
                                items-center px-3 whitespace-nowrap shrink-0 ${
                                  location.pathname === route.path
                                    ? "text-zinc-800 bg-zinc-100 font-bold"
                                    : "text-zinc-500"
                                }`}
                          >
                            {route.name}
                          </Link>
                        );
                      })}
                  </motion.div>
                )}
              </AnimatePresence>
            </li>
          );
        })}
      </ul>

      {/** =========== Settings ============= */}
      <Link
        to="/settings"
        className="w-full h-fit shrink-0 p-2 flex items-center gap-2 text-zinc-600
         rounded-md hover:bg-zinc-50 transition-all"
      >
        <Cog className="h-5 w-5 regular_icon" />
        <span className="text-xs capitalize font-semibold">Settings</span>
      </Link>
    </aside>
  );
};

export default Sidenav;
