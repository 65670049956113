import React from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { ScheduleEventType } from "../../../types/schedule_types.d";
import { months } from "../../../utils/reusable_objects";
import { RiArrowRightUpLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { setFixedBody } from "../../../redux/slices/session_slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ClientType } from "../../../types/client_types";
import { displayLondonTime, encryptData } from "../../../utils/reusable_functions";

type Props = {
  filteredShifts: any[];
  setClockModal: (state: any) => void;
};

const Shifts = ({ filteredShifts, setClockModal }: Props) => {
  const clients_data: ClientType[] = useSelector(
    (state: RootState) => state.lists_data.clients
  );
  const [shiftsContainer] = useAutoAnimate();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Handle Shift Navigation
  const handleShiftNavigation = (shift: any) => {
    const ONE_HOUR_IN_MS = 3600000*3;

    if (!shift) return; // Exit if shift is not available

    // Handle pending shift within 1 hour of start
    if (
      shift?.status === "pending" &&
      (Math.abs(Number(shift?.start_date) - Date.now()) <= ONE_HOUR_IN_MS ||
        shift?.end_date < Date.now())
    ) {
      setClockModal(shift);
      dispatch(setFixedBody(true));
    }

    // Navigate to care profile if already clocked in
    else if (shift?.status === "clocked_in") {
      navigate("/care_profile");
    }

    // Handle other shift statuses or situations here if needed
  };

  //Component
  return (
    <ul
      ref={shiftsContainer}
      className="mt-2 w-full h-full flex flex-col gap-1 overflow-hidden overflow-y-scroll md:pr-2 pb-10"
    >
      {filteredShifts?.map((shift: ScheduleEventType) => {
        return (
          <li
            key={shift?.id}
            className="w-full h-16 p-1 shrink-0 rounded border border-zinc-300 overflow-hidden flex items-center
                 gap-2 bg-white relative hover:shadow transition-all cursor-pointer select-none"
          >
            <div
              className={`h-full w-14 rounded-sm relative shrink-0 border-r border-zinc-200
                 flex flex-col items-center justify-center gap-1 px-3 overflow-hidde ${
                   shift?.status === "swapped"
                     ? "bg-orange-400"
                     : shift?.status === "pending"
                     ? "bg-yellow-400"
                     : shift?.status === "missed"
                     ? "bg-red-400"
                     : shift?.status === "clocked_in"
                     ? "bg-sky-400"
                     : shift?.status === "clocked_out"
                     ? "bg-emerald-400"
                     : "bg-black"
                 }`}
            >
              {shift?.is_late && (
                <div
                  className="h-4 w-fit px-2  md:pt-0 rounded-t bg-red-500 text-white whitespace-nowrap
                    text-[0.5rem] uppercase font-bold !leading-none absolute top-4.5 -left-4 flex items-center justify-center
                    shadow-2xl drop-shadow-2xl rotate-90"
                >
                  late
                </div>
              )}

              <span className="text-lg uppercase font-extrabold text-zinc-100 !leading-none">
                {new Date(shift?.start_date).getDate()}
              </span>
              <span className="text-xss uppercase font-semibold text-white !leading-none">
                {months?.[new Date(shift?.start_date).getMonth()]?.month_string}
              </span>
            </div>
            <div className="flex flex-col w-full overflow-hidden p-2">
              <h1 className="text-xss uppercase font-bold text-zinc-800 whitespace-nowrap truncate">
                {shift?.client?.["first name"] ||
                  shift?.client?.username ||
                  "Unkown"}{" "}
                - {shift?.client?.["last name"]}
              </h1>
              <span className="text-xxs font-mono uppercase font-semibold text-zinc-500 whitespace-nowrap truncate">
                {shift?.title} -{" "}
                {displayLondonTime(shift?.start_date)?.split(" ")?.[1]}{" "}
                <span className="text-sky-600">to</span>{" "}
                {displayLondonTime(shift?.end_date)?.split(" ")?.[1]}
              </span>
            </div>
            <div className="flex items-center p-4 pl-2 overflow-hidden shrink-0">
              {(shift?.status === "pending" ||
                shift?.status === "clocked_in") && (
                  <button
                    key={shift?.id}
                    onClick={() => {
                      const active_client = clients_data?.find(
                        (client: ClientType) => client.id === shift?.client_uid
                      );

                      // Store relevant shift and client data in local storage
                      localStorage.setItem(
                        "client_id",
                        JSON.stringify(shift?.client_uid)
                      );
                      localStorage.setItem(
                        "active_client_doc",
                        JSON.stringify(active_client)
                      );
                      localStorage.setItem(
                        "active_client_doc",
                        JSON.stringify(
                          encryptData(
                            active_client,
                            import.meta.env.VITE_APP_CHECK_KEY
                          )
                        )
                      );
                      localStorage.setItem("shift_id", JSON.stringify(shift));

                      // Handle shift based on status and timing
                      handleShiftNavigation(shift);
                    }}
                    className="h-9 w-9 bg-cream hover:opacity-80 rounded-full font-semibold uppercase
                   text-xs text-sky-500 shrink-0 flex items-center justify-center border border-sky-500/50"
                  >
                    <RiArrowRightUpLine className="text-xl" />
                  </button>
                )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default React.memo(Shifts);
