import React from "react";
import { TbArrowLeft } from "react-icons/tb";
import { motion } from "framer-motion";
import { ScheduleEventType } from "../../../../types/schedule_types";
import { displayLondonTime } from "../../../../utils/reusable_functions";

type Props = {
  debouncedSetActiveClient: any;
  shifts: ScheduleEventType[];
};

const Shifts = ({ debouncedSetActiveClient, shifts }: Props) => {
  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%" }}
      className="absolute inset-0 z-10 bg-white p-2 overflow-hidden flex flex-col gap-4"
    >
      <button
        onClick={() => debouncedSetActiveClient("")}
        className="h-10 w-10 rounded-full hover:opacity-80 bg-zinc-50 border border-zinc-200 
         flex items-center justify-center text-lg text-zinc-800 transition-all shrink-0"
      >
        <TbArrowLeft />
      </button>

      {/**Shifts */}
      <ul className="w-full h-full flex flex-col overflow-hidden overflow-y-scroll no-scrollbar">
        {shifts?.map((data: ScheduleEventType) => {
          return (
            <li
              key={data.id}
              className={`w-full h-14 shrink-0 hover:bg-sky-50 flex items-center justify-between gap-2
                  px-2 cursor-pointer border-b border-zinc-200 transition-all`}
            >
              <img
                onError={(e) => {
                  if (e?.currentTarget) {
                    e.currentTarget.src = "/images/default_profile.png";
                  }
                }}
                src={
                  data?.assigned_to?.profile || "/images/default_profile.png"
                }
                alt="pro"
                className="h-9 w-9 rounded-full shrink-0 bg-zinc-50 object-cover object-center border border-sky-500"
              />
              <div className="w-full flex flex-col overflow-hidden">
                <span className="text-xs font-bold text-zinc-800 whitespace-nowrap truncate">
                  {data?.assigned_to?.username}
                </span>
                <span className="text-xxs text-zinc-500 whitespace-nowrap truncate uppercase">
                  {displayLondonTime(data?.start_date)} -{" "}
                  {displayLondonTime(data?.end_date)?.split(" ")[1]}{" "}
                  <strong>
                    {data?.status.replace(/_/gim, " ") || "Unkonwn status"}
                  </strong>
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </motion.div>
  );
};

export default React.memo(Shifts);
