import React, { useEffect } from "react";

const CurrentTime = () => {
  const [minutePercentage, setMinutePercentage] = React.useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const percentage = ((minutes * 60 + seconds) / (60 * 60)) * 100;
      setMinutePercentage(percentage);
    }, 1000); // Update every second

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  //HTML Content ========================
  return (
    <div
      style={{ top: `${minutePercentage}%` }}
      className="absolute left-20 right-0 h-0.5 z-10 bg-sky-400 shadow-2xl drop-shadow-2xl"
    ></div>
  );
};

export default CurrentTime;
