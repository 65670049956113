import { RiSearch2Line, RiUserAddFill } from "react-icons/ri";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useNavigate } from "react-router-dom";
import { EmployeeType } from "../../../types/employee";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Select } from "@mantine/core";
import Invites from "./invites";
import { HiChevronRight } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Card } from "@/components/ui/card";
import { capitalize } from "moderndash";

type Props = {
  filteredUsers: EmployeeType[];
  search: string;
  setSearch: (state: string) => any;
};

const EmployeesList = ({ filteredUsers, search, setSearch }: Props) => {
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const [active_option, setMode] = React.useState<
    "active" | "archived" | "invites"
  >("active");
  const preped_employee = React.useMemo(() => {
    const preped = filteredUsers?.filter((data: EmployeeType) =>
      active_option === "archived" ? data.status === "archived" : true
    );
    return preped;
  }, [filteredUsers, active_option]);
  const navigate = useNavigate();
  const [parent] = useAutoAnimate();

  //Component ===================
  return (
    <div className="w-full h-fit shrink-0 flex flex-col overflow-hidden gap-2">
      <div className="w-full h-fit shrink-0 flex items-center gap-2 overflow-hidden">
        {/**Search ====== */}
        <label
          htmlFor="menu_search"
          className="h-10 w-full bg-white rounded-md border border-zinc-300 focus-within:border-sky-500/40
         transition-all flex items-center justify-between pl-3"
        >
          <RiSearch2Line className="text-xl shrink-0 text-zinc-400" />
          <input
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            placeholder="Who are you looking for ?"
            type="search"
            name="menu_search"
            id="menu_search"
            className="w-full h-full bg-transparent text-xs text-zinc-700 placeholder:text-zinc-500 border-0"
          />
        </label>

        {/**Pending Requests */}
        <Select
          onChange={(e: any) => {
            setMode(e as "active" | "archived" | "invites");
          }}
          data={["active", "invites", "archived"]}
          value={active_option}
          required
          autoComplete="off"
          name="medicine_name"
          id="medicine_name"
          placeholder="Pick medicine ..."
          className="rounded-md border border-zinc-300 bg-white text-xs font-medium text-zinc-800
           w-32 h-full placeholder:text-zinc-400 shrink-0"
          classNames={{
            input: `!border-0 !text-xs !text-zinc-600 !capitalize !h-full`,
            option: `!capitalize`,
            wrapper: `!h-full`,
          }}
        />
      </div>

      {active_option !== "invites" ? (
        <AnimatePresence>
          {/**Employees Lists  */}
          {preped_employee?.length >= 1 ? (
            <motion.ul
              layout
              ref={parent}
              className="mt-2 w-full h-fit shrink-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4
                2xl:grid-cols-5 gap-1.5 overflow-hidden"
            >
              {preped_employee?.map(
                (employee: EmployeeType | any, index: number) => {
                  return (
                    <Card
                      onClick={() => {
                        navigate(
                          `/people/employee/${employee?.["first name"]}?id=${employee?.id}`
                        );
                      }}
                      key={index}
                      className="w-full h-16 px-3 flex items-center  gap-3 shrink-0 overflow-hidden
                       hover:cursor-pointer hover:shadow-lg"
                    >
                      <div className="h-full w-full flex items-center gap-2 overflow-hidden">
                        <img
                          onError={(e) => {
                            if (e.currentTarget) {
                              e.currentTarget.src =
                                "/images/default_profile.png";
                            }
                          }}
                          src={
                            employee?.["professional photo"] ||
                            "/images/default_profile.png"
                          }
                          alt="profile"
                          className={`h-10 w-10 rounded-full bg-sky-500 border-2 object-center
                            object-cover shrink-0 ${
                              employee?.status === "approved"
                                ? "border-green-500"
                                : employee?.status === "pending"
                                ? "border-yellow-500"
                                : "border-red-500"
                            } `}
                        />
                        <div className="w-full flex flex-col gap-1.5 overflow-hidden py-0.5">
                          <span
                            className="text-xss font-bold text-zinc-700 whitespace-nowrap truncate
                             capitalize !leading-none shrink-0"
                          >
                            {capitalize(
                              `${employee?.["first name"]} ${employee?.["last name"]}`
                            )}
                          </span>
                          <span
                            className="text-xss font-medium text-zinc-500 whitespace-nowrap truncate
                             lowercase !leading-none shrink-0 pb-0.5"
                          >
                            {employee?.email}
                          </span>
                        </div>
                      </div>
                      <HiChevronRight className="shrink-0 text-zinc-300 text-xl" />
                    </Card>
                  );
                }
              )}
            </motion.ul>
          ) : (
            <div
              className="w-full h-full bg-gradient-to-b from-white to-transparent rounded-t border
             border-zinc-200 border-b-0 p-6 flex flex-col items-center justify-center"
            >
              <div
                className="flex items-center justify-center w-fit h-fit hover:gap-1
              transition-all cursor-pointer ml-12"
              >
                {Array.from({ length: 3 }).map((_, i) => {
                  return (
                    <div
                      key={i}
                      className="h-24 even:h-32 w-24 -ml-12 bg-white rounded shadow-sm even:shadow-lg even:shadow-sky-600/30
               p-3 -rotate-[9deg] even:rotate-0 even:z-10 last:rotate-[9deg]"
                    >
                      <RiUserAddFill className="text-xl text-zinc-200 shrink-0" />
                      <div className="mt-1 h-1 w-8 rounded-full bg-zinc-200" />
                      <div className="mt-2 h-1 w-12 rounded-full bg-zinc-200" />
                      {Array.from({ length: 4 }).map((_, i) => (
                        <div
                          key={i}
                          className="mt-1 h-1 w-full last:w-3/5 rounded-full bg-zinc-200"
                        />
                      ))}
                    </div>
                  );
                })}
              </div>
              <h1 className="mt-7 text-base font-semibold text-zinc-700 text-center">
                Add employees
              </h1>
              <h2 className="mt-2 text-center max-w-[20rem] text-xs font-medium text-zinc-500">
                There are no employees to display, add employees to get started
              </h2>
            </div>
          )}
        </AnimatePresence>
      ) : (
        <>
          {session_role?.permissions?.["can the user manage employees"] && (
            <Invites search={search} />
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(EmployeesList);
