import { PiChartBarFill } from "react-icons/pi";

const EmptyStateV1 = () => {
  return (
    <div
      className="w-full h-full rounded bg-zinc-50/50 border border-zinc-200
       border-dashed flex flex-col items-center justify-center gap-1"
    >
      <PiChartBarFill className="text-3xl text-zinc-500" />
      <span className="text-xs font-semibold text-zinc-800 text-center">
        No data to show
      </span>
      <p className="text-xss text-zinc-500 max-w-[20rem] text-center">
        Please make sure your filter or date range is within the correct period
      </p>
    </div>
  );
};

export default EmptyStateV1;
