import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { motion } from "framer-motion";
import { retrieveLocalData } from "../../../utils/reusable_functions";
import { RoleType } from "../../../types/session_type";
import Stats from "./stats/stats";
import RecentIncidents from "./recent_incidents";
import Tasks from "./tasks";
import { circleVariants } from "../../../utils/reusable_objects";

const date = retrieveLocalData("client_summary_date") || {
  start: Date.now() - 86000000,
  end: Date.now() + 86000000,
};

const Summary = () => {
  const session_role: RoleType = useSelector(
    (state: RootState) => state.session_data?.session_role
  );

  //Component ====================
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={circleVariants}
      className="relative w-full h-fit shrink-0 flex flex-col overflow-hidden"
    >
      {/**Stats */}
      <Stats date={date} />

      {/**Meds and Body Map */}
      {/* <div className="mt-4 w-full h-fit flex items-stretch gap-4">
        <RecentBodyMaps date={date} />
        <div className="w-3/5 bg-white border border-zinc-200 rounded"></div>
      </div> */}

      {/**Incidents */}
      {session_role?.permissions?.["can the user view incidetns"] && (
        <RecentIncidents />
      )}

      {/** ============== Tasks =============== */}
      {session_role?.permissions?.["can the user view client's tasks"] && (
        <Tasks />
      )}
    </motion.div>
  );
};

export default React.memo(Summary);
