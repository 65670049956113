import React, { useMemo } from "react";
import { capitalize } from "moderndash";
import { TaksLogsType, TaskType } from "../../../types/care_types";
import { dateFromLondonMilliseconds } from "../../../utils/reusable_functions";
import { useNavigate } from "react-router-dom";

type Props = {
  task: TaskType;
  setTask: any;
  date: { start: number; end: number };
  tasks_logs: TaksLogsType[];
};

const Task = ({ task, setTask, date, tasks_logs }: Props) => {
  const navigate = useNavigate();
  const completedLog = useMemo(() => {
    return [...(tasks_logs || [])]?.find(
      (log: TaksLogsType) =>
        log?.task_id === task.id &&
        dateFromLondonMilliseconds(date.start).getDate() ===
          dateFromLondonMilliseconds(log.date).getDate()
    );
  }, [date.start, tasks_logs]);

  //Component =============
  return (
    <>
      <div
        onClick={() => {
          setTask ? setTask(task) : navigate("/care_profile/tasks");
          localStorage.setItem("active_task_log", JSON.stringify(completedLog));
        }}
        className={`w-[49%] md:w-full min-w-[45%] md:min-w-[10rem] max-w-[12rem] h-full relative cursor-pointer
          rounded border border-dashed p-1 pl-5 flex items-center gap-2 overflow-hidden ${
            completedLog
              ? "bg-green-500/30 border-green-500 after:bg-green-500"
              : "bg-yellow-500/30 border-yellow-500 after:bg-yellow-500"
          } after:absolute after:left-1 after:top-1 after:bottom-1 after:w-1 after:rounded-full`}
      >
        <h2 className="text-xs font-bold text-zinc-800 w-full shrink-0 text-left whitespace-nowrap truncate">
          {capitalize(task?.task || "")}
        </h2>
      </div>
    </>
  );
};

export default React.memo(Task);
