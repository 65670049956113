import { useState, useEffect } from "react";
import { TbReload } from "react-icons/tb";

function ErrorBoundary(props: any) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setMessage] = useState("Something went wrong");

  useEffect(() => {
    const handleGlobalError = (event: any) => {
      // Handle the error here
      console.error("Global error caught:", event.error);
      setHasError(true);
    };
    window.addEventListener("error", handleGlobalError);
    return () => {
      window.removeEventListener("error", handleGlobalError);
    };
  }, []);

  useEffect(() => {
    const handleGlobalError: any = (error: any, errorInfo: any) => {
      // Log the error to an error reporting service
      console.error("Global error caught:", error, errorInfo);
      setMessage(
        "Error: Data has been collected to help troubleshoot and prevent the error from happening again"
      );
      // Update state to show the fallback UI
      setHasError(true);
    };

    window.addEventListener("error", handleGlobalError);
    return () => {
      window.removeEventListener("error", handleGlobalError);
    };
  }, []);

  // Render the children as normal
  return hasError && !window.location.toString()?.includes("localhost") ? (
    <div className="w-screen h-screen flex items-center justify-center flex-col bg-light-bg">
      <img
        src="/images/error_bound.png"
        alt="erro iamg"
        className="h-20 w-20"
      />
      <h1 className="mt-4 font-bold text-black-600">Something went wrong</h1>
      <p className="mt-1 text-xs font-medium text-black-600 text-center max-w-[25rem] px-4">
        {errorMessage}{" "}
      </p>
      <button
        onClick={() => {
          window.location.reload();
        }}
        className="mt-4 h-9 w-36 bg-sky-800 rounded-full text-white text-xs font-semibold capitalize
         flex items-center justify-center gap-3 hover:opacity-80 transition-all hover:ring-2 ring-offset-2
         ring-sky-600"
      >
        <TbReload className="text-lg" /> <span>reload</span>
      </button>
    </div>
  ) : (
    props.children
  );
}

export default ErrorBoundary;
