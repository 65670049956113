import { ClientType } from "../../types/client_types";
import {
  encryptData,
} from "../../utils/reusable_functions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import React from "react";
import { CareDocType } from "../../types/care_types";
import { HiChevronRight } from "react-icons/hi2";
import { Card } from "@/components/ui/card";
import { capitalize } from "moderndash";

type Props = {
  client: ClientType;
};

const Client = ({ client }: Props) => {
  const navigate = useNavigate();
  const clients_data = useSelector(
    (state: RootState) => state.care_data.care_docs
  );

  //JSX Code ====================
  return (
    <Card
      onClick={() => {
        localStorage.setItem("client_id", JSON.stringify(client?.id));
        localStorage.setItem(
          "active_client_doc",
          JSON.stringify(
            encryptData(client, import.meta.env.VITE_APP_CHECK_KEY)
          )
        );
        const care_doc = (clients_data || [])?.find(
          (data: CareDocType) => data?.id === client?.id
        );
        localStorage.setItem(
          "active_client_care_doc",
          JSON.stringify(encryptData(care_doc, client.id as string))
        );
        //Route
        //`/care_profile/${client?.id}`
        navigate(`/care_profile`);
      }}
      className="w-full h-16 shronk-0 px-3 flex items-center gap-3 overflow-hidden
       cursor-pointer  hover:shadow-md transition-all relative shrink-0"
    >
      <img
        onError={(e) => {
          if (e.currentTarget) {
            e.currentTarget.src = "/images/default_profile.png";
          }
        }}
        src={client?.profile || "/images/default_profile.png"}
        alt="profile"
        className={`h-10 w-10 shrink-0 rounded-full border-2 ${
          client?.status === "active" ? "border-sky-500" : "border-yellow-500"
        }  bg-zinc-100 object-center object-cover`}
      />
      <div className="w-full flex flex-col overflow-hidden relative">
        <span className="text-xs font-bold text-zinc-700 whitespace-nowrap truncate capitalize">
          {capitalize(`${client?.["first name"]} ${client?.["last name"]}`)}
        </span>
        <span className="text-xxs font-semibold text-zinc-500 whitespace-nowrap truncate">
          {client?.full_address}
        </span>
      </div>
      <HiChevronRight className="shrink-0 text-zinc-300 text-xl" />
    </Card>
  );
};

export default React.memo(Client);
