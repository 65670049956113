import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LeaveDataType } from "../../types/leave_types";

interface InitialStateType {
  active_leave_preview: LeaveDataType | null;
  all_leaves: LeaveDataType[];
}

const initialState: InitialStateType = {
  active_leave_preview: null,
  all_leaves: [],
};

export const LeaveSlice = createSlice({
  name: "leave_data",
  initialState,
  reducers: {
    setLeavePreview: (
      state,
      action: PayloadAction<LeaveDataType | null>
    ) => {
      state.active_leave_preview = action.payload;
    },
    loadAllLeaves: (
      state,
      action: PayloadAction<LeaveDataType[] | []>
    ) => {
      state.all_leaves = action.payload;
    },
  },
});

export const { setLeavePreview,loadAllLeaves } = LeaveSlice.actions;

export default LeaveSlice.reducer;
