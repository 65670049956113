import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { decrypt } from "../../utils/reusable_functions";
import { RoleType } from "../../types/session_type";

export const retriveObjectLocal = (name: string) => {
  const data = window.localStorage.getItem(name);

  try {
    const decryptedData = decrypt(
      import.meta.env.VITE_API_KEY,
      JSON.parse(data || "") as string
    );

    if (!decryptedData) return null;

    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Error parsing JSON or decrypting data:", error);
    return null;
  }
};

interface InitialStateType {
  user: any;
  users: any[];
  session_role: RoleType | any;
  all_roles: RoleType[] | [];
  isFixed: boolean;
  system_data: any[];
}

const initialState: InitialStateType = {
  user: null,
  users: [],
  session_role: null,
  all_roles: [],
  isFixed: false,
  system_data: [],
};

export const SessionSlice = createSlice({
  name: "session_data",
  initialState,
  reducers: {
    updateUserData: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    loadRole: (state, action: PayloadAction<any>) => {
      state.session_role = action.payload;
    },
    loadAllRoles: (state, action: PayloadAction<any>) => {
      state.all_roles = action.payload;
    },
    setFixedBody: (state, action: PayloadAction<boolean>) => {
      state.isFixed = action.payload;
    },
    loadAllUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
    loadSystemSettings: (state, action: PayloadAction<any>) => {
      state.system_data = action.payload;
    },
  },
});

export const {
  updateUserData,
  setFixedBody,
  loadAllRoles,
  loadRole,
  loadAllUsers,
  loadSystemSettings
} = SessionSlice.actions;

export default SessionSlice.reducer;
