import React from "react";

import { UserCog, Users, ShieldCheck, Fingerprint } from "lucide-react";
import SubMenuTemplate from "../sub-menus-template/sub-menu-template";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

const SettingsLayout = () => {
  const session_role = useSelector(
    (state: RootState) => state.session_data?.session_role
  );
  const options = [
    {
      name: "account",
      path: "/settings",
      description: "Manage your account",
      icon: <UserCog className="regular_icon" />,
    },
    {
      name: "security",
      path: "/settings/security",
      description: "Manage your security",
      icon: <ShieldCheck className="regular_icon" />,
    },
    {
      name: "users",
      path: "/settings/users",
      description: "Manage your users",
      icon: <Users className="regular_icon" />,
    },
    {
      name: "roles",
      path: "/settings/roles_permissions",
      description: "Manage your roles",
      icon: <Fingerprint className="regular_icon" />,
    },
  ].filter((link) => {
    if (link.name === "roles" && !session_role?.permissions?.["can the user view and manage roles and permissions"]) {
      return false;
    }
    if (link.name === "users" && !session_role?.permissions?.["can the user view and manage users auth accounts"]) {
      return false;
    }
    return true;
  });

  //JSX Code ========================
  return (
    <React.Fragment>
      <SubMenuTemplate options={options} />
    </React.Fragment>
  );
};

export default SettingsLayout;
