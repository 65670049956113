import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateType {
  master_lists_data: any;
  custom_lists_data: any;
  companies_list: any;
  clients: any;
  clients_data: any;
}

const initialState: InitialStateType = {
  master_lists_data: null,
  custom_lists_data: null,
  companies_list: null,
  clients: [],
  clients_data:null,
};

export const ListSlice = createSlice({
  name: "lists_data",
  initialState,
  reducers: {
    updateLocalMasterLists: (state, action: PayloadAction<any>) => {
      state.master_lists_data = action.payload;
    },
    updateLocalOptionalList: (state, action: PayloadAction<any>) => {
      state.custom_lists_data = action.payload;
    },
    updateLocalCompanies: (state, action: PayloadAction<any>) => {
      state.companies_list = action.payload;
    },
    updateLocalClients: (state, action: PayloadAction<any>) => {
      state.clients = action.payload;
    },
    loadClientsData: (state, action: PayloadAction<any>) => {
      state.clients_data = action.payload;
    },
  },
});

export const {
  updateLocalMasterLists,
  updateLocalOptionalList,
  updateLocalCompanies,
  updateLocalClients,
  loadClientsData
} = ListSlice.actions;

export default ListSlice.reducer;
