import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  limit as firestoreLimit,
  QueryConstraint,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase/firebase_config";
import { useAuth } from "./useAuth";

const useFirestoreCollection = (
  collectionName: string,
  limit: number,
  dep?: any,
  queryConstraints: QueryConstraint[] = [],
  dep_array: any[] = [],
  liveUpdates: boolean = false // New parameter for live updates
) => {
  const { currentUser }: any = useAuth();
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dep && currentUser?.uid) {
      const collectionRef = collection(db, collectionName);
      let constraints: QueryConstraint[] = [...queryConstraints];

      if (limit <= 10000) {
        constraints = [...constraints, firestoreLimit(limit)];
      }

      const q = query(collectionRef, ...constraints);

      if (liveUpdates) {
        // Using onSnapshot for real-time updates
        const unsubscribe = onSnapshot(
          q,
          (querySnapshot) => {
            const documents: any = [];
            querySnapshot.forEach((doc) => {
              documents.push({ ...doc.data(), id: doc.id });
            });
            setData(documents);
          },
          (err) => {
            setError(err);
          }
        );

        // Cleanup subscription on unmount
        return () => {
          unsubscribe();
        };
      } else {
        // One-time fetch
        (async () => {
          setLoading(true);
          try {
            const querySnapshot = await getDocs(q);
            const documents: any = [];
            querySnapshot.forEach((doc) => {
              documents.push({ ...doc.data(), id: doc.id });
            });
            setData(documents);
          } catch (err: any) {
            setError(err);
            console.error("Error fetching collection:", err);
          } finally {
            setLoading(false);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.uid, collectionName, limit, dep, ...(dep_array || [])]);

  return { data, error, loading };
};

export default useFirestoreCollection;
