import React, { useMemo } from "react";
import { motion } from "framer-motion";
import { Select } from "@mantine/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { EmployeeType } from "../../../types/employee";
import { useClickOutside } from "@mantine/hooks";

type FiltersType = {
  client_id: string;
  "position applied for": string;
  employee: string;
  status: string;
};

type Props = {
  openFilters: (state: boolean) => any;
  filtersData: FiltersType;
  setFilters: (status: FiltersType | any) => any;
};

const Filters = ({ openFilters, filtersData, setFilters }: Props) => {
  const drawerRef = useClickOutside(() => openFilters(false));
  //const clients = useSelector((state: RootState) => state.lists_data.clients);
  const employees_docs = useSelector(
    (state: RootState) => state.people_data.employees_docs
  );

  const positions = useMemo(() => {
    return [
      ...new Set(
        [...(employees_docs || [])]?.map(
          (emp: EmployeeType) => emp?.["position applied for"]
        )
      ),
    ];
  }, [employees_docs]);

  //Component =============
  return (
    <motion.div
      initial={{ left: "-100%", right: "100%" }}
      exit={{ left: "-100%", right: "100%" }}
      animate={{ left: 0, right: 0 }}
      className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 backdrop-blur-sm
       flex items-center justify-start z-[999999]"
    >
      <div
        ref={drawerRef}
        className="h-full w-full md:w-[30rem] bg-cream flex flex-col justify-between p-6 overflow-hidden"
      >
        <div className="w-full h-full flex flex-col gap-4">
          <h1 className="text-sm font-extrabold uppercase text-zinc-600">
            all filters
          </h1>

          {/**By Client */}
          {/* <fieldset
            className="w-full min-h-[4rem] h-fit px-2 shrink-0 overflow-hidden rounded-sm border border-zinc-300
            focus-within:border-sky-600/70 transition-all bg-white pt-1"
          >
            <legend className="text-xss uppercase text-zinc-500 font-bold px-2 w-fit">
              Client / House
            </legend>
            <Select
              onChange={(e) => {
                setFilters((prev: FiltersType) => ({
                  ...prev,
                  client_id: e || "",
                }));
              }}
              value={filtersData?.client_id || ""}
              data={clients?.map((client: ClientType) => ({
                label: `${client?.["first name"]} ${client?.["last name"]}`,
                value: client?.id,
              }))}
              placeholder={"Select client"}
              nothingFoundMessage="No results found"
              searchable
              clearable
              classNames={{
                input: `!border-none !bg-inherit !h-full w-full shrink-0 rounded-none border-0 uppercase !text-xxs 
                          text-zinc-700 placeholder:text-zinc-400 font-medium bg-transparent`,
                wrapper:
                  "!uppercase w-full !h-fit !min-h-full !border-none !text-xxs ",
                dropdown:
                  "!text-xxs !bg-white uppercase !font-medium !text-zinc-800 !text-xxs ",
                option: `!text-xss !font-bold`,
              }}
            />
          </fieldset> */}

          {/**By Department */}
          <fieldset
            className="w-full min-h-[4rem] h-fit px-2 shrink-0 overflow-hidden rounded-sm border border-zinc-300
            focus-within:border-sky-600/70 transition-all bg-white pt-1"
          >
            <legend className="text-xss uppercase text-zinc-500 font-bold px-2 w-fit">
              Department / role
            </legend>
            <Select
              onChange={(e) => {
                setFilters((prev: FiltersType) => ({
                  ...prev,
                  "position applied for": e || "",
                }));
              }}
              value={filtersData?.["position applied for"] || ""}
              data={positions}
              placeholder={"Select role"}
              nothingFoundMessage="No results found"
              searchable
              clearable
              classNames={{
                input: `!border-none !bg-inherit !h-full w-full shrink-0 rounded-none border-0 uppercase !text-xxs 
                          text-zinc-700 placeholder:text-zinc-400 font-medium bg-transparent`,
                wrapper:
                  "!uppercase w-full !h-fit !min-h-full !border-none !text-xxs ",
                dropdown:
                  "!text-xxs !bg-white uppercase !font-medium !text-zinc-800 !text-xxs ",
                option: `!text-xss !font-bold`,
              }}
            />
          </fieldset>

          {/**By Employee */}
          <fieldset
            className="w-full min-h-[4rem] h-fit px-2 shrink-0 overflow-hidden rounded-sm border border-zinc-300
            focus-within:border-sky-600/70 transition-all bg-white pt-1"
          >
            <legend className="text-xss uppercase text-zinc-500 font-bold px-2 w-fit">
              Employee
            </legend>
            <Select
              onChange={(e) => {
                setFilters((prev: FiltersType) => ({
                  ...prev,
                  employee: e || "",
                }));
              }}
              value={filtersData?.employee || ""}
              data={employees_docs?.map((employee: any) => ({
                label: `${employee?.["first name"]} ${employee?.["last name"]}`,
                value: employee?.id,
              }))}
              placeholder={"Select an employee"}
              nothingFoundMessage="No results found"
              searchable
              clearable
              classNames={{
                input: `!border-none !bg-inherit !h-full w-full shrink-0 rounded-none border-0 uppercase !text-xxs 
                          text-zinc-700 placeholder:text-zinc-400 font-medium bg-transparent`,
                wrapper:
                  "!uppercase w-full !h-fit !min-h-full !border-none !text-xxs ",
                dropdown:
                  "!text-xxs !bg-white uppercase !font-medium !text-zinc-800 !text-xxs ",
                option: `!text-xss !font-bold`,
              }}
            />
          </fieldset>

          {/**By Status */}
          <fieldset
            className="w-full min-h-[4rem] h-fit px-2 shrink-0 overflow-hidden rounded-sm border border-zinc-300
            focus-within:border-sky-600/70 transition-all bg-white pt-1"
          >
            <legend className="text-xss uppercase text-zinc-500 font-bold px-2 w-fit">
              status
            </legend>
            <Select
              onChange={(e) => {
                setFilters((prev: FiltersType) => ({
                  ...prev,
                  status: e || "",
                }));
              }}
              value={filtersData?.status || ""}
              data={["approved", "pending", "inactive"]}
              placeholder={"Select employee status"}
              nothingFoundMessage="No results found"
              searchable
              clearable
              classNames={{
                input: `!border-none !bg-inherit !h-full w-full shrink-0 rounded-none border-0 uppercase !text-xxs 
                          text-zinc-700 placeholder:text-zinc-400 font-medium bg-transparent`,
                wrapper:
                  "!uppercase w-full !h-fit !min-h-full !border-none !text-xxs ",
                dropdown:
                  "!text-xxs !bg-white uppercase !font-medium !text-zinc-800 !text-xxs ",
                option: `!text-xss !font-bold`,
              }}
            />
          </fieldset>
        </div>

        {/**Bottom Options */}
        <div className="w-full h-12 flex items-center justify-between gap-2">
          <button
            onClick={() => openFilters(false)}
            type="button"
            className="hover:opacity-80 transition-all bg-sky-500 text-white font-bold text-xs  h-full
             w-full uppercase rounded"
          >
            close
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default React.memo(Filters);
