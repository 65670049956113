import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InventoryItemType } from "../../types/inventory_types";


interface InitialStateType {
  inventory_items: InventoryItemType[]|[];
}

const initialState: InitialStateType = {
    inventory_items:[]
};

export const InventorySlice = createSlice({
  name: "inventory_data",
  initialState,
  reducers: {
    loadInventoryItems: (state, action: PayloadAction<any>) => {
      state.inventory_items = action.payload;
    },
  },
});

export const {
    loadInventoryItems
} = InventorySlice.actions;

export default InventorySlice.reducer;
